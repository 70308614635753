import React from "react";
import { useState, useEffect } from "react";
import TitleH1 from "../components/elements/titles/TitleH1";
import TableNotifications from "./TableNotifications";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import { PATH_IMG } from "../../../constants/constant";
import "./index.css";
import { useAuth } from "../../../providers";
import { usersInterface } from "../../../types/adminManagementPages";
import { fetchGenAiExpenses } from "../AdminManagementsUsers/api/userApi";
import ModalLoading from "../components/modals/ModalLoading";

export default function AdminManagementsNotifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [endLoading, setEndLoading] = useState(false);
  const [navigationPage, setNavigationPage] = useState("GenAI");
  const [allNotificationsGenAi, setAllNotificationsGenAi] = useState<any[]>([]);
  const [allNotificationsPermissions, setAllNotificationsPermissions] =
    useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [genAiExpenses, setGenAiExpenses] = React.useState<
    Record<string, number>
  >({});
  const [usersAskedPemissions, setUsersAskedPemissions] = useState<
    usersInterface[]
  >([]);

  const handleNotificationsPermissions = (
    permission: any,
    permissionAfterCancel: any,
    permissionAsked: any,
    type: string
  ) => {
    console.log("permission", permission);
    console.log("permissionAfterCancel", permissionAfterCancel);
    console.log("permissionAsked", permissionAsked);
    console.log("type", type);
    const updatedNotifications = allNotificationsPermissions.map(
      (notification) => {
        if (
          notification.askedBy === permissionAsked.askedBy &&
          notification.idVINCI === permissionAsked.idVINCI &&
          notification.actionType === permissionAsked.actionType
        ) {
          const perm = JSON.parse(notification.permissions);

          if (perm[type]?.length > 0) {
            // On filtre toutes les permissions sauf celle à supprimer
            perm[type] = perm[type].filter((p: any) => {
              if (type === "dashboard") {
                console.log("p1", p);
                console.log("permission", permission);
                return !(
                  p.business_unit === permission.business_unit &&
                  p.airport === permission.airport &&
                  p.dashboard_level === permission.dashboard_level &&
                  p.dashboard === permission.dashboard
                );
              }
              console.log("p2", p);
              return true; // ne modifie rien si ce n'est pas dashboard
            });
            console.log("perm", perm);
          }

          return {
            ...notification,
            permissions: JSON.stringify(perm), // on ré-encode le JSON
          };
        }

        return notification; // on retourne tel quel sinon
      }
    );

    console.log("updatedNotifications", updatedNotifications);
    setAllNotificationsPermissions(updatedNotifications);
  };

  const itemsPerPage = 8;

  const { token } = useAuth();

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNavigation = (value: string) => {
    setNavigationPage(value);
    if (value === "GenAI") {
      setTotalItems(allNotificationsGenAi.length);
    } else {
      setTotalItems(usersAskedPemissions.length);
    }
    currentPage !== 1 && setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleRemoveNotificationGenAi = (idVINCI: string) => {
    const newNotifications = allNotificationsGenAi.filter(
      (notification) => notification.idVINCI !== idVINCI
    );
    setAllNotificationsGenAi(newNotifications);
  };

  const getInfosNotificationPermissions = async () => {
    try {
      const response = await fetch(
        "/api/admin/permissions_management/get_access_requests",
        { method: "GET", headers: { Authorization: `Bearer ${token}` } }
      );
      if (!response.ok) {
        throw new Error("Erreur de récupération des demandes de permissions.");
      }
      const data = await response.json();
      return data.requests;
    } catch (error) {
      console.error("Erreur dans getInfosNotificationPermissions:", error);
      return [];
    }
  };

  const getInfosNotificationGenAi = async () => {
    try {
      const response = await fetch(
        "/api/admin/credits_management/get_all_credits_requests",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Erreur de récupération des crédits.");
      }

      const data = await response.json();
      return data.requests; // Ensure the function returns the fetched data
    } catch (error) {
      console.error("Erreur dans getInfosNotificationGenAi:", error);
      return []; // Return an empty array in case of an error
    }
  };

  const getUsersByIds = async (
    idsVinciList: string[]
  ): Promise<usersInterface[]> => {
    try {
      const response = await fetch(
        "/api/admin/user_management/get_users_by_ids",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ids_vinci: idsVinciList,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des utilisateurs.");
      }

      interface UserPayload {
        upns: usersInterface[];
      }
      const data: UserPayload = await response.json();
      return data.upns;
    } catch (error) {
      console.error("Erreur dans getUsersByIds:", error);
      return [];
    }
  };

  const getGenAiExpenses = async (
    user: usersInterface,
    newGenAiExpenses: Record<string, number>
  ) => {
    try {
      const result = await fetchGenAiExpenses(
        "/user_management/get_current_month_consumption/",
        user.idVINCI,
        token
      );

      if (result.amount === undefined || result.amount === null) {
        throw new Error("Format de données inattendu.");
      }

      const creditLimit =
        user.type === "Standard"
          ? 5000
          : user.type === "Premium"
          ? 10000
          : 15000;

      const remaining = creditLimit - Math.round(result.amount);

      setGenAiExpenses((prev) => ({
        ...prev,
        [user.idVINCI]: remaining,
      }));
      newGenAiExpenses[user.idVINCI] = remaining;
    } catch (err) {
      const fallbackAmount =
        user.type === "Standard"
          ? 5000
          : user.type === "Premium"
          ? 10000
          : 15000;

      setGenAiExpenses((prev) => ({
        ...prev,
        [user.idVINCI]: fallbackAmount,
      }));
      newGenAiExpenses[user.idVINCI] = fallbackAmount;
      if (err instanceof Error) console.log(err.message);
    }
  };

  const notificationsPemissions = async () => {
    const notifications = await getInfosNotificationPermissions();
    const askedByIdList = notifications
      .filter(
        (item: any, index: number, self: any) =>
          index === self.findIndex((obj: any) => obj.askedBy === item.askedBy)
      )
      .map((item: any) => item.askedBy);
    const users = await getUsersByIds(askedByIdList);
    setUsersAskedPemissions(users);
    setAllNotificationsPermissions(notifications);
  };

  const notificationsGenAi = async () => {
    const notifications = await getInfosNotificationGenAi();
    const idsVinciList: string[] = notifications.map((n: any) => n.idVINCI);
    if (idsVinciList.length === 0) return;
    const users = await getUsersByIds(idsVinciList);
    const newGenAiExpenses: Record<string, number> = {};
    await Promise.all(
      users.map((user) => getGenAiExpenses(user, newGenAiExpenses))
    );

    // 5. On enrichit les notifications avec les infos users
    const enriched = notifications.map((notification: any) => {
      const user = users.find((u) => u.idVINCI === notification.idVINCI);
      return {
        idVINCI: notification.idVINCI,
        credits: notification.creditAsked,
        upn: user?.upn,
        message: notification.message,
        genAICurrentCredit: newGenAiExpenses[notification.idVINCI] ?? 5000,
        date: notification.updateDate,
        formatDate: new Date(notification.updateDate).toLocaleDateString(
          "en-GB",
          { day: "numeric", month: "long", year: "numeric" }
        ),
        formatTime: new Date(notification.updateDate).toLocaleTimeString(
          "en-US",
          { hour: "2-digit", minute: "2-digit", hour12: true }
        ),
        type: user?.type ?? "Standard",
      };
    });
    setTotalItems(enriched.length);
    setAllNotificationsGenAi(enriched);
  };

  const getDisplayedPages = (): (number | string)[] => {
    const pages: (number | string)[] = [];

    if (totalPages <= 6) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }

    pages.push(1); // toujours le premier

    if (currentPage > 3) {
      pages.push("..."); // si on est loin du début
    }

    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      pages.push("..."); // si on est loin de la fin
    }

    pages.push(totalPages); // toujours le dernier

    return pages;
  };

  // recuperation de toutes les notifications ici pour les passes en props
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        // Lancement des deux appels en parallèle
        await Promise.all([notificationsGenAi(), notificationsPemissions()]);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      } finally {
        setEndLoading(true);
        setTimeout(() => {
          setEndLoading(false);
          setIsLoading(false);
        }, 500);
      }
    })();
  }, []);

  //

  return (
    <div className="flex bg-[#F2F2F2]">
      {(isLoading || endLoading) && <ModalLoading endLoading={endLoading} />}

      <div className="bg-[#F9F9F9] w-[1605px]  pl-10">
        <div className="font-inter">
          <TitleH1 text="Notifications" />
        </div>
        <NavigationNotifications
          handleNavigation={handleNavigation}
          navigationPage={navigationPage}
        />
        <div>
          <div
            className="bg-white rounded-b-lg"
            style={{ boxShadow: "0px 4px 4px 0px #0000000D" }}
          >
            <div className="mt-[1px] h-[670px] min-w-[96%] w-[96%] m-auto  rounded-lg">
              {navigationPage === "GenAI" ? (
                <TableNotifications
                  navType="GenAI"
                  page={currentPage}
                  itemsPerPage={itemsPerPage}
                  notifications={allNotificationsGenAi}
                  handleRemoveNotification={handleRemoveNotificationGenAi}
                />
              ) : (
                <TableNotifications
                  navType="Permissions"
                  page={currentPage}
                  itemsPerPage={itemsPerPage}
                  notifications={allNotificationsPermissions}
                  usersAskedPemissions={usersAskedPemissions}
                  handleRemoveNotification={() => {}}
                  handleNotificationsPermissions={
                    handleNotificationsPermissions
                  }
                />
              )}
            </div>
            <div className="flex justify-between align-middle items-center px-10 py-10 w-[96%] m-auto">
              <div onClick={() => handlePageChange(currentPage - 1)}>
                <ButtonSvgText
                  logo={`${PATH_IMG}/arrowLeft.svg`}
                  text="Previous"
                  width="114"
                  height="36"
                />
              </div>

              <div className="flex gap-4">
                {getDisplayedPages().map((page, index) => {
                  if (typeof page === "string") {
                    return (
                      <span
                        key={`ellipsis-${index}`}
                        className="h-10 w-10 flex items-center justify-center"
                      >
                        ...
                      </span>
                    );
                  }

                  return (
                    <button
                      key={page}
                      className={`h-10 w-10 rounded-lg ${
                        currentPage === page
                          ? "text-[#004289] bg-[#0042890A]"
                          : ""
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  );
                })}
              </div>

              <div onClick={() => handlePageChange(currentPage + 1)}>
                <ButtonSvgText
                  logo={`${PATH_IMG}/arrowRight.svg`}
                  text="Next"
                  width="88"
                  height="36"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface NavigationNotificationsProps {
  handleNavigation: (value: string) => void;
  navigationPage: string;
}

function NavigationNotifications({
  handleNavigation,
  navigationPage,
}: NavigationNotificationsProps) {
  return (
    <div className="w-full pl-[2%] m-auto bg-white font-medium text-sm leading-4 text-[#667085] flex items-center rounded-t-lg ">
      <div
        className={`p-2 py-7 cursor-pointer flex gap-2 align-middle items-center  ${
          navigationPage === "GenAI" ? "dashboardGenAI-active" : ""
        }`}
        style={navigationPage === "GenAI" ? { color: "#004289" } : {}}
        onClick={() => handleNavigation("GenAI")}
      >
        <p>GenAI Credits</p>
      </div>
      <div
        className={`p-2 py-7 cursor-pointer flex gap-2 align-middle items-center ${
          navigationPage === "Permissions" ? "dashboardGenAI-active" : ""
        }`}
        onClick={() => handleNavigation("Permissions")}
        style={navigationPage === "Permissions" ? { color: "#004289" } : {}}
      >
        <p>Permissions</p>
      </div>
    </div>
  );
}
