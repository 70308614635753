import React from "react";
import { useState, useEffect } from "react";

import ToggleInput from "../components/elements/inputs/ToggleInput";
import DropdownSelect from "../components/elements/dropdown/DropdownSelect";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import AddNewPermissionContainerList from "./NewPermissionContainerList";
import AddNewPermissionContainerListGenAI from "./AddNewPermissionContainerListGenAI";
import SmallElemCheck from "../components/elements/paragraph/SmallElemCheck";
import LayerControlSectionDashBoard from "./LayerControlSectionDashBoard";
import LayerControlSectionGenAI from "./LayerControlSectionGenAI";
import Minus from "../components/elements/formSvg/Minus";
import PlusElement from "../components/elements/formSvg/Plus";
import { PATH_IMG } from "../../../constants/constant";
import SegmentedToggle from "../components/elements/inputs/SegmentedToggle";
import {
  DashboardPermissions,
  DataGovPermissions,
  GenAiPermissions,
  RolePermissions,
} from "./type/permissionsTypes";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import LayerControlSectionDataGov from "./LayerControlSectionDataGov";
import AddNewPermissionContainerListDataGov from "./AddNewPermissionContainerListDataGov";
import AddNewPermissionContainerListCopieUser from "./AddNewPermissionContainerListCopieUser";
import { add } from "date-fns";

export default function AddNewPermissionContainer() {
  const [copiePemissions, setCopiePermissions] = useState<RolePermissions>({
    admin: [],
    dashboard: [],
    gen_ai: [],
    data_gov: [],
  });
  const [userCopieSelected, setUserCopieSelected] = useState("");
  const [layerCount, setLayerCount] = useState(1);
  const [layerCountGenAI, setLayerCountGenAI] = useState(1);
  const [layerCountDataGov, setLayerCountDataGov] = useState(1);
  const [optionPermissionsToAdd, setOptionPermissionsToAdd] = useState("1");
  const [openLayerAddNewPermission, setOpenLayerAddNewPermission] =
    useState(false);
  const [openLayerCopyNewPermission, setOpenLayerCopyNewPermission] =
    useState(false);
  const [permissionAdded, setPermissionsAdded] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });

  const {
    addPermissionToAdd,
    usersInAppDirectory,
    permissions,
    handleCopieSelectionUser,
    loadingSearchCopieUserPerm,
  } = usePermissionsDataAdmin();

  // copie user logic
  const [isPermissionsReady, setIsPermissionsReady] = useState(false);

  useEffect(() => {
    if (userCopieSelected) {
      handleCopieSelectionUser(userCopieSelected);
      setIsPermissionsReady(false); // Réinitialiser l'état quand un nouvel utilisateur est sélectionné
    }
  }, [userCopieSelected]);

  useEffect(() => {
    if (!isPermissionsReady) return; // Attendre que permissions soit prêt

    const userChooseInAppDirectory = usersInAppDirectory.find(
      (user) => user.upn === userCopieSelected
    );

    if (userChooseInAppDirectory) {
      const dataPermissions = permissions.get(userChooseInAppDirectory.idVINCI);
      if (dataPermissions) {
        setCopiePermissions(() => ({
          admin: dataPermissions.admin.map((perm) => ({ ...perm })),
          dashboard: dataPermissions.dashboard.map((perm) => ({ ...perm })),
          gen_ai: dataPermissions.gen_ai.map((perm) => ({ ...perm })),
          data_gov: dataPermissions.data_gov.map((perm) => ({ ...perm })),
        }));
      } else {
        setCopiePermissions(() => ({
          admin: [],
          dashboard: [],
          gen_ai: [],
          data_gov: [],
        }));
      }
    }
  }, [isPermissionsReady]);

  // ✅ Nouveau `useEffect` pour détecter quand `permissions` est prêt
  useEffect(() => {
    if (permissions.size > 0) {
      setIsPermissionsReady(true);
    }
  }, [permissions]);

  // copie user logic

  const [listDashboardPermissions, setListDashboardPermissions] = useState<
    DashboardPermissions[]
  >([
    {
      dashboard_level: "L1",
      dashboard: "Finance",
      business_unit: "",
      airport: "",
    },
  ]);

  const [listGenAIPermissions, setListGenAIPermissions] = useState<
    GenAiPermissions[]
  >([
    {
      app_name: "CompetitionAnalysis",
      use_case: "Traffic",
      access_type: "",
      business_unit: "",
      airport: "",
      role: "",
    },
  ]);

  const [listDataGovPermissions, setListDataGovPermissions] = useState<
    DataGovPermissions[]
  >([
    {
      business_unit: "",
      use_case: "Communication",
      action: "Ingestion",
    },
  ]);

  const handleDashboardPermissions = (
    permissions: DashboardPermissions,
    index: number
  ) => {
    setListDashboardPermissions((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return permissions;
        }
        return item;
      });
    });
  };

  const handleGenAIPermissions = (
    permissions: GenAiPermissions,
    index: number
  ) => {
    setListGenAIPermissions((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return permissions;
        }
        return item;
      });
    });
  };

  const handleDataGovPermissions = (
    permissions: DataGovPermissions,
    index: number
  ) => {
    setListDataGovPermissions((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return permissions;
        }
        return item;
      });
    });
  };

  const handleOptionPermissionsToAdd = (value: any) => {
    setOptionPermissionsToAdd(value);
  };

  const addLayer = () => {
    setLayerCount((prev) => prev + 1);
    setListDashboardPermissions((prev) => [
      ...prev,
      {
        dashboard_level: "L1",
        dashboard: "Finance",
        business_unit: "",
        airport: "",
      },
    ]);
  };

  const removeLayer = (indexToRemove: any) => {
    if (layerCount > 1) {
      setLayerCount((prev) => prev - 1);
      setListDashboardPermissions((prev) =>
        prev.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const addLayerGenAI = () => {
    setLayerCountGenAI((prev) => prev + 1);
    setListGenAIPermissions((prev) => [
      ...prev,
      {
        app_name: "CompetitionAnalysis",
        use_case: "Traffic",
        access_type: "",
        business_unit: "",
        airport: "",
        role: "",
      },
    ]);
  };

  const removeLayerGenAI = (indexToRemove: any) => {
    if (layerCountGenAI > 1) {
      setLayerCountGenAI((prev) => prev - 1);
      setListGenAIPermissions((prev) =>
        prev.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const addLayerDataGov = () => {
    setLayerCountDataGov((prev) => prev + 1);
    setListDataGovPermissions((prev) => [
      ...prev,
      {
        business_unit: "",
        use_case: "Communication",
        action: "Ingestion",
      },
    ]);
  };

  const removeLayerDataGov = (indexToRemove: any) => {
    if (layerCountDataGov > 1) {
      setLayerCountDataGov((prev) => prev - 1);
      setListDataGovPermissions((prev) =>
        prev.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  return (
    <div className="bg-white w-[701px] font-inter">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => {
          if (openLayerCopyNewPermission) {
            setOpenLayerCopyNewPermission(!openLayerCopyNewPermission);
          }
          setOpenLayerAddNewPermission(!openLayerAddNewPermission);
        }}
      >
        <h3 className="p-3 font-medium text-base leading-5 text-black h-14 flex align-middle items-center">
          Add a new permission(s)
        </h3>
        {openLayerAddNewPermission ? (
          <Minus width="14" height="14" />
        ) : (
          <PlusElement width="14" height="14" />
        )}
      </div>
      <div
        className="p-3  w-[701px] h-[350px] flex flex-col   overflow-y-auto"
        style={openLayerAddNewPermission ? {} : { display: "none" }}
      >
        <SegmentedToggle
          title="Select app"
          width="660"
          height="52"
          options={[
            { id: "1", title: "Dashboard" },
            { id: "2", title: "GenAI" },
            { id: "3", title: "Data gouv" },
            { id: "4", title: "Admin" },
          ]}
          handleToggle={handleOptionPermissionsToAdd}
        />

        {optionPermissionsToAdd === "1"
          ? listDashboardPermissions.map(
              (item: DashboardPermissions, index: number) => {
                return (
                  <LayerControlSectionDashBoard
                    item={item}
                    key={index}
                    index={index}
                    onRemove={() => removeLayer(index)}
                    onAdd={addLayer}
                    isLast={index === layerCount - 1}
                    isOnly={layerCount === 1}
                    handleDashboardPermissions={handleDashboardPermissions}
                  />
                );
              }
            )
          : optionPermissionsToAdd === "2"
          ? listGenAIPermissions.map(
              (item: GenAiPermissions, index: number) => {
                return (
                  <LayerControlSectionGenAI
                    item={item}
                    key={index}
                    index={index}
                    onRemove={() => removeLayerGenAI(index)}
                    onAdd={addLayerGenAI}
                    isLast={index === layerCountGenAI - 1}
                    isOnly={layerCountGenAI === 1}
                    handleGenAIPermissions={handleGenAIPermissions}
                  />
                );
              }
            )
          : optionPermissionsToAdd === "3"
          ? listDataGovPermissions.map(
              (item: DataGovPermissions, index: number) => {
                return (
                  <LayerControlSectionDataGov
                    item={item}
                    key={index}
                    index={index}
                    onRemove={() => removeLayerDataGov(index)}
                    onAdd={addLayerDataGov}
                    isLast={index === layerCountDataGov - 1}
                    isOnly={layerCountDataGov === 1}
                    handleDataGovPermissions={handleDataGovPermissions}
                  />
                );
              }
            )
          : null}

        {optionPermissionsToAdd == "1" ? (
          <>
            <AddNewPermissionContainerList
              listDashboardPermissions={listDashboardPermissions}
              onRemove={removeLayer}
            />
            <div className="flex gap-2 mt-4 items-center align-middle">
              <div
                onClick={() => {
                  addPermissionToAdd(listDashboardPermissions, "dashboard");
                  setPermissionsAdded({ ...permissionAdded, first: true });
                  setTimeout(() => {
                    setPermissionsAdded({ ...permissionAdded, first: false });
                  }, 2000);
                }}
                className="cursor-pointer"
              >
                <ButtonClassic
                  text="Add permissions"
                  width="146"
                  height="40"
                  backgroundColor="#004289"
                />
              </div>
              {permissionAdded.first && (
                <SmallElemCheck text="Permissions have been added" />
              )}
            </div>
          </>
        ) : optionPermissionsToAdd == "2" ? (
          <>
            <AddNewPermissionContainerListGenAI
              listGenAIPermissions={listGenAIPermissions}
              onRemove={removeLayerGenAI}
            />
            <div className="flex gap-2 mt-4 items-center align-middle">
              <div
                onClick={() => {
                  addPermissionToAdd(listGenAIPermissions, "genai");
                  setPermissionsAdded({ ...permissionAdded, second: true });
                  setTimeout(() => {
                    setPermissionsAdded({ ...permissionAdded, second: false });
                  }, 2000);
                }}
                className="cursor-pointer"
              >
                <ButtonClassic
                  text="Add permissions"
                  width="146"
                  height="40"
                  backgroundColor="#004289"
                />
              </div>
              {permissionAdded.second && (
                <SmallElemCheck text="Permissions have been added" />
              )}
            </div>
          </>
        ) : optionPermissionsToAdd == "3" ? (
          <>
            <AddNewPermissionContainerListDataGov
              listDataGovPermissions={listDataGovPermissions}
              onRemove={removeLayerDataGov}
            />
            <div className="flex gap-2 mt-4 items-center align-middle">
              <div
                onClick={() => {
                  addPermissionToAdd(listDataGovPermissions, "datagouv");
                  setPermissionsAdded({ ...permissionAdded, third: true });
                  setTimeout(() => {
                    setPermissionsAdded({ ...permissionAdded, third: false });
                  }, 2000);
                }}
                className="cursor-pointer"
              >
                <ButtonClassic
                  text="Add permissions"
                  width="146"
                  height="40"
                  backgroundColor="#004289"
                />
              </div>
              {permissionAdded.third && (
                <SmallElemCheck text="Permissions have been added" />
              )}
            </div>
          </>
        ) : null}
      </div>
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => {
          if (openLayerAddNewPermission) {
            setOpenLayerAddNewPermission(!openLayerAddNewPermission);
          }
          setOpenLayerCopyNewPermission(!openLayerCopyNewPermission);
        }}
      >
        <h3 className="p-3 w-full h-14 flex align-middle items-center font-medium text-base leading-5 text-black border-y border-[#f2f2f2]">
          Copy a user permission
        </h3>
        {openLayerCopyNewPermission ? (
          <Minus width="14" height="14" />
        ) : (
          <PlusElement width="14" height="14" />
        )}
      </div>
      <div
        className="p-3  w-[701px] h-[300px] flex flex-col   "
        style={openLayerCopyNewPermission ? {} : { display: "none" }}
      >
        {/* <DropdownSelect
          title=""
          widthSearchBar="640px"
          width="675px"
          logo={`${PATH_IMG}/user.svg`}
          placeholderBar="Select user(s)"
        /> */}
        <DropdownSelect
          title=""
          width="675px"
          widthSearchBar="640px"
          selectedValue={userCopieSelected}
          data={usersInAppDirectory.map((user) => user.upn)}
          logo={`${PATH_IMG}/user.svg`}
          placeholderBar="Select user"
          handleSelectOneValue={(value: string) => {
            setUserCopieSelected(value);
          }}
        />
        <AddNewPermissionContainerListCopieUser
          copiePermissions={copiePemissions}
          onRemove={(type: keyof RolePermissions, index: number) => {
            setCopiePermissions((prev) => {
              return {
                ...prev,
                [type]: prev[type].filter((_, i) => i !== index),
              };
            });
          }}
        />

        <div className="flex  gap-2 mt-4 items-center align-middle">
          <div
            onClick={() => {
              addPermissionToAdd(copiePemissions.dashboard, "dashboard");
              addPermissionToAdd(copiePemissions.gen_ai, "genai");
              addPermissionToAdd(copiePemissions.data_gov, "datagouv");
              setPermissionsAdded({ ...permissionAdded, fourth: true });
              setTimeout(() => {
                setPermissionsAdded({ ...permissionAdded, fourth: false });
              }, 2000);
            }}
          >
            <ButtonClassic
              text="Add permissions"
              width="146"
              height="40"
              backgroundColor="#004289"
            />
          </div>
          {permissionAdded.fourth && (
            <SmallElemCheck text="Permissions have been added" />
          )}
        </div>
      </div>
    </div>
  );
}
