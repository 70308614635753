import React from "react";

import { useEffect } from "react";
import DropdownUser from "./DropdownUser";
import SmallElemDot from "../components/elements/paragraph/SmallElemDot";
import InputWithLogo from "../components/elements/inputs/InputWithLogo";
import InputClassic from "../components/elements/inputs/InputClassic";
import InformationInput from "../components/elements/inputs/InformationInput";
import OptionUser from "../components/elements/inputs/OptionUser";
import { usersInterface } from "../../../types/adminManagementPages";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";
import { useInfoUser } from "../../../providers/InfoUser";

interface SettingsFormProps {
  copieData: usersInterface[];
}

export default function SettingsForm({ copieData }: SettingsFormProps) {
  const { upnFromNotificationToUser, setUpnFromNotificationToUser } =
    useInfoUser();

  const {
    allUpn,
    upnActive,
    allLocations,
    isCreateUser,
    profile,
    handleUpnSelection,
    handleChangementCopieData,
  } = useUserDataAdmin();

  const dataUpn = copieData.find((user) => user.upn === upnActive);

  if (dataUpn?.airport === "None") {
    const businessUnit = dataUpn.businessUnit;
    if (
      businessUnit &&
      allLocations[businessUnit as keyof typeof allLocations]?.length > 0
    ) {
      const airport =
        allLocations[businessUnit as keyof typeof allLocations][0];
      handleChangementCopieData("airport", airport);
    } else {
      console.warn("Aucune valeur trouvée pour businessUnit:", businessUnit);
    }
  }

  const handleSelectionBefore = (upn: string) => {
    handleUpnSelection(upn);
    setUpnFromNotificationToUser("");
  };

  const handleChangementEmail = (value: string) => {
    handleChangementCopieData("email", value);
  };

  const handleChangementFirstName = (value: string) => {
    handleChangementCopieData("firstName", value);
  };
  const handleChangementLastName = (value: string) => {
    handleChangementCopieData("lastName", value);
  };

  const handleChangementAirport = (value: string) => {
    handleChangementCopieData("airport", value);
  };

  const handleChangementBusinessUnit = (value: string) => {
    handleChangementCopieData("businessUnit", value);
  };

  const handleChangementAdminEmail = (value: string) => {
    handleChangementCopieData("adminEmail", value);
  };

  useEffect(() => {
    if (!allUpn || allUpn.length === 0) return;
    if (!upnFromNotificationToUser) return;
    if (upnFromNotificationToUser === "") return;
    handleUpnSelection(upnFromNotificationToUser);
  }, [upnFromNotificationToUser, allUpn]);

  return (
    <div className="flex flex-col h-[610px] w-[654px] justify-between ">
      <div>
        <DropdownUser
          title="UPN"
          list={allUpn}
          handleSelection={handleSelectionBefore}
          valueSelected={upnActive}
        />
        <div className="mt-3 flex gap-4">
          {dataUpn && (
            <>
              {dataUpn.isInVinciAirportsDirectory ? (
                <SmallElemDot
                  color="#18AB7B"
                  bgColor="#3BD19F1A"
                  text="In VINCI Airports directory"
                />
              ) : (
                <SmallElemDot
                  color="#570505"
                  bgColor="#b6353550"
                  text="Not in VINCI Airports directory"
                />
              )}
              {dataUpn.isInAppDirectory ? (
                <SmallElemDot
                  color="#18AB7B"
                  bgColor="#3BD19F1A"
                  text="In the application directory"
                />
              ) : (
                <SmallElemDot
                  color="#570505"
                  bgColor="#b6353550"
                  text="Not in the application directory"
                />
              )}
            </>
          )}
        </div>
        <div className="h-[2px] mt-2 -ml-4  bg-[#F9F9F9] w-[720px] "></div>
      </div>
      <InputWithLogo
        title="Email Microsoft"
        dataEmail={dataUpn ? dataUpn.email : ""}
        handleChangementEmail={handleChangementEmail}
      />
      <div className="flex justify-between ">
        <InputClassic
          text="First Name"
          name="firstName"
          handleChangementExt={handleChangementFirstName}
          valueExt={dataUpn ? dataUpn.firstName : ""}
        />
        <InputClassic
          valueExt={dataUpn ? dataUpn.lastName : ""}
          handleChangementExt={handleChangementLastName}
          text="Last Name"
          name="lastName"
        />
      </div>
      {/* { ? (
        <InformationInput
          title="Business unit"
          text={dataUpn ? dataUpn.businessUnit : ""}
          width="654px"
          isIcon={false}
        />
      ) : ( */}
      <OptionUser
        dataToSelect={allLocations}
        width="654"
        title="Business unit"
        handleChangementExt={handleChangementBusinessUnit}
        valueExt={dataUpn ? dataUpn.businessUnit : ""}
      />
      {/* )} */}
      <OptionUser
        width="654"
        title="Airport"
        arrayToSelect={
          dataUpn
            ? allLocations[
                dataUpn?.businessUnit as keyof typeof allLocations
              ] ?? []
            : []
        }
        handleChangementExt={handleChangementAirport}
        valueExt={dataUpn ? dataUpn.airport : ""}
      />
      {profile?.businessUnit == "HQ" ? (
        <InputWithLogo
          title="Admin email"
          dataEmail={dataUpn ? dataUpn.adminEmail : ""}
          handleChangementEmail={handleChangementAdminEmail}
        />
      ) : (
        <InformationInput
          title="Admin email"
          text={dataUpn ? dataUpn.adminEmail : ""}
          width="654px"
          isIcon={true}
        />
      )}
    </div>
  );
}
