// the elem have to contain date and time properties
export const sortByDateTime = (
  logs: any[],
  first: any,
  second: any,
  order: "asc" | "desc" = "desc"
) => {
  // Créer une copie du tableau pour ne pas modifier l'original
  const logsCopy = [...logs];

  return logsCopy.sort((a, b) => {
    console.log(a[first], a[second]);
    const dateA = new Date(`${a[first]}, ${a[second]}`);
    const dateB = new Date(`${b[first]}, ${b[second]}`);

    return order === "desc"
      ? dateB.getTime() - dateA.getTime()
      : dateA.getTime() - dateB.getTime();
  });
};

export const sortByAlphabeticalOrder = <T>(
  items: T[],
  key: keyof T,
  order: "asc" | "desc" = "asc"
) => {
  const itemsCopy = [...items]; // Création d'une copie du tableau
  itemsCopy.sort((a, b) => {
    const valueA = String(a[key]).toLowerCase();
    const valueB = String(b[key]).toLowerCase();

    if (valueA < valueB) return order === "asc" ? -1 : 1;
    if (valueA > valueB) return order === "asc" ? 1 : -1;
    return 0;
  });
  return itemsCopy; // Retourner la copie triée
};

export const sortByNumericOrder = <T>(
  items: T[],
  key: keyof T,
  order: "asc" | "desc" = "asc"
): T[] => {
  const itemsCopy = [...items]; // Copie du tableau pour éviter la mutation
  itemsCopy.sort((a, b) => {
    const valueA = Number(a[key]);
    const valueB = Number(b[key]);

    return order === "asc" ? valueA - valueB : valueB - valueA;
  });

  return itemsCopy; // Retourner le tableau trié
};
