import React, { useState } from "react";
import DropdownSelect from "../components/elements/dropdown/DropdownSelect";
import { PATH_IMG } from "../../../constants/constant";
import { DataGovPermissions } from "./type/permissionsTypes";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

interface LayerControlSectionDataGovProps {
  index: number;
  onRemove: () => void;
  onAdd: () => void;
  isLast: boolean;
  isOnly: boolean;
  item: DataGovPermissions;
  handleDataGovPermissions: (
    permissions: DataGovPermissions,
    index: number
  ) => void;
}

export default function LayerControlSectionDataGov({
  index,
  onRemove,
  onAdd,
  isLast,
  isOnly,
  item,
  handleDataGovPermissions,
}: LayerControlSectionDataGovProps) {
  const { allRolesPermissions } = usePermissionsDataAdmin();

  const filterActionData = () => {
    return allRolesPermissions.data_gov
      .filter((elem) => (elem.use_case ?? "") === (item.use_case ?? ""))
      .map((elem) => elem.action ?? "")
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  const handleSelectOneValueDropdownSelectUseCase = (value: string) => {
    item.use_case = value;
    const actionDataTmp = filterActionData();
    if (!actionDataTmp.includes(item.action)) {
      item.action = "Ingestion";
    }
    handleDataGovPermissions(item, index);
  };

  const handleSelectOneValueDropdownSelectAction = (value: string) => {
    item.action = value;
    handleDataGovPermissions(item, index);
  };

  // const businessUnitData = allRolesPermissions.data_gov
  //   .map((elem) => elem.business_unit)
  //   .filter((value, index, self) => self.indexOf(value) === index);

  const useCaseData = allRolesPermissions.data_gov
    .map((elem) => elem.use_case)
    .filter((value, index, self) => self.indexOf(value) === index);

  const actionData = allRolesPermissions.data_gov
    .filter((elem) => elem.use_case === item.use_case)
    .map((elem) => elem.action)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className="mt-4 flex items-center gap-2">
      {/* <DropdownSelect
        title="Business Unit"
        width="200px"
        widthSearchBar="180px"
        selectedValue={item.business_unit || ""}
        data={businessUnitData}
        handleSelectOneValue={handleSelectOneValueDropdownSelectBusinessUnit}
      /> */}
      <DropdownSelect
        title="Use case"
        width="300px"
        widthSearchBar="280px"
        selectedValue={item.use_case || ""}
        data={useCaseData}
        handleSelectOneValue={handleSelectOneValueDropdownSelectUseCase}
      />
      <DropdownSelect
        title="Action"
        width="300px"
        widthSearchBar="280px"
        selectedValue={item.action || ""}
        data={actionData}
        handleSelectOneValue={handleSelectOneValueDropdownSelectAction}
      />

      {isOnly ? (
        <img
          onClick={onAdd}
          className="mt-5 cursor-pointer"
          src={`${PATH_IMG}/plusCircle.svg`}
          alt="Add Layer"
        />
      ) : isLast ? (
        <>
          <img
            onClick={onRemove}
            className="mt-5 cursor-pointer"
            src={`${PATH_IMG}/minusCircle.svg`}
            alt="Remove Layer"
          />
          <img
            onClick={onAdd}
            className="mt-5 cursor-pointer"
            src={`${PATH_IMG}/plusCircle.svg`}
            alt="Add Layer"
          />
        </>
      ) : (
        <img
          onClick={onRemove}
          className="mt-5 cursor-pointer"
          src={`${PATH_IMG}/minusCircle.svg`}
          alt="Remove Layer"
        />
      )}
    </div>
  );
}
