import React from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import { PATH_IMG } from "../../../constants/constant";

interface ModalTransfertProps {
  handleCloseModal: (value: boolean) => void;
  handleValidationPopup: () => void;
  dataError?: {
    errorMessage1: string;
    errorMessage2: string;
    permissionsToDelete: string[];
  };
}

function ModalTransfert({
  handleCloseModal,
  dataError,
  handleValidationPopup,
}: ModalTransfertProps) {
  return (
    <div className="fixed inset-0 bg-[#344054b2] backdrop-blur-lg z-30 flex items-center justify-center">
      <div className="font-inter bg-white w-[400px] h-[600px] p-2 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
        <div className="m-auto  pt-2 pb-0   flex justify-between align-middle items-center">
          <div className=" flex gap-5 align-top items-center">
            <div>
              <img src={`${PATH_IMG}/atention.svg`} alt="#" />
            </div>
            <h2 className="w-72 font-medium text-lg leading-7">
              VINCI ID Transfer Due to Pre-existing Account
            </h2>
          </div>
        </div>
        <div className="m-auto -mt-2">
          <div className="h-32 align-middle items-center p-4">
            <p className=" text-[#DC6803] font-bold leading-5">
              The user already exists. By clicking 'Accept', you agree to
              transfer the account to another business unit/airport. Clicking
              'Abort' will cancel the transfer. By proceeding, you also consent
              to the removal of the following permissions associated with the
              user.
            </p>
          </div>
          {/** */}
        </div>

        <div className="  m-auto align-middle items-center p-4 h-[300px]  max-h-[300px] overflow-y-auto mt-6">
          <div>
            {dataError &&
              dataError.permissionsToDelete &&
              dataError.permissionsToDelete.map((permissionToDelete, index) => (
                <div key={index} className="mt-4">
                  <p>{permissionToDelete}</p>
                </div>
              ))}
          </div>
        </div>

        <div className="flex w-full justify-between align-middle items-center pl-4 pr-4 h-[68px] ">
          <div onClick={() => handleCloseModal(false)}>
            <ButtonClassic text="Abort" width="170" height="44" />
          </div>
          <div
            onClick={() => {
              handleValidationPopup();
            }}
          >
            <ButtonClassic
              text="Accept"
              width="170"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTransfert;
