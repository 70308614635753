import React from "react";
import { useEffect, useState } from "react";
import TitleH1 from "../components/elements/titles/TitleH1";
import PermissionsManagementHeader from "./PermissionsManagementHeader";
import PermissionsManagementFooter from "./PermissionsManagementFooter";
import PermissionContainerList from "./PermissionContainerList";
import AddNewPermissionContainer from "./AddNewPermissionContainer";
import ProgressContainer from "./ProgressContainer";
import { SideBar } from "../components/SideBar";
import "./index.css";
import {
  PermissionsDataAdminProvider,
  usePermissionsDataAdmin,
} from "./context/PermissionsDataAdminProvider";
import ModalLoading from "../components/modals/ModalLoading";
import ModalPermissionsUpdateErrors from "./ModalPermissionsUpdateErrors";
import ModalErrorFileSelect from "./ModalErrorFileSelect";

function AdminManagementPermissionsWithProvider() {
  const { isLoading, endLoading, hasErrorUpdate, hasErrorFile } =
    usePermissionsDataAdmin();
  const [isUpdatable, setIsupdatable] = useState(false);

  const handleUpdatable = (value: boolean) => {
    setIsupdatable(value);
  };

  const { usersSelected } = usePermissionsDataAdmin();

  useEffect(() => {
    if (usersSelected.length > 0) {
      setIsupdatable(true);
    }
  }, [usersSelected]);

  return (
    <div className="flex h-screen bg-[#F2F2F2]">
      {/* <SideBar /> */}
      <div className="bg-[#F2F2F2] w-[1605px] pl-10">
        <div className="font-inter ">
          <TitleH1 text="Permissions" />
          <div className="w-[1530px] bg-white rounded-t-lg">
            <div className="w-[1461px] m-auto py-2 pb-4 mb-[1px]">
              <PermissionsManagementHeader handleUpdatable={handleUpdatable} />
            </div>
          </div>

          {isUpdatable ? (
            <div className="w-[1530px] min-h-[596px] bg-white ">
              <div className="flex w-[1461px] justify-between m-auto  ">
                <PermissionContainerList />
                <AddNewPermissionContainer />
              </div>
            </div>
          ) : (
            <ProgressContainer />
          )}

          <div className="w-[1530px] bg-white rounded-b-lg">
            <div className="w-[1461px] m-auto py-5 mt-[1px]">
              <PermissionsManagementFooter isUpdatable={isUpdatable} />
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      {(isLoading || endLoading) && <ModalLoading endLoading={endLoading} />}
      {hasErrorUpdate && <ModalPermissionsUpdateErrors />}
      {hasErrorFile && <ModalErrorFileSelect />}
    </div>
  );
}

export default function AdminManagementPermissions() {
  return (
    <PermissionsDataAdminProvider>
      <AdminManagementPermissionsWithProvider />
    </PermissionsDataAdminProvider>
  );
}
