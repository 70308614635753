import { useEffect, useRef, useState } from "react";
import { useDataGouv } from "../providers/DataGouvProvider";
import { ButtonIngestionRef, ErrorButton } from "./ButtonIngestion";
import { useAuth } from "../../../providers";
import { SVGIcon } from "../../../assets";
import { FileStatusReferentiel } from "./FileStatusReferentiel";
import { LoaderSpinner } from "./Icons";


export const FileChecksTableReferentiel = () => {
  const { buSelected, dataReferentiel, loaderReferentielData, useCase } = useDataGouv();
  const [openModalRowId, setOpenModalRowId] = useState<string | null>(null);

  const buOptions = dataReferentiel && buSelected ? dataReferentiel[buSelected] || [] : [];

  const isEmptyData = !buOptions || buOptions.length === 0;

  return (
    <div className="w-full border border-gray-200 rounded-md">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-[#ECF5FF] text-[#004289]">
            <th className="px-4 py-2 text-left text-sm font-medium">File{' '}Type</th>
            {/* <th className="py-2 text-left text-sm font-medium">File{' '}Name</th> */}
            <th className="px-4 py-2 text-left text-sm font-medium">Status</th>
            <th className="text-left text-sm font-medium"></th>
            <th className="text-left text-sm font-medium"></th>
            <th className="text-left text-sm font-medium"></th>
          </tr>
        </thead>
        <tbody>
          {loaderReferentielData ? (
            <tr>
              <td colSpan={5} className="text-center py-4">
                <LoaderSpinner />
              </td>
            </tr>
          ) : isEmptyData ? (
            <tr>
              <td colSpan={6} className="text-center py-4">
                No referential data available for {buSelected!} - {useCase}.
              </td>
            </tr>
          ) : (
            buOptions.map((file: any, index: any) => (
              <FileRow
                key={`unlinked-${index}`}
                fileType={file.file_type}
                version={file.version}
                final_filename={file.final_filename}
                rowId={`unlinked-${file.file_type}-${file.version || ''}-${index}`}
                openModalRowId={openModalRowId}
                setOpenModalRowId={setOpenModalRowId}
                linked_url={file.linked_url}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

interface FileRowProps {
  fileType: string;
  version?: string;
  isDropdown?: boolean
  fileLinked?: File | null
  final_filename?: string | null
  rowId: string;
  openModalRowId: string | null;
  setOpenModalRowId: (id: string | null) => void;
  linked_url?: string
}
export const FileRow: React.FC<FileRowProps> = ({ fileType, version, isDropdown, fileLinked, final_filename, rowId, openModalRowId, setOpenModalRowId, linked_url }) => {
  const { buSelected, dateSelected, dataReferentiel, uploadFileFromDropzoneRef, uploadFileFromDropzoneRefIngestion } = useDataGouv();
  // const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isExpandedError, setIsExpandedError] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null)
  const [lineHeight, setLineHeight] = useState<number>(0);
  const [loaderDownload, setLoaderDownload] = useState(false)
  const { token } = useAuth()
  const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});
  const modalRef = useRef<HTMLDivElement | null>(null);
  const showModal = rowId === openModalRowId;

  const buOptions = dataReferentiel![buSelected!]

  const handleToggleModal = () => {
    if (showModal) {
      setOpenModalRowId(null);
    } else {
      setOpenModalRowId(rowId);
    }
  };

  useEffect(() => {
    if (showModal && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showModal]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (showModal) {
          setOpenModalRowId(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const handleDownload = async (gs_url: string) => {
    setLoadingDownloads((prev) => ({ ...prev, [gs_url]: true }));
    try {
      // console.log("sdfsd")
      const response = await fetch(`/api/data_gov/referentiel/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          gs_url: gs_url  // La variable contenant l'URL
        })
      });
      if (!response.ok) {
        throw new Error(`Download failed: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const contentDisposition = response.headers.get('content-disposition');
      console.log(contentDisposition)
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      console.log(filenameMatch)
      const filename = filenameMatch ? filenameMatch[1] : `template-${buSelected}-${fileType}.xlsx`;

      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
    catch (error) {
      console.log(error)
      return null
    }
    finally {
      setLoadingDownloads((prev) => ({ ...prev, [gs_url]: false }));
    }
  };

  const currentFile = buOptions.find((file: any) => (file.file_type === fileType)) || {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
    file_url: null,
    final_filename: null,
  };

  const dropdownRef = useRef<HTMLTableRowElement | null>(null);


  useEffect(() => {
    if (file) {
      handleConfirmClick()
      setFile(null)
    }
  }, [file])

  useEffect(() => {
    if (dropdownRef.current && (isExpandedError)) {
      const scrollableContentHeight = dropdownRef.current.scrollHeight;
      setLineHeight(scrollableContentHeight);
    }
  }, [isExpandedError]);

  const handleConfirmClick = () => {
    console.log(fileType)
    if (!buSelected || !fileType) return;
    if (fileType === 'MAPPING_LOCAL' || fileType === 'MAPPING_USECASE') {
      uploadFileFromDropzoneRefIngestion(fileType, file!, buSelected!, '11', '2024')
    }
    else {
      uploadFileFromDropzoneRef(fileType, file!, linked_url!);
    }
  };

  return (
    <>
      <tr className={`border-b border-gray-200 transition-all duration-300 overflow-x-scroll`}>
        <td className={` ${isDropdown ? 'px-8' : 'px-4'} py-1.5 text-sm text-gray-800`}>{fileType}</td>
        {/* <td className="text-xs text-gray-800 flex flex-row items-center">
          <div className="flex items-center justify-center h-full py-2 mt-1">
          {final_filename ? 
            <p className={`text-[#00BE00] ${
              (currentFile.status.checkfile === 'error' || 
              currentFile.status.loaded === 'error' || 
              currentFile.status.processed === 'error') ? 'text-black' : 'text-[#00BE00]'}`}>
                {(final_filename.substring(0, 15).replaceAll(" ", "\u00A0") + "...").toLocaleLowerCase()}
            </p>
              : 
              file ? 
              file.name.substring(0, 15).replaceAll(" ", "\u00A0") + "..." 
              : 
              currentFile.final_filename ? currentFile.final_filename.substring(0, 15).replaceAll(" ", "\u00A0") + "..." :
              <p className="text-[#E61A23] font-bold">no ref file</p>
          } 
          </div>         
            {file && (currentFile.status.loaded !== "finished" && currentFile.status.loaded !== "running") && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={() => setFile(null)}
              className="cursor-pointer"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          )}
        </td> */}
        {/* <td className="px-4 py-1.5 text-sm text-gray-800">25-04-2024</td>
        <td className="px-4 py-1.5 text-sm text-gray-800">John Smith</td> */}
        <td className="px-4 py-1.5 text-sm text-gray-800">
          <FileStatusReferentiel status={currentFile.status} />
        </td>

        <td className="px-4 py-1.5 text-sm text-gray-800">
          {isDropdown && (currentFile.status.loaded === 'finished' && currentFile.status.checkfile === 'finished') && <ButtonIngestionRef onClick={() => setIsExpanded(!isExpanded)} status={currentFile.status} setFile={setFile} />}
          {!isDropdown && <ButtonIngestionRef onClick={() => setIsExpanded(!isExpanded)} status={currentFile.status} setFile={setFile} />}
        </td>
        {(['loaded', 'checkfile', 'processed'] as Array<keyof typeof currentFile.status>).some(
          (key) => currentFile.status[key] === 'error'
        ) && (
            <td className="px-4 py-1.5 text-sm text-gray-800">
              <ErrorButton onClick={() => setIsExpandedError(!isExpandedError)} isExpanded={isExpandedError} />
            </td>
          )}
        {!(['loaded', 'checkfile', 'processed'] as Array<keyof typeof currentFile.status>).some(
          (key) => currentFile.status[key] === 'error'
        ) && <td className="px-4 py-1.5 text-sm text-gray-800"></td>}
        {
          currentFile.file_url &&
          <td className="relative px-4 py-1.5 text-sm text-gray-800">
            <div className="flex flex-col items-center justify-center bg-[#EEEEEE] rounded-md h-[33px] w-[33px] p-1 gap-1 cursor-pointer" onClick={handleToggleModal}>
              <div className="rounded-full w-1 h-1 bg-[#686868]" />
              <div className="rounded-full w-1 h-1 bg-[#686868]" />
              <div className="rounded-full w-1 h-1 bg-[#686868]" />
              {showModal &&
                <div ref={modalRef} className="absolute flex justify-start border-2 border-[#F6F6F6] flex-col items-start top-[40px] shadow-sm right-5 bg-white rounded-xl z-9999 w-[250px] py-3 px-5" style={{ zIndex: 9999 }}>
                  {/* <div className="hover:bg-[#EEEEEE] flex flex-row gap-3 w-full items-center cursor-pointer rounded-xl py-2 px-3" onClick={handleDownloadTemplate}>
                  <SVGIcon icon="FOLDER_DOWNLOAD_ICON"/>
                  <p className="w-full font-medium">Download Template</p>
                </div> */}
                  {/* {currentFile.file_url && currentFile.file_url?.length > 0 &&  */}
                  <div className="hover:bg-[#EEEEEE] py-2 px-3 rounded-xl flex flex-row gap-3 w-full justify-start items-center cursor-pointer" onClick={() => handleDownload(currentFile.file_url!)}>
                    <SVGIcon icon="FOLDER_DOWNLOAD_ICON" />
                    <p className="w-full font-medium">Download File</p>
                  </div>
                  {/* } */}
                </div>
              }
            </div>
          </td>
        }
      </tr>
      {isExpandedError && currentFile.error &&
        <tr className="bg-gray-100" ref={dropdownRef} >
          <td colSpan={9} className="">
            <div className="flex flex-row gap-10 w-full justify-start items-start px-6 pb-2">
              <div className="relative">
                <div className="w-[2px] bg-gray-400" style={{ height: `${lineHeight}px` }}></div>
                <div className="absolute  bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full w-2 h-2"></div>
              </div>
              <div className="flex flex-col items-center justify-start no-scrollbar gap-1">
                <p className="text-red-500 text-sm w-full underline">{currentFile.error.title}</p>
                <p className="text-red-500 text-sm w-full">
                  <div
                    dangerouslySetInnerHTML={{ __html: currentFile.error.description }}
                    className="text-justify text-sm"
                  />
                </p>
              </div>
            </div>
          </td>
        </tr>
      }
    </>
  );
};