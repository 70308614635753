const data = [
  {
    mail: "john.doe239@fakeinbox.com",
  },
  {
    mail: "lucy.skye88@randommail.org",
  },
  {
    mail: "fake.account745@trashmail.net",
  },
  {
    mail: "anon1234@tempmailbox.com",
  },
  {
    mail: "phantom.user54@noemail.org",
  },
  {
    mail: "emma.shine93@ghostmail.com",
  },
];

export default data;
