import React from "react";
import { PATH_IMG } from "../../../../../constants/constant";

interface SmallElemCheckProps {
  text: string;
}

export default function SmallElemCheck({ text }: SmallElemCheckProps) {
  return (
    <div className="flex items-center gap-1 font-inter font-normal text-sm leading-5 text-[#23a379]">
      <img src={`${PATH_IMG}/checkmarkCircle.svg`} alt="#" />
      <p>{text}</p>
    </div>
  );
}
