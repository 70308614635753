import React, { useState } from "react";
import DropdownSelect from "../components/elements/dropdown/DropdownSelect";
import { PATH_IMG } from "../../../constants/constant";
import { GenAiPermissions } from "./type/permissionsTypes";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

interface LayerControlSectionGenAIProps {
  index: number;
  onRemove: () => void;
  onAdd: () => void;
  isLast: boolean;
  isOnly: boolean;
  item: GenAiPermissions;
  handleGenAIPermissions: (
    permissions: GenAiPermissions,
    index: number
  ) => void;
}

export default function LayerControlSectionGenAI({
  index,
  onRemove,
  onAdd,
  isLast,
  isOnly,
  item,
  handleGenAIPermissions,
}: LayerControlSectionGenAIProps) {
  const { allRolesPermissions } = usePermissionsDataAdmin();

  const filterUseCaseData = () => {
    return allRolesPermissions.gen_ai
      .filter((elem) => (elem.app_name ?? "") === (item.app_name ?? ""))
      .map((elem) => elem.use_case ?? "")
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  const filterAirportsData = () => {
    return allRolesPermissions.gen_ai
      .filter(
        (elem) =>
          (elem.app_name ?? "") === (item.app_name ?? "") &&
          (elem.use_case ?? "") === (item.use_case ?? "")
      )
      .map((elem) => elem.airport ?? "")
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  const handleSelectOneValueDropdownSelectTypeApp = (value: string) => {
    item.app_name = value;
    const useCaseDataTmp = filterUseCaseData();
    if (!useCaseDataTmp.includes(item.use_case)) {
      item.use_case = useCaseDataTmp.length > 0 ? useCaseDataTmp[0] : "";
      const airportsDataTmp = filterAirportsData();
      item.airport = airportsDataTmp.length > 0 ? airportsDataTmp[0] : "";
      return handleGenAIPermissions(item, index);
    }
    handleGenAIPermissions(item, index);
  };

  const handleSelectOneValueDropdownSelectUseCase = (value: string) => {
    item.use_case = value;
    const airportsDataTmp = filterAirportsData();
    if (!airportsDataTmp.includes(item.airport)) {
      const airportsDataTmp = filterAirportsData();
      item.airport = airportsDataTmp.length > 0 ? airportsDataTmp[0] : "";
      return handleGenAIPermissions(item, index);
    }
    handleGenAIPermissions(item, index);
  };

  const handleSelectOneValueDropdownSelectAirports = (value: string) => {
    if (value === "Automatic") {
      value = "";
    }
    item.airport = value;
    handleGenAIPermissions(item, index);
  };

  const appNameData = allRolesPermissions.gen_ai
    .map((elem) => elem.app_name ?? "")
    .filter((value, index, self) => self.indexOf(value) === index);

  const useCaseData = allRolesPermissions.gen_ai
    .filter((elem) => (elem.app_name ?? "") === (item.app_name ?? ""))
    .map((elem) => elem.use_case ?? "")
    .filter((value, index, self) => self.indexOf(value) === index);

  const airportsData = allRolesPermissions.gen_ai
    .filter((elem) => (elem.app_name ?? "") === (item.app_name ?? ""))
    .filter((elem) => (elem.use_case ?? "") === (item.use_case ?? ""))
    .map((elem) => elem.airport ?? "")
    .filter((value, index, self) => self.indexOf(value) === index);

  airportsData.unshift("Automatic");

  const displayAirportsData = (): boolean => {
    const resFilter = allRolesPermissions.gen_ai.filter(
      (elem) =>
        (elem.app_name ?? "") === (item.app_name ?? "") &&
        (elem.use_case ?? "") === (item.use_case ?? "")
    );
    if (resFilter.length === 0) return false;

    if (resFilter.some((elem) => elem.access_type === "Airport")) return true;

    return false;
  };

  const isAirportDisplay = displayAirportsData();

  return (
    <div className="mt-4 flex items-center gap-2">
      <DropdownSelect
        title="Name of app"
        width={isAirportDisplay ? "200px" : "300px"}
        widthSearchBar={isAirportDisplay ? "180px" : "280px"}
        selectedValue={item.app_name || ""}
        data={appNameData}
        handleSelectOneValue={handleSelectOneValueDropdownSelectTypeApp}
      />
      <DropdownSelect
        title="Use case"
        width={isAirportDisplay ? "200px" : "300px"}
        widthSearchBar={isAirportDisplay ? "180px" : "280px"}
        selectedValue={item.use_case || ""}
        data={useCaseData}
        handleSelectOneValue={handleSelectOneValueDropdownSelectUseCase}
      />

      {isAirportDisplay && (
        <DropdownSelect
          title="Airports"
          width="200px"
          widthSearchBar="180px"
          selectedValue={item.airport == "" ? "Automatic" : item.airport}
          data={airportsData}
          handleSelectOneValue={handleSelectOneValueDropdownSelectAirports}
        />
      )}

      {isOnly ? (
        <img
          onClick={onAdd}
          className="mt-5 cursor-pointer"
          src={`${PATH_IMG}/plusCircle.svg`}
          alt="Add Layer"
        />
      ) : isLast ? (
        <>
          <img
            onClick={onRemove}
            className="mt-5 cursor-pointer"
            src={`${PATH_IMG}/minusCircle.svg`}
            alt="Remove Layer"
          />
          <img
            onClick={onAdd}
            className="mt-5 cursor-pointer"
            src={`${PATH_IMG}/plusCircle.svg`}
            alt="Add Layer"
          />
        </>
      ) : (
        <img
          onClick={onRemove}
          className="mt-5 cursor-pointer"
          src={`${PATH_IMG}/minusCircle.svg`}
          alt="Remove Layer"
        />
      )}
    </div>
  );
}
