import React, { useRef } from "react";

import { PATH_IMG } from "../../../constants/constant";
import useClickOutside from "../../../hooks/useClickOutside";

interface ModalErrorUserProps {
  handleModalErrorUser: () => void;
  errorMessage: string;
}

export default function ModalErrorUser({
  handleModalErrorUser,
  errorMessage,
}: ModalErrorUserProps) {
  const closeWindow = useRef<HTMLDivElement>(null);
  useClickOutside(closeWindow, () => handleModalErrorUser());

  return (
    <div>
      <div className="fixed inset-0  bg-[#344054B2] backdrop-blur-[10px]  z-30 transition-all duration-300"></div>
      <div
        ref={closeWindow}
        className=" font-inter  fixed w-[400px] h-[303px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-br from-blue-50 to-white p-7 z-30 rounded-xl shadow-2xl border border-blue-100 overflow-y-auto overflow-x-hidden"
      >
        <div className="flex justify-between items-center">
          <img src={`${PATH_IMG}/atention.svg`} alt="#" />
          <img
            onClick={handleModalErrorUser}
            className="mb-3 cursor-pointer"
            src={`${PATH_IMG}/cross.svg`}
            alt="#"
          />
        </div>

        <p className="font-semibold text-lg leading-7 mt-2">{errorMessage}</p>
      </div>
    </div>
  );
}
