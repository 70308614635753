import React from "react";
import { useState } from "react";
import data from "../../../AdminManagementDataAnalytics/dataAssignedUserList";
import { PATH_IMG } from "../../../../../constants/constant";

interface TableAssignedUserListProps {
  handleDeleteList: (name: string) => void;
}

export default function TableAssignedUserList({
  handleDeleteList,
}: TableAssignedUserListProps) {
  const [listToDelete, setListToDelete] = useState<string[]>([]);

  const handleDelete = (name: string) => {
    if (listToDelete.includes(name)) {
      setListToDelete(listToDelete.filter((item) => item !== name));
    } else {
      setListToDelete([...listToDelete, name]);
    }
    handleDeleteList(name);
  };

  return (
    <div>
      <div className="w-full py-5 font-inter m-auto rounded-lg">
        <table className="w-full table-fixed border-collapse border-y rounded-lg">
          <thead className="sticky top-0 bg-gray-100 z-10">
            <tr className="text-left text-xs leading-5 text-gray-500 h-11 w-full ">
              <th className=" w-[361px] font-medium text-xs leading-5 px-2 py-2 cursor-pointer">
                <div className="flex gap-2 items-center">
                  {listToDelete.length > 0 ? (
                    <img src={`${PATH_IMG}/checkboxModal.svg`} alt="" />
                  ) : (
                    <img src={`${PATH_IMG}/checkboxBase.svg`} alt="" />
                  )}

                  <div className="flex  items-center">
                    UPN
                    <img
                      className="pl-1 "
                      src={`${PATH_IMG}/arrowDown.svg`}
                      alt="#"
                    />
                  </div>
                </div>
              </th>
              <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 ">
                Full name
                <img
                  className="pl-2 inline-block"
                  src={`${PATH_IMG}/arrowUp.svg`}
                  alt="#"
                />
              </th>
              <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 ">
                User type
                <img
                  className="pl-2 inline-block"
                  src={`${PATH_IMG}/arrowUp.svg`}
                  alt="#"
                />
              </th>

              <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5">
                Business Unit
                <img
                  className="pl-2 inline-block"
                  src={`${PATH_IMG}/arrowUp.svg`}
                  alt="#"
                />
              </th>
              <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5">
                Airport
                <img
                  className="pl-2 inline-block"
                  src={`${PATH_IMG}/arrowUp.svg`}
                  alt="#"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className="h-11 w-full cursor-pointer border-b"
                onClick={() => handleDelete(item.fullName)}
              >
                <td className="flex gap-2 px-2 py-2 font-medium text-sm leading-6 text-[#101828]">
                  {listToDelete.includes(item.fullName) ? (
                    <img src={`${PATH_IMG}/checkboxCheck.svg`} alt="" />
                  ) : (
                    <img src={`${PATH_IMG}/checkboxBase.svg`} alt="" />
                  )}
                  <img src={`${PATH_IMG}/user.svg`} alt="#" />
                  {item.upn}
                </td>
                <td className="px-2 py-2 font-medium text-sm leading-5 text-[#101828]">
                  {item.fullName}
                </td>
                <td className="px-2 py-2 font-medium text-xs leading-5">
                  {item.userType == "Premium" ? (
                    <span className="text-[#b54708] bg-[#fffaeb] p-1 rounded-lg">
                      {item.userType}
                    </span>
                  ) : item.userType == "VIP" ? (
                    <span className="text-[#c11574] bg-[#fdf2fa] p-1 rounded-lg">
                      {item.userType}
                    </span>
                  ) : (
                    <span className="text-[#3538cd] bg-[#eef4ff] p-1 rounded-lg">
                      {item.userType}
                    </span>
                  )}
                </td>
                <td className="px-2 py-2 font-medium text-sm leading-5 text-[#101828]">
                  {item.businessUnit}
                </td>
                <td className="px-2 py-2 font-medium text-sm leading-5 text-[#101828]">
                  {item.airport}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
