import React from "react";

import { useState, useRef } from "react";

import DropdownPermissionUser from "./DropdownPermissionUser";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import ModalHeader from "../components/modals/ModalHeader";
import ElemInsideModalHeaderAirport from "./ElemInsideModalHeaderAirport";
import ElemInsideModalHeaderRoleUser from "./ElemInsideModalHeaderRoleUser";
import CsvFileModal from "../AdminManagementsUsers/CsvFileModal";
import useClickOutside from "../../../hooks/useClickOutside";
import { PATH_IMG } from "../../../constants/constant";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import ModalCsvPermissions from "./ModalCsvPermisssion";

interface PermissionsManagementHeaderProps {
  handleUpdatable: (value: boolean) => void;
}

export default function PermissionsManagementHeader({
  handleUpdatable,
}: PermissionsManagementHeaderProps) {
  const [showModalAirplane, setShowModalAirplane] = useState(false);
  const [showModalUserRole, setShowModalUserRole] = useState(false);
  const [showModalCsv, setShowModalCsv] = useState(false);
  // const [upn, setUpn] = useState("");
  const modalAirplane = useRef<HTMLDivElement>(null);
  const modalUserRole = useRef<HTMLDivElement>(null);
  const modalCsv = useRef<HTMLDivElement>(null);
  useClickOutside(modalAirplane, () => setShowModalAirplane(false));
  useClickOutside(modalUserRole, () => setShowModalUserRole(false));
  useClickOutside(modalCsv, () => setShowModalCsv(false));

  const { usersInAppDirectory } = usePermissionsDataAdmin();

  const handleCloseModalAirplane = () => {
    setShowModalAirplane(false);
  };

  const handleCloseModalUserRole = () => {
    setShowModalUserRole(false);
  };

  const handleShowModalCsv = (value: boolean) => {
    setShowModalCsv(value);
  };

  return (
    <div className="flex m-auto bg-white justify-between w-full place-items-end">
      <DropdownPermissionUser
        title="Users"
        handleUpdatable={handleUpdatable}
        list={usersInAppDirectory.map((user) => user.upn)}
      />
      <div className="w-[619px] h-11 flex justify-between">
        <div className="relative" ref={modalAirplane}>
          <div
            className="rounded-lg"
            style={{
              boxShadow: showModalAirplane
                ? "0px 0px 0px 4px #00428926, 0px 1px 2px 0px #1018280D"
                : "0px 1px 2px 0px #1018280D",
              border: showModalAirplane
                ? "1px solid #004289"
                : "1px solid transparent",
            }}
            onClick={() => {
              setShowModalAirplane(true);
              setShowModalUserRole(false);
              setShowModalCsv(false);
            }}
          >
            <ButtonSvgText
              logo={`${PATH_IMG}/airplane.svg`}
              text="Search user by airport"
              width="211"
              height="44"
            />
          </div>
          {showModalAirplane && (
            <ModalHeader width="431" height="266" left="left-0">
              <ElemInsideModalHeaderAirport
                handleCloseModal={handleCloseModalAirplane}
              />
            </ModalHeader>
          )}
        </div>
        <div className="relative" ref={modalUserRole}>
          <div
            className="rounded-lg m-0 p-0"
            style={{
              boxShadow: showModalUserRole
                ? "0px 0px 0px 4px #00428926, 0px 1px 2px 0px #1018280D"
                : "0px 1px 2px 0px #1018280D",
              border: showModalUserRole
                ? "1px solid #004289"
                : "1px solid transparent",
            }}
            onClick={() => {
              setShowModalUserRole(true);
              setShowModalAirplane(false);
              setShowModalCsv(false);
            }}
          >
            <ButtonSvgText
              logo={`${PATH_IMG}/userSearch.svg`}
              text="Search user by role"
              width="221"
              height="44"
            />
          </div>
          {showModalUserRole && (
            <ModalHeader width="431" height="190" right="right-0">
              <ElemInsideModalHeaderRoleUser
                handleCloseModal={handleCloseModalUserRole}
              />
            </ModalHeader>
          )}
        </div>
        <div className="relative" ref={modalCsv}>
          <div
            className="rounded-lg"
            style={{
              boxShadow: "0px 1px 2px 0px #1018280D",
              border: showModalCsv
                ? "3px solid #00428926"
                : "3px solid transparent",
            }}
            onClick={() => {
              setShowModalCsv((prev: boolean) => !prev);
              setShowModalUserRole(false);
              setShowModalAirplane(false);
            }}
          >
            <ButtonSvgText
              logo={PATH_IMG + "/fileCsvWhite.svg"}
              text="Select from file"
              width="163"
              height="44"
              backgroundColor="#004289"
            />
          </div>
          {showModalCsv && (
            <ModalCsvPermissions handleShowModal={handleShowModalCsv} />
          )}
        </div>
      </div>
    </div>
  );
}
