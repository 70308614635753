import React from "react";
import UserManagementHeader from "./UserManagementHeader";
import TitleH1 from "../components/elements/titles/TitleH1";
import UserManagementFooter from "./UserManagementFooter";
import SettingsForm from "./SettingsForm";
import UserManagementAccountStatus from "./UserManagementAccountStatus";
import { SideBar } from "../components/SideBar";
import ModalLoading from "../components/modals/ModalLoading";
import ModalGreenAlert from "../components/modals/ModalGreenAlert";
import {
  UserDataAdminProvider,
  useUserDataAdmin,
} from "./context/UserDataAdminProvider";
import "./index.css";

function AdminManagementUsersWithProvider() {
  const {
    copieData,
    createData,
    isCreateUser,
    isLoading,
    displayAlert,
    endLoading,
  } = useUserDataAdmin();

  return (
    <div className="flex h-screen bg-[#f2f2f2]">
      {/* <SideBar /> */}

      <div className="w-[1605px] bg-[#f2f2f2] pl-10">
        <div className="">
          <TitleH1 text="Users" />
        </div>

        <div className="w-[1500px] ">
          <div className="flex flex-col justify-around bg-white p-4 rounded-lg rounded-b-sm">
            <div className="h-[44px] flex flex-col justify-center">
              <UserManagementHeader />
            </div>
          </div>

          <div className="flex justify-between mt-[1px] bg-white p-4 rounded-sm">
            <SettingsForm copieData={isCreateUser ? createData : copieData} />
            <UserManagementAccountStatus
              copieData={isCreateUser ? createData : copieData}
            />
          </div>

          <div
            className="h-[70px] flex flex-col justify-center bg-white p-4 rounded-lg mt-[1px] rounded-t-sm "
            style={{ boxShadow: "0px 4px 4px 0px #0000000D" }}
          >
            <UserManagementFooter
              copieData={isCreateUser ? createData : copieData}
            />
          </div>
        </div>
      </div>
      {(isLoading || endLoading) && <ModalLoading endLoading={endLoading} />}
      {displayAlert.alert && <ModalGreenAlert text={displayAlert.text} />}
    </div>
  );
}

function AdminManagementUsers() {
  return (
    <UserDataAdminProvider>
      <AdminManagementUsersWithProvider />
    </UserDataAdminProvider>
  );
}

export default AdminManagementUsers;
