import React, { useEffect, useRef, useState } from "react";
import SearchInput from "../components/elements/inputs/SearchInput";
import PermissionsListElements from "./PermissionsListElements";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import MiniLoader from "../AdminManagementsUsers/MiniLoader";

const USERS_BATCH = 50;

export default function PermissionContainerList() {
  const { loadingPermissionsUser, usersSelected } = usePermissionsDataAdmin();
  const [visibleCount, setVisibleCount] = useState(USERS_BATCH);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleSearchQuery = (searchQuery: string) => {};

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - 100;

      if (isNearBottom && visibleCount < usersSelected.length) {
        setVisibleCount((prev) => prev + USERS_BATCH);
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [visibleCount, usersSelected.length]);

  const visibleUsers = usersSelected.slice(0, visibleCount);

  return (
    <div className="bg-white w-[701px] font-inter">
      <h3 className="p-3 font-medium text-base leading-5 text-black">
        Permissions list
      </h3>
      <div
        ref={scrollContainerRef}
        className="p-3 bg-[#F9FAFB] w-[701px] h-[439px] flex flex-col overflow-y-auto"
      >
        <SearchInput handleSearchQuery={handleSearchQuery} width="640px" />
        {loadingPermissionsUser ? (
          <div className="flex justify-center items-center h-[350px]">
            <MiniLoader size="300px" />
          </div>
        ) : (
          <PermissionsListElements users={visibleUsers} />
        )}
      </div>
    </div>
  );
}
