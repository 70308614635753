import React from "react";
import { useState } from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import SearchInput from "../components/elements/inputs/SearchInput";
import dataMail from "./dataEmailUsers";
import { PATH_IMG } from "../../../constants/constant";

export default function AddUserToThisListProduct() {
  const [searchQuery, setSearchQuery] = useState("");
  const [mailSelected, setMailSelected] = useState<string[]>([]);
  const handleSearchQuery = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const handleMailSelected = (mail: string) => {
    if (mailSelected.includes(mail)) {
      setMailSelected(mailSelected.filter((elem) => elem !== mail));
    } else {
      setMailSelected([...mailSelected, mail]);
    }
  };

  return (
    <div className="w-full  h-full rounded-lg  font-inter overflow-auto">
      <div className="w-full rounded-lg  flex justify-between align-middle items-center border p-2">
        <h3 className="font-normal text-base leading-6 text-[#667085]">
          Selected:{" "}
          <span className="text-[#101828]">{mailSelected.length}</span>
        </h3>
        <ButtonClassic
          text="Apply"
          width="68"
          height="36"
          backgroundColor="#004289"
        />
      </div>
      <div className="flex justify-center items-center mt-4">
        <SearchInput width="450px" handleSearchQuery={handleSearchQuery} />
      </div>
      <div className="mt-4">
        {dataMail.map((elem) => {
          return (
            elem.mail.includes(searchQuery) && (
              <div
                className="h-11 p-3 flex justify-between align-middle items-center border-b border-[#EAECFO] cursor-pointer"
                onClick={() => handleMailSelected(elem.mail)}
              >
                <div className="flex gap-2 font-medium text-base leading-6 text-[#101828] cursor-pointer">
                  <img src={`${PATH_IMG}/user.svg`} alt="#" />
                  <p>{elem.mail}</p>
                </div>
                {mailSelected.includes(elem.mail) && (
                  <img src={`${PATH_IMG}/checkBlue.svg`} alt="#" />
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
