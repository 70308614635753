import React, { useEffect } from "react";
import { useState } from "react";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";

import ModalRevision from "../components/modals/ModalRevision";
import TableRevisionPermission from "../components/elements/tables/TableRevisionPermission";
import { PATH_IMG } from "../../../constants/constant";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

interface PermissionsManagementFooterProps {
  isUpdatable: boolean;
}

export default function PermissionsManagementFooter({
  isUpdatable,
}: PermissionsManagementFooterProps) {
  const [showModalRevisionUser, setShowModalRevisionUser] = useState(false);
  const [countUsersModifications, setCountUsersModifications] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleCurrentPage = (page: number, nbrOfPages: number) => {
    if (page >= 1 && page <= nbrOfPages) {
      setCurrentPage(page);
    }
  };

  const {
    updatePermissionsUsers,
    resetPermissions,
    permissionsToAdd,
    permissionsToDelete,
    usersInAppDirectory,
  } = usePermissionsDataAdmin();

  const countUniqueKeys = <T, U>(
    map1: ReadonlyMap<string, T>,
    map2: ReadonlyMap<string, U>
  ): number => {
    const uniqueKeys = new Set<string>();
    map1.forEach((_, key) => uniqueKeys.add(key));
    map2.forEach((_, key) => uniqueKeys.add(key));
    return uniqueKeys.size;
  };

  const getNumbersOfPermissions = (idVINCI: string) => {
    let count = 0;
    const permissionsAdd = permissionsToAdd.get(idVINCI);
    const permissionsDelete = permissionsToDelete.get(idVINCI);
    if (permissionsAdd) {
      count += permissionsAdd.admin.length;
      count += permissionsAdd.dashboard.length;
      count += permissionsAdd.data_gov.length;
      count += permissionsAdd.gen_ai.length;
    }
    if (permissionsDelete) {
      count += permissionsDelete.admin.length;
      count += permissionsDelete.dashboard.length;
      count += permissionsDelete.data_gov.length;
      count += permissionsDelete.gen_ai.length;
    }

    return count;
  };

  const getUpn = (idVINCI: string) => {
    const upn = usersInAppDirectory.find(
      (user) => user.idVINCI === idVINCI
    )?.upn;
    return upn ? upn : "";
  };

  const getAllKeysWithUpn = (
    map1: Map<string, any>,
    map2: Map<string, any>
  ): { idVINCI: string; upn: string; numbersOfPermissions: number }[] => {
    return Array.from(
      new Set([...Array.from(map1.keys()), ...Array.from(map2.keys())])
    ).map((idVINCI) => ({
      idVINCI,
      upn: getUpn(idVINCI),
      numbersOfPermissions: getNumbersOfPermissions(idVINCI),
    }));
  };

  const handleRevisionUser = () => {
    setShowModalRevisionUser(!showModalRevisionUser);
    setCurrentPage(1);
  };

  // const allKeysWithUpn = getAllKeysWithUpn(
  //   permissionsToAdd,
  //   permissionsToDelete
  // );

  useEffect(() => {
    setCountUsersModifications(
      countUniqueKeys(permissionsToAdd, permissionsToDelete)
    );
  }, [permissionsToAdd, permissionsToDelete]);

  return (
    <div className="flex justify-between w-[1464px] h-11 items-center">
      {countUsersModifications > 0 ? (
        <div
          onClick={handleRevisionUser}
          style={
            isUpdatable ? { visibility: "visible" } : { visibility: "hidden" }
          }
        >
          <ButtonSvgText
            text={"Revisions: " + countUsersModifications}
            logo={`${PATH_IMG}/time.svg`}
            width="148"
            height="40"
          />
        </div>
      ) : (
        <div></div>
      )}
      <div
        className="flex gap-4"
        style={isUpdatable ? { opacity: "1" } : { opacity: "0.5" }}
      >
        <div className="cursor-pointer" onClick={resetPermissions}>
          <ButtonClassic text="Reset" width="104" height="40" />
        </div>
        <div onClick={() => updatePermissionsUsers()}>
          <ButtonSvgText
            text="Update"
            width="104"
            height="40"
            logo={`${PATH_IMG}/reload.svg`}
            backgroundColor="#004289"
          />
        </div>
      </div>

      {showModalRevisionUser && (
        <ModalRevision
          currentPage={currentPage}
          dataRevisions={getAllKeysWithUpn(
            permissionsToAdd,
            permissionsToDelete
          )}
          handleCurrentPage={handleCurrentPage}
          handleRevisionUser={handleRevisionUser}
          title="Permission"
        >
          <TableRevisionPermission
            dataPermissions={getAllKeysWithUpn(
              permissionsToAdd,
              permissionsToDelete
            )}
            currentPage={currentPage}
          />
        </ModalRevision>
      )}
    </div>
  );
}
