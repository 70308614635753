import React from "react";
import { useState } from "react";
import { PATH_IMG } from "../../constants/constant";
import ButtonClassic from "../../pages/AdminManagement/components/elements/buttons/ButtonClassic";
import { useAuth } from "../../providers";
import { useInfoUser } from "../../providers/InfoUser";

interface stokeInputInformationData {
  message: string;
  creditAsked: number;
}

interface ModalSendGenAICreditProps {
  handleCloseModalSendGenAICredit: (value: boolean) => void;
  sideBarClass: string;
}

export default function ModalSendGenAICredit({
  handleCloseModalSendGenAICredit,
  sideBarClass,
}: ModalSendGenAICreditProps) {
  const { token } = useAuth();
  const { handleHasRequestedCredit } = useInfoUser();
  const [witchModalIsOpen, setWitchModalIsOpen] =
    useState<string>("sendGenAICredit");

  const handleSendRequestGenAi = async (info: stokeInputInformationData) => {
    // Call API to send request
    try {
      console.log("info", info);
      const response = await fetch(
        "/api/admin/credits_management/create_credits_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(info),
        }
      );
      console.log("response asked credit_management", response);
      if (!response.ok) {
        handleCloseModalSendGenAICredit(false);
        return;
      }
      handleHasRequestedCredit(true);
      setWitchModalIsOpen("send");
    } catch (error) {
      console.error("handleSendRequestGenAi error:", error);
      handleCloseModalSendGenAICredit(false);
    }

    // console.log("info", info);
    // handleHasRequestedCredit(true);
    // setWitchModalIsOpen("send");
  };

  return (
    <div
      className="fixed top-0  h-full inset-0 bg-[#344054b2] z-10 flex items-center justify-center"
      style={sideBarClass === "w-20" ? { left: "80px" } : { left: "240px" }}
    >
      {witchModalIsOpen === "sendGenAICredit" ? (
        <ModalGetMoreGenAi
          handleCloseModalSendGenAICredit={handleCloseModalSendGenAICredit}
          handleSendRequestGenAi={handleSendRequestGenAi}
        />
      ) : (
        <ModalThanks
          handleCloseModalSendGenAICredit={handleCloseModalSendGenAICredit}
        />
      )}
    </div>
  );
}

interface ModalThanksProps {
  handleCloseModalSendGenAICredit: (value: boolean) => void;
}

function ModalThanks({ handleCloseModalSendGenAICredit }: ModalThanksProps) {
  const [count, setCount] = useState(5);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  if (count === 0) {
    handleCloseModalSendGenAICredit(false);
  }

  return (
    <div className="mb-96 font-inter bg-white z-10 w-[400px] h-[236px] p-3 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
      <div className="flex flex-col gap-3">
        <div className="flex justify-center items-center">
          <img src={`${PATH_IMG}/timeAfterRequest.svg`} alt="" />
        </div>
        <h2 className="text-[101828] font-semibold text-lg leading-7 text-center">
          Thank you
        </h2>
        <p className="font-normal text-sm leading-5 text-center text-[#667085]">
          Your request is being reviewed.
        </p>
        <div
          className="flex justify-center items-center mt-2"
          onClick={() => handleCloseModalSendGenAICredit(false)}
        >
          <div style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}>
            <ButtonClassic
              width="159"
              height="44"
              text={`Close (${count}s)`}
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface ModalGetMoreGenAiProps {
  handleCloseModalSendGenAICredit: (value: boolean) => void;
  handleSendRequestGenAi: (
    stokeInputInformation: stokeInputInformationData
  ) => void;
}

function ModalGetMoreGenAi({
  handleCloseModalSendGenAICredit,
  handleSendRequestGenAi,
}: ModalGetMoreGenAiProps) {
  const [stokeInputInformation, setSttokeInputInformation] =
    useState<stokeInputInformationData>({ message: "", creditAsked: 500 });
  return (
    <div className="font-inter bg-white z-10 w-[400px] h-[489px] p-3 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
      <div>
        <img src={`${PATH_IMG}/iconLosangeModal.svg`} alt="#" />
        <div className="px-4">
          <h2 className="font-semibold text-lg leading-7 text-[#101828]">
            Get more GenAI credits
          </h2>
          <p className="mt-2 mb-2 font-normal text-sm leading-5 text-[#667085]">
            Please fill the fields below to specify the required amount of GenAI
            credits and provide any feedback you may have.
          </p>

          <label
            htmlFor="amout"
            className=" font-medium text-sm leading-5 text-[#344054]"
          >
            Amount
          </label>

          <div className="relative mt-1 mb-5">
            <input
              style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
              className=" pl-4 placeholder-[#667085] font-normal text-base leading-6 w-[352px] h-11 rounded-lg border border-[#D0D5DD]"
              id="amout"
              type="text"
              value={
                stokeInputInformation.creditAsked > 0
                  ? stokeInputInformation.creditAsked
                  : ""
              }
              onChange={(e) =>
                setSttokeInputInformation({
                  ...stokeInputInformation,
                  creditAsked: parseInt(e.target.value),
                })
              }
            />

            <div className="absolute right-3 top-2 flex flex-col gap-3">
              <div
                onClick={() => {
                  setSttokeInputInformation((prev) => ({
                    ...prev,
                    creditAsked: prev.creditAsked + 500,
                  }));
                }}
              >
                <img
                  src={`${PATH_IMG}/miniArrowUp.svg`}
                  alt="#"
                  className=" cursor-pointer"
                />
              </div>
              <div
                onClick={() =>
                  setSttokeInputInformation((prev) => ({
                    ...prev,
                    creditAsked: prev.creditAsked - 500,
                  }))
                }
              >
                <img
                  src={`${PATH_IMG}/miniArrowDown.svg`}
                  alt="#"
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>

          <label
            className=" font-medium text-sm leading-5 text-[#344054]"
            htmlFor="message"
          >
            Message (optional)
          </label>

          <textarea
            value={stokeInputInformation.message}
            onChange={(e) =>
              setSttokeInputInformation({
                ...stokeInputInformation,
                message: e.target.value,
              })
            }
            style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            className="mt-2 font-medium text-sm leading-5 text-[#344054] w-[352px] min-h-[85px] border border-[#d0d5dd] rounded-lg resize-none p-2"
            id="message"
          />

          <div className="flex justify-between items-center mt-10 w-[352px] ">
            <div
              style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
              className="border border-[#D0D5DD] rounded-lg"
              onClick={() => handleCloseModalSendGenAICredit(false)}
            >
              <ButtonClassic width="170" height="44" text="Cancel" />
            </div>
            <div
              style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
              className=" border-[#004289] rounded-lg"
              onClick={() => handleSendRequestGenAi(stokeInputInformation)}
            >
              <ButtonClassic
                width="170"
                height="44"
                text="Send request"
                backgroundColor="#004289"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
