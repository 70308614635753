import React from "react";

type PercentageCircleProps = {
  percentage: number; // Le pourcentage à afficher (0-100)
  size?: number; // Taille du cercle
  strokeWidth?: number; // Largeur du cercle
  strokeColor?: string; // Couleur du cercle
};

const PercentageCircle: React.FC<PercentageCircleProps> = ({
  percentage,
  size = 100,
  strokeWidth = 10,
  strokeColor = "#4caf50",
}) => {
  const radius = (size - strokeWidth) / 2; // Rayon du cercle
  const circumference = 2 * Math.PI * radius; // Circonférence
  const offset = circumference - (percentage / 100) * circumference; // Décalage pour créer le pourcentage

  return (
    <div className="flex items-center justify-center">
      <svg width={size} height={size} className="relative">
        {/* Cercle de fond */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Cercle de pourcentage */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          style={{
            transform: "rotate(-90deg)",
            transformOrigin: "50% 50%",
            transition: "stroke-dashoffset 0.5s ease",
          }}
        />
      </svg>
      {/* Texte au centre */}
      <div
        className="absolute text-center font-bold"
        style={{
          width: size,
          height: size,
          lineHeight: `${size}px`,
          fontSize: size / 5,
        }}
      >
        {percentage}%
      </div>
    </div>
  );
};

export default PercentageCircle;
