import React, { useState } from "react";
import ToggleInput from "../components/elements/inputs/ToggleInput";
import DropdownSelect from "../components/elements/dropdown/DropdownSelect";
import { PATH_IMG } from "../../../constants/constant";
import DropdownSelectMultiUsers from "../components/elements/dropdown/DropdownSelectMultiUsers";
import { DashboardPermissions } from "./type/permissionsTypes";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

interface LayerControlSectionDashBoardProps {
  index: number;
  onRemove: () => void;
  onAdd: () => void;
  isLast: boolean;
  isOnly: boolean;
  item: DashboardPermissions;
  handleDashboardPermissions: (
    permissions: DashboardPermissions,
    index: number
  ) => void;
}

export default function LayerControlSectionDashBoard({
  index,
  onRemove,
  onAdd,
  isLast,
  isOnly,
  item,
  handleDashboardPermissions,
}: LayerControlSectionDashBoardProps) {
  const { allRolesPermissions } = usePermissionsDataAdmin();

  const filterDashboardData = () => {
    return allRolesPermissions.dashboard
      .filter(
        (elem) => (elem.dashboard_level ?? "") === (item.dashboard_level ?? "")
      )
      .map((elem) => elem.dashboard ?? "")
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  // const filterBusinessUnitData = () => {
  //   return allRolesPermissions.dashboard
  //     .filter(
  //       (elem) =>
  //         (elem.dashboard ?? "") === (item.dashboard ?? "") &&
  //         (elem.dashboard_level ?? "") === (item.dashboard_level ?? "")
  //     )
  //     .map((elem) => elem.business_unit ?? "")
  //     .filter((value, index, self) => self.indexOf(value) === index);
  // };

  const filterAirportsData = () => {
    return allRolesPermissions.dashboard
      .filter(
        (elem) =>
          (elem.dashboard ?? "") === (item.dashboard ?? "") &&
          (elem.dashboard_level ?? "") === (item.dashboard_level ?? "")
      )
      .map((elem) => elem.airport ?? "")
      .filter((value, index, self) => self.indexOf(value) === index);
  };

  const handleToggle = (value: boolean) => {
    item.dashboard_level = value ? "L1" : "L2";
    const dashboardDataNext = filterDashboardData();
    if (!dashboardDataNext.includes(item.dashboard)) {
      item.dashboard = dashboardDataNext.length > 1 ? dashboardDataNext[0] : "";
      const airportsDataNext = filterAirportsData();
      if (!airportsDataNext.includes(item.airport)) {
        item.airport = airportsDataNext.length > 1 ? airportsDataNext[0] : "";
      }
      handleDashboardPermissions(item, index);
      return;
    }
    handleDashboardPermissions(item, index);
  };

  const handleSelectOneValueDropdownSelectDashboard = (value: string) => {
    item.dashboard = value;
    const airportsDataNext = filterAirportsData();
    if (!airportsDataNext.includes(item.airport)) {
      item.airport = airportsDataNext.length > 1 ? airportsDataNext[0] : "";
    }
    handleDashboardPermissions(item, index);
  };

  // const handleSelectOneValueDropdownSelectBusinessUnit = (value: string) => {
  //   item.business_unit = value;
  //   const airportsDataNext = filterAirportsData();
  //   if (!airportsDataNext.includes(item.airport)) {
  //     item.airport = "";
  //   }
  //   handleDashboardPermissions(item, index);
  // };

  const handleSelectValueDropdownSelectAirports = (values: string) => {
    if (values === "Automatic") {
      values = "";
    }
    item.airport = values;
    handleDashboardPermissions(item, index);
  };

  const dashboardData = allRolesPermissions.dashboard
    .filter(
      (elem) => (elem.dashboard_level ?? "") === (item.dashboard_level ?? "")
    )
    .map((elem) => elem.dashboard ?? "")
    .filter((value, index, self) => self.indexOf(value) === index);

  // const businessUnitData = allRolesPermissions.dashboard
  //   .filter(
  //     (elem) =>
  //       (elem.dashboard ?? "") === (item.dashboard ?? "") &&
  //       (elem.dashboard_level ?? "") === (item.dashboard_level ?? "")
  //   )
  //   .map((elem) => elem.business_unit ?? "")
  //   .filter((value, index, self) => self.indexOf(value) === index);

  const airportsData = allRolesPermissions.dashboard
    .filter(
      (elem) =>
        (elem.dashboard ?? "") === (item.dashboard ?? "") &&
        (elem.dashboard_level ?? "") === (item.dashboard_level ?? "")
    )
    .map((elem) => elem.airport ?? "Automatic")
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className="mt-4 flex items-center gap-2">
      <ToggleInput
        title="Layer"
        width="200"
        height="44"
        firstTitle="L1"
        secondTitle="L2"
        idFirst={`L1-${index}`}
        idSecond={`L2-${index}`}
        selectedValue={item.dashboard_level}
        handleToggle={handleToggle}
      />
      <DropdownSelect
        title="Dashboard"
        width="200px"
        widthSearchBar="180px"
        selectedValue={item.dashboard}
        data={dashboardData}
        handleSelectOneValue={handleSelectOneValueDropdownSelectDashboard}
      />

      {/* <DropdownSelect
        title="BU"
        width="165px"
        widthSearchBar="150px"
        data={businessUnitData}
        selectedValue={item.business_unit}
        handleSelectOneValue={handleSelectOneValueDropdownSelectBusinessUnit}
      /> */}
      <DropdownSelect
        title="Airports"
        width="200px"
        widthSearchBar="180px"
        data={airportsData}
        selectedValue={item.airport == "" ? "Automatic" : item.airport}
        handleSelectOneValue={handleSelectValueDropdownSelectAirports}
      />
      {/* <DropdownSelectMultiUsers
        title="Airports"
        width="165px"
        widthSearchBar="150px"
        placeholderBar="Select"
        selectedValues={item.airport}
        options={["ATL", "DFW", "LAX", "ORD", "DEN", "JFK", "SFO"]}
        handleSelectValues={handleSelectValuesDropdownSelectAirports}
      /> */}
      {isOnly ? (
        <img
          onClick={onAdd}
          className="mt-5 cursor-pointer"
          src={`${PATH_IMG}/plusCircle.svg`}
          alt="Add Layer"
        />
      ) : isLast ? (
        <>
          <img
            onClick={onRemove}
            className="mt-5 cursor-pointer"
            src={`${PATH_IMG}/minusCircle.svg`}
            alt="Remove Layer"
          />
          <img
            onClick={onAdd}
            className="mt-5 cursor-pointer"
            src={`${PATH_IMG}/plusCircle.svg`}
            alt="Add Layer"
          />
        </>
      ) : (
        <img
          onClick={onRemove}
          className="mt-5 cursor-pointer"
          src={`${PATH_IMG}/minusCircle.svg`}
          alt="Remove Layer"
        />
      )}
    </div>
  );
}
