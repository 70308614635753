import React from "react";
import { useState } from "react";
import { PATH_IMG } from "../../../../../constants/constant";

interface SearchInputProps {
  placeholder?: string;
  width: string;
  handleSearchQuery: (searchQuery: string) => void;
}

export default function SearchInput({
  width,
  handleSearchQuery,
}: SearchInputProps) {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <div className="relative">
      <img
        className="absolute left-2 top-3"
        src={`${PATH_IMG}/search.svg`}
        alt=""
      />
      <input
        type="text"
        placeholder="Search"
        className=" h-11 pl-8 pr-4 py-1 text-base border leading-6 rounded-md focus:outline-none cursor-pointer"
        style={{ width: width, boxShadow: "0px 1px 2px 0px #1018280D" }}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          handleSearchQuery(e.target.value);
        }}
      />
    </div>
  );
}
