import React from "react";
import { useState, useEffect } from "react";
import { PATH_IMG } from "../../../../../constants/constant";

interface OptionUserBisProps {
  title: string;
  width: string;
  dataToSelect?: any;
  handleChangementExt?: (value: string) => void;
  valueExt?: string;
  arrayToSelect?: string[];
}

function OptionUserBis({
  width,
  title,
  handleChangementExt,
  valueExt,
  dataToSelect,
  arrayToSelect,
}: OptionUserBisProps) {
  const [dataValue, setDataValue] = useState<string>(valueExt || "");
  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    if (valueExt !== undefined) setDataValue(valueExt);
  }, [valueExt]);

  const handleOpen = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div
      className="font-inter relative h-[70px] flex flex-col justify-between"
      style={{ width: `${width}px` }}
    >
      <label htmlFor={title} className="text-sm font-medium">
        {title}
      </label>
      <button
        onClick={handleOpen}
        className="h-[44px] min-h-11 w-full text-left border border-[#D0D5DD] cursor-pointer appearance-none rounded-lg pl-4 pr-10 bg-white text-base focus:outline-none"
        style={
          isOpened
            ? {
                borderBottom: "none",
                WebkitBorderBottomLeftRadius: "0px",
                WebkitBorderBottomRightRadius: "0px",
              }
            : {}
        }
      >
        {dataValue}
      </button>
      <img
        src={`${PATH_IMG}/chevronDown.svg`}
        className="absolute z-10 right-4 top-[55%] pointer-events-none"
      />
      {isOpened && (
        <div
          className="absolute w-full top-[70px] z-20 bg-white border border-[#D0D5DD] border-t-0 rounded-b-lg shadow-md"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          {dataToSelect
            ? Object.keys(dataToSelect).map((key, index) => (
                <p
                  onClick={() => {
                    setDataValue(key);
                    handleChangementExt?.(key);
                    setIsOpened(false);
                  }}
                  key={index}
                  className="cursor-pointer h-11 p-2 pl-4 hover:bg-gray-100"
                >
                  {key}
                </p>
              ))
            : arrayToSelect && arrayToSelect.length > 0
            ? arrayToSelect.map((elem, index) => (
                <p
                  onClick={() => {
                    setDataValue(elem);
                    handleChangementExt?.(elem);
                    setIsOpened(false);
                  }}
                  key={index}
                  className="cursor-pointer h-11 p-2 pl-4 hover:bg-gray-100"
                >
                  {elem}
                </p>
              ))
            : null}
        </div>
      )}
    </div>
  );
}

export default React.memo(OptionUserBis);
