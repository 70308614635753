import React from "react";

import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import { PATH_IMG } from "../../../constants/constant";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

export default function ModalPermissionsUpdateErrors() {
  const {
    errorUpdateMessage,
    setErrorUpdateMessage,
    setHasErrorUpdate,
    usersInAppDirectory,
  } = usePermissionsDataAdmin();

  return (
    <div>
      <div className="fixed inset-0  bg-[#344054B2] backdrop-blur-[10px]  z-30 transition-all duration-300"></div>
      <div className="flex flex-col font-inter justify-between fixed w-[520px] h-[603px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-br from-blue-50 to-white p-4 z-30 rounded-xl shadow-2xl border border-blue-100 overflow-y-auto overflow-x-hidden">
        <div>
          <div className="">
            <img src={`${PATH_IMG}/atention.svg`} alt="#" />
          </div>
          <h3 className="text-center  -mt-9 font-semibold text-lg leading-7">
            Permission Update Errors
          </h3>
          <div className="mt-5 p-5">
            <div className="flex flex-col gap-2 font-normal  leading-5 text[#667085]">
              {errorUpdateMessage.map((error, index) => (
                <div key={index} className="flex flex-col gap-2">
                  <h4 className="mb-2">
                    Upn
                    {usersInAppDirectory.find(
                      (elem) => elem.idVINCI == error.idVINCI
                    )?.upn || ""}
                  </h4>
                  {error.messages.map((message, idx) => (
                    <p key={idx} className="text-[#DC6803]">
                      {message}
                    </p>
                  ))}
                  <div className="w-[440px] h-[1px] bg-[#dedede] mt-3 "></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center justify-items-center gap-3">
          <div
            onClick={() => {
              setErrorUpdateMessage([]);
              setHasErrorUpdate(false);
            }}
          >
            <ButtonClassic
              text="Close"
              width="170"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
