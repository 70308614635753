import React from "react";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import TitleH2 from "../components/elements/titles/TitleH2";
import { useState } from "react";
import CsvFileModal from "./CsvFileModal";
import { PATH_IMG } from "../../../constants/constant";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";

function UserManagementHeader() {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (value: boolean) => {
    setShowModal(value);
  };

  const { profile } = useUserDataAdmin();

  return (
    <div className="flex justify-between w-[1464px] h-11 items-center">
      <TitleH2 text="User management" />

      {profile && profile.businessUnit == "HQ" ? (
        <div className="relative">
          <div onClick={() => handleShowModal(true)}>
            <div
              className="rounded-lg"
              style={{
                boxShadow: showModal
                  ? "0px 0px 0px 4px #00428926, 0px 1px 2px 0px #1018280D"
                  : "",
                border: showModal ? "1px solid #004289" : "",
              }}
            >
              <ButtonSvgText
                width="200"
                height="44"
                logo={`${PATH_IMG}/fileCsv.svg`}
                text="Massive user creation"
              />
            </div>
          </div>
          {showModal && <CsvFileModal handleShowModal={handleShowModal} />}
        </div>
      ) : null}
    </div>
  );
}

export default UserManagementHeader;
