import React from "react";

interface MinusProps {
  width: string;
  height: string;
}

export default function Minus({ width, height }: MinusProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16663 1H12.8333"
        stroke="#ABC1D0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
