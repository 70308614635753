import React from "react";
import { PATH_IMG } from "../../../../constants/constant";

interface ModalGreenAlertProps {
  text: string;
}

export default function ModalGreenAlert({ text }: ModalGreenAlertProps) {
  return (
    <div
      className="bg-white
     w-[370px] h-[58px] absolute top-0 left-1/2 transform -translate-x-1/2  font-inter flex justify-center gap-2 items-center rounded-b-lg"
    >
      <img src={`${PATH_IMG}/checkmarkCircleGreen.svg`} alt="#" />
      <p className="text-[#23a379] font-normal text-sm leading-5">{text}</p>
    </div>
  );
}
