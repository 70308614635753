import React from "react";
import { useState } from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import { PATH_IMG } from "../../../constants/constant";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

export default function ModalErrorFileSelect() {
  const { errorsFile, setErrorsFile, setHasErrorFile } =
    usePermissionsDataAdmin();

  return (
    <div>
      <div className="fixed inset-0  bg-[#34405466]    z-20 transition-all duration-300"></div>
      <div className="  font-inter  fixed w-[500px] h-[700px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  bg-white   z-30 rounded-xl  border border-blue-100 overflow-y-auto overflow-x-hidden">
        <div className="h-4">
          <img src={`${PATH_IMG}/atention.svg`} alt="#" />
        </div>

        <div className="flex justify-center w-full">
          <h3 className="text-center font-semibold text-lg">
            Error during file selection
          </h3>
        </div>
        <div className="h-[540px] w-full mt-7 overflow-auto">
          {Object.entries(errorsFile).map(([upn, message]) => (
            <div key={upn} className="mt-3">
              <h4 className="w-full flex justify-center font-inter text-lg ">
                {upn}
              </h4>
              <p className="mt-[2px] w-full flex justify-center text-[#DC6803] text-base font-inter">
                {message}
              </p>
              <div className="w-11/12 h-[1px] bg-[#dedede] mt-1 m-auto "></div>
            </div>
          ))}
        </div>
        <div className="flex justify-center justify-items-center gap-3">
          <div
            onClick={() => {
              setErrorsFile({});
              setHasErrorFile(false);
            }}
          >
            <ButtonClassic
              text="Close"
              width="170"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
