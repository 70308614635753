import React, { useState, useRef, useEffect } from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import useClickOutside from "../../../hooks/useClickOutside";
import MiniTogle from "../components/elements/object/MiniTogle";
import { PATH_IMG } from "../../../constants/constant";

interface DropdownUserProps {
  title: string;
  list: string[];
  handleSelection: (upn: string) => void;
  valueSelected: string;
}

export default function DropdownUser({
  title,
  handleSelection,
  list,
  valueSelected,
}: DropdownUserProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [togle, setTogle] = useState(false);
  const [batchSize, setBatchSize] = useState(20);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const filteredUsers = list.filter((user) =>
    user.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const visibleUsers = filteredUsers.slice(0, batchSize);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setBatchSize((prev) => prev + 20);
      }
    }
  };

  const handleTogle = () => {
    setTogle(!togle);
  };

  const handleValidityUpn = (upn: string) => {
    const isValidEmail = (email: string): boolean => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    };

    if (!isValidEmail(upn) || upn === "" || list.includes(upn)) {
      return false;
    }

    handleSelection(upn);
    setIsOpen(false);
    setSearchQuery("");
    handleTogle();
  };

  return (
    <div className="block min-h-[70px]" ref={dropdownRef}>
      <div className="relative h-11 w-[654px] font-inter">
        <label
          htmlFor={title}
          className="h-[20px] text-sm leading-5 font-medium block text-[#344054] mb-1"
        >
          {title}
        </label>
        <button
          id={title}
          onClick={() => setIsOpen(!isOpen)}
          className="w-full p-2 pr-3 flex items-center justify-between bg-white border rounded-md hover:bg-gray-50"
        >
          <span className="text-[#101828] font-normal text-base leading-6">
            {valueSelected}
          </span>
          <img src={`${PATH_IMG}/chevronDown.svg`} alt="#" />
        </button>
        {isOpen && (
          <div className="absolute w-full h-[342px] mt-1 bg-white border rounded-md z-10">
            <div className="p-2 flex justify-between items-center">
              <div className="relative">
                <img
                  className="absolute left-2 top-3"
                  src={`${PATH_IMG}/search.svg`}
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Search"
                  className="h-11 pl-8 pr-4 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setBatchSize(20); // Reset du batchSize si on modifie la recherche
                  }}
                  style={{ width: "409px" }}
                />
              </div>

              <div className="font-inter flex items-center gap-2 p-2">
                <MiniTogle isOn={togle} handleToggle={handleTogle} />
                <span className="text-[#344054] font-medium text-sm leading-5">
                  Create a new user
                </span>
              </div>
            </div>
            {!togle ? (
              <div
                ref={listRef}
                className="max-h-60 overflow-y-auto"
                onScroll={handleScroll}
              >
                {visibleUsers.map((user, index) => (
                  <button
                    key={index}
                    className="w-full p-2 flex items-center gap-2 hover:bg-gray-50 text-left"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSelection(user);
                      setIsOpen(false);
                    }}
                  >
                    <div className="flex items-center justify-between w-full p-2">
                      <div className="flex items-center h-7">
                        <img src={`${PATH_IMG}/user.svg`} alt="#" />
                        <span className="text-[#101828] font-medium text-base leading-6 pl-[10px]">
                          {user}
                        </span>
                      </div>
                      {valueSelected === user ? (
                        <img src={`${PATH_IMG}/checkBlue.svg`} alt="#" />
                      ) : null}
                    </div>
                  </button>
                ))}
                {/* Ajout d'un message si la recherche ne retourne aucun résultat */}
                {filteredUsers.length === 0 && (
                  <p className="p-2 text-gray-500 text-sm text-center">
                    Aucun utilisateur trouvé
                  </p>
                )}
              </div>
            ) : (
              <div className="w-4/6 mt-5 display flex justify-center items-center">
                <div onClick={() => handleValidityUpn(searchQuery)}>
                  <ButtonClassic
                    text="Add user"
                    width="104"
                    height="40"
                    backgroundColor="#004289"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
