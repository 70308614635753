import React, { useState } from "react";

export default function ButtonClassic({
  text,
  width,
  height,
  backgroundColor,
}: {
  text: string;
  width: string;
  height: string;
  backgroundColor?: string;
}) {
  const [isHovered, setIsHovered] = useState(false);

  // Fonction pour assombrir une couleur hexadécimale
  const darkenColor = (color: string, amount = 0.1): string => {
    let hex = color.replace("#", "");
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((c) => c + c)
        .join("");
    }

    const num = parseInt(hex, 16);
    const r = Math.max(0, ((num >> 16) & 0xff) * (1 - amount));
    const g = Math.max(0, ((num >> 8) & 0xff) * (1 - amount));
    const b = Math.max(0, (num & 0xff) * (1 - amount));

    return (
      "#" +
      [r, g, b]
        .map((x) => {
          const hexPart = Math.round(x).toString(16);
          return hexPart.length === 1 ? "0" + hexPart : hexPart;
        })
        .join("")
    );
  };

  const getHoverColor = (color: string | undefined) => {
    if (!color) return "#f0f0f0";
    return darkenColor(color, 0.15); // 15% plus sombre
  };

  return (
    <button
      className="flex justify-center items-center rounded-lg border transition-colors duration-200"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: isHovered
          ? getHoverColor(backgroundColor)
          : backgroundColor || "white",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p
        className={`font-inter font-semibold text-sm leading-5 ${
          backgroundColor ? "text-white" : "text-[#344054]"
        }`}
      >
        {text}
      </p>
    </button>
  );
}
