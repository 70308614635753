import React from "react";
import StepCard from "./StepCard";
import { PATH_IMG } from "../../../constants/constant";

export default function ProgressSteps() {
  return (
    <div className="flex w-[1049px] h-36 justify-between">
      <StepCard
        logo={`${PATH_IMG}/idVerification.svg`}
        text="First, use the 'Users' field to select user(s), search by
airport/role/app, or select from a file."
        num="1"
      />
      <StepCard
        logo={`${PATH_IMG}/insertColumnRight.svg`}
        text="Then, update their permissions using the column on the right-hand side."
        num="2"
      />
      <StepCard
        logo={`${PATH_IMG}/structureCheck.svg`}
        text="Don’t forget to click 'Update' to save any changes you’ve made!"
        num="3"
      />
    </div>
  );
}
