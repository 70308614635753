import React, { useEffect, useState } from 'react';
import { format, addMonths, subMonths } from 'date-fns';
import { useDataGouv } from '../providers/DataGouvProvider';


export const Calendar: React.FC= () => {
  const {setMonthYearSelected, loaderIngestionData, buSelected, useCase} = useDataGouv()
  const [nextYear, setNextYear] = useState('')

  const generateMonths = (current: Date): Date[] => {
    const months = [];
    for (let i = 3; i >= 0; i--) {
      const month = subMonths(current, i);
      months.push(month);
    }
    return months;
  };  

  const currentMonth = new Date();
  const [currentStartDate, setCurrentStartDate] = useState(currentMonth);
  const months = generateMonths(currentStartDate);
  const [selectedDate, setSelectedDate] = useState<Date | null>(months[3]);

  useEffect(() => {
    const currentMonthYear = format(currentMonth, 'MM/yyyy');
    // console.log(currentMonthYear)
    const yearAfter = (currentMonth.getFullYear() + 1).toString();
    // console.log(yearAfter)
    setMonthYearSelected(currentMonthYear);
    setSelectedDate(currentMonth)
    setNextYear(yearAfter);
  }, [buSelected]);

  return (
    <div className="flex w-full text-sm gap-1">
      <div className="flex overflow-x-auto no-scrollbar">
        {months.map((month, index) => (
          <div key={index} className="">
            <div
              onClick={() => {
                if(loaderIngestionData) return;
                setSelectedDate(month);
                const monthYear = format(new Date(month), 'MM/yyyy');
                setMonthYearSelected(monthYear);
              }}
              className={`cursor-pointer flex items-center text-[#004289] justify-center w-[120px] text-sm rounded-md ${
                selectedDate?.getMonth() === month.getMonth() && selectedDate.getFullYear() === month.getFullYear() ? 'bg-[#ECF5FF] ' : 'bg-white'
              } px-1 py-2 border border-[#EEEEEE]`}
            >
              {format(month, 'MMMM yyyy')}
            </div>
          </div>
        ))}
        {nextYear && useCase == "Finance" &&
          <div 
            onClick={()=>{
              if(loaderIngestionData) return
              const newDate = new Date(parseInt(nextYear), 0);
              setSelectedDate(newDate)
              setMonthYearSelected(format(newDate, 'MM/yyyy'));
            }}
            className={`cursor-pointer ${
              selectedDate?.getFullYear() === parseInt(nextYear) ? 'bg-[#ECF5FF] ' : 'bg-white'
            } flex items-center text-[#004289] justify-center w-[120px] text-sm rounded-md px-1 py-2 border border-[#EEEEEE]`}>
            
            {nextYear}
          </div>
        }
      </div>
    </div>
  );
};