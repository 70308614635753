import { useState } from "react";

export interface FileStatus {
  loaded: string;
  checkfile: string;
  checkKpis: string;
  processed: string;
}

interface ErrorIngestion {
  title: string;
  description: string
}

export interface KPIsData {
  [key: string]: Array<Array<string | number>>;
}

export interface FileData {
  file_type: string;
  status: FileStatus;
  error?: ErrorIngestion
  version?: string;
  linked?: string;
  final_filename?:string;
  url?: string;
  landing_url?:string;
  kpis?: KPIsData;
  file_id?: string
  uploaded_by?: string;
  date: string;
  check_ref_messages?: string[];
  tmp_bucket_url?: string[];
}

export interface IngestionData {
  [locationCode: string]: FileData[];
}


export const useDataIngestion = (token: string | null) => {
    const [ingestionData, setIngestionData] = useState<IngestionData | null>(null)
    const [loaderIngestionData, setLoaderIngestionData] = useState(false)
    const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});

    const getIngestionData = async (use_case?: string,asset?: string, date?: string) => {
      if (token === null) return;
      setLoaderIngestionData(true)
      const [month, year] = date ? date.split('/') : [undefined, undefined];
      let use_case_type = null
      const currentYear = new Date().getFullYear()
      if(parseInt(year!) == currentYear + 1){
        use_case_type = 'BUDGET'
      }
      try {
        const params = new URLSearchParams({
          ...(use_case && { use_case: use_case.toUpperCase() }),
          ...(asset && { asset }),
          ...(year && { year }),
          ...(month && { month }),
          ...(use_case_type && { use_case_type }),
        })
        const response = await fetch(`/api/data_gov/ingestion/data?${params.toString()}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
    
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }
    
        const data = await response.json();
        setIngestionData(data);
      } catch (error) {
        console.error('An error occurred while fetching the ingestion data:', error);
        return null
      }
      finally{
        setLoaderIngestionData(false)
      }
    };


    const confirmFile = async(file_asset: string, file_type: string, file_version?: string, month?:string, year?: string, tmp_bucket_url?: string) => {
      if(token === null) return
      try{
        
        const formData = new FormData();
        formData.append('file_asset', file_asset);
        formData.append('file_type', file_type);
        if (file_version)
          formData.append('file_version', file_version);
        formData.append('month', month!)
        formData.append('year', year!)
        formData.append('tmp_bucket_url', tmp_bucket_url!)

        const response = await fetch(`api/data_gov/ingestion/validate`,{
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData
        })

        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data)

        return data
      }
      catch(error) {
        console.log(error)
        return null
      }
    }


    
    const uploadFile = async (use_case: string, file_asset: string, file_type: string, file: File, file_version?: string, month?:string, year?: string, day?: string) => {
        if (token === null) return;

        const formData = new FormData();
        formData.append('file_asset', file_asset);
        formData.append('use_case', use_case);
        formData.append('file_type', file_type);
        formData.append('file', file);
        formData.append('month', month!)
        formData.append('year', year!)
        formData.append('day', day!)
        {file_version && formData.append('file_version', file_version)}

        return fetch(`/api/data_gov/ingestion/file`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error("Error uploading file:", error);
            return [];
        });
    };

    const handleDownload = async (url: string) => {
      setLoadingDownloads((prev) => ({ ...prev, [url]: true }));
      try {
        const response = await fetch(`/api/data_gov/gouvernance/download`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ gs_url: url })
        });
        if (!response.ok) return
        const signedUrl = JSON.parse(await response.text());
        const a = document.createElement('a');
        a.href = signedUrl;
        a.download = url.split('/').pop() || 'file';
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      catch (error) {
        console.log(error)
        return null
      }
      finally {
        setLoadingDownloads((prev) => ({ ...prev, [url]: false }));
      }
    };
    
    return { uploadFile, getIngestionData, ingestionData, setIngestionData, confirmFile, loaderIngestionData, handleDownload, loadingDownloads };
};