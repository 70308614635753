import React, { useEffect } from "react";
import { useState } from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import PercentageCircle from "../components/elements/PercentageCircle";
import { PATH_IMG } from "../../../constants/constant";
import { useActiveTabs, useAuth } from "../../../providers";

import { adminManagement } from "../../../assets";
import { useInfoUser } from "../../../providers/InfoUser";
import ModalLoading from "../components/modals/ModalLoading";
import ModalRevision from "../components/modals/ModalRevision";
import { usersInterface } from "../../../types/adminManagementPages";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import { set } from "date-fns";
import {
  DashboardPermissions,
  RolePermissions,
} from "../AdminManagementPermissions/type/permissionsTypes";
import PermissionElement from "../AdminManagementPermissions/PermissionElement";
import Cross from "../components/elements/formSvg/Cross";
import ArrowTurnBackward from "../components/elements/formSvg/ArrowTurnBackward";

interface TableNotificationsProps {
  page: number;
  itemsPerPage: number;
  notifications: any[];
  navType: string;
  usersAskedPemissions?: any[];
  handleNotificationsPermissions?: (
    permission: any,
    permissionAfterCancel: any,
    permissionAsked: any,
    type: string
  ) => void;

  handleRemoveNotification: (idVINCI: string) => void;
}

export default function TableNotifications({
  page,
  itemsPerPage,
  notifications,
  navType,
  handleRemoveNotification,
  usersAskedPemissions,
  handleNotificationsPermissions,
}: TableNotificationsProps) {
  const { setUpnFromNotificationToUser } = useInfoUser();

  const { token } = useAuth();
  const { openTab } = useActiveTabs();

  const handleRedirectToUsers = (upn: string) => {
    const userTab = adminManagement.tabs.find((tab) => tab.id === 70);
    if (userTab) {
      openTab(userTab);
    }
    setUpnFromNotificationToUser(upn);
  };

  let notificationsTodisplay = notifications;

  if (navType == "GenAI") {
    notifications = notifications.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage
    );
  } else {
    const val = notifications.filter(
      (item: any, index: number, self: any) =>
        index === self.findIndex((obj: any) => obj.askedBy === item.askedBy)
    );
    notificationsTodisplay = val.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage
    );
  }

  return (
    <div>
      <div className="w-full py-5 font-inter m-auto rounded-lg">
        <div className="rounded-lg overflow-hidden  border border-[#EAECF0]">
          <table className="w-full table-fixed border-collapse">
            <TabbleHeader navType={navType} />
            {navType === "GenAI" ? (
              <TableBodyGenAi
                token={token}
                notifications={notifications}
                handleRemoveNotification={handleRemoveNotification}
                handleRedirectToUsers={handleRedirectToUsers}
              />
            ) : (
              <TableBodyPermissions
                notificationsTodisplay={notificationsTodisplay}
                notifications={notifications}
                token={token}
                handleRemoveNotification={handleRemoveNotification}
                handleRedirectToUsers={handleRedirectToUsers}
                usersAskedPemissions={usersAskedPemissions}
                handleNotificationsPermissions={handleNotificationsPermissions}
              />
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

interface TabbleHeaderProps {
  navType: string;
}

function TabbleHeader({ navType }: TabbleHeaderProps) {
  return (
    <thead className="sticky top-0 bg-gray-100 z-10">
      <tr className="text-left text-xs leading-5 text-gray-500 h-11 w-full">
        <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 w-64 ">
          Request
        </th>
        <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 w-[300px]">
          {navType === "GenAI" ? "UPN" : "Request from"}
        </th>
        <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 w-[265px]">
          {navType == "GenAI" ? "message" : "Details"}
        </th>
        {navType == "GenAI" && (
          <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 w-44">
            GenAI Current credit
            <img
              className="pl-2 inline-block"
              src={`${PATH_IMG}/arrowDown.svg`}
              alt="#"
            />
          </th>
        )}
        <th className="px-2 py-2 cursor-pointer font-medium text-xs leading-5 w-32">
          Date time
          <img
            className="pl-2 inline-block"
            src={`${PATH_IMG}/arrowDown.svg`}
            alt="#"
          />
        </th>
        <th className="w-[89px]"></th>
        <th className="w-[78px]"></th>
        <th className="w-[87px]"></th>
      </tr>
    </thead>
  );
}

interface TableBodyGenAiProps {
  notifications: any[];
  handleRedirectToUsers: (upn: string) => void;
  handleRemoveNotification: (idVINCI: string) => void;
  token: any;
}

function TableBodyGenAi({
  notifications,
  handleRedirectToUsers,
  handleRemoveNotification,
  token,
}: TableBodyGenAiProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [endLoading, setEndLoading] = useState(false);

  const cancelCreditsRequest = async (valueToSend: any) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "/api/admin/credits_management/cancel_credits_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(valueToSend),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de l'annulation de la demande.");
      }

      const data = await response.json();
      console.log("Réponse du back :", data);

      handleRemoveNotification(valueToSend.idVINCI);
    } catch (err) {
      console.error("Erreur côté front :", err);
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 500);
    }
  };

  const approveCreditRequest = async (payload: any) => {
    try {
      const response = await fetch(
        "/api/admin/credits_management/approve_credits_request",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || "Erreur lors de l'approbation");
      }

      const data = await response.json();
      handleRemoveNotification(payload.idVINCI);
    } catch (err) {
    } finally {
      {
        setEndLoading(true);
        setTimeout(() => {
          setEndLoading(false);
          setIsLoading(false);
        }, 500);
      }
    }
  };

  return (
    <tbody>
      {notifications.map((row, index) => {
        const creditLimit =
          row.type === "Standard"
            ? 5000
            : row.type === "Premium"
            ? 10000
            : 15000;
        const pourcentageCurrentCredit = parseInt(
          ((parseInt(row.genAICurrentCredit) / creditLimit) * 100).toFixed(2)
        );
        const colorPourcentage =
          pourcentageCurrentCredit >= 70
            ? "#39b88e"
            : pourcentageCurrentCredit <= 35
            ? "#ea5a6c"
            : "#004289";

        return (
          <tr
            key={index}
            className="w-full min-h-9 text-left text-xs leading-5 border-b"
          >
            <td className="px-2 py-2 ">
              <div className="flex gap-6 items-center">
                <img src={`${PATH_IMG}/doubleChevronUp.svg`} alt="" />
                <div>
                  <p className="font-medium text-sm leading-5 text-black">
                    {row.credits} GenAICredits
                  </p>
                  <p className="font-normal text-sm leading-5 text-[#747c8a]">
                    Request to increase
                  </p>
                </div>
              </div>
            </td>
            <td className="px-2 py-2 font-medium text-sm leading-6 text-[#101828]">
              <div className="flex gap-2 items-center">
                <img src={`${PATH_IMG}/user.svg`} alt="#" />
                <p>{row.upn}</p>
              </div>
            </td>
            <td className="px-2 py-2 font-normal text-sm leading-5 text-[#747c8a] ">
              <p>{row.message}</p>
            </td>
            <td className="px-2 py-2 font-medium text-xs leading-6 ">
              <div className="flex gap-2 items-center">
                <div>
                  <PercentageCircle
                    percentage={pourcentageCurrentCredit}
                    size={40}
                    strokeWidth={5}
                    strokeColor={colorPourcentage}
                  />
                </div>
                <p>
                  <span style={{ color: colorPourcentage }}>
                    {row.genAICurrentCredit}
                  </span>
                  <span>
                    {row.type === "Standard"
                      ? " / 5000"
                      : row.type === "Standard"
                      ? " / 10000"
                      : " / 15000"}
                  </span>
                </p>
              </div>
            </td>
            <td className="px-2 py-2 font-medium text-xs leading-[18px] ">
              <p>{row.formatDate}</p>
              <p>{row.formatTime}</p>
            </td>
            <td className="">
              <div onClick={() => handleRedirectToUsers(row.upn)}>
                <ButtonClassic width="79" height="36" text="Edit user" />
              </div>
            </td>
            <td className="">
              <div
                onClick={() =>
                  cancelCreditsRequest({
                    idVINCI: row.idVINCI,
                    creditAsked: row.credits,
                    message: row.message, // ou depuis un input
                    updateDate: row.date,
                  })
                }
              >
                <ButtonClassic width="68" height="36" text="Cancel" />
              </div>
            </td>
            <td className="">
              <div
                onClick={() =>
                  approveCreditRequest({
                    idVINCI: row.idVINCI,
                    creditAsked: row.credits,
                    message: row.message, // ou depuis un input
                    updateDate: row.date,
                  })
                }
              >
                <ButtonClassic
                  width="77"
                  height="36"
                  text="Approve"
                  backgroundColor="#004289"
                />
              </div>
            </td>
          </tr>
        );
      })}
      {(isLoading || endLoading) && <ModalLoading endLoading={endLoading} />}
    </tbody>
  );
}

interface TableBodyPermissionsProps {
  notificationsTodisplay: any[];
  notifications: any[];
  token: any;
  handleRemoveNotification: (idVINCI: string) => void;
  handleRedirectToUsers: (upn: string) => void;
  usersAskedPemissions?: any[];
  handleNotificationsPermissions?: (
    permission: any,
    permissionAfterCancel: any,
    permissionAsked: any,
    type: string
  ) => void;
}

function TableBodyPermissions({
  notifications,
  notificationsTodisplay,
  token,
  handleRemoveNotification,
  handleRedirectToUsers,
  usersAskedPemissions,
  handleNotificationsPermissions,
}: TableBodyPermissionsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [endLoading, setEndLoading] = useState(false);
  const [askedByIdVINCI, setAskedByIdVINCI] = useState("");
  const [showModalPermissions, setShowModalPermissions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  console.log("notifications", notifications);

  // permissions

  const cancelCreditsRequest = async () => {
    const newNotification = notifications[0];
    console.log("newNotification", newNotification);
    const payload = {
      id_vinci: newNotification.idVINCI, // string
      action: "refuse", // ou "refuse"
      update_date: newNotification.updateDate, // format 'YYYY-MM-DD'
      action_type: newNotification.actionType, // ou "delete"
      permissions: JSON.stringify(newNotification.permissions), // JSON.stringify d'un objet respectant PermissionsByType
      asked_by: newNotification.askedBy,
    };
    try {
      const response = await fetch(
        "/api/admin/permissions_management/process_access_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.detail || "Erreur lors de l'envoi");
      }

      console.log("Succès :", data.message);
    } catch (err) {
      console.error("Erreur côté front :", err);
    } finally {
    }
  };

  const handleRemovePermission = async (
    permission: DashboardPermissions,
    type: string,
    idVINCI: string
  ) => {
    console.log("permission", permission);
    console.log("type", type);
    console.log("idVINCI", idVINCI);
    console.log("askedByIdVINCI", askedByIdVINCI);

    const permissionAsked = notifications.find((elem) => {
      if (elem.idVINCI === idVINCI && elem.askedBy === askedByIdVINCI) {
        const parsedPermissions = JSON.parse(elem.permissions);
        console.log("parsedPermissions", parsedPermissions);
        for (const key in parsedPermissions[type]) {
          console.log("parsedPermissions", parsedPermissions[type][key]);
          if (type === "dashboard") {
            if (
              parsedPermissions[type][key].dashboard === permission.dashboard &&
              parsedPermissions[type][key].airport === permission.airport &&
              parsedPermissions[type][key].business_unit ===
                permission.business_unit &&
              parsedPermissions[type][key].dashboard_level ===
                permission.dashboard_level
            ) {
              return true;
            }
          }
        }
        // return parsedPermissions[type].includes(permission);
      }
      return false;
    });

    const permissionRole: RolePermissions = {
      dashboard: [],
      data_gov: [],
      gen_ai: [],
      admin: [],
    };

    if (type === "dashboard") {
      permissionRole.dashboard.push(permission);
    }

    console.log("permissionAsked", permissionAsked);
    const payload = {
      id_vinci: permissionAsked?.idVINCI,
      action: "refuse",
      update_date: permissionAsked?.updateDate,
      action_type: permissionAsked?.actionType,
      permissions: JSON.stringify(permissionRole),
      asked_by: permissionAsked?.askedBy,
    };

    console.log("payload", payload);

    try {
      const response = await fetch(
        "/api/admin/permissions_management/process_access_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      console.log(permissionAsked.permissions);
      const newPermissionAfterCancel = JSON.parse(permissionAsked.permissions);
      for (const key in newPermissionAfterCancel[type]) {
        if (type === "dashboard") {
          if (
            newPermissionAfterCancel[type][key].dashboard ===
              permission.dashboard &&
            newPermissionAfterCancel[type][key].airport ===
              permission.airport &&
            newPermissionAfterCancel[type][key].business_unit ===
              permission.business_unit &&
            newPermissionAfterCancel[type][key].dashboard_level ===
              permission.dashboard_level
          ) {
            newPermissionAfterCancel[type].splice(key, 1);
          }
        }
      }
      console.log("newPermissionAfterCancel", newPermissionAfterCancel);

      if (handleNotificationsPermissions) {
        handleNotificationsPermissions(
          permission,
          newPermissionAfterCancel,
          permissionAsked,
          type
        );
      }

      if (!response.ok) {
        throw new Error(data.detail || "Erreur lors de l'envoi");
      }

      console.log("Succès :", data.message);
    } catch (err) {
      console.error("Erreur côté front :", err);
    } finally {
    }
  };

  const formatDateToLongString = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    return date.toLocaleDateString("en-GB", options);
  };

  const handleShowModal = (askedBy: string) => {
    setAskedByIdVINCI(askedBy);
    setShowModalPermissions(true);
  };

  const filterNotifiactionsBy = (askedBy: string) => {
    return notifications.reduce((acc: any[], current: any) => {
      const alreadyExists = acc.some((n) => n.idVINCI === current.idVINCI);
      if (current.askedBy === askedBy && !alreadyExists) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <tbody>
      {notificationsTodisplay.map((row, index) => {
        const creditLimit =
          row.type === "Standard"
            ? 5000
            : row.type === "Premium"
            ? 10000
            : 15000;
        const pourcentageCurrentCredit = parseInt(
          ((parseInt(row.genAICurrentCredit) / creditLimit) * 100).toFixed(2)
        );
        const colorPourcentage =
          pourcentageCurrentCredit >= 70
            ? "#39b88e"
            : pourcentageCurrentCredit <= 35
            ? "#ea5a6c"
            : "#004289";

        const user = usersAskedPemissions?.find(
          (user) => user.idVINCI === row.askedBy
        );
        const nbrUsersTryToModify = notifications
          .filter((item) => item.askedBy === row.askedBy)
          .filter(
            (item, index, self) =>
              index === self.findIndex((obj) => obj.idVINCI === item.idVINCI)
          ).length;

        console.log("user", user);
        console.log("row", row);
        console.log("nbrUsersTryToModify", nbrUsersTryToModify);
        return (
          <tr
            key={index}
            className="w-full min-h-9 text-left text-xs leading-5 border-b"
          >
            <td className="px-2 py-2 h-12">
              <p>{user?.businessUnit}</p>
            </td>
            <td className="px-2 py-2 font-medium text-sm leading-6 text-[#101828]">
              <div className="flex gap-2 items-center">
                <img src={`${PATH_IMG}/user.svg`} alt="#" />
                <p>{user?.upn}</p>
              </div>
            </td>
            <td className="px-2 py-2 font-normal text-sm leading-5 text-[#747c8a] ">
              <div onClick={() => handleShowModal(row.askedBy)}>
                <ButtonClassic
                  width="300"
                  height="36"
                  text={
                    nbrUsersTryToModify +
                    " users - " +
                    notifications.length +
                    " new permissions"
                  }
                  backgroundColor="#004289"
                />
              </div>
            </td>
            <td className="px-2 py-2 font-medium text-xs leading-[18px] ">
              <p>{formatDateToLongString(row.updateDate)}</p>
            </td>
            <td className="">
              <div onClick={() => handleRedirectToUsers(row.upn)}>
                <ButtonClassic width="79" height="36" text="Edit user" />
              </div>
            </td>
            <td className="">
              <div onClick={() => cancelCreditsRequest()}>
                <ButtonClassic width="68" height="36" text="Cancel" />
              </div>
            </td>
            <td className="">
              <div onClick={() => {}}>
                <ButtonClassic
                  width="77"
                  height="36"
                  text="Approve"
                  backgroundColor="#004289"
                />
              </div>
            </td>
          </tr>
        );
      })}
      {(isLoading || endLoading) && <ModalLoading endLoading={endLoading} />}
      {showModalPermissions && (
        <ModalRevision
          currentPage={currentPage}
          dataRevisions={filterNotifiactionsBy(askedByIdVINCI)}
          handleCurrentPage={handleCurrentPage}
          handleRevisionUser={() => setShowModalPermissions(false)}
          title="Permission"
        >
          <ModalTablePermissions
            askedByIdVINCI={askedByIdVINCI}
            notifications={notifications}
            currentPage={currentPage}
            handleRemovePermission={handleRemovePermission}
          />
        </ModalRevision>
      )}
    </tbody>
  );
}

// faire ma modal ici dans un premier temps.

interface ModalTablePermissionsProps {
  askedByIdVINCI: string;
  notifications: any[];
  currentPage: number;
  handleRemovePermission: (
    permission: DashboardPermissions,
    type: string,
    idVINCI: string
  ) => void;
}

function ModalTablePermissions({
  askedByIdVINCI,
  notifications,
  currentPage,
  handleRemovePermission,
}: ModalTablePermissionsProps) {
  const { token } = useAuth();

  const [arrowDownDown, setArrowDownDown] = useState({
    usersUpdate: true,
    numbersOfPermissions: false,
  });

  const [permissionsUsers, setPermissionsUsers] = useState<any[]>([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [selectUpn, setSelectUpn] = useState("");
  const [addpermissionsSelected, setAddpermissionsSelected] =
    useState<RolePermissions>({
      dashboard: [],
      data_gov: [],
      gen_ai: [],
      admin: [],
    });

  const [deletePermissionsSelected, setDeletePermissionsSelected] =
    useState<RolePermissions>({
      dashboard: [],
      data_gov: [],
      gen_ai: [],
      admin: [],
    });

  const handleRowClick = (id: string, upn: string) => {
    if (selectedRowId === id) {
      setSelectedRowId("");
      setSelectUpn("");
    } else {
      setSelectedRowId(id);
      setSelectUpn(upn);
      const filteredNotifications = notifications.filter(
        (notification) => notification.askedBy === askedByIdVINCI
      );
      const permissions = filteredNotifications.filter(
        (item) => item.idVINCI === id
      );
      console.log("permissions", permissions);
      const permissionsAdd = permissions.filter(
        (item) => item.actionType === "create"
      );
      const permissionsDelete = permissions.filter(
        (item) => item.actionType === "delete"
      );
      console.log("permissionsAdd", permissionsAdd);
      console.log("permissionsDelete", permissionsDelete);

      const mergedToAdd: RolePermissions = {
        dashboard: [],
        data_gov: [],
        gen_ai: [],
        admin: [],
      };

      const mergedToDelete: RolePermissions = {
        dashboard: [],
        data_gov: [],
        gen_ai: [],
        admin: [],
      };

      permissionsAdd.forEach((item) => {
        const parsed = JSON.parse(item.permissions);

        mergedToAdd.dashboard.push(...parsed.dashboard);
        mergedToAdd.data_gov.push(...parsed.data_gov);
        mergedToAdd.gen_ai.push(...parsed.gen_ai);
        mergedToAdd.admin.push(...parsed.admin);
      });

      permissionsDelete.forEach((item) => {
        const parsed = JSON.parse(item.permissions);

        mergedToDelete.dashboard.push(...parsed.dashboard);
        mergedToDelete.data_gov.push(...parsed.data_gov);
        mergedToDelete.gen_ai.push(...parsed.gen_ai);
        mergedToDelete.admin.push(...parsed.admin);
      });
      console.log("mergedToDelete", mergedToDelete);
      setDeletePermissionsSelected(mergedToDelete);
      console.log("mergedToAdd", mergedToAdd);
      setAddpermissionsSelected(mergedToAdd);
    }
  };

  const getUsersByIds = async (
    idsVinciList: string[]
  ): Promise<usersInterface[]> => {
    try {
      const response = await fetch(
        "/api/admin/user_management/get_users_by_ids",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ids_vinci: idsVinciList,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des utilisateurs.");
      }

      interface UserPayload {
        upns: usersInterface[];
      }
      const data: UserPayload = await response.json();
      return data.upns;
    } catch (error) {
      console.error("Erreur dans getUsersByIds:", error);
      return [];
    }
  };

  useEffect(() => {
    if (selectedRowId === "") {
      return;
    }
    const filteredNotifications = notifications.filter(
      (notification) => notification.askedBy === askedByIdVINCI
    );
    const permissions = filteredNotifications.filter(
      (item) => item.idVINCI === selectedRowId
    );
    console.log("permissions", permissions);
    const permissionsAdd = permissions.filter(
      (item) => item.actionType === "create"
    );
    const permissionsDelete = permissions.filter(
      (item) => item.actionType === "delete"
    );
    console.log("permissionsAdd", permissionsAdd);
    console.log("permissionsDelete", permissionsDelete);

    const mergedToAdd: RolePermissions = {
      dashboard: [],
      data_gov: [],
      gen_ai: [],
      admin: [],
    };

    const mergedToDelete: RolePermissions = {
      dashboard: [],
      data_gov: [],
      gen_ai: [],
      admin: [],
    };

    permissionsAdd.forEach((item) => {
      const parsed = JSON.parse(item.permissions);

      mergedToAdd.dashboard.push(...parsed.dashboard);
      mergedToAdd.data_gov.push(...parsed.data_gov);
      mergedToAdd.gen_ai.push(...parsed.gen_ai);
      mergedToAdd.admin.push(...parsed.admin);
    });

    permissionsDelete.forEach((item) => {
      const parsed = JSON.parse(item.permissions);

      mergedToDelete.dashboard.push(...parsed.dashboard);
      mergedToDelete.data_gov.push(...parsed.data_gov);
      mergedToDelete.gen_ai.push(...parsed.gen_ai);
      mergedToDelete.admin.push(...parsed.admin);
    });
    console.log("mergedToDelete", mergedToDelete);
    setDeletePermissionsSelected(mergedToDelete);
    console.log("mergedToAdd", mergedToAdd);
    setAddpermissionsSelected(mergedToAdd);
  }, [notifications]);

  useEffect(() => {
    (async () => {
      console.log("notifications", notifications);
      console.log("askedByIdVINCI", askedByIdVINCI);
      const filteredNotifications = notifications.filter(
        (notification) => notification.askedBy === askedByIdVINCI
      );
      console.log("filteredNotifications", filteredNotifications);
      const idsVinci = filteredNotifications
        .filter(
          (item, index, self) =>
            index === self.findIndex((obj) => obj.idVINCI === item.idVINCI)
        )
        .map((item) => item.idVINCI);
      console.log("idsVinci", idsVinci);
      const users = await getUsersByIds(idsVinci);
      console.log("users", users);
      setPermissionsUsers(users);
    })();
  }, [notifications]);

  const handleArrowDown = (value: number) => {
    if (value === 1) {
      setArrowDownDown({
        usersUpdate: true,
        numbersOfPermissions: false,
      });
    } else if (value === 2) {
      setArrowDownDown({
        usersUpdate: false,
        numbersOfPermissions: true,
      });
    }
  };

  const startIndex = (currentPage - 1) * 8;
  const paginatedData = permissionsUsers.slice(startIndex, startIndex + 8);

  return (
    <div className="w-full min-h-[415px]">
      <table className="w-[100%] table-fixed font-inter">
        <thead className="sticky top-0 bg-[#f9fafb] z-10">
          <tr className="text-left text-xs leading-5 text-[#667085]">
            <th
              className="w-1/3 px-2 py-2 font-medium text-xs leading-5 cursor-pointer text-center"
              onClick={() => handleArrowDown(1)}
            >
              <span>User(s) updated</span>
              <img
                className="pl-2 inline-block"
                src={
                  arrowDownDown.usersUpdate
                    ? PATH_IMG + "/arrowDown.svg"
                    : PATH_IMG + "/arrowUp.svg"
                }
                alt="#"
              />
            </th>
            <th
              className="w-1/3 px-2 py-2 font-medium text-xs leading-5 cursor-pointer text-center"
              onClick={() => handleArrowDown(2)}
            >
              Number(s) of permissions changed
              <img
                className="inline-block"
                src={
                  arrowDownDown.numbersOfPermissions
                    ? PATH_IMG + "/arrowDown.svg"
                    : PATH_IMG + "/arrowUp.svg"
                }
                alt="#"
              />
            </th>

            <th className="w-1/3 px-2 py-2 font-medium text-xs leading-5 cursor-pointer"></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((user) => {
            if (selectedRowId === user.idVINCI || selectedRowId === "") {
              return (
                <tr
                  key={user.idVINCI}
                  className="h-9 text-left text-xs leading-5 border-b"
                >
                  <td className="px-2 py-1 truncate font-normal text-sm leading-5 text-[#747c8a] text-center">
                    {user.upn}
                  </td>
                  <td className="px-2 py-1 truncate font-medium text-sm leading-5 text-[#101828] text-center">
                    {user.numbersOfPermissions}
                  </td>
                  <td className="flex justify-center items-center px-2 py-1">
                    <div onClick={() => handleRowClick(user.idVINCI, user.upn)}>
                      {user.idVINCI === selectedRowId ? (
                        <ButtonSvgText
                          width="71"
                          height="28"
                          text="Hide"
                          logo={`${PATH_IMG}/chevronUp.svg`}
                          fontSize="12"
                        />
                      ) : (
                        <ButtonSvgText
                          width="84"
                          height="30"
                          text="Details"
                          logo={`${PATH_IMG}/chevronDown.svg`}
                          fontSize="12"
                          gap="5"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            }
            return null;
          })}
          {selectedRowId && (
            <tr className="w-full font-inter ">
              <td colSpan={5} className="px-5 ">
                <div className="h-[340px] w-[100%] overflow-y-auto">
                  {/* <SearchInput
                              width="100%"
                              handleSearchQuery={(query) => console.log(query)}
                            /> */}
                  <p className="mt-4 text-[#747C8A] flex font-normal text-base leading-6 ">
                    {selectUpn}
                    <span className="font-medium text-[#2B60A3] ml-1">
                      added permissions
                    </span>
                  </p>
                  <div className="flex flex-wrap gap-2 mt-2 px-5">
                    {addpermissionsSelected && [
                      ...addpermissionsSelected.dashboard.map(
                        (permissionsToAdd) => (
                          <PermissionElement
                            key={`dashboard-${permissionsToAdd}`}
                            backgroundColor="#F2F6FB"
                            permission={permissionsToAdd}
                            type="dashboard"
                          >
                            <div
                              className="relative inline-block"
                              onClick={() =>
                                handleRemovePermission(
                                  permissionsToAdd,
                                  "dashboard",
                                  selectedRowId
                                )
                              }
                            >
                              <div className="group cursor-pointer inline-block">
                                {/* Tooltip : s’affiche seulement au survol de la croix, ne capte pas la souris */}
                                <div
                                  className="absolute -top-12 -left-[104px] min-w-[123px] w-52 h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center 
      opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none"
                                >
                                  <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
                                    Cancel the permission
                                  </p>
                                </div>

                                {/* La croix */}
                                <Cross
                                  width="14"
                                  height="14"
                                  color={"#004289"}
                                />
                              </div>
                            </div>
                          </PermissionElement>
                        )
                      ),
                      ...addpermissionsSelected.gen_ai.map(
                        (permissionsToAdd) => (
                          <PermissionElement
                            key={`genai-${permissionsToAdd}`}
                            backgroundColor="#F2F6FB"
                            permission={permissionsToAdd}
                            type="genai"
                          >
                            <div className="relative inline-block">
                              <div className="group cursor-pointer inline-block">
                                {/* Tooltip : s’affiche seulement au survol de la croix, ne capte pas la souris */}
                                <div
                                  className="absolute -top-12 -left-[104px] min-w-[123px] w-52 h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center 
      opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none"
                                >
                                  <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
                                    Cancel the permission
                                  </p>
                                </div>

                                {/* La croix */}
                                <Cross
                                  width="14"
                                  height="14"
                                  color={"#004289"}
                                />
                              </div>
                            </div>
                          </PermissionElement>
                        )
                      ),
                      ...addpermissionsSelected.data_gov.map(
                        (permissionsToAdd) => (
                          <PermissionElement
                            key={`datagov-${permissionsToAdd}`}
                            backgroundColor="#F2F6FB"
                            permission={permissionsToAdd}
                            type="datagov"
                          >
                            <div className="relative inline-block">
                              <div className="group cursor-pointer inline-block">
                                {/* Tooltip : s’affiche seulement au survol de la croix, ne capte pas la souris */}
                                <div
                                  className="absolute -top-12 -left-[104px] min-w-[123px] w-52 h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center 
      opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none"
                                >
                                  <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
                                    Cancel the permission
                                  </p>
                                </div>

                                {/* La croix */}
                                <Cross
                                  width="14"
                                  height="14"
                                  color={"#004289"}
                                />
                              </div>
                            </div>
                          </PermissionElement>
                        )
                      ),
                    ]}
                  </div>
                  <p className=" mt-4 text-[#747C8A] flex font-normal text-base leading-6">
                    {selectUpn}
                    <span className="font-medium text-[#C14242] ml-1">
                      removed permissions
                    </span>
                  </p>

                  <div className=" mt-2 flex flex-wrap gap-2 px-5">
                    {deletePermissionsSelected && [
                      ...deletePermissionsSelected.dashboard.map(
                        (permissionsToDelete) => (
                          <PermissionElement
                            key={`dashboard-${permissionsToDelete}`}
                            backgroundColor="#FFF8F8"
                            permission={permissionsToDelete}
                            type="dashboard"
                          >
                            <div className="relative inline-block">
                              <div className="group cursor-pointer inline-block">
                                {/* Tooltip : s’affiche seulement au survol de la croix, ne capte pas la souris */}
                                <div
                                  className="absolute -top-12 -left-[104px] min-w-[123px] w-52 h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center 
      opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none"
                                >
                                  <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
                                    Cancel the permission
                                  </p>
                                </div>
                                <ArrowTurnBackward height="16" width="16" />
                              </div>
                            </div>
                          </PermissionElement>
                        )
                      ),
                      ...deletePermissionsSelected.gen_ai.map(
                        (permissionsToDelete) => (
                          <PermissionElement
                            key={`genai-${permissionsToDelete}`}
                            backgroundColor="#FFF8F8"
                            permission={permissionsToDelete}
                            type="genai"
                          >
                            <div className="relative inline-block">
                              <div className="group cursor-pointer inline-block">
                                {/* Tooltip : s’affiche seulement au survol de la croix, ne capte pas la souris */}
                                <div
                                  className="absolute -top-12 -left-[104px] min-w-[123px] w-52 h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center 
      opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none"
                                >
                                  <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
                                    Cancel the permission
                                  </p>
                                </div>
                                <ArrowTurnBackward height="16" width="16" />
                              </div>
                            </div>
                          </PermissionElement>
                        )
                      ),
                      ...deletePermissionsSelected.data_gov.map(
                        (permissionsToDelete) => (
                          <PermissionElement
                            key={`datagov-${permissionsToDelete}`}
                            backgroundColor="#FFF8F8"
                            permission={permissionsToDelete}
                            type="datagov"
                          >
                            <div className="relative inline-block">
                              <div className="group cursor-pointer inline-block">
                                {/* Tooltip : s’affiche seulement au survol de la croix, ne capte pas la souris */}
                                <div
                                  className="absolute -top-12 -left-[104px] min-w-[123px] w-52 h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center 
      opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none"
                                >
                                  <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
                                    Cancel the permission
                                  </p>
                                </div>
                                <ArrowTurnBackward height="16" width="16" />
                              </div>
                            </div>
                          </PermissionElement>
                        )
                      ),
                    ]}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
