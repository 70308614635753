import React from "react";

// react element for ModalHeader
interface ModalHeaderProps {
  children: React.ReactNode;
  width: string;
  height: string;
  right?: string;
  left?: string;
}

export default function ModalHeader({
  children,
  left,
  right,
  width,
  height,
}: ModalHeaderProps) {
  return (
    <div
      className="absolute top-14 
             bg-white z-20 border border-[#f2f4f7] rounded-lg"
      style={{
        boxShadow: "0px 12px 16px -4px #10182814",
        width: `${width}px`,
        height: `${height}px`,
        left: `${left ? "0" : ""}`,
        right: `${right ? "0" : ""}`,
      }}
    >
      {React.isValidElement(children)
        ? React.cloneElement(children as React.ReactElement<any>)
        : children}
    </div>
  );
}
