import { usersInterface } from "../../../../types/adminManagementPages";

const BASE_URL = "/api/admin";

// /api/admin/user_management/get_upns_app_users

export const fetchUsersPermissions = async (path: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    const data = await response.json();
    if (!data || typeof data !== "object" || !data.upns) {
      throw new Error("Réponse inattendue du serveur");
    }
    return data;
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const fetchPermissionsFromUsers = async (
  path: string,
  token: any,
  idVINCI: string[]
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ids_vinci: idVINCI }),
    });

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const fetchAllRoles = async (path: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const fetchIdVinciByRole = async (
  path: string,
  token: any,
  role: string
) => {
  try {
    const response = await fetch(BASE_URL + path + encodeURIComponent(role), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const fetchRolesPermissions = async (path: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const postCreatePermissions = async (
  path: string,
  token: any,
  permissions: any
) => {
  let newPem = permissions.permissions;
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ permissions: newPem }),
    });

    const data = await response.json();

    if (response.status === 418) {
      console.error("Erreur 418 détectée:", data.detail);
      return { success: false, errors: data.detail };
    }

    if (!response.ok) {
      throw new Error(
        `Erreur HTTP ${response.status}: ${data?.message || "Erreur inconnue"}`
      );
    }

    return { success: true, data };
  } catch (e) {
    console.error("Erreur API:", e);
    return { success: false, errors: "Erreur API inconnue" };
  }
};

export const postDeletePermissions = async (
  path: string,
  token: any,
  permissions: any
) => {
  try {
    let newPem = permissions.permissions;

    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ permissions: newPem }),
    });

    const data = await response.json(); // ✅ Toujours parser la réponse JSON

    if (response.status === 418) {
      console.error("Erreur 418 détectée:", data.detail); // ✅ Affiche les erreurs du backend
      return { success: false, errors: data.detail };
    }

    if (!response.ok) {
      throw new Error(
        `Erreur HTTP ${response.status}: ${data?.message || "Erreur inconnue"}`
      );
    }

    return { success: true, data }; // ✅ Retourne les données correctement
  } catch (e) {
    console.error("Erreur API:", e);
    return { success: false, errors: "Erreur API inconnue" };
  }
};

export const getTemplatePermissionFile = async (path: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    return response;
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const postSelectMultipleUsers = async (
  path: string,
  formData: FormData,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }

    return response;
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};
