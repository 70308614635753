import React, { act, SetStateAction, useEffect, useState } from 'react';
import { SelectOptions, SelectOptionsDataGouv, SelectOptionsQuality } from '../DocumentTranslator/SelectedOptions';
import { useDataGouv } from './providers/DataGouvProvider';
import { useActiveTabs, useAuth } from '../../providers';
import { LoaderCircle } from '../../components';
import { CheckIcon, CrossIcon, DelayedIcon, PendingIcon, RunningIcon } from './SummaryBU';
import { ArrowDownIcon, FileIcon, LinkIcon } from './icons/Icons';
import { formatLastUpdated } from './SummaryBU';
import { LoaderSpinner } from './components/Icons';
import { NoDataIcon, WarningModalIcon } from './icons/Icons';

interface MenuProps {
  activeTable: string;
  setActiveTable: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
}

export const Menu: React.FC<MenuProps> = ({ activeTable, setActiveTable, options }) => {
  return (
    <div className="flex justify-between items-center overflow-scroll no-scrollbar w-full min-w-[300px] bg-white bg-gray-100 p-2 rounded-md mb-6 -mt-3">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => setActiveTable(option)}
          className={`px-4 py-2 rounded-md flex justify-center w-full ${
            activeTable === option
              ? 'bg-[#ECF5FF] text-[#004289]'
              : 'bg-white text-[#344054]'
          }`}
        >
          {option != "Mapping Coverage" ? option : "Referential"}
        </button>
      ))}
    </div>
  );
};

interface SearchBarProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBar: React.FC<SearchBarProps> = ({ value, setValue }) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="min-w-[300px] ml-[50px] flex items-center border rounded-md p-2 w-full bg-white border-[#D0D5DD]">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2"
      >
        <path
          d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
          stroke="#ABC1D0"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <input
        type="text"
        placeholder="Search..."
        className="w-full outline-none text-black placeholder-[#90A7B9] text-sm"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export const NoDataModal:React.FC<{activeTable: string}> = ({activeTable}) =>{
  return(
    <div className='flex flex-col items-center justify-center gap-3 px-3 py-8 h-[270px]'>
      <NoDataIcon/>
      <p>There are no "{activeTable}" errors to report at this time.</p>
    </div>
  )
}

interface CalendarProps {
  htmlString: string;
}


const CalendarComponent: React.FC<CalendarProps> = ({ htmlString }) => {
  const [cleanedText, setCleanedText] = useState<string>("");
  const [groupedDates, setGroupedDates] = useState<Record<string, string[]>>({});

  useEffect(() => {
    const dateRegex = /"?(\d{4}-\d{2}-\d{2})"?[,]?/g;
    
    const dateMatches = Array.from(htmlString.matchAll(dateRegex), (match) => match[1]);
    // console.log("Extracted Dates:", dateMatches);

    let newText = htmlString.replace(dateRegex, "").replace(/\s{2,}/g, " ").trim();

    newText = newText.replace(/(:\s*)\.$/, "$1");

    setCleanedText(newText);

    const newGroupedDates = dateMatches.reduce((acc: Record<string, string[]>, dateStr) => {
      const date = new Date(dateStr);
      const month = date.toLocaleString("en-US", { month: "short" });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(date.toLocaleDateString("fr-FR"));
      return acc;
    }, {} as Record<string, string[]>);

    // console.log("Grouped Dates:", newGroupedDates);
    setGroupedDates(newGroupedDates);
  }, [htmlString]);

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const fullGroupedDates = months.reduce((acc, month) => {
    acc[month] = groupedDates[month] || [];
    return acc;
  }, {} as Record<string, string[]>);

  const maxRows = Math.max(...Object.values(fullGroupedDates).map((arr) => arr.length), 1);

  return (
    <div className="mt-4">
      <p className="text-black text-[14px] font-semibold mb-4">Detailed issue:</p>
      <div className="mt-2 text-[14px] font-normal text-[#324465] bg-[#C8D6EE96] rounded-xl">
        <div className="p-3 text-[14px] font-normal text-[#324465]">
            <div dangerouslySetInnerHTML={{ __html: cleanedText }} />
        </div>        
        {Object.keys(groupedDates).length > 0 && (
          <div className="mt-2 max-h-[180px] overflow-y-scroll no-scrollbar">
            <div className="w-full overflow-auto rounded-md">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#f0f4fa] px-1 py-2 text-[#324465] w-full">
                    {months.map((month) => (
                      <th key={month} className="px-2 py-1 text-sm">{month}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: maxRows }).map((_, rowIndex) => (
                    <tr key={rowIndex} className="text-center">
                      {months.map((month) => (
                        <td key={month} className="p-2">
                          {fullGroupedDates[month][rowIndex] || "-"}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const transformKeyToActiveTable = (key: string): string => {
  if (key === 'url') return 'Download';
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

interface DataTableProps {
  data: {
    [key: string]: any[];
  };
  loader: boolean;
  filterStatus: string | null;
  setFilterStatus: React.Dispatch<React.SetStateAction<string | null>>;
  selectedUniqueFileType: string | null;
  setSelectedUniqueFileType: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DataTableQuality: React.FC<DataTableProps> = ({ data, loader, filterStatus, setFilterStatus, selectedUniqueFileType, setSelectedUniqueFileType }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCause, setSelectedCause] = useState<string | null>(null);
  const [selectedDetailedCause, setSelectedDetailedCause] = useState<string | null>(null);
  const [selectedDetailedCauseFix, setSelectedDetailedCauseFix] = useState<string | null>(null);
  const [sortedByDate, setSortedByDate] = useState(false);
  const {app, availableYears, dateSelected, setDateSelected} = useDataGouv()
  const {token} = useAuth()
  const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});
  // const [uniqueFileTypes, setUniqueFileTypes] = useState<string[]>([]);
  // const [filterOptions, setFilterOptions] = useState<string[]>([])
  const { isSidebarOpen, toggleSidebar} = useActiveTabs()


  const [transformedData, setTransformedData] = useState<any[]>([]);

  const {activeTable, setActiveTable} = useDataGouv()


  const menuOptions = ['File Not Received', 'File Integrity', 'Data Integrity', 'Mapping Coverage'];

  const filterColumnsPerMenuOption: { [key: string]: string[] } = {
    'File Not Received': ['file_type'],
    'File Integrity': ['file_type', 'status'],
    'Data Integrity': ['year','table_name', 'cause'],
    'Mapping Coverage': ['Table Name', 'year', 'Reference Name'],
  };

  const activeFilterColumns = filterColumnsPerMenuOption[activeTable];

  const [filterValues, setFilterValues] = useState<{ [key: string]: string | null }>({});
  const [filterOptions, setFilterOptions] = useState<{ [key: string]: string[] }>({});

  const transformActiveTableToKey = (table: string) => {
    return table
      .toLowerCase()
      .replace(/ /g, '_');
  };

  useEffect(() => {
    const key = transformActiveTableToKey(activeTable);
    setTransformedData(data[key] || []);
  }, [activeTable, data, token]);
  
  

  // useEffect(() => {
  //   if (data && Object.keys(data).length > 0) {
  //     const key = transformActiveTableToKey(activeTable);
  //     setTransformedData(data[key] || []);
  //   }
  // }, [activeTable, data]);

  const [searchBarInput, setSearchBarInput] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(transformedData.length / itemsPerPage);

  useEffect(() => {
    if (transformedData && transformedData.length > 0) {
      const newFilterOptions: { [key: string]: string[] } = {};

      activeFilterColumns.forEach((column) => {
        const optionsSet = new Set(transformedData.map((item) => item[column] || ''));
        newFilterOptions[column] = Array.from(optionsSet).filter((opt) => opt);
      });

      setFilterOptions(newFilterOptions);
    }
  }, [transformedData, activeTable]);

  const filteredData = transformedData.filter((item) => {
    let matchesFilters = true;
    const activeFilterColumns = filterColumnsPerMenuOption[activeTable];
  
    activeFilterColumns.forEach((column) => {
      const selectedValue = filterValues[column];
      if (selectedValue && selectedValue !== 'All') {
        if (column === 'year') {
          if (item[column] !== Number(selectedValue)) {
            matchesFilters = false;
          }
        } else {
          if (item[column] !== selectedValue) {
            matchesFilters = false;
          }
        }
      }
    });
    if (searchBarInput.trim()) {
      const searchTerm = searchBarInput.toLowerCase();
      const matchesSearch = Object.values(item).some(
        (value) => value && value.toString().toLowerCase().includes(searchTerm)
      );
      matchesFilters = matchesFilters && matchesSearch;
    }
  
    return matchesFilters;
  });

  const activeFilters = Object.entries(filterValues)
    .filter(([key, value]) => value && value !== 'All')
    .map(([key, value]) => `${transformKeyToActiveTable(key)}: ${value}`)
    .join(', ');
  
  const handleDownload = async(url: string) => {
    setLoadingDownloads((prev) => ({ ...prev, [url]: true }));
    try{
      const response = await fetch(`/api/data_gov/gouvernance/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({gs_url : url})
      });
      if(!response.ok) return
      const signedUrl = JSON.parse(await response.text());
      const a = document.createElement('a');
      a.href = signedUrl;
      a.download = url.split('/').pop() || 'file';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    catch(error){
      console.log(error)
      return null
    }
    finally{
      setLoadingDownloads((prev) => ({ ...prev, [url]: false }));
    }
  };

  const sortByDate = (data: any) => {
    return [...data].sort((a, b) => new Date(a.reception_time).getTime() - new Date(b.reception_time).getTime());
  };

  const handleDateHeaderClick = () => {
    setSortedByDate(!sortedByDate);
  };

  const displayedData = sortedByDate ? sortByDate(filteredData) : filteredData;

  const paginatedData = displayedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const getStatusColor = (status: string) => {
    if (status === 'failed') return <CrossIcon/>;
    if (status === 'pending') return <PendingIcon/>;
    if (status === 'success') return <CheckIcon/>;
    if (status === 'running') return <RunningIcon/>;
    if (status === 'delayed') return <DelayedIcon/>;
    return <PendingIcon/>;
  };

  const openModal = (cause: string, detailed_cause?: string, detailed_cause_fix?: string) => {
    setSelectedCause(cause);
    setSelectedDetailedCause(detailed_cause!);
    setSelectedDetailedCauseFix(detailed_cause_fix!);
    setShowModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
    setSelectedCause(null);
    setSelectedDetailedCause(null);
  };

  const headers = transformedData && transformedData.length > 0 ? Object.keys(transformedData[0]) : [];
  const filteredHeaders = headers.filter((header) => header !== 'detailed_cause' && header !== 'detailed_cause_fix');

  return (
    <div className="w-full p-5">
        <Menu activeTable={activeTable} setActiveTable={setActiveTable} options={menuOptions} />
        <div className={`bg-white rounded-xl ${transformedData.length === 0 && !loader ? 'ml-[30%] min-w-[300px] w-[44%]': 'w-full'}`}>
          {transformedData.length !== 0 && 
            <div className="flex w-full">
                <div className='flex flex-row items-center w-full mb-3 overflow-x-scroll no-scrollbar'>
                  <div className="flex flex-row gap-5 w-full items-center justify-start p-3">
                    {activeFilterColumns.map((column) => {
                    if (column === 'year') {
                      return (
                        <SelectOptionsQuality
                          key={column}
                          options={availableYears!}
                          defaultValue=""
                          renderOption={(option) => ({
                            value: option,
                            label: option
                          })}
                          selectedValue={dateSelected!}
                          onChange={(e) => setDateSelected(e.target.value.toString())}
                        />
                      );
                    }
                    return (
                      <SelectOptionsQuality
                        key={column}
                        options={filterOptions[column] || []}
                        defaultValue={transformKeyToActiveTable(column)}
                        renderOption={(option) => ({
                          value: option,
                          label: activeTable ? transformKeyToActiveTable(option) : option
                        })}
                        selectedValue={filterValues[column] || ''}
                        onChange={(e) => setFilterValues((prev) => ({ ...prev, [column]: e.target.value }))}
                      />
                    );
                  })}
                  <SearchBar value={searchBarInput!} setValue={setSearchBarInput}/>
                  </div>
                </div>
            </div>
          }
          <div className='w-full overflow-x-scroll no-scrollbar'>
            {loader ? (
              <div className="text-center py-4">
                <LoaderSpinner/>
              </div>
            ) : transformedData.length === 0 && !loader ? (
              <div className="flex w-full items-center justify-center text-center py-2 text-gray-600">
                <NoDataModal activeTable={activeTable}/>
              </div>
            ) : 
          (
            <table className="w-full">
              <thead className="text-[12px] bg-[#EAECF0] bg-opacity-50 text-[#667085] border-t border-b border-gray-200">
                <tr>
                  {filteredHeaders.map((header, index) => (
                    <th
                      key={index}
                      className="p-4 font-medium text-gray-600 cursor-pointer"
                      onClick={(header === "Uploaded Date" || header === "Reception Time") ? handleDateHeaderClick : undefined}
                    >
                      <div className="flex items-center gap-2">
                        {transformKeyToActiveTable(header).replaceAll(' ', '\u00A0')}
                        <ArrowDownIcon
                          className={`ml-2 ${
                            sortedByDate && (header === "Uploaded Date" || header === "Reception Time")  ? "rotate-180" : ""
                          }`}
                        />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-[14px]">
                {loader ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center py-4">
                      <LoaderSpinner/>
                    </td>
                  </tr>
                ) :  transformedData.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center py-4">
                      There are no "{activeTable}" errors to report at this time.
                    </td>
                  </tr>
                ) : filteredData.length === 0 && activeFilters ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center text-sm py-4">
                      No data available for filter ({activeFilters})
                    </td>
                  </tr>
                ) : (
                  paginatedData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="border-b border-gray-200">
                      {filteredHeaders.map((header, colIndex) => {
                        if (header === 'cause') {
                          const causeValue = row['cause'];
                          const hasCause = causeValue && causeValue.length > 0;
                          if (!hasCause) {
                            return (
                              <td key={colIndex} className="p-5 text-gray-800">
                             
                              </td>
                            );
                          }
                          
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              <div
                                className="cursor-pointer text-[#004289] font-bold flex flex-row gap-3 items-center"
                                onClick={() => openModal(row['cause'], row['detailed_cause'], row['detailed_cause_fix'])}
                              >
                                <LinkIcon/>
                                {row['cause'].replaceAll(' ', '\u00A0').replaceAll('_', ' ')}
                              </div>
                            </td>
                          );
                        }

                        if ((activeTable === 'Mapping Coverage') && header === 'cause' || header === 'Cause') {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              <div className="cursor-pointer" onClick={() => openModal(row['cause'])}>
                                <CrossIcon />
                              </div>
                            </td>
                          );
                        }

                        if (header === 'url') {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              <div className="cursor-pointer flex flex-row gap-2 items-center" onClick={()=> handleDownload(row['url'])}>
                                <FileIcon />
                                {row['file_name_sftp']}
                              </div>
                            </td>
                          );
                        }

                        if (header === 'status') {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              {getStatusColor(row[header])}
                            </td>
                          );
                        }

                        if (typeof row[header] === 'string' && row[header].includes('T') && row[header].includes('+')) {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              {formatLastUpdated(row[header])}
                            </td>
                          );
                        }

                        return (
                          <td key={colIndex} className="p-5 text-gray-800">
                            {row[header] || 'Not available'}
                          </td>
                        );
                      })}
                    </tr>
                  ))                  
                )}
              </tbody>
            </table>
          )}
          </div>
          {transformedData.length !== 0 && totalPages > 1 &&
            <div className="flex justify-between items-center mt-4 px-5 py-3">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                className={`p-2 border border-[#D0D5DD] px-3 py-2 rounded-md text-[#667085] hover:bg-gray-200 ${
                  currentPage === 1 ? 'opacity-60 cursor-not-allowed' : ''
                }`}            >
                <ArrowDownIcon className='rotate-90 scale-[1.2] text-[#0D3D62]'/>
              </button>
              <div className="flex items-center">
                {(() => {
                  const pageNumbers = [];
                  const showLeftEllipsis = currentPage > 4;
                  const showRightEllipsis = currentPage < totalPages - 3;

                  pageNumbers.push(
                    <button
                      key={1}
                      onClick={() => handlePageChange(1)}
                      className={`p-2 w-10 h-10 rounded-md ${
                        currentPage === 1
                          ? 'bg-[#ECF5FF] text-[#004289]'
                          : 'bg-white text-[#667085]'
                      }`}
                    >
                      1
                    </button>
                  );

                  if (showLeftEllipsis) {
                    pageNumbers.push(<span key="left-ellipsis" className="text-[#667085] px-2">...</span>);
                  }

                  const start = Math.max(2, currentPage - 2);
                  const end = Math.min(totalPages - 1, currentPage + 2);

                  for (let i = start; i <= end; i++) {
                    pageNumbers.push(
                      <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={`p-2 w-10 h-10 rounded-md ${
                          currentPage === i
                            ? 'bg-[#ECF5FF] text-[#004289]'
                            : 'bg-white text-[#667085]'
                        }`}
                      >
                        {i}
                      </button>
                    );
                  }

                  if (showRightEllipsis) {
                    pageNumbers.push(<span key="right-ellipsis" className="text-[#667085] px-2">...</span>);
                  }

                  if (totalPages > 1) {
                    pageNumbers.push(
                      <button
                        key={totalPages}
                        onClick={() => handlePageChange(totalPages)}
                        className={`p-2 w-10 h-10 rounded-md ${
                          currentPage === totalPages
                            ? 'bg-[#ECF5FF] text-[#004289]'
                            : 'bg-white text-[#667085]'
                        }`}
                      >
                        {totalPages}
                      </button>
                    );
                  }

                  return pageNumbers;
                })()}
              </div>

              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className={`p-2 border border-[#D0D5DD] px-3 py-2 rounded-md text-[#667085] hover:bg-gray-200 ${
                  currentPage === totalPages ? 'opacity-60 cursor-not-allowed' : ''
                }`}            >
                <ArrowDownIcon className='-rotate-90 scale-[1.2] text-[#0D3D62]'/>
              </button>
            </div>
          }
        </div>
        {showModal && (selectedCause || selectedDetailedCause || selectedDetailedCauseFix) && (
          <div className={`fixed top-0 ${isSidebarOpen ? 'left-[10%]' : 'left-[2%]'} bg-[#344054B2] w-full h-full backdrop-blur-sm flex items-center justify-center`}>
            <div className='w-[50%] max-w-full p-3 flex items-center justify-center'>
              <div className="bg-white border border-gray-200 p-6 rounded-md shadow-md  no-scrollbar overflow-scroll">
                <div className='flex flex-col items-start justify-center w-full'>
                  <WarningModalIcon></WarningModalIcon>
                  <h2 className="text-xl text-[#101828] font-semibold mb-1 mt-2">{app} Error Details</h2>
                  <p className='text-[#667085] text-[14px]'><span className='font-semibold'>Cause:</span> {selectedCause!}</p>
                  {selectedDetailedCauseFix && selectedDetailedCauseFix.length > 0 && 
                    <p className='text-[#667085] font-semibold mt-4 text-[14px]'>
                      Suggested fix:
                      <div
                        dangerouslySetInnerHTML={{ __html: selectedDetailedCauseFix! }}
                        className="font-normal text-[14px]"
                      />
                    </p>
                  }
                  <div className="mt-4 max-w-[650px]">
                    {selectedCause === "missing_data_test" ? (
                      <CalendarComponent htmlString={selectedDetailedCause!} />
                    ) : (
                      <>
                        <p className="text-black text-[14px] font-semibold mb-4">Detailed issue:</p>
                        <div
                          dangerouslySetInnerHTML={{ __html: selectedDetailedCause! }}
                          className="min-w-[500px] mt-2 text-[14px] font-normal text-[#324465] p-4 bg-[#C8D6EE96] rounded-xl"
                        />
                      </>
                    )}
                  </div>
                  <div className='w-full p-1 flex items-center justify-center'>
                    <button onClick={closeModal} className="mt-4 px-2 py-3 text-[#344054] w-[35%] hover:bg-black hover:text-white border border-[#D0D5DD] rounded-xl">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};