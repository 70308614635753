import React from "react";
import { useState, useEffect, useCallback } from "react";

interface InputClassicProps {
  text: string;
  name: string;
  valueExt?: string;
  handleChangementExt?: (value: string) => void;
}

function InputClassic({
  text,
  name,
  valueExt,
  handleChangementExt,
}: InputClassicProps) {
  const [dataValue, setDataValue] = useState<string>(valueExt || "");

  useEffect(() => {
    if (valueExt !== undefined) setDataValue(valueExt);
  }, [valueExt]);

  const handleChangement = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDataValue(e.target.value);
      if (handleChangementExt) handleChangementExt(e.target.value);
    },
    [handleChangementExt]
  );

  return (
    <div className="w-[315px] h-[70px] flex flex-col justify-between font-inter">
      <label
        className="text-[#344054] font-medium text-sm leading-5"
        htmlFor={name}
      >
        {text}:
      </label>
      <input
        type="text"
        id={name}
        name={name}
        value={dataValue}
        onChange={handleChangement}
        className="h-[44px] w-[315px] border border-[#D0D5DD] rounded-lg pl-2 bg-white text-base text-[#101828]"
        required
      />
    </div>
  );
}

export default React.memo(InputClassic);
