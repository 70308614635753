import React, { useState, useEffect, useRef } from "react";
import UserTypeSelection from "./UserTypeSelection";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import Chart from "../components/elements/Chart";
import { fetchDataCharts, fetchGenAiExpenses } from "./api/userApi";
import { PATH_IMG } from "../../../constants/constant";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";
import { useAuth } from "../../../providers/Auth";
import { usersInterface } from "../../../types/adminManagementPages";
import useClickOutside from "../../../hooks/useClickOutside";
import MiniLoader from "./MiniLoader";
import Calendar from "./Calendar";
import UserLimitIncreaseNotification from "./UserLimitIncreaseNotification";
import { profile } from "console";
import data from "../AdminManagementDataAnalytics/dataAssignedUserList";

interface UserManagementAccountStatusProps {
  copieData: usersInterface[];
}

export default function UserManagementAccountStatus({
  copieData,
}: UserManagementAccountStatusProps) {
  const { upnActive, handleChangementCopieData, notificationUser, profile } =
    useUserDataAdmin();

  const [loadingChart, setLoadingChart] = useState(false);
  const [loadingExpenses, setLoadingExpenses] = useState(false);
  const [getGenAiExpenses, setGetGenAiExpenses] = useState<number | null>(null);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [selectCalendar, setSelectCalendar] = useState("this Month");
  const [dataDateCalendar, setDataDateCalendar] = useState<Date[]>([
    new Date(new Date().setMonth(new Date().getMonth() - 1)),
    new Date(),
  ]);
  const [dataCharts, setDataCharts] = useState<any | null>(null);
  const calendar = useRef<HTMLDivElement>(null);
  useClickOutside(calendar, () => setIsOpenCalendar(false));
  const { token } = useAuth();
  const dataUpn = copieData.find((user) => user.upn === upnActive);

  const handleChangementType = (value: string) => {
    handleChangementCopieData("type", value);
  };

  const handleDataCalendar = (data: Date[], type: string) => {
    setDataDateCalendar(data);
    setIsOpenCalendar(false);
    console.log("type", type);
    setSelectCalendar(type);
  };

  useEffect(() => {
    if (!dataUpn || !dataUpn.upn || !dataUpn.isInAppDirectory) {
      setGetGenAiExpenses(5000);
      return;
    }
    const getGenAiExpenses = async () => {
      try {
        setLoadingExpenses(true);
        const result = await fetchGenAiExpenses(
          "/user_management/get_current_month_consumption/",
          dataUpn.idVINCI,
          token
        );
        if (result.amount === undefined || result.amount === null) {
          throw new Error("Format de données inattendu.");
        }
        const amountType =
          dataUpn?.type === "Standard"
            ? 5000
            : dataUpn?.type === "Premium"
            ? 10000
            : 15000;
        setGetGenAiExpenses(amountType - parseFloat(result.amount.toFixed(0)));
      } catch (err) {
        dataUpn?.type === "Standard"
          ? setGetGenAiExpenses(5000)
          : dataUpn?.type === "Premium"
          ? setGetGenAiExpenses(10000)
          : setGetGenAiExpenses(15000);
        if (err instanceof Error) console.log(err.message);
      } finally {
        setLoadingExpenses(false);
      }
    };

    getGenAiExpenses();
  }, [upnActive, dataUpn?.upn]);

  useEffect(() => {
    if (!dataUpn || !dataUpn.upn || !dataUpn.isInAppDirectory) {
      setDataCharts({});
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const getDataCharts = async () => {
      try {
        console.log("je dois passer par ici");
        console.log(upnActive);
        setLoadingChart(true);
        const result = await fetchDataCharts(
          "/user_management/get_app_consumption/",
          dataUpn.idVINCI,
          dataDateCalendar,
          signal,
          token
        );

        if (!result.appNames || !result.consumptionValues) {
          throw new Error("Format de données inattendu.");
        }

        const dataConverted = result.appNames.reduce(
          (acc: any, appName: any, index: number) => {
            acc[appName] = result.consumptionValues[index] || 0;
            return acc;
          },
          {}
        );

        setDataCharts(dataConverted);
      } catch (err) {
        setDataCharts({});
        if (err instanceof Error && err.name !== "AbortError") {
        }
      } finally {
        setLoadingChart(false);
      }
    };

    getDataCharts();

    return () => {
      controller.abort();
    };
  }, [upnActive, selectCalendar, dataDateCalendar, dataUpn?.upn]);

  return (
    <div className="h-[645px] flex flex-col justify-between pl-4">
      <div className="bg-[#f9fafb] m-auto w-[766px] rounded-lg">
        <UserTypeSelection
          valueExt={dataUpn ? dataUpn.type : ""}
          handleChangementExt={handleChangementType}
        />
      </div>

      <div className="bg-[#f9fafb] p-5 rounded-lg mt-6">
        {dataUpn && dataUpn.isInAppDirectory && (
          <div className="w-[726px] gap-14 flex justify-around font-inter text-[#344054] font-medium text-sm leading-4 items-center rounded-lg">
            <p className="flex gap-2">
              <span>GenAI Credit Use</span>
              <div className="relative cursor-help group">
                <img src={`${PATH_IMG}/helpIcon.svg`} alt="Help" />
                <div className="absolute bottom-full right-0 mb-2 w-96 p-4 text-sm text-[#667085] bg-[#f9fafb] rounded-md shadow-lg hidden group-hover:block transition-opacity duration-200">
                  <p>
                    {" "}
                    Every time you use a GenAI-powered product on the SDH
                    portal, a specific number of credits will be consumed.{" "}
                  </p>
                </div>
              </div>
            </p>
            {loadingExpenses ? (
              <MiniLoader size="44px" />
            ) : (
              <p>
                <span
                  style={
                    getGenAiExpenses !== null && getGenAiExpenses < 500
                      ? { color: "#A90F0F" }
                      : {}
                  }
                >
                  {getGenAiExpenses?.toString()} out {""}
                </span>
                <span className="text-[#667085]">
                  of{" "}
                  {dataUpn.type === "Standard"
                    ? "5000 "
                    : dataUpn.type === "Premium"
                    ? "10000 "
                    : "15000 "}
                  left
                </span>
              </p>
            )}
            <div className="relative">
              <div onClick={() => setIsOpenCalendar(!isOpenCalendar)}>
                <ButtonSvgText
                  logo={`${PATH_IMG}/calendar.svg`}
                  text={selectCalendar}
                  width="125"
                  height="45"
                />
              </div>
              {isOpenCalendar && (
                <div
                  ref={calendar}
                  className="absolute right-[800px] -top-56 z-10 bg-white w-[125px] flex flex-col rounded-lg shadow-lg"
                >
                  <Calendar
                    dataDateCalendar={dataDateCalendar}
                    handleExterneData={handleDataCalendar}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="overflow-y-auto h-[246px] mt-4">
          {notificationUser && notificationUser.creditAsked != 0 && (
            <UserLimitIncreaseNotification />
          )}
          {loadingChart ? (
            <MiniLoader size="200px" />
          ) : dataCharts &&
            Object.entries(dataCharts).length > 0 &&
            dataUpn &&
            dataUpn.isInAppDirectory ? (
            <Chart dataCharts={dataCharts} />
          ) : (
            <div className="flex justify-center items-center h-full">
              <p>No data to display</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
