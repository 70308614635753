import React from "react";
import ButtonClassic from "../buttons/ButtonClassic";

interface ToggleInputProps {
  title: string;
  width: string;
  height: string;
  firstTitle: string;
  secondTitle: string;
  idFirst: string;
  idSecond: string;
  selectedValue: string; // Comparé avec firstTitle et secondTitle
  handleToggle: (value: boolean) => void;
}

export default function ToggleInput({
  title,
  width,
  height,
  firstTitle,
  secondTitle,
  idFirst,
  idSecond,
  selectedValue,
  handleToggle,
}: ToggleInputProps) {
  const sideWidth = parseFloat(width) / 2;
  const sideHeight = (parseFloat(height) / 100) * 95;

  const handleChange = (value: string) => {
    handleToggle(value === firstTitle); // Retourne true si firstTitle est sélectionné, sinon false
  };

  return (
    <div className="font-inter">
      <h3 className="text-[#272b32] font-medium text-sm leading-4">{title}</h3>
      <div
        className="flex justify-around mt-2 bg-[#f2f2f2] rounded-lg"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {[
          { id: idFirst, value: firstTitle, title: firstTitle },
          { id: idSecond, value: secondTitle, title: secondTitle },
        ].map(({ id, value, title }) => (
          <label
            key={id}
            htmlFor={id}
            className="relative flex justify-center items-center cursor-pointer"
            style={{ height: `${height}px`, width: `${sideWidth}px` }}
          >
            {selectedValue === value ? (
              <div
                className="rounded-lg"
                style={{ boxShadow: "0px 1px 2px 0px #00000040" }}
              >
                <ButtonClassic
                  width={sideWidth.toString()}
                  height={sideHeight.toString()}
                  text={title}
                />
              </div>
            ) : (
              <div
                className="flex justify-center items-center text-[#344054] cursor-pointer text-sm"
                style={{ height: `${height}px` }}
              >
                <p>{title}</p>
              </div>
            )}
            <input
              id={id}
              className="opacity-0 absolute h-0 w-0"
              name="toggle"
              type="radio"
              checked={selectedValue === value}
              onChange={() => handleChange(value)}
            />
          </label>
        ))}
      </div>
    </div>
  );
}
