import { AlertTriangle, ExternalLink, Globe } from "lucide-react";

export const ChatGPTModalSearch = ({ }: {}) => {
    const searchOptions = [
        {
            name: "ChatGPT",
            url: "https://chat.openai.com/",
            description: "OpenAI's conversational AI with web search capabilities"
        },
        {
            name: "Perplexity AI",
            url: "https://www.perplexity.ai/",
            description: "AI search engine with real-time information and sources"
        },
        {
            name: "Microsoft Copilot",
            url: "https://m365.cloud.microsoft/chat?form=bingchat&auth=2",
            description: "Microsoft's AI assistant with integrated web search functionality"
        }
    ];

    return (
        <div className="max-w-2xl mx-auto p-6 font-trendaSemiBold">
            <div className="flex items-center gap-3 mb-4">
                <Globe className="text-text-primary-light" size={24} />
                <h2 className="text-xl font-medium text-text-primary-light">Web Search Not Supported</h2>
            </div>

            <p className="mb-6 text-text-primary-light">
                Web search functionality is not currently supported through this portal.
                For search capabilities, we recommend using one of the following applications:
            </p>

            <div className="space-y-4 mb-8">
                {searchOptions.map((option, index) => (
                    <a
                        key={index}
                        href={option.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-start p-4 border border-gray-200 rounded-lg hover:bg-gray-50 hover:shadow-md hover:border-gray-300 transition-all duration-200 ease-in-out"
                    >
                        <div className="flex-1">
                            <div className="flex items-center gap-2">
                                <h3 className="font-medium text-text-primary-light">{option.name}</h3>
                                <ExternalLink size={14} className="text-text-primary-light opacity-50" />
                            </div>
                            <p className="text-sm text-gray-600 mt-1">{option.description}</p>
                        </div>
                    </a>
                ))}
            </div>

            <div className="border border-amber-300 bg-amber-50 p-4 rounded-lg">
                <div className="flex gap-2">
                    <AlertTriangle className="h-5 w-5 text-amber-600 flex-shrink-0 mt-0.5" />
                    <div>
                        <h3 className="text-amber-800 font-medium text-sm">Important Security Warning</h3>
                        <p className="text-amber-700 mt-2 text-sm">
                            Please be careful not to upload any confidential documents or information to these external tools.
                            Information shared with these services may be stored on their servers and used to train their models.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}