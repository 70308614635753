import React from "react";
import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import Minus from "../components/elements/formSvg/Minus";
import Cross from "../components/elements/formSvg/Cross";

import PlusElement from "../components/elements/formSvg/Plus";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import { usersInterface } from "../../../types/adminManagementPages";
import {
  DashboardPermissions,
  DataGovPermissions,
  GenAiPermissions,
} from "./type/permissionsTypes";
import { PATH_IMG } from "../../../constants/constant";

export default function PermissionsListElements({
  users,
}: {
  users: usersInterface[];
}) {
  return (
    <div className="mt-4 font-inter">
      {users.map((user, index) => (
        <React.Fragment key={index}>
          <PermissionElement userSelected={user} />
          {index < users.length - 1 && (
            <div className="mt-5 mb-5 w-[639px] h-[1px] bg-[#d0d5dd] rounded-lg" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

interface PermissionElementProps {
  userSelected: usersInterface;
}

function PermissionElement({ userSelected }: PermissionElementProps) {
  const [openLayer, setOpenLayer] = useState(false);
  const {
    permissions,
    permissionsToDelete,
    permissionsToAdd,
    permissionsPendingDelete,
    permissionsPendingAdd,
  } = usePermissionsDataAdmin();

  const dataPermissions = permissions.get(userSelected.idVINCI);
  const dataPermissionsToDelete = permissionsToDelete.get(userSelected.idVINCI);
  const dataPermissionsToAdd = permissionsToAdd.get(userSelected.idVINCI);
  const dataPermissionsPendingDelete = permissionsPendingDelete.get(
    userSelected.idVINCI
  );
  const dataPermissionsPendingAdd = permissionsPendingAdd.get(
    userSelected.idVINCI
  );
  if (!dataPermissions) return null;
  return (
    <>
      <div
        className="flex justify-between items-center w-[642px] cursor-pointer"
        onClick={() => setOpenLayer(!openLayer)}
      >
        <p className="font-normal text-base leading-6 text-[#747c8a]">
          {userSelected.upn}
        </p>
        {openLayer ? (
          <Minus width="14" height="14" />
        ) : (
          <PlusElement width="14" height="14" />
        )}
      </div>
      <div style={openLayer ? {} : { display: "none" }} className="w-[642px]">
        <p className="mt-2 mb-2 text-[#004289] font-bold">
          Dashboard permissions
        </p>
        <div className="flex flex-wrap  gap-4">
          <ListDashboard
            dashboard={dataPermissions.dashboard}
            idVINCI={userSelected.idVINCI}
            dashboardToDelete={dataPermissionsToDelete?.dashboard}
            dashboardToAdd={dataPermissionsToAdd?.dashboard}
            pendingDelete={dataPermissionsPendingDelete?.dashboard}
            pendingAdd={dataPermissionsPendingAdd?.dashboard}
          />
        </div>
        <p className="mt-2 mb-2 text-[#004289] font-bold">GenAI permissions</p>
        <div className="flex flex-wrap  gap-4">
          <ListGenAi
            genAiPermissions={dataPermissions.gen_ai}
            idVINCI={userSelected.idVINCI}
            genAiToDelete={dataPermissionsToDelete?.gen_ai}
            genAiToAdd={dataPermissionsToAdd?.gen_ai}
            pendingDelete={dataPermissionsPendingDelete?.gen_ai}
            pendingAdd={dataPermissionsPendingAdd?.gen_ai}
          />
        </div>
        <p className="mt-2 mb-2 text-[#004289] font-bold">
          Data Governance permissions
        </p>
        <div className="flex flex-wrap  gap-4">
          <ListDataGov
            dataGovPermissions={dataPermissions.data_gov}
            idVINCI={userSelected.idVINCI}
            datagovToDelete={dataPermissionsToDelete?.data_gov}
            datagovToAdd={dataPermissionsToAdd?.data_gov}
            pendingDelete={dataPermissionsPendingDelete?.data_gov}
            pendingAdd={dataPermissionsPendingAdd?.data_gov}
          />
          {/* <ListGenAi
            genAiPermissions={dataPermissions.gen_ai}
            idVINCI={userSelected.idVINCI}
            genAiToDelete={dataPermissionsToDelete?.gen_ai}
            genAiToAdd={dataPermissionsToAdd?.gen_ai}
          /> */}
        </div>
      </div>
    </>
  );
}

interface ListDashboardProps {
  dashboard: DashboardPermissions[];
  dashboardToDelete: DashboardPermissions[] | undefined;
  idVINCI: string;
  dashboardToAdd: DashboardPermissions[] | undefined;
  pendingDelete: DashboardPermissions[] | undefined;
  pendingAdd: DashboardPermissions[] | undefined;
}

function ListDashboard({
  dashboard,
  idVINCI,
  dashboardToDelete,
  dashboardToAdd,
  pendingAdd,
  pendingDelete,
}: ListDashboardProps) {
  const { addPermissionToDelete, removePermissionToAdd } =
    usePermissionsDataAdmin();
  // if (dashboard.length === 0) return null;
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <>
      {dashboard.map((user, index) => {
        const isPendingDelete = pendingDelete?.some(
          (elem) =>
            user.airport === elem.airport &&
            user.business_unit === elem.business_unit &&
            user.dashboard === elem.dashboard &&
            user.dashboard_level === elem.dashboard_level
        );

        const isDashboardToDelete = dashboardToDelete?.some(
          (elem) =>
            user.airport === elem.airport &&
            user.business_unit === elem.business_unit &&
            user.dashboard === elem.dashboard &&
            user.dashboard_level === elem.dashboard_level
        );

        return (
          <div
            key={index}
            className="h-11 font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1 relative "
            style={
              isDashboardToDelete
                ? { backgroundColor: "#b6353550", position: "relative" }
                : isPendingDelete
                ? { backgroundColor: "#b6353550" }
                : { backgroundColor: "white" }
            }
          >
            <p className=" flex items-center gap-2 ">
              {isPendingDelete ? (
                <div className="group overflow-visible">
                  {isPendingDelete && (
                    <div
                      ref={(el) => (refs.current[index] = el)}
                      className="relative group flex items-center cursor-pointer"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {hoveredIndex === index && (
                        <PendingApprovalTooltip
                          targetRef={{ current: refs.current[index] }}
                        />
                      )}
                      <img src={PATH_IMG + "/pending.svg"} alt="Pending" />
                    </div>
                  )}
                </div>
              ) : null}
              {user.dashboard_level && user.dashboard_level != "" && (
                <span className="text-sm leading-5 text-[#747c8a]">
                  {user.dashboard_level}
                </span>
              )}
              {user.dashboard && user.dashboard != "" && (
                <span className="font-semibold text-[#344054] text-sm leading-5">
                  {user.dashboard}
                </span>
              )}
              {user.business_unit && user.business_unit != "" && (
                <span className="text-[#747c8a] font-semibold text-sm leading-5">
                  [{user.business_unit}]
                </span>
              )}
              {user.airport && user.airport != "" && (
                <span className="text-[#747c8a] font-semibold text-sm leading-5">
                  [{user.airport}]
                  {/* {user.airport.map((elem, index) => {
                if (user.airport.length === 1) {
                  return <span key={index}>[{elem}]</span>;
                }
                if (index === user.airport.length - 1) {
                  return <span key={index}>,{elem}]</span>;
                } else if (index > 0) {
                  return <span key={index}>,{elem}</span>;
                }
                return <span key={index}>[{elem}</span>;
              })} */}
                </span>
              )}
            </p>
            <div
              onClick={() =>
                isPendingDelete
                  ? null
                  : addPermissionToDelete(idVINCI, "dashboard", user)
              }
              className="cursor-pointer"
            >
              <Cross width="14" height="14" />
            </div>
          </div>
        );
      })}
      {dashboardToAdd?.map((user, index) => (
        <div
          key={index}
          className="h-11 bg-[#F2F6FB] font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1"
        >
          <p className=" flex items-center gap-2">
            {user.dashboard_level && user.dashboard_level != "" && (
              <span className="text-sm leading-5 text-[#747c8a]">
                {user.dashboard_level}
              </span>
            )}
            {user.dashboard && user.dashboard != "" && (
              <span className="font-semibold text-[#344054] text-sm leading-5">
                {user.dashboard}
              </span>
            )}
            {user.business_unit && user.business_unit != "" && (
              <span className="text-[#747c8a] font-semibold text-sm leading-5">
                [{user.business_unit}]
              </span>
            )}
            {user.airport && user.airport != "" && (
              <span className="text-[#747c8a] font-semibold text-sm leading-5">
                [{user.airport}]
                {/* {user.airport.map((elem, index) => {
                if (user.airport.length === 1) {
                  return <span key={index}>[{elem}]</span>;
                }
                if (index === user.airport.length - 1) {
                  return <span key={index}>,{elem}]</span>;
                } else if (index > 0) {
                  return <span key={index}>,{elem}</span>;
                }
                return <span key={index}>[{elem}</span>;
              })} */}
              </span>
            )}
          </p>
          <div
            onClick={() => removePermissionToAdd(idVINCI, "dashboard", user)}
            className="cursor-pointer"
          >
            <Cross width="14" height="14" />
          </div>
        </div>
      ))}
      {pendingAdd?.map((user, index) => (
        <div
          key={index}
          className="h-11 font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1 relative bg-[#F2F6FB]"
        >
          <p className=" flex items-center gap-2 ">
            <div className="group overflow-visible">
              <div
                ref={(el) => (refs.current[index] = el)}
                className="relative group flex items-center cursor-pointer"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {hoveredIndex === index && (
                  <PendingApprovalTooltip
                    targetRef={{ current: refs.current[index] }}
                  />
                )}
                <img src={PATH_IMG + "/pending.svg"} alt="Pending" />
              </div>
            </div>

            {user.dashboard_level && user.dashboard_level != "" && (
              <span className="text-sm leading-5 text-[#747c8a]">
                {user.dashboard_level}
              </span>
            )}
            {user.dashboard && user.dashboard != "" && (
              <span className="font-semibold text-[#344054] text-sm leading-5">
                {user.dashboard}
              </span>
            )}
            {user.business_unit && user.business_unit != "" && (
              <span className="text-[#747c8a] font-semibold text-sm leading-5">
                [{user.business_unit}]
              </span>
            )}
            {user.airport && user.airport != "" && (
              <span className="text-[#747c8a] font-semibold text-sm leading-5">
                [{user.airport}]
              </span>
            )}
          </p>
          <div onClick={() => {}} className="cursor-pointer">
            <Cross width="14" height="14" />
          </div>
        </div>
      ))}
    </>
  );
}

interface ListGenAiProps {
  genAiPermissions: GenAiPermissions[];
  idVINCI: string;
  genAiToDelete: GenAiPermissions[] | undefined;
  genAiToAdd: GenAiPermissions[] | undefined;
  pendingDelete: GenAiPermissions[] | undefined;
  pendingAdd: GenAiPermissions[] | undefined;
}

function ListGenAi({
  genAiPermissions,
  idVINCI,
  genAiToDelete,
  genAiToAdd,
  pendingDelete,
  pendingAdd,
}: ListGenAiProps) {
  const { addPermissionToDelete, removePermissionToAdd } =
    usePermissionsDataAdmin();
  // if (genAiPermissions.length === 0) return null;

  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <>
      {genAiPermissions.map((genai, index) => {
        const isPendingDelete = pendingDelete?.some(
          (elem) =>
            genai.app_name == elem.app_name &&
            genai.use_case == elem.use_case &&
            genai.business_unit == elem.business_unit &&
            genai.airport == elem.airport &&
            genai.role == elem.role &&
            genai.access_type == elem.access_type
        );
        const isGenAiToDelete = genAiToDelete?.some(
          (elem) =>
            genai.app_name == elem.app_name &&
            genai.use_case == elem.use_case &&
            genai.business_unit == elem.business_unit &&
            genai.airport == elem.airport &&
            genai.role == elem.role &&
            genai.access_type == elem.access_type
        );

        return (
          <div
            key={index}
            className="h-11 font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1 relative"
            style={
              genAiToDelete && isGenAiToDelete
                ? { backgroundColor: "#b6353550", position: "relative" }
                : isPendingDelete
                ? { backgroundColor: "#b6353550" }
                : { backgroundColor: "white" }
            }
          >
            <p className=" flex items-center gap-2">
              {isPendingDelete ? (
                <div className="group overflow-visible">
                  {isPendingDelete && (
                    <div
                      ref={(el) => (refs.current[index] = el)}
                      className="relative group flex items-center cursor-pointer"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {hoveredIndex === index && (
                        <PendingApprovalTooltip
                          targetRef={{ current: refs.current[index] }}
                        />
                      )}
                      <img src={PATH_IMG + "/pending.svg"} alt="Pending" />
                    </div>
                  )}
                </div>
              ) : null}
              <span className="font-semibold text-sm leading-5 text-[#747c8a]">
                {genai.app_name}
              </span>
              <span className="font-semibold text-sm leading-5 text-[#344054]">
                {genai.use_case}
              </span>
              <span className="font-semibold text-sm leading-5 text-[#344054]">
                {genai.business_unit}
              </span>
              <span className="font-semibold text-sm leading-5 text-[#344054]">
                {genai.airport}
              </span>
              <span className="font-semibold text-sm leading-5 text-[#344054]">
                {genai.role}
              </span>
            </p>
            <div
              className=" cursor-pointer"
              onClick={() =>
                isPendingDelete
                  ? null
                  : addPermissionToDelete(idVINCI, "genai", genai)
              }
            >
              <Cross width="14" height="14" />
            </div>
          </div>
        );
      })}
      {genAiToAdd?.map((genai, index) => (
        <div
          key={index}
          className="h-11 bg-[#F2F6FB]  font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1"
        >
          <p className=" flex items-center gap-2">
            <span className="font-semibold text-sm leading-5 text-[#747c8a]">
              {genai.app_name}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.use_case}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.business_unit}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.airport}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.role}
            </span>
          </p>
          <div
            className=" cursor-pointer"
            onClick={() => removePermissionToAdd(idVINCI, "genai", genai)}
          >
            <Cross width="14" height="14" />
          </div>
        </div>
      ))}
      {pendingAdd?.map((genai, index) => (
        <div
          key={index}
          className="h-11 font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1 relative bg-[#F2F6FB]"
        >
          <p className=" flex items-center gap-2">
            <div className="group overflow-visible">
              <div
                ref={(el) => (refs.current[index] = el)}
                className="relative group flex items-center cursor-pointer"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {hoveredIndex === index && (
                  <PendingApprovalTooltip
                    targetRef={{ current: refs.current[index] }}
                  />
                )}
                <img src={PATH_IMG + "/pending.svg"} alt="Pending" />
              </div>
            </div>
            <span className="font-semibold text-sm leading-5 text-[#747c8a]">
              {genai.app_name}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.use_case}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.business_unit}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.airport}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {genai.role}
            </span>
          </p>
          <div className=" cursor-pointer" onClick={() => {}}>
            <Cross width="14" height="14" />
          </div>
        </div>
      ))}
    </>
  );
}

interface ListDataGovProps {
  dataGovPermissions: DataGovPermissions[];
  idVINCI: string;
  datagovToDelete: DataGovPermissions[] | undefined;
  datagovToAdd: DataGovPermissions[] | undefined;
  pendingDelete: DataGovPermissions[] | undefined;
  pendingAdd: DataGovPermissions[] | undefined;
}

function ListDataGov({
  dataGovPermissions,
  idVINCI,
  datagovToDelete,
  datagovToAdd,
  pendingDelete,
  pendingAdd,
}: ListDataGovProps) {
  const { addPermissionToDelete, removePermissionToAdd } =
    usePermissionsDataAdmin();
  // if (dataGovPermissions.length === 0) return null;
  const refs = useRef<(HTMLDivElement | null)[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <>
      {dataGovPermissions.map((datagov, index) => {
        const isPendingDelete = pendingDelete?.some(
          (elem) =>
            datagov.action == elem.action &&
            datagov.business_unit == elem.business_unit &&
            datagov.use_case == elem.use_case
        );
        const isDataGovToDelete = datagovToDelete?.some(
          (elem) =>
            datagov.action == elem.action &&
            datagov.business_unit == elem.business_unit &&
            datagov.use_case == elem.use_case
        );

        return (
          <div
            key={index}
            className="h-11  font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1"
            style={
              isDataGovToDelete
                ? { backgroundColor: "#b6353550", position: "relative" }
                : isPendingDelete
                ? { backgroundColor: "#b6353550" }
                : { backgroundColor: "white" }
            }
          >
            {isPendingDelete ? (
              <div className="group overflow-visible">
                {isPendingDelete && (
                  <div
                    ref={(el) => (refs.current[index] = el)}
                    className="relative group flex items-center cursor-pointer"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    {hoveredIndex === index && (
                      <PendingApprovalTooltip
                        targetRef={{ current: refs.current[index] }}
                      />
                    )}
                    <img src={PATH_IMG + "/pending.svg"} alt="Pending" />
                  </div>
                )}
              </div>
            ) : null}
            <p className=" flex items-center gap-2">
              <span className="font-semibold text-sm leading-5 text-[#747c8a]">
                {datagov.business_unit}
              </span>
              <span className="font-semibold text-sm leading-5 text-[#344054]">
                {datagov.use_case}
              </span>
              <span className="font-semibold text-sm leading-5 text-[#344054]">
                {datagov.action}
              </span>
            </p>
            <div
              className=" cursor-pointer"
              onClick={() =>
                isPendingDelete
                  ? null
                  : addPermissionToDelete(idVINCI, "datagouv", datagov)
              }
            >
              <Cross width="14" height="14" />
            </div>
          </div>
        );
      })}
      {datagovToAdd?.map((datagov, index) => (
        <div
          key={index}
          className="h-11 bg-[#F2F6FB]  font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1"
        >
          <p className=" flex items-center gap-2">
            <span className="font-semibold text-sm leading-5 text-[#747c8a]">
              {datagov.business_unit}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {datagov.use_case}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {datagov.action}
            </span>
          </p>
          <div
            className=" cursor-pointer"
            onClick={() => removePermissionToAdd(idVINCI, "datagouv", datagov)}
          >
            <Cross width="14" height="14" />
          </div>
        </div>
      ))}
      {pendingAdd?.map((datagov, index) => (
        <div
          key={index}
          className="h-11  font-normal text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1 bg-[#F2F6FB]"
        >
          <div className="group overflow-visible">
            <div
              ref={(el) => (refs.current[index] = el)}
              className="relative group flex items-center cursor-pointer"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {hoveredIndex === index && (
                <PendingApprovalTooltip
                  targetRef={{ current: refs.current[index] }}
                />
              )}
              <img src={PATH_IMG + "/pending.svg"} alt="Pending" />
            </div>
          </div>
          <p className=" flex items-center gap-2">
            <span className="font-semibold text-sm leading-5 text-[#747c8a]">
              {datagov.business_unit}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {datagov.use_case}
            </span>
            <span className="font-semibold text-sm leading-5 text-[#344054]">
              {datagov.action}
            </span>
          </p>
          <div className=" cursor-pointer" onClick={() => {}}>
            <Cross width="14" height="14" />
          </div>
        </div>
      ))}
    </>
  );
}

interface PendingApprovalTooltipProps {
  targetRef: React.RefObject<HTMLDivElement>;
}

function PendingApprovalTooltip({ targetRef }: PendingApprovalTooltipProps) {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - 40, // Ajuste la hauteur du tooltip
        left: rect.left - 55, // Ajuste la position horizontale
      });
    }
  }, [targetRef]);

  return createPortal(
    <div
      className="fixed min-w-[123px] h-[34px] rounded-[8px] px-[12px] py-[8px] bg-[#101828] text-white flex items-center justify-center transition-opacity duration-200"
      style={{ top: position.top, left: position.left }}
    >
      <p className="font-inter font-normal text-[12px] leading-[18px] text-center">
        Pending approval
      </p>
    </div>,
    document.body
  );
}
