import React from "react";
import { useState } from "react";

import TitleH1 from "../components/elements/titles/TitleH1";
import TableDataAnalytics from "../components/elements/tables/TableDataAnalytics";
import dataAnalyticsDashBoard from "./dataAnalyticsDashboard";
import dataAnalyticsGenAI from "./dataAnalyticsGenAI";
import "./index.css";

export default function AdminManagementDataAnalytics() {
  const [genAIOrDashboard, setGenAIOrDashboard] = useState("Dashboard");

  const handleGenAIOrDashboard = (value: string) => {
    setGenAIOrDashboard(value);
  };

  return (
    <div className="flex bg-[#F2F2F2]">
      <div className="bg-[#F9F9F9] w-[1605px] pl-10">
        <div className="font-inter ">
          <TitleH1 text="Data Analytics" />
        </div>
        <div>
          <div className="w-full pl-[2%] m-auto bg-white font-medium text-sm leading-4 text-[#667085] flex items-center rounded-t-lg">
            <div
              className={`p-2 py-7 cursor-pointer flex gap-2 align-middle items-center  ${
                genAIOrDashboard === "Dashboard" ? "dashboardGenAI-active" : ""
              }`}
              style={
                genAIOrDashboard === "Dashboard" ? { color: "#004289" } : {}
              }
              onClick={() => handleGenAIOrDashboard("Dashboard")}
            >
              <p>Dashboard products</p>
              <p className="bg-[#f2f2f2] text-[#004289]  flex justify-center items-center align-middle h-6 w-6 rounded-lg">
                {dataAnalyticsDashBoard.length}
              </p>
            </div>
            <div
              className={`p-2 py-7 cursor-pointer flex gap-2 align-middle items-center ${
                genAIOrDashboard === "GenAI" ? "dashboardGenAI-active" : ""
              }`}
              onClick={() => handleGenAIOrDashboard("GenAI")}
              style={genAIOrDashboard === "GenAI" ? { color: "#004289" } : {}}
            >
              <p>GenAI products </p>
              <p className="bg-[#f2f2f2] text-[#004289] flex justify-center items-center align-middle h-6 w-6 rounded-lg">
                {dataAnalyticsGenAI.length}
              </p>
            </div>
          </div>
          <div
            className="bg-white rounded-b-lg  "
            style={{ boxShadow: "0px 4px 4px 0px #0000000D" }}
          >
            <div className="mt-[1px] w-[96%] m-auto  rounded-lg">
              {genAIOrDashboard === "Dashboard" ? (
                <TableDataAnalytics
                  data={dataAnalyticsDashBoard}
                  category="Dashboard"
                />
              ) : (
                <TableDataAnalytics
                  data={dataAnalyticsGenAI}
                  category="GenAI"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
