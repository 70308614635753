import React, { useState, useRef } from "react";

import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import useClickOutside from "../../../hooks/useClickOutside";
import { PATH_IMG } from "../../../constants/constant";
import { useAuth } from "../../../providers/Auth";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";
import {
  getTemplateToCreateMultipleUsers,
  postCreateMultipleUsers,
} from "./api/userApi";

interface CsvFileModalProps {
  handleShowModal: (value: boolean) => void;
}

export default function CsvFileModal({ handleShowModal }: CsvFileModalProps) {
  const [dragOver, setDragOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [downloadModal, setDownloadModal] = useState<{
    isActive: boolean;
    file: Blob | null;
  }>({
    isActive: false,
    file: null,
  });
  const [uploadFailed, setUploadFailed] = useState<{
    first: boolean;
    second: boolean;
    downloadModalFirst?: boolean;
    downloadModalSecond?: boolean;
  }>({
    first: false,
    second: false,
    downloadModalFirst: false,
    downloadModalSecond: false,
  });
  const [errorsFile, setErrorsFile] = useState<{
    dictError: Record<string, string[]>;
    externUsers: string[];
    file1: Blob | null;
    file2: Blob | null;
  }>({
    dictError: {},
    externUsers: [],
    file1: null,
    file2: null,
  });

  const closeWindow = useRef<HTMLDivElement>(null);

  const { token } = useAuth();

  const {
    setIsLoading,
    handleDisplayAlert,
    setEndLoading,
    handleCreateMultipleUsers,
  } = useUserDataAdmin();

  useClickOutside(closeWindow, () => handleShowModal(false));

  const handleCloseModal = (value: number) => {
    if (value === 1) {
      setUploadFailed((prev) => ({
        first: false,
        second: false,
        downloadModalFirst: true,
        downloadModalSecond: false,
      }));
    } else if (value === 2) {
      setUploadFailed((prev) => ({
        first: false,
        second: false,
        downloadModalFirst: false,
        downloadModalSecond: true,
      }));
    } else if (value == 3) {
      setUploadFailed((prev) => ({
        first: false,
        second: false,
        downloadModalFirst: false,
        downloadModalSecond: false,
      }));
      setIsUploading(false);
      handleShowModal(false);
    } else if (value == 4) {
      setUploadFailed((prev) => ({
        first: false,
        second: false,
        downloadModalFirst: false,
        downloadModalSecond: false,
      }));
      setIsUploading(false);
      handleShowModal(false);
    } else if (value == 5) {
      setDownloadModal({ isActive: false, file: null });
    } else {
      setIsUploading(false);
      handleShowModal(false);
    }
  };

  const handleExternalsUsersAndErrors = (popup: number) => {
    if (popup === 1) {
      if (errorsFile.externUsers.length > 0) {
        setUploadFailed((prev) => ({
          first: false,
          second: true,
          downloadModalFirst: false,
          downloadModalSecond: false,
        }));
      } else {
        const allUpn = Object.keys(errorsFile.dictError);
        handleUploadCreateMUltipleUser(allUpn, []);
      }
    } else if (popup === 2) {
      const externalsUsers = errorsFile.externUsers;
      const listErrors = Object.keys(errorsFile.dictError) || [];
      setUploadFailed((prev) => ({
        first: false,
        second: false,
        downloadModalFirst: false,
        downloadModalSecond: false,
      }));
      handleUploadCreateMUltipleUser(listErrors, externalsUsers);
    }
  };

  const downloadFile = async (file: Blob, filename: string) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const downloadTemplateCrateMultiUser = async () => {
    try {
      const response = await getTemplateToCreateMultipleUsers(
        "/user_management/get_template_create_multiple_users_csv",
        token
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers.get("content-disposition");
      const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
      let filename = filenameMatch
        ? filenameMatch[1]
        : "template-fillMultipleUser.csv";

      link.download = decodeURIComponent(filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setDragOver(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      if (file.size > 10 * 1024 * 1024) {
        return;
      }
      setIsUploading(true);
      setSelectedFile(file);
    } else {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      if (file.size > 10 * 1024 * 1024) {
        return;
      }
      setIsUploading(true);
      setSelectedFile(file);
    } else {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const handleUploadCreateMUltipleUser = async (
    upnToRemove: any,
    validatedExternalUsers: any
  ) => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    if (upnToRemove.length === 0) {
    } else {
      upnToRemove.forEach((upn: string) => {
        formData.append("upns_to_remove", upn);
      });
    }
    if (validatedExternalUsers.length === 0) {
    } else {
      validatedExternalUsers.forEach((upn: string) => {
        formData.append("validated_external_users", upn);
      });
    }
    try {
      setIsUploading(true);
      setIsLoading(true);
      const response: any = await postCreateMultipleUsers(
        "/user_management/create_multiple_users_csv",
        formData,
        token
      );
      if (response.status === 418) {
        const dataError = await response.json();
        const file1Blob = base64ToBlob(
          dataError.detail.csv_files.validation_errors_csv,
          "text/csv"
        );
        const file2Blob = base64ToBlob(
          dataError.detail.csv_files.external_users_csv,
          "text/csv"
        );
        setErrorsFile({
          dictError: dataError.detail.validation_errors,
          externUsers: dataError.detail.external_users,
          file1: file1Blob,
          file2: file2Blob,
        });

        if (
          dataError.detail.validation_errors &&
          Object.keys(dataError.detail.validation_errors).length > 0
        ) {
          setUploadFailed((prev) => ({ ...prev, first: true }));
        } else if (
          dataError.detail.external_users &&
          dataError.detail.external_users.length > 0
        ) {
          setUploadFailed((prev) => ({ ...prev, second: true }));
        }
      }

      if (!response.ok) {
        throw new Error(
          `Upload failed: ${response.status} - ${response.statusText}`
        );
      }

      const result = await response.json();
      console.log("result", result);
      handleCreateMultipleUsers(result.created_users);
      const summaryBlob = base64ToBlob(result.csv_content, "text/csv");
      setDownloadModal({ isActive: true, file: summaryBlob });
      setIsUploading(false);
      setUploadFailed((prev) => ({ ...prev, first: false }));
      handleDisplayAlert("Users created successfully");
    } catch (error) {
      console.error("Erreur d'upload :", error);
      setIsUploading(false);
    } finally {
      console.log("l'envoie upload est terminé");
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 500);
    }
  };

  return (
    <div
      ref={closeWindow}
      className={`flex justify-center absolute top-14 right-0 bg-white w-[575px] h-[213px] z-30 border border-[#f2f4f7] rounded-lg ${
        dragOver ? "border-blue-500 bg-blue-50" : ""
      }`}
      style={{ boxShadow: "0px 4px 6px -2px #10182808" }}
    >
      <label
        htmlFor="fileCsv"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="cursor-pointer absolute"
      >
        <input
          id="fileCsv"
          type="file"
          accept=".csv"
          className="absolute opacity-0"
          onChange={handleFileChange}
        />
        {!isUploading ? (
          <div className="flex flex-col justify-center text-center mt-4 w-[536px] h-[126px] rounded-lg border items-center gap-3">
            <img src={`${PATH_IMG}/browseFile.svg`} alt="#" />
            <div className="font-inter text-[#667085] font-normal text-sm leading-5">
              <p>
                <span className="text-[#004289] font-semibold">
                  Click to browse files
                </span>
                <span> or drag and drop</span>
              </p>
              <p>CSV (limit 10MB per file)</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col font-inter justify-center  mt-4 w-[536px] h-[126px] rounded-lg border items-center gap-3 bg-[#0fe48b4f]">
            <div className="text-[#0a291c]">
              <p>name of file: {selectedFile?.name}</p>
              <p>
                size of file:{" "}
                {selectedFile ? (selectedFile.size / 1024).toFixed(2) : "0"} Ko
              </p>
            </div>
            <div>
              <img src={`${PATH_IMG}/checkFile.svg`} alt="" />
            </div>
          </div>
        )}
      </label>

      <div className="font-inter absolute bottom-0 flex justify-between w-[536px] items-center p-4">
        <div onClick={downloadTemplateCrateMultiUser}>
          <ButtonSvgText
            width="253"
            height="36"
            logo={`${PATH_IMG}/download.svg`}
            text="Download CSV template to fill"
          />
        </div>
        <div className="flex gap-4">
          <div onClick={() => handleShowModal(false)}>
            <ButtonClassic text="Cancel" width="100" height="36" />
          </div>

          <div onClick={() => handleUploadCreateMUltipleUser([], [])}>
            <ButtonClassic
              text="Upload"
              width="100"
              height="36"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
      {uploadFailed.first && (
        <ModalFailedUploadCreateUserPopup
          popupError={1}
          handleCloseModal={handleCloseModal}
          handleExternalsUsersAndErrors={handleExternalsUsersAndErrors}
          listErrors={errorsFile.dictError}
          fileName={selectedFile?.name || ""}
        />
      )}
      {uploadFailed.second && (
        <ModalFailedUploadCreateUserPopup
          popupError={2}
          handleCloseModal={handleCloseModal}
          handleExternalsUsersAndErrors={handleExternalsUsersAndErrors}
          createdUsers={errorsFile.externUsers}
          fileName={selectedFile?.name || ""}
        />
      )}
      {uploadFailed.downloadModalFirst && (
        <ModalDownloadFile
          popupError={3}
          handleCloseModal={handleCloseModal}
          downloadFile={downloadFile}
          file={errorsFile.file1}
        />
      )}
      {uploadFailed.downloadModalSecond && (
        <ModalDownloadFile
          popupError={4}
          handleCloseModal={handleCloseModal}
          downloadFile={downloadFile}
          file={errorsFile.file2}
        />
      )}
      {downloadModal.isActive && (
        <ModalDownloadFile
          popupError={5}
          handleCloseModal={handleCloseModal}
          downloadFile={downloadFile}
          file={downloadModal.file}
        />
      )}
    </div>
  );
}

interface ModalFailedUploadCreateUserPopupProps {
  popupError: number;
  handleCloseModal: (value: number) => void;
  handleExternalsUsersAndErrors: (popup: any) => void;
  listErrors?: Record<string, string[]> | null;
  createdUsers?: string[];
  fileName: string;
}

function ModalFailedUploadCreateUserPopup({
  popupError,
  handleCloseModal,
  handleExternalsUsersAndErrors,
  listErrors,
  createdUsers,
  fileName,
}: ModalFailedUploadCreateUserPopupProps) {
  return (
    <div className="fixed inset-0 bg-[#344054b2] backdrop-blur-lg z-30 flex items-center justify-center">
      <div className="font-inter bg-white w-[400px] h-[600px] p-2 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
        <div className="m-auto  pt-2 pb-0   flex justify-between align-middle items-center">
          <div className=" flex gap-2 align-middle items-center">
            <img src={`${PATH_IMG}/atention.svg`} alt="#" />
          </div>
        </div>
        <div className=" m-auto h-28">
          <div className=" align-middle items-center p-4">
            <h2 className="font-medium text-lg leading-7">
              {popupError === 1
                ? "Error: Creation from File"
                : "External User Creation"}
            </h2>
            <p className="text-[#667085] text-sm font-normal leading-5 mt-2">
              {popupError === 1
                ? `Errors were detected in ${fileName} for the account names listed. By clicking the ‘Process’ button, the system will add only the valid user accounts specified in your file and exclude those with errors.`
                : "List of users missing from the VINCI directory to be created as external Users. By clicking 'Accept,' you will create the following external users:"}
            </p>
          </div>
        </div>
        <div className=" m-auto align-middle items-center p-4 h-[310px]  max-h-[310px] overflow-y-auto mt-6">
          {listErrors &&
            Object.entries(listErrors).map(([key, value], index) => (
              <div key={index} className="mt-1">
                <p className="text-[#33373E] text-sm font-medium leading-5">
                  {key}
                </p>
                {(value as string[]).map((error: string, index) => (
                  <p
                    key={index}
                    className="text-[#DC6803] text-sm font-normal leading-5 mt-2"
                  >
                    {error}
                  </p>
                ))}
                <div className="w-[352px] h-[1px] bg-[#dedede] mt-2"></div>
              </div>
            ))}
          {createdUsers &&
            createdUsers.map((user, index) => (
              <div key={index} className="mt-1">
                <p className="text-[#14356e] text-sm font-medium leading-5">
                  {user}
                </p>
                <div className="w-[352px] h-[1px] bg-[#dedede] mt-2"></div>
              </div>
            ))}
        </div>
        <div className="flex w-full justify-between align-middle items-center pl-4 pr-4 h-[68px] ">
          <div onClick={() => handleCloseModal(popupError)}>
            <ButtonClassic text="Abort" width="170" height="44" />
          </div>
          <div onClick={() => handleExternalsUsersAndErrors(popupError)}>
            <ButtonClassic
              text="Create"
              width="170"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface ModalDownloadFileProps {
  popupError: number;
  handleCloseModal: (value: number) => void;
  downloadFile: (file: Blob, filename: string) => void;
  file: Blob | null;
}

function ModalDownloadFile({
  popupError,
  handleCloseModal,
  downloadFile,
  file: Blob,
}: ModalDownloadFileProps) {
  return (
    <div className="fixed inset-0 bg-[#344054b2] backdrop-blur-lg z-30 flex items-center justify-center">
      <div className="font-inter bg-white w-[400px] h-[300px] p-2 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
        <div className="m-auto  pt-2 pb-0   flex justify-between align-middle items-center">
          <div className=" flex gap-2 align-middle items-center">
            <img src={`${PATH_IMG}/atention.svg`} alt="#" />
          </div>
        </div>
        <div className="m-auto">
          <div className="h-20 align-middle items-center p-4">
            <h2 className="font-medium text-lg leading-7">
              {popupError == 3
                ? "Would you like to download a summary of the errors "
                : popupError == 4
                ? "Would you like to download a summary of the users that can be create"
                : "Would you like to download a summary report detailing the operations performed on the submitted user lists for creation, including any failures and their error messages?"}
            </h2>
          </div>
          <div className="min-h-20"></div>
          {/** */}
        </div>
        <div className=" flex w-full justify-between align-middle items-center pl-4 pr-4 h-[68px] ">
          <div onClick={() => handleCloseModal(popupError)}>
            <ButtonClassic text="No" width="170" height="44" />
          </div>
          <div
            onClick={() => {
              if (Blob) {
                if (popupError === 3) {
                  downloadFile(Blob, "errorFile.csv");
                  handleCloseModal(popupError);
                } else if (popupError === 4) {
                  downloadFile(Blob, "createdUsers.csv");
                  handleCloseModal(popupError);
                } else {
                  downloadFile(Blob, "summaryMassiveCreation.csv");
                  handleCloseModal(popupError);
                }
              }
            }}
          >
            <ButtonClassic
              text="Yes"
              width="170"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
