import React, { useState } from "react";

import InformationInput from "../components/elements/inputs/InformationInput";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import DropdownSelect from "../components/elements/dropdown/DropdownSelect";
import OptionUser from "../components/elements/inputs/OptionUser";
import OptionUserBis from "../components/elements/inputs/OptionUserBis";

interface ElemInsideModalHeaderAirportProps {
  handleCloseModal: () => void;
}

export default function ElemInsideModalHeaderAirport({
  handleCloseModal,
}: ElemInsideModalHeaderAirportProps) {
  const { profile, allLocations, handleSelectionMultipleUsers } =
    usePermissionsDataAdmin();
  const [businessUnitSelected, setBusinessUnitSelected] = useState(
    profile?.businessUnit || ""
  );
  const [airportSelected, setAirportSelected] = useState(
    profile?.airport || ""
  );

  const handleBusinessUnitSelected = (value: string) => {
    setBusinessUnitSelected(value);
  };

  const handleAirportSelected = (value: string) => {
    setAirportSelected(value);
  };

  return (
    <div className="p-4 w-full flex flex-col justify-between h-full">
      <div className="flex flex-col gap-4 w-[387px]">
        <OptionUserBis
          dataToSelect={allLocations}
          width="387"
          title="Business unit"
          handleChangementExt={handleBusinessUnitSelected}
          valueExt={businessUnitSelected}
        />

        <OptionUserBis
          width="387"
          title="Airport"
          arrayToSelect={
            allLocations[businessUnitSelected as keyof typeof allLocations] ||
            []
          }
          handleChangementExt={handleAirportSelected}
          valueExt={airportSelected}
        />
      </div>
      <div className="flex gap-2 justify-end w-full">
        <div onClick={() => handleCloseModal()}>
          <ButtonClassic text="Cancel" width="76" height="36" />
        </div>
        <div
          onClick={() =>
            handleSelectionMultipleUsers(businessUnitSelected, airportSelected)
          }
        >
          <ButtonClassic
            text="Search this airport"
            width="154"
            height="36"
            backgroundColor="#004289"
          />
        </div>
      </div>
    </div>
  );
}
