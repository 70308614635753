import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  userProfileInterface,
  usersInterface,
} from "../../../../types/adminManagementPages";
import {
  fetchLocations,
  fetchUserProfile,
} from "../../AdminManagementsUsers/api/userApi";
import {
  fetchAllRoles,
  fetchIdVinciByRole,
  fetchPermissionsFromUsers,
  fetchRolesPermissions,
  fetchUsersPermissions,
  postCreatePermissions,
  postDeletePermissions,
} from "../api/permissionsApi";
import { useAuth } from "../../../../providers";
import {
  DashboardPermissions,
  DataGovPermissions,
  GenAiPermissions,
  PermissionsMap,
  RolePermissions,
} from "../type/permissionsTypes";
import { set } from "date-fns";

interface PermissionsDataAdminContextType {
  handleRemovePermissionToAddAndDeleteToOneUser: (upn: string) => void;
  errorsFile: Record<string, string>;
  setErrorsFile: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  handleSelectionUsersByResponseFileError: (values: any) => void;
  hasErrorFile: boolean;
  setHasErrorFile: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectionUsersByResponseFile: (values: any) => void;
  getIdsVinciByRole: (role: string) => void;
  rolesPermissions: string[];
  handleSelectionMultipleUsers: (businessUnit: string, airport: string) => void;
  permissionsPendingDelete: PermissionsMap;
  permissionsPendingAdd: PermissionsMap;
  loadingSearchCopieUserPerm: boolean;
  handleCopieSelectionUser: (upn: string) => void;
  hasErrorUpdate: boolean;
  setHasErrorUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  errorUpdateMessage: {
    idVINCI: string;
    messages: string[];
  }[];
  setErrorUpdateMessage: React.Dispatch<
    React.SetStateAction<{ idVINCI: string; messages: string[] }[]>
  >;
  allRolesPermissions: RolePermissions;
  resetPermissions: () => void;
  updatePermissionsUsers: () => void;
  loadingPermissionsUser: boolean;
  removePermissionToAdd: (
    idVINCI: string,
    type: "admin" | "genai" | "datagouv" | "dashboard",
    permission: any
  ) => void;
  permissionsToAdd: PermissionsMap;
  addPermissionToAdd: (
    permissionsToValidate:
      | DashboardPermissions[]
      | GenAiPermissions[]
      | DataGovPermissions[],
    type: "admin" | "genai" | "datagouv" | "dashboard"
  ) => void;
  permissionsToDelete: PermissionsMap;
  addPermissionToDelete: (
    idVINCI: string,
    type: "admin" | "genai" | "datagouv" | "dashboard",
    permission: any
  ) => void;
  permissions: PermissionsMap;
  setPermissions: React.Dispatch<React.SetStateAction<PermissionsMap>>;
  handleSelectionUsers: (upnList: string[], upn: string) => void;
  profile?: userProfileInterface;
  endLoading: boolean;
  setEndLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  usersInAppDirectory: usersInterface[];
  usersSelected: usersInterface[];
  setUsersSelected: React.Dispatch<React.SetStateAction<usersInterface[]>>;
  allLocations: Record<string, string[]>;
  setAllLocations: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >;
}

const PermissionsDataAdminProviderContext = createContext<
  PermissionsDataAdminContextType | undefined
>(undefined);

interface PermissionsDataAdminProviderProps {
  children: ReactNode;
}

export const PermissionsDataAdminProvider: React.FC<
  PermissionsDataAdminProviderProps
> = ({ children }) => {
  const [allLocations, setAllLocations] = useState<Record<string, string[]>>(
    {}
  );

  const [errorsFile, setErrorsFile] = useState<Record<string, string>>({});
  const [hasErrorFile, setHasErrorFile] = useState<boolean>(false);
  const [hasErrorUpdate, setHasErrorUpdate] = useState<boolean>(false);
  const [errorUpdateMessage, setErrorUpdateMessage] = useState<
    {
      idVINCI: string;
      messages: string[];
    }[]
  >([]);
  const [profile, setProfile] = useState<userProfileInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [endLoading, setEndLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<PermissionsMap>(new Map());
  const [permissionsPendingDelete, setPermissionsPendingDelete] =
    useState<PermissionsMap>(new Map());
  const [permissionsPendingAdd, setPermissionsPendingAdd] =
    useState<PermissionsMap>(new Map());
  const [permissionsToDelete, setPermissionsToDelete] =
    useState<PermissionsMap>(new Map());
  const [permissionsToAdd, setPermissionsToAdd] = useState<PermissionsMap>(
    new Map()
  );

  const [usersInAppDirectory, setUserInAppDirectory] = useState<
    usersInterface[]
  >([]);
  const [usersSelected, setUsersSelected] = useState<usersInterface[]>([]);
  const [loadingPermissionsUser, setLoadingPermissionsUser] =
    useState<boolean>(false);
  const [loadingSearchCopieUserPerm, setLoadingSearchCopieUserPerm] =
    useState<boolean>(false);
  const [allRolesPermissions, setAllRolesPermissions] =
    useState<RolePermissions>({
      admin: [],
      gen_ai: [],
      data_gov: [],
      dashboard: [],
    });

  const [rolesPermissions, setRolesPermissions] = useState<string[]>([]);

  const { token } = useAuth();

  const resetPermissions = () => {
    setPermissionsToAdd(new Map());
    setPermissionsToDelete(new Map());
  };

  const handleRemovePermissionToAddAndDeleteToOneUser = (upn: string) => {
    const idVINCI = usersInAppDirectory.find(
      (user) => user.upn === upn
    )?.idVINCI;
    if (!idVINCI) return;

    setPermissionsToAdd((prev) => {
      const newPermissions = new Map(prev);
      newPermissions.delete(idVINCI);
      return newPermissions;
    });
    setPermissionsToDelete((prev) => {
      const newPermissions = new Map(prev);
      newPermissions.delete(idVINCI);
      return newPermissions;
    });
  };

  const removePermissionsToUsers = (newPermissions: any) => {
    setPermissions((prev) => {
      const newPermissionsMap = new Map(prev);

      Object.entries(newPermissions).forEach(
        ([key, value]: [string, unknown]) => {
          const rolePermissions = value as RolePermissions;
          const rolePrev = newPermissionsMap.get(key) as
            | RolePermissions
            | undefined;
          if (!rolePrev) return;

          for (let i = 0; i < rolePermissions.admin.length; i++) {
            rolePrev.admin = rolePrev.admin.filter(
              (elem) =>
                String((elem as { feature: string }).feature) !==
                String(
                  (rolePermissions.admin[i] as { feature: string }).feature
                )
            );
          }

          for (let i = 0; i < rolePermissions.gen_ai.length; i++) {
            rolePrev.gen_ai = rolePrev.gen_ai.filter((elem) => {
              if (
                String(elem.app_name) !==
                  String(rolePermissions.gen_ai[i].app_name) ||
                String(elem.use_case) !==
                  String(rolePermissions.gen_ai[i].use_case) ||
                String(elem.business_unit) !==
                  String(rolePermissions.gen_ai[i].business_unit) ||
                String(elem.airport) !==
                  String(rolePermissions.gen_ai[i].airport) ||
                String(elem.role) !== String(rolePermissions.gen_ai[i].role)
              ) {
                return elem;
              }
            });
          }

          for (let i = 0; i < rolePermissions.data_gov.length; i++) {
            rolePrev.data_gov = rolePrev.data_gov.filter(
              (elem) =>
                String(elem.action) !==
                  String(rolePermissions.data_gov[i].action) ||
                String(elem.business_unit) !==
                  String(rolePermissions.data_gov[i].business_unit) ||
                String(elem.use_case) !==
                  String(rolePermissions.data_gov[i].use_case)
            );
          }

          for (let i = 0; i < rolePermissions.dashboard.length; i++) {
            rolePrev.dashboard = rolePrev.dashboard.filter(
              (elem) =>
                String(elem.airport) !==
                  String(rolePermissions.dashboard[i].airport) ||
                String(elem.business_unit) !==
                  String(rolePermissions.dashboard[i].business_unit) ||
                String(elem.dashboard) !==
                  String(rolePermissions.dashboard[i].dashboard) ||
                String(elem.dashboard_level) !==
                  String(rolePermissions.dashboard[i].dashboard_level)
            );
          }

          newPermissionsMap.set(key, rolePrev);
        }
      );

      return newPermissionsMap;
    });
  };

  const addNewPermissionsTodeletePending = (newPermissions: any) => {
    setPermissionsPendingDelete((prev) => {
      const newPermissionsMap = new Map(prev);
      Object.entries(newPermissions).forEach(
        ([key, value]: [string, unknown]) => {
          const rolePermissions = value as RolePermissions;
          const rolePrev = newPermissionsMap.get(key);
          if (!rolePrev) {
            newPermissionsMap.set(key, rolePermissions);
            return;
          } else {
            for (let i = 0; i < rolePermissions.admin.length; i++) {
              rolePrev.admin.push(rolePermissions.admin[i]);
            }
            for (let i = 0; i < rolePermissions.gen_ai.length; i++) {
              if (
                !rolePrev.gen_ai.some(
                  (elem) =>
                    elem.access_type ===
                      rolePermissions.gen_ai[i].access_type &&
                    elem.app_name === rolePermissions.gen_ai[i].app_name &&
                    elem.use_case === rolePermissions.gen_ai[i].use_case &&
                    elem.business_unit ===
                      rolePermissions.gen_ai[i].business_unit &&
                    elem.airport === rolePermissions.gen_ai[i].airport &&
                    elem.role === rolePermissions.gen_ai[i].role
                )
              ) {
                rolePrev.gen_ai.push(rolePermissions.gen_ai[i]);
              }
            }
            for (let i = 0; i < rolePermissions.data_gov.length; i++) {
              if (
                !rolePrev.data_gov.some(
                  (elem) =>
                    elem.action === rolePermissions.data_gov[i].action &&
                    elem.business_unit ===
                      rolePermissions.data_gov[i].business_unit &&
                    elem.use_case === rolePermissions.data_gov[i].use_case
                )
              ) {
                rolePrev.data_gov.push(rolePermissions.data_gov[i]);
              }
            }
            for (let i = 0; i < rolePermissions.dashboard.length; i++) {
              if (
                !rolePrev.dashboard.some(
                  (elem) =>
                    elem.airport === rolePermissions.dashboard[i].airport &&
                    elem.business_unit ===
                      rolePermissions.dashboard[i].business_unit &&
                    elem.dashboard === rolePermissions.dashboard[i].dashboard &&
                    elem.dashboard_level ===
                      rolePermissions.dashboard[i].dashboard_level
                )
              ) {
                rolePrev.dashboard.push(rolePermissions.dashboard[i]);
              }
            }
            newPermissionsMap.set(key, rolePrev);
          }
        }
      );
      return newPermissionsMap;
    });
  };

  const addNewPermissionsToCreatePending = (newPermissions: any) => {
    setPermissionsPendingAdd((prev) => {
      const newPermissionsMap = new Map(prev);
      Object.entries(newPermissions).forEach(
        ([key, value]: [string, unknown]) => {
          const rolePermissions = value as RolePermissions;
          const rolePrev = newPermissionsMap.get(key);
          if (!rolePrev) {
            newPermissionsMap.set(key, rolePermissions);
            return;
          } else {
            for (let i = 0; i < rolePermissions.admin.length; i++) {
              rolePrev.admin.push(rolePermissions.admin[i]);
            }
            for (let i = 0; i < rolePermissions.gen_ai.length; i++) {
              if (
                !rolePrev.gen_ai.some(
                  (elem) =>
                    elem.access_type ===
                      rolePermissions.gen_ai[i].access_type &&
                    elem.app_name === rolePermissions.gen_ai[i].app_name &&
                    elem.use_case === rolePermissions.gen_ai[i].use_case &&
                    elem.business_unit ===
                      rolePermissions.gen_ai[i].business_unit &&
                    elem.airport === rolePermissions.gen_ai[i].airport &&
                    elem.role === rolePermissions.gen_ai[i].role
                )
              ) {
                rolePrev.gen_ai.push(rolePermissions.gen_ai[i]);
              }
            }
            for (let i = 0; i < rolePermissions.data_gov.length; i++) {
              if (
                !rolePrev.data_gov.some(
                  (elem) =>
                    elem.action === rolePermissions.data_gov[i].action &&
                    elem.business_unit ===
                      rolePermissions.data_gov[i].business_unit &&
                    elem.use_case === rolePermissions.data_gov[i].use_case
                )
              ) {
                rolePrev.data_gov.push(rolePermissions.data_gov[i]);
              }
            }
            for (let i = 0; i < rolePermissions.dashboard.length; i++) {
              if (
                !rolePrev.dashboard.some(
                  (elem) =>
                    elem.airport === rolePermissions.dashboard[i].airport &&
                    elem.business_unit ===
                      rolePermissions.dashboard[i].business_unit &&
                    elem.dashboard === rolePermissions.dashboard[i].dashboard &&
                    elem.dashboard_level ===
                      rolePermissions.dashboard[i].dashboard_level
                )
              ) {
                rolePrev.dashboard.push(rolePermissions.dashboard[i]);
              }
            }
            newPermissionsMap.set(key, rolePrev);
          }
        }
      );
      return newPermissionsMap;
    });
  };

  const addNewPermissionsToUsers = (newPermissions: any) => {
    setPermissions((prev) => {
      const newPermissionsMap = new Map(prev);
      Object.entries(newPermissions).forEach(
        ([key, value]: [string, unknown]) => {
          const rolePermissions = value as RolePermissions;
          const rolePrev = newPermissionsMap.get(key);
          if (!rolePrev) {
            newPermissionsMap.set(key, rolePermissions);
            return;
          } else {
            for (let i = 0; i < rolePermissions.admin.length; i++) {
              rolePrev.admin.push(rolePermissions.admin[i]);
            }
            for (let i = 0; i < rolePermissions.gen_ai.length; i++) {
              if (
                !rolePrev.gen_ai.some(
                  (elem) =>
                    elem.access_type ===
                      rolePermissions.gen_ai[i].access_type &&
                    elem.app_name === rolePermissions.gen_ai[i].app_name &&
                    elem.use_case === rolePermissions.gen_ai[i].use_case &&
                    elem.business_unit ===
                      rolePermissions.gen_ai[i].business_unit &&
                    elem.airport === rolePermissions.gen_ai[i].airport &&
                    elem.role === rolePermissions.gen_ai[i].role
                )
              ) {
                rolePrev.gen_ai.push(rolePermissions.gen_ai[i]);
              }
            }
            for (let i = 0; i < rolePermissions.data_gov.length; i++) {
              if (
                !rolePrev.data_gov.some(
                  (elem) =>
                    elem.action === rolePermissions.data_gov[i].action &&
                    elem.business_unit ===
                      rolePermissions.data_gov[i].business_unit &&
                    elem.use_case === rolePermissions.data_gov[i].use_case
                )
              ) {
                rolePrev.data_gov.push(rolePermissions.data_gov[i]);
              }
            }
            for (let i = 0; i < rolePermissions.dashboard.length; i++) {
              if (
                !rolePrev.dashboard.some(
                  (elem) =>
                    elem.airport === rolePermissions.dashboard[i].airport &&
                    elem.business_unit ===
                      rolePermissions.dashboard[i].business_unit &&
                    elem.dashboard === rolePermissions.dashboard[i].dashboard &&
                    elem.dashboard_level ===
                      rolePermissions.dashboard[i].dashboard_level
                )
              ) {
                rolePrev.dashboard.push(rolePermissions.dashboard[i]);
              }
            }
            newPermissionsMap.set(key, rolePrev);
          }
        }
      );
      return newPermissionsMap;
    });
  };

  const updatePermissionsUsers = async () => {
    const delay = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    let deletedPermissionsToSend: { permissions: Record<string, any> } = {
      permissions: {},
    };
    let addedPermissionsToSend: { permissions: Record<string, any> } = {
      permissions: {},
    };

    permissionsToDelete.forEach((value, key) => {
      deletedPermissionsToSend.permissions[key] = value;

      const propertiesToUpdate = ["admin", "gen_ai", "data_gov", "dashboard"];

      propertiesToUpdate.forEach((prop) => {
        if (Array.isArray(deletedPermissionsToSend.permissions[key][prop])) {
          deletedPermissionsToSend.permissions[key][prop] =
            deletedPermissionsToSend.permissions[key][prop].map((elem: any) => {
              if (prop === "dashboard") {
                elem.airport = elem.airport === "" ? null : elem.airport;
                elem.business_unit =
                  elem.business_unit === "" ? null : elem.business_unit;
                elem.dashboard = elem.dashboard === "" ? null : elem.dashboard;
                elem.dashboard_level =
                  elem.dashboard_level === "" ? null : elem.dashboard_level;
              } else if (prop === "gen_ai") {
                elem.app_name = elem.app_name === "" ? null : elem.app_name;
                elem.use_case = elem.use_case === "" ? null : elem.use_case;
                elem.business_unit =
                  elem.business_unit === "" ? null : elem.business_unit;
                elem.airport = elem.airport === "" ? null : elem.airport;
                elem.role = elem.role === "" ? null : elem.role;
              } else if (prop === "data_gov") {
                elem.action = elem.action === "" ? null : elem.action;
                elem.business_unit =
                  elem.business_unit === "" ? null : elem.business_unit;
                elem.use_case = elem.use_case === "" ? null : elem.use_case;
              } else if (prop === "admin") {
                elem.feature = elem.feature === "" ? null : elem.feature;
              }
              return elem;
            });
        }
      });
    });

    permissionsToAdd.forEach((value, key) => {
      addedPermissionsToSend.permissions[key] = value;
      const propertiesToUpdate = ["admin", "gen_ai", "data_gov", "dashboard"];
      propertiesToUpdate.forEach((prop) => {
        if (Array.isArray(addedPermissionsToSend.permissions[key][prop])) {
          addedPermissionsToSend.permissions[key][prop] =
            addedPermissionsToSend.permissions[key][prop].map((elem: any) => {
              if (prop === "dashboard") {
                elem.airport = elem.airport === "" ? null : elem.airport;
                elem.business_unit =
                  elem.business_unit === "" ? null : elem.business_unit;
                elem.dashboard = elem.dashboard === "" ? null : elem.dashboard;
                elem.dashboard_level =
                  elem.dashboard_level === "" ? null : elem.dashboard_level;
              } else if (prop === "gen_ai") {
                elem.app_name = elem.app_name === "" ? null : elem.app_name;
                elem.use_case = elem.use_case === "" ? null : elem.use_case;
                elem.business_unit =
                  elem.business_unit === "" ? null : elem.business_unit;
                elem.airport = elem.airport === "" ? null : elem.airport;
                elem.role = elem.role === "" ? null : elem.role;
                const filterAllRolesPermissions =
                  allRolesPermissions.gen_ai.filter((elem2) => {
                    if (elem2.app_name === elem.app_name) {
                      return elem2;
                    }
                  });

                if (filterAllRolesPermissions.length > 0) {
                  elem.access_type = filterAllRolesPermissions[0].access_type;
                }
              } else if (prop === "data_gov") {
                elem.action = elem.action === "" ? null : elem.action;
                elem.business_unit =
                  elem.business_unit === "" ? null : elem.business_unit;
                elem.use_case = elem.use_case === "" ? null : elem.use_case;
              } else if (prop === "admin") {
                elem.feature = elem.feature === "" ? null : elem.feature;
              }
              return elem;
            });
        }
      });
    });

    (async () => {
      setIsLoading(true);
      const hasError: { success: boolean; data: Record<string, string[]> } = {
        success: true,
        data: {},
      };
      if (permissionsToAdd.size > 0) {
        const res = await postCreatePermissions(
          "/permissions_management/create_access",
          token,
          addedPermissionsToSend
        );
        let allNewPermissionsCreates;
        if (res.success) {
          allNewPermissionsCreates = res.data;

          if (profile && profile.businessUnit == "HQ") {
            addNewPermissionsToUsers(allNewPermissionsCreates.permissions);
          } else {
            addNewPermissionsToCreatePending(
              allNewPermissionsCreates.permissions
            );
          }
        } else {
          hasError.success = false;
          Object.entries(res.errors.errors).forEach(([id, message]) => {
            if (!hasError.data[id]) {
              hasError.data[id] = [];
            }
            hasError.data[id].push(message as string);
          });
        }
      }
      await delay(1500);
      if (permissionsToDelete.size > 0) {
        const res = await postDeletePermissions(
          "/permissions_management/delete_access",
          token,
          deletedPermissionsToSend
        );
        let allNewPermissionsToDelete;
        if (res.success) {
          allNewPermissionsToDelete = res.data;

          if (profile && profile.businessUnit == "HQ") {
            removePermissionsToUsers(allNewPermissionsToDelete.permissions);
          } else {
            addNewPermissionsTodeletePending(
              allNewPermissionsToDelete.permissions
            );
          }
        } else {
          hasError.success = false;

          Object.entries(res.errors.errors).forEach(([id, message]) => {
            if (!hasError.data[id]) {
              hasError.data[id] = [];
            }
            hasError.data[id].push(message as string);
          });
          console.error(res.data);
        }
      }
      if (!hasError.success) {
        setHasErrorUpdate(true);
        setErrorUpdateMessage((prev) => {
          const newErrorMessages = prev;
          Object.entries(hasError.data).forEach(([id, messages]) => {
            newErrorMessages.push({
              idVINCI: id,
              messages,
            });
          });
          return newErrorMessages;
        });
      }
      resetPermissions();
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 500);
    })();
  };

  const removePermissionToAdd = (
    idVINCI: string,
    type: "admin" | "genai" | "datagouv" | "dashboard",
    permission: any
  ) => {
    setPermissionsToAdd((prev) => {
      const newPermissions = new Map(prev);
      const role = newPermissions.get(idVINCI);
      if (!role) return newPermissions;
      if (type === "genai") {
        role.gen_ai = role.gen_ai.filter(
          (elem) =>
            elem.app_name !== permission.app_name ||
            elem.use_case !== permission.use_case ||
            elem.business_unit !== permission.business_unit ||
            elem.airport !== permission.airport ||
            elem.role !== permission.role
        );
      } else if (type === "dashboard") {
        role.dashboard = role.dashboard.filter(
          (elem) =>
            elem.airport !== permission.airport ||
            elem.business_unit !== permission.business_unit ||
            elem.dashboard !== permission.dashboard ||
            elem.dashboard_level !== permission.dashboard_level
        );
      } else if (type === "datagouv") {
        role.data_gov = role.data_gov.filter(
          (elem) =>
            elem.action !== permission.action ||
            elem.business_unit !== permission.business_unit ||
            elem.use_case !== permission.use_case
        );
      }
      newPermissions.set(idVINCI, role);
      return newPermissions;
    });
    return;
  };

  const addPermissionToAdd = (
    permissionsToValidate:
      | DashboardPermissions[]
      | GenAiPermissions[]
      | DataGovPermissions[],
    type: "admin" | "genai" | "datagouv" | "dashboard"
  ) => {
    setPermissionsToAdd((prevPermissions) => {
      const newPermissions = new Map(prevPermissions);

      usersSelected.forEach(({ idVINCI }) => {
        if (!newPermissions.has(idVINCI)) {
          newPermissions.set(idVINCI, {
            admin: [],
            gen_ai: [],
            data_gov: [],
            dashboard: [],
          });
        }

        const permissionsUserHave = permissions.get(idVINCI) ?? {
          admin: [],
          gen_ai: [],
          data_gov: [],
          dashboard: [],
        };
        const permissionsUserValidated = newPermissions.get(idVINCI)!;

        let updatedPermissions: RolePermissions = {
          ...permissionsUserValidated,
        };

        if (type === "genai") {
          const filteredPermissions = (
            permissionsToValidate as GenAiPermissions[]
          ).filter(
            (elem) =>
              !permissionsUserHave.gen_ai.some(
                (elem2) =>
                  elem2.app_name === elem.app_name &&
                  elem2.use_case === elem.use_case &&
                  elem2.business_unit === elem.business_unit &&
                  elem2.airport === elem.airport &&
                  elem2.role === elem.role
              ) &&
              !permissionsUserValidated.gen_ai.some(
                (elem2) =>
                  elem2.app_name === elem.app_name &&
                  elem2.use_case === elem.use_case &&
                  elem2.business_unit === elem.business_unit &&
                  elem2.airport === elem.airport &&
                  elem2.role === elem.role
              )
          );

          for (let j = 0; j < filteredPermissions.length; j++) {
            updatedPermissions.gen_ai.push({ ...filteredPermissions[j] });
          }

          updatedPermissions = {
            ...updatedPermissions,
          };
        }

        if (type === "dashboard") {
          const filteredPermissions = (
            permissionsToValidate as DashboardPermissions[]
          ).filter(
            (elem) =>
              !permissionsUserHave.dashboard.some(
                (elem2) =>
                  elem2.airport === elem.airport &&
                  elem2.business_unit === elem.business_unit &&
                  elem2.dashboard === elem.dashboard &&
                  elem2.dashboard_level === elem.dashboard_level
              ) &&
              !permissionsUserValidated.dashboard.some(
                (elem2) =>
                  elem2.airport === elem.airport &&
                  elem2.business_unit === elem.business_unit &&
                  elem2.dashboard === elem.dashboard &&
                  elem2.dashboard_level === elem.dashboard_level
              )
          );

          for (let j = 0; j < filteredPermissions.length; j++) {
            updatedPermissions.dashboard.push({ ...filteredPermissions[j] });
          }
          updatedPermissions = {
            ...updatedPermissions,
          };
        }
        if (type === "datagouv") {
          const filteredPermissions = (
            permissionsToValidate as DataGovPermissions[]
          ).filter(
            (elem) =>
              !permissionsUserHave.data_gov.some(
                (elem2) =>
                  elem2.action === elem.action &&
                  elem2.business_unit === elem.business_unit &&
                  elem2.use_case === elem.use_case
              ) &&
              !permissionsUserValidated.data_gov.some(
                (elem2) =>
                  elem2.action === elem.action &&
                  elem2.business_unit === elem.business_unit &&
                  elem2.use_case === elem.use_case
              )
          );
          for (let j = 0; j < filteredPermissions.length; j++) {
            updatedPermissions.data_gov.push({ ...filteredPermissions[j] });
          }
          updatedPermissions = {
            ...updatedPermissions,
          };
        }

        newPermissions.set(idVINCI, updatedPermissions);
      });

      return newPermissions;
    });
  };

  const addPermissionToDelete = (
    idVINCI: string,
    type: "admin" | "genai" | "datagouv" | "dashboard",
    permission: any
  ) => {
    setPermissionsToDelete((prev) => {
      const newPermissions = new Map(prev);
      if (!newPermissions.has(idVINCI)) {
        newPermissions.set(idVINCI, {
          admin: [],
          gen_ai: [],
          data_gov: [],
          dashboard: [],
        });
      }

      const role = newPermissions.get(idVINCI);
      if (!role) return newPermissions;

      const typeMapping: Record<string, any[]> = {
        admin: role.admin,
        gen_ai: role.gen_ai,
        data_gov: role.data_gov,
        dashboard: role.dashboard,
      };

      const isDuplicate = () => {
        if (type === "genai") {
          return role.gen_ai.some(
            (elem) =>
              elem.app_name === permission.app_name &&
              elem.use_case === permission.use_case &&
              elem.business_unit === permission.business_unit &&
              elem.airport === permission.airport &&
              elem.role === permission.role
          );
        } else if (type === "dashboard") {
          return role.dashboard.some(
            (elem) =>
              elem.airport === permission.airport &&
              elem.business_unit === permission.business_unit &&
              elem.dashboard === permission.dashboard &&
              elem.dashboard_level === permission.dashboard_level
          );
        } else if (type === "datagouv") {
          return role.data_gov.some(
            (elem) =>
              elem.action === permission.action &&
              elem.business_unit === permission.business_unit &&
              elem.use_case === permission.use_case
          );
        }
        return false;
      };

      if (!isDuplicate()) {
        if (type === "genai") {
          typeMapping["gen_ai"].push(permission);
        } else if (type === "datagouv") {
          typeMapping["data_gov"].push(permission);
        } else {
          typeMapping[type].push(permission);
        }
      } else {
        if (type === "genai") {
          role.gen_ai.splice(role.gen_ai.indexOf(permission), 1);
        } else if (type === "datagouv") {
          role.data_gov.splice(role.data_gov.indexOf(permission), 1);
        } else {
          typeMapping[type].splice(typeMapping[type].indexOf(permission), 1);
        }
        if (
          role.admin.length === 0 &&
          role.gen_ai.length === 0 &&
          role.data_gov.length === 0 &&
          role.dashboard.length === 0
        ) {
          newPermissions.delete(idVINCI);
        }
      }
      return newPermissions;
    });
  };

  const getIdsVinciByRole = async (role: string) => {
    try {
      const idsVinci = await fetchIdVinciByRole(
        "/permissions_management/get_ids_vinci_by_role/",
        token,
        role
      );

      const allUsersSelected: usersInterface[] = usersInAppDirectory
        .filter((user) => idsVinci.ids_vinci.includes(user.idVINCI))
        .map((user) => user);

      setUsersSelected(allUsersSelected);

      await getPermissionsWithIdUser(idsVinci.ids_vinci, "", 0);
    } catch (err) {
      console.error(err);
    }
  };

  const getPermissionsWithIdUser = async (
    idVINCIList: string[], // <-- maintenant c'est un tableau
    upn: string,
    indexLoading: number
  ) => {
    try {
      if (indexLoading === 0) {
        setLoadingPermissionsUser(true);
      } else if (indexLoading === 1) {
        setLoadingSearchCopieUserPerm(true);
      }

      const permissions = await fetchPermissionsFromUsers(
        "/permissions_management/get_users_permissions",
        token,
        idVINCIList // <-- plus besoin de mettre entre crochets ici
      );

      const dictPermissions = permissions.upns;

      setPermissionsPendingAdd((prev) => {
        const newPermissions = new Map(prev);

        for (const id of idVINCIList) {
          const userData = dictPermissions[id] || {};
          const pendingAdd = userData.create_pending || {};

          newPermissions.set(id, {
            admin: pendingAdd.admin || [],
            gen_ai: pendingAdd.gen_ai || [],
            data_gov: pendingAdd.data_gov || [],
            dashboard: pendingAdd.dashboard || [],
          });
        }

        return newPermissions;
      });

      setPermissionsPendingDelete((prev) => {
        const newPermissions = new Map(prev);

        for (const id of idVINCIList) {
          const userData = dictPermissions[id] || {};
          const pendingDelete = userData.delete_pending || {};

          newPermissions.set(id, {
            admin: pendingDelete.admin || [],
            gen_ai: pendingDelete.gen_ai || [],
            data_gov: pendingDelete.data_gov || [],
            dashboard: pendingDelete.dashboard || [],
          });
        }

        return newPermissions;
      });

      setPermissions((prev) => {
        const newPermissions = new Map(prev);

        for (const id of idVINCIList) {
          const userData = dictPermissions[id] || {};
          const currentPerms = userData.current || {};

          newPermissions.set(id, {
            admin: currentPerms.admin || [],
            gen_ai: currentPerms.gen_ai || [],
            data_gov: currentPerms.data_gov || [],
            dashboard: currentPerms.dashboard || [],
          });
        }

        return newPermissions;
      });
    } catch (err) {
      console.error(err);
    } finally {
      if (indexLoading === 0) {
        setLoadingPermissionsUser(false);
      } else if (indexLoading === 1) {
        setLoadingSearchCopieUserPerm(false);
      }
    }
  };

  const handleCopieSelectionUser = (upn: string) => {
    const user = usersInAppDirectory.find((user) => user.upn === upn);
    if (user) {
      getPermissionsWithIdUser([user.idVINCI], user.upn, 1);
    }
  };

  const handleSelectionUsersByResponseFileError = async (
    values: Record<string, string>
  ) => {
    setHasErrorFile(true);
    setErrorsFile(values);
  };

  const handleSelectionUsersByResponseFile = async (
    values: Record<string, string>
  ) => {
    const userMap = new Map(
      usersInAppDirectory.map((user) => [user.upn, user])
    );

    const allUsersSelected: usersInterface[] = Object.keys(values)
      .map((upn) => userMap.get(upn))
      .filter((user): user is usersInterface => user !== undefined);

    setUsersSelected(allUsersSelected);

    if (allUsersSelected.length > 0) {
      getPermissionsWithIdUser(
        allUsersSelected.map((user) => user.idVINCI),
        allUsersSelected[0].upn,
        0
      );
    }
  };

  const handleSelectionMultipleUsers = (
    businessUnit: string,
    airport: string
  ) => {
    const userMap = new Map(
      usersInAppDirectory.map((user) => [user.upn, user])
    );

    const allUsersSelected: usersInterface[] = usersInAppDirectory
      .filter(
        (user) => user.businessUnit === businessUnit && user.airport === airport
      )
      .map((user) => userMap.get(user.upn))
      .filter((user): user is usersInterface => user !== undefined);

    setUsersSelected(allUsersSelected);

    if (allUsersSelected.length > 0) {
      getPermissionsWithIdUser(
        allUsersSelected.map((user) => user.idVINCI),
        allUsersSelected[0].upn,
        0
      );
    }
  };

  const handleSelectionUsers = (upnList: string[], upn: string) => {
    const userMap = new Map(
      usersInAppDirectory.map((user) => [user.upn, user])
    );
    const allUsersSelected: usersInterface[] = upnList
      .map((upn) => userMap.get(upn))
      .filter((user): user is usersInterface => user !== undefined);

    setUsersSelected(allUsersSelected);

    if (upn !== "") {
      const user = userMap.get(upn);
      if (user) {
        getPermissionsWithIdUser([user.idVINCI], user.upn, 0);
      }
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await fetchUserProfile(
          "/user_management/get_current_user_data",
          token
        );
        setProfile(profile);
      } catch (err) {}
    };

    const getUsersPermissions = async () => {
      try {
        const users = await fetchUsersPermissions(
          "/user_management/get_upns_app_users",
          token
        );
        if (!Array.isArray(users.upns)) {
          throw new Error("Les données reçues ne sont pas valides.");
        }
        // data ou stocker les utilisateurs
        setUserInAppDirectory(users.upns);
      } catch (err) {
        if (err instanceof Error) {
        } else {
        }
      }
    };

    const getAllRoles = async () => {
      try {
        const allRoles = await fetchAllRoles(
          "/permissions_management/get_all_roles",
          token
        );
        setAllRolesPermissions((prev) => {
          return {
            ...prev,
            admin: allRoles.admin,
            gen_ai: allRoles.gen_ai,
            data_gov: allRoles.data_gov,
            dashboard: allRoles.dashboard,
          };
        });
      } catch (e) {
        console.error(e);
      }
    };

    const getLocations = async () => {
      try {
        const data = await fetchLocations(token);
        setAllLocations(data);
      } catch (err) {}
    };

    const getRolesPermissions = async () => {
      try {
        const rolesPermissions = await fetchRolesPermissions(
          "/permissions_management/get_roles",
          token
        );
        setRolesPermissions(rolesPermissions.roles);
      } catch (e) {}
    };

    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getUsersPermissions();
        await getProfile();
        await getAllRoles();
        await getLocations();
        await getRolesPermissions();
      } catch (e) {
        console.error(e);
      } finally {
        setEndLoading(true);
        setTimeout(() => {
          setEndLoading(false);
          setIsLoading(false);
        }, 1000);
      }
    };
    fetchData();
  }, []);

  return (
    <PermissionsDataAdminProviderContext.Provider
      value={{
        handleRemovePermissionToAddAndDeleteToOneUser,
        errorsFile,
        setErrorsFile,
        handleSelectionUsersByResponseFileError,
        hasErrorFile,
        setHasErrorFile,
        handleSelectionUsersByResponseFile,
        getIdsVinciByRole,
        rolesPermissions,
        permissionsPendingDelete,
        permissionsPendingAdd,
        loadingSearchCopieUserPerm,
        handleCopieSelectionUser,
        hasErrorUpdate,
        setHasErrorUpdate,
        errorUpdateMessage,
        setErrorUpdateMessage,
        allRolesPermissions,
        resetPermissions,
        updatePermissionsUsers,
        loadingPermissionsUser,
        removePermissionToAdd,
        permissionsToAdd,
        addPermissionToAdd,
        permissionsToDelete,
        addPermissionToDelete,
        permissions,
        setPermissions,
        handleSelectionUsers,
        usersSelected,
        setUsersSelected,
        usersInAppDirectory,
        isLoading,
        setIsLoading,
        endLoading,
        setEndLoading,
        profile,
        allLocations,
        setAllLocations,
        handleSelectionMultipleUsers,
      }}
    >
      {children}
    </PermissionsDataAdminProviderContext.Provider>
  );
};

export const usePermissionsDataAdmin = (): PermissionsDataAdminContextType => {
  const context = useContext(PermissionsDataAdminProviderContext);
  if (context === undefined) {
    throw new Error(
      "usePermissionsDataAdmin must be used within a PermissionsDataAdminProvider"
    );
  }
  return context;
};
