import React from "react";

interface TitleH1Props {
  text: string;
}

export default function TitleH1({ text }: TitleH1Props) {
  return (
    <h1 className="h-[88px] flex items-center font-inter font-medium text-[26px] leading-[31.47px] text-black">
      {text}
    </h1>
  );
}
