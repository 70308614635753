import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

// interface dataChartsInterface {
//   dataCharts: any;
//   // trafficReport: number;
//   // competitionAnalysis: number;
//   // secureChatGPT: number;
//   // documentTranslator: number;
//   // customTrafficReport: number;
// }

interface ChartProps {
  dataCharts: any | null;
}

export default function Chart({ dataCharts }: ChartProps) {
  console.log(dataCharts);
  const data = Object.entries(dataCharts)
    .map(([key, value]: [string, unknown]) => ({
      name: key,
      amount: parseFloat((value as number).toFixed(2)),
    }))
    .sort((a, b) => b.amount - a.amount);
  // const data = [
  //   {
  //     name: "Traffic Report",
  //     uv: dataCharts?.trafficReport || 0,
  //   },
  //   {
  //     name: "Competition Analysis",
  //     uv: dataCharts?.competitionAnalysis || 0,
  //   },
  //   {
  //     name: "Secure ChatGPT",
  //     uv: dataCharts?.secureChatGPT || 0,
  //   },
  //   {
  //     name: "Document Translator",
  //     uv: dataCharts?.documentTranslator || 0,
  //   },
  //   {
  //     name: "Custom Traffic Report",
  //     uv: dataCharts?.customTrafficReport || 0,
  //   },
  // ];

  return (
    <div className="h-[246px] w-[681px] min-w-[1px] min-h-[1px] max-h-[246px]">
      {/* Conteneur scrollable */}
      <div className="h-full w-full overflow-y-auto">
        <ResponsiveContainer
          width="100%"
          height={data.length > 6 ? data.length * 40 : 246}
        >
          <BarChart
            data={data}
            layout="vertical"
            className="font-inter"
            margin={{ top: 30, right: 20, left: 50, bottom: 10 }}
          >
            <CartesianGrid horizontal={false} strokeDasharray="476" />

            <XAxis
              type="number"
              domain={[0, 500]}
              ticks={[0, 50, 100, 200, 300, 400, 500]}
              tick={{ fill: "#6B7280" }}
              orientation="top"
              tickLine={false}
              axisLine={false}
            />

            <YAxis
              type="category"
              dataKey="name"
              width={200}
              tickLine={false}
              axisLine={false}
              tick={{ fill: "#344054", fontSize: 14, fontWeight: 500 }}
            />

            <Tooltip cursor={{ fill: "rgba(0, 0, 0, 0.1)" }} />

            <defs>
              <linearGradient id="colorUv" x1="1" y1="0" x2="0" y2="0">
                <stop offset="0%" stopColor="#004289" stopOpacity={1} />
                <stop offset="100%" stopColor="#004289" stopOpacity={0.2} />
              </linearGradient>
            </defs>

            <Bar dataKey="amount" fill="url(#colorUv)" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
