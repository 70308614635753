import { SVGIcon } from "../SVGIcon";
import { DocumentTranslator, CustomFinanceReport, PowerBI } from "../../pages";
import { Newsletter } from "../../pages/Newsletter";
import { SecureChat } from "../../pages/SecureChat";
import { Home } from "../../pages/Home";
import { HomeSDH } from "../../pages/HomeSDH";
import { SentimentAnalysis } from "../../pages/SentimentAnalysis";
import { LargeDoc } from "../../pages/LargeDoc";
import { CompetitionAnalysis } from "../../pages/CompetitionAnalysis";
import { useRef } from "react";
import { useHover } from "../../hooks";
import { CustomTrafficReport } from "../../pages/CustomTrafficReport";
import { StreamlitApp } from "../../pages/StreamlitApp";
import { ChatGPT } from "../../pages/ChatGPT";
import { AIFactory } from "../../pages/AIFactory";
import { useActiveTabs } from "../../providers";
import { useChatGPT } from "../../pages/ChatGPT/providers";
import { DataGouvHome } from "../../pages/DataGouvernance/DataGouvHome";
import { BUMainApp } from "../../pages/DataGouvernance/BUMainApp";
import { PDFViewer } from "../../pages/PDFViewer";
import { OverviewHQ } from "../../pages/DataGouvernance/OverviewHQ";
import AdminManagementsUsers from "../../pages/AdminManagement/AdminManagementsUsers";
import AdminManagementPermissions from "../../pages/AdminManagement/AdminManagementPermissions";
import AdminManagementDataAnalytics from "../../pages/AdminManagement/AdminManagementDataAnalytics";
import AdminManagementNotifications from "../../pages/AdminManagement/AdminManagementNotifications";

export type Tabs = {
  id: number;
  appId: string;
  title: string;
  icon: JSX.Element;
  description?: string[];
  app: () => JSX.Element;
  metadata?: string;
};

export type Section = {
  title: string;
  icon: JSX.Element;
  tabs: Tabs[];
};

export type Menu = (Section | Tabs)[];

export function isSection(menuItem: Section | Tabs): menuItem is Section {
  return (menuItem as Section).tabs !== undefined;
}

export const homeTab: Tabs = {
  id: 9,
  appId: "GenAI/SecureChatGPT",
  title: "SecuredGPT",
  icon: <SVGIcon size="18px" icon="SECURE_CHAT_ICON" />,
  app: () => <ChatGPT />,
};

export const Sharepoint = ({ isSidebarOpen }: { isSidebarOpen: Boolean }) => {
  const classic = `py-1 px-2 flex items-center rounded-lg text-text-primary-light`;
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-center";
  const hoverOpt =
    "hover:bg-secondary-light hover:text-text-secondary-light hover:opacity-100";
  const tabsBtnClassName = `${classic} ${sidebarOpt} ${hoverOpt}`;
  const divRef = useRef(null);
  const { isHover } = useHover(divRef);
  const opacity = isHover ? "opacity-100" : "opacity-50";

  return (
    <div ref={divRef} className="flex justify-center">
      <a
        href="https://vinciconcessions.sharepoint.com/sites/10396-SmartDataHubSP"
        target="_blank"
        rel="noopener noreferrer"
        className={`inline-block ${tabsBtnClassName}`}
      >
        {
          <span>
            <SVGIcon size="20px" icon="SHAREPOINT_ICON" />
          </span>
        }
        {isSidebarOpen && (
          <span className={` text-sm pl-3 ${opacity}`}>
            Project Documentation
          </span>
        )}
      </a>
    </div>
  );
};

export const ExploreBtn = ({ isSidebarOpen }: { isSidebarOpen: Boolean }) => {
  const classic = `py-1 px-2 flex items-center rounded-lg text-text-primary-light`;
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-center";
  const hoverOpt =
    "hover:bg-secondary-light hover:text-text-secondary-light hover:opacity-100";
  const tabsBtnClassName = `${classic} ${sidebarOpt} ${hoverOpt}`;
  const divRef = useRef(null);
  const { isHover } = useHover(divRef);
  const { homeStatus, setHomeStatus, gptConfig } = useChatGPT();
  const { openTab, returnFav, tabState } = useActiveTabs();

  const opacity =
    isHover || (homeStatus && tabState.activeTabId === 9)
      ? "opacity-100"
      : "opacity-50";
  const secureChatAccess = returnFav([9]);

  return (
    <div
      ref={divRef}
      className={`flex justify-center group mb-2 cursor-pointer`}
      onClick={() => {
        secureChatAccess.length > 0 && openTab(secureChatAccess[0]);
        setHomeStatus(true);
        gptConfig.setMDModeActive(false);
      }}
    >
      <div
        className={`inline-block ${tabsBtnClassName} ${
          homeStatus && tabState.activeTabId === 9 && "bg-secondary-light"
        }`}
      >
        {
          <span>
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 0.6 0.6"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                  id="System"
                  transform="translate(-528 -336)"
                  fillRule="nonzero"
                >
                  <g id="dot_grid_fill" transform="translate(528 336)">
                    <path
                      d="M0.6 0v0.6H0V0zM0.315 0.581l0 0 -0.002 0.001 -0.001 0 0 0 -0.002 -0.001q0 0 -0.001 0l0 0 0 0.011 0 0.001 0 0 0.003 0.002 0 0 0 0 0.003 -0.002 0 0 0 0 0 -0.011q0 0 0 0m0.007 -0.003 0 0 -0.005 0.002 0 0 0 0 0 0.011 0 0 0 0 0.005 0.002q0 0 0.001 0l0 0 -0.001 -0.015q0 0 -0.001 -0.001m-0.018 0a0.001 0.001 0 0 0 -0.001 0l0 0 -0.001 0.015q0 0 0 0.001l0 0 0.005 -0.002 0 0 0 0 0 -0.011 0 0 0 0z"
                      id="MingCute"
                      fillRule="nonzero"
                    />
                    <path
                      d="M0.138 0.413a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m0.163 0a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m0.163 0a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1M0.138 0.25a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m0.163 0a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m0.163 0a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m-0.325 -0.163a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m0.163 0a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1m0.163 0a0.05 0.05 0 1 1 0 0.1 0.05 0.05 0 0 1 0 -0.1"
                      fill="currentColor"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </span>
        }
        {isSidebarOpen && (
          <span className={` text-sm pl-3 ${opacity}`}>Explore</span>
        )}
      </div>
    </div>
  );
};

export const BugReport = ({ isSidebarOpen }: { isSidebarOpen: Boolean }) => {
  const classic = `py-1 px-2 flex items-center rounded-lg text-text-primary-light`;
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-center";
  const hoverOpt =
    "hover:bg-secondary-light hover:text-text-secondary-light hover:opacity-100";
  const tabsBtnClassName = `${classic} ${sidebarOpt}`;
  const divRef = useRef(null);

  return (
    <div ref={divRef} className="relative group">
      {isSidebarOpen && (
        <a
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=FC49vDMcz0alBzRaep3UWpc-wzcEiFRDiQ8XKkXbCwZUMUg3T0g4UDI1MUNTN1BNRTJMOVY3Rlg1Ui4u"
          target="_blank"
          rel="noopener noreferrer"
          className={`inline-block ${tabsBtnClassName}`}
        >
          <span className={` text-md pl-3`}>Report a bug</span>
        </a>
      )}
    </div>
  );
};

export const aiFactory: Section = {
  title: "AI Factory",
  icon: <SVGIcon size="18px" icon="SENTIMENT_ANALYSIS_ICON" />,
  tabs: [
    {
      id: 1000,
      appId: "App/Streamlit",
      title: "Streamlit Test",
      icon: <SVGIcon size="18px" icon="COMMUNICATION_ICON" />,
      app: () => <StreamlitApp />,
    },
    {
      id: 1001,
      appId: "App/Streamlit",
      title: "Test Auth",
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      description: [],
      app: () => <AIFactory appName="streamlit-test-auth" id={1001} />,
      metadata: "streamlit-test-auth",
    },
    {
      id: 1002,
      appId: "GenAI/Financial Forecasting",
      title: "Financial Forecasting",
      icon: <SVGIcon size="16px" icon="FINANCE_BU_ICON" />,
      description: [],
      app: () => <AIFactory appName="financial-forecasting" id={1002} />,
      metadata: "financial-forecasting",
    },
    {
      id: 1003,
      appId: "GenAI/Flights Delay Analysis",
      title: "Flights Delay Analysis",
      icon: <SVGIcon size="16px" icon="OPERATIONS_ICON" />,
      description: [],
      app: () => <AIFactory appName="flights-delay-analysis" id={1003} />,
      metadata: "flights-delay-analysis",
    },
    {
      id: 1004,
      appId: "GenAI/Traffic Dashboard",
      title: "Traffic Dashboard",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,
      description: [],
      app: () => <AIFactory appName="traffic-dashboard" id={1004} />,
      metadata: "traffic-dashboard",
    },
    {
      id: 1005,
      appId: "GenAI/Traffic Documentation",
      title: "Traffic Documentation",
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      description: [],
      app: () => <AIFactory appName="traffic-documentation" id={1005} />,
      metadata: "traffic-documentation",
    },
    {
      id: 1006,
      appId: "GenAI/Knowledge Base",
      title: "Knowledge Base",
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      description: [],
      app: () => <AIFactory appName="knowledge-base" id={1006} />,
      metadata: "knowledge-base",
    },
    {
      id: 1007,
      appId: "GenAI/CV Match",
      title: "CV Match",
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      description: [],
      app: () => <AIFactory appName="cv-match" id={1007} />,
      metadata: "cv-match",
    },
    {
      id: 1008,
      appId: "GenAI/Ai Search",
      title: "Ai Search",
      icon: <SVGIcon size="18px" icon="SECURE_CHAT_ICON" />,
      description: [],
      app: () => <AIFactory appName="ai-search" id={1008} />,
      metadata: "ai-search",
    },
    {
      id: 1009,
      appId: "GenAI/Download Data",
      title: "Download Data",
      icon: <SVGIcon size="18px" icon="SECURE_CHAT_ICON" />,
      description: [],
      app: () => <AIFactory appName="download-data" id={1009} />,
      metadata: "download-data",
    },
    {
      id: 1010,
      appId: "GenAI/Gate Sizing",
      title: "Gate Sizing",
      icon: <SVGIcon size="18px" icon="SECURE_CHAT_ICON" />,
      description: [],
      app: () => <AIFactory appName="gate-sizing" id={1010} />,
      metadata: "download-data",
    },
  ],
};

export const dashboardsTabsHQ: Section = {
  title: "Dashboards HQ",
  icon: <SVGIcon size="18px" icon="DASHBOARD_HQ_ICON" />,
  tabs: [
    {
      id: 40,
      appId: "Dashboard/Traffic", // L1
      title: "Smart Data Hub",
      icon: <SVGIcon size="16px" icon="SMALL_VINCI_ICON" />,
      description: [
        "Get a summary of airport activity with one-page overviews for each use case.",
      ],
      app: () => <PowerBI report="sdh" id={40} />,
    },
    {
      id: 25,
      appId: "Dashboard/Traffic",
      title: "Excom Dashboard",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description: [
        "Get a summary of airport activity - oriented comparison between airports",
      ],
      app: () => <PowerBI report="steering" id={25} />,
    },
    //{
    //  id: 101,
    //  appId: "Dashboard/AirlineID",
    //  title: "Market Data",
    //  icon: <SVGIcon size="16px" icon="SMALL_VINCI_ICON" />,
    //  description:["OAG Snapshots", "Other external data"],
    //  app: () => <PowerBI report="market" />,
    //},
    {
      id: 41,
      appId: "Dashboard/Communication", //L1
      title: "Communication",
      icon: <SVGIcon size="18px" icon="COMMUNICATION_ICON" />,
      description: [
        "Track social media KPIs, website metrics, and overall media reputation.",
      ],
      app: () => <PowerBI report="communication_hq" id={41} />,
    },
  ],
};

export const dashboardsTabsBU: Section = {
  title: "Dashboards BU",
  icon: <SVGIcon size="18px" icon="DASHBOARD_BU_ICON" />,
  tabs: [
    {
      id: 10,
      appId: "Dashboard/Traffic",
      title: "Executive",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description: [
        "Get a summary of airport activity with one-page overviews for each use case.",
      ],
      app: () => <PowerBI report="executive" id={10} />,
    },
    {
      id: 11,
      appId: "Dashboard/Traffic",
      title: "Traffic",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,
      description: [
        "Monitor airport traffic data, including airlines, routes, and key performance indicators.",
      ],
      app: () => <PowerBI report="traffic" id={11} />,
    },
    {
      id: 14,
      appId: "Dashboard/Traffic",
      title: "Airline ID",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description: [
        "Measure airline performance in Operations, Finance, and Retail.",
      ],
      app: () => <PowerBI report="airline" id={14} />,
    },
    {
      id: 19,
      appId: "Dashboard/OPS",
      title: "Operations",
      icon: <SVGIcon size="18px" icon="OPERATIONS_ICON" />,
      description: [
        "Evaluate on-time performance, airside operations, security flows, and turnaround times.",
      ],
      app: () => <PowerBI report="operations" id={19} />,
    },
    {
      id: 24,
      appId: "Dashboard/ACIASQ",
      title: "ACI / ASQ",
      icon: <SVGIcon size="18px" icon="OPERATIONS_ICON" />,
      description: [
        "Results of ACI Airport Service Quality Departure surveys.",
      ],
      app: () => <PowerBI report="aci_asq" id={24} />,
    },
    {
      id: 23,
      appId: "Dashboard/Business Aviation",
      title: "Business Aviation",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,
      description: [
        "Monitor business aviation traffic data through movements KPIs and revenues details",
      ],
      app: () => <PowerBI report="business_aviation" id={23} />,
    },
    {
      id: 12,
      appId: "Dashboard/Retail",
      title: "Retail & F&B",
      icon: <SVGIcon size="16px" icon="LABEL_ICON" />,
      description: [
        "Analyze revenue, SPP, market shares, and shop/operator performance at the airport.",
      ],
      app: () => <PowerBI report="retail" id={12} />,
    },
    {
      id: 22,
      appId: "Dashboard/Retail",
      title: "EPOS",
      icon: <SVGIcon size="16px" icon="LABEL_ICON" />,
      description: [
        "Dive into detailed sales data by product through the EPOS system.",
      ],
      app: () => <PowerBI report="epos" id={22} />,
    },
    {
      id: 20,
      appId: "Dashboard/EPOS_KAP",
      title: "Retail EPOS",
      icon: <SVGIcon size="16px" icon="LABEL_ICON" />,
      description: [
        "Dive into detailed sales data by product through the EPOS system.",
      ],
      app: () => <PowerBI report="epos_kap" id={20} />,
    },
    {
      id: 15,
      appId: "Dashboard/Car Parks",
      title: "Car Parks",
      icon: <SVGIcon size="16px" icon="CAR_PARKS_ICON" />,
      description: [
        "Track roll-up, prebooking, and drop-off data for airport car parks.",
      ],
      app: () => <PowerBI report="carparks" id={15} />,
    },
    {
      id: 13,
      appId: "Dashboard/Finance",
      title: "Finance",
      icon: <SVGIcon size="16px" icon="FINANCE_BU_ICON" />,
      description: [
        "Assess financial performance, P&L, and account receivables.",
      ],
      app: () => <PowerBI report="finance" id={13} />,
    },
    {
      id: 21,
      appId: "Dashboard/VISION",
      title: "VISION BU",
      icon: <SVGIcon size="16px" icon="FINANCE_BU_ICON" />,
      description: ["Access VISION Local data through modern visualisations."],
      app: () => <PowerBI report="vision_bu" id={21} />,
    },
    {
      id: 16,
      appId: "Dashboard/HR",
      title: "Human Resources",
      icon: <SVGIcon size="16px" icon="PLANE_ICON" />,
      description: [
        "Review staffing levels, absenteeism rates, and related KPIs.",
      ],
      app: () => <PowerBI report="hr" id={16} />,
    },
    {
      id: 18,
      appId: "Dashboard/Communication",
      title: "Communication",
      icon: <SVGIcon size="18px" icon="COMMUNICATION_ICON" />,
      description: [
        "Track social media KPIs, website metrics, and overall media reputation.",
      ],
      app: () => <PowerBI report="communication" id={18} />,
    },
  ],
};

export const securedSDH: Section = {
  title: "Generative Analytics",
  icon: <SVGIcon size="18px" icon="SENTIMENT_ANALYSIS_ICON" />,
  tabs: [
    {
      id: 1,
      appId: "GenAI/SecureChatGPT",
      title: "ChatBot_SDH",
      description: [
        "Query SDH data using natural language for quick insights.",
      ],
      icon: <SVGIcon size="18px" icon="SECURE_CHAT_ICON" />,
      app: () => <SecureChat />,
    },
    {
      id: 5,
      appId: "GenAI/CustomTrafficReport",
      title: "Traffic Report",
      description: ["Generate text summaries of airport traffic activity."],
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      app: () => <CustomTrafficReport />,
    },
    /* {
      id: 6,
      appId: "GenAI/CustomFinanceReport",
      title: "Finance Report",
      description: ["Create detailed text reports summarizing financial figures."],
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      app: () => <CustomFinanceReport />,
    }, */
    {
      id: 8,
      appId: "GenAI/CompetitionAnalysis",
      title: "Competition Analysis",
      description: ["Compare OAG forecasts with competitor airport data."],
      icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
      app: () => <CompetitionAnalysis />,
    },
    {
      id: 2,
      appId: "GenAI/News",
      title: "Newsletter",
      icon: <SVGIcon size="18px" icon="NEWSLETTER_ICON" />,
      description: [
        "Sign up to receive regular updates about VINCI Airports and Market trend directly to your inbox.",
      ],
      app: () => <Newsletter />,
    },
  ],
};

export const dataGouvernance: Section = {
  title: "Data Governance",
  icon: <SVGIcon size="18px" icon="DATA_GOUVERNANCE_ICON" />,
  tabs: [
    {
      id: 49,
      appId: "App/Gouvernance",
      title: "Overview HQ",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description: [],
      app: () => <OverviewHQ />,
    },
    {
      id: 50,
      appId: "App/Gouvernance",
      title: "Overview",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description: [],
      app: () => <DataGouvHome />,
    },
    {
      id: 51,
      appId: "Dashboard/Traffic",
      title: "Traffic",
      icon: <SVGIcon size="16px" icon="TRAFFIC_ICON" />,
      description: [],
      app: () => <BUMainApp title="Traffic" tabId={51} />,
    },
    {
      id: 52,
      appId: "Dashboard/Finance",
      title: "Finance",
      icon: <SVGIcon size="16px" icon="FINANCE_ICON" />,
      description: [],
      app: () => <BUMainApp title="Finance" tabId={52} />,
    },
    {
      id: 53,
      appId: "Dashboard/Retail",
      title: "Retail",
      icon: <SVGIcon size="16px" icon="RETAIL_ICON" />,
      description: [],
      app: () => <BUMainApp title="Retail" tabId={53} />,
    },
    {
      id: 54,
      appId: "Dashboard/Communication",
      title: "Communication",
      icon: <SVGIcon size="16px" icon="COM_ICON" />,
      description: [],
      app: () => <BUMainApp title="Communication" tabId={54} />,
    },
    {
      id: 55,
      appId: "Dashboard/Car Parks",
      title: "Carparks",
      icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
      description: [],
      app: () => <BUMainApp title="Carparks" tabId={55} />,
    },
    {
      id: 56,
      appId: "Dashboard/OPS",
      title: "Ops",
      icon: <SVGIcon size="16px" icon="OPS_ICON" />,
      description: [],
      app: () => <BUMainApp title="Ops" tabId={56} />,
    },
    {
      id: 57,
      appId: "Dashboard/HR",
      title: "HR",
      icon: <SVGIcon size="16px" icon="HR_ICON" />,
      description: [],
      app: () => <BUMainApp title="HR" tabId={57} />,
    },
    // {
    //   id: 58,
    //   appId: "App/Gouvernance",
    //   title: "External",
    //   icon: <SVGIcon size="16px" icon="EXTERNAL_ICON" />,
    //   description: [],
    //   app: () => <BUMainApp title="HR" tabId={58}/>,
    // },
    // {
    //   id: 59,
    //   appId: "App/Gouvernance",
    //   title: "Predict",
    //   icon: <SVGIcon size="16px" icon="PREDICT_ICON" />,
    //   description: [],
    //   app: () => <BUMainApp title="HR" tabId={59}/>,
    // },
  ],
};

export const adminManagement: Section = {
  title: "Admin Management",
  icon: <SVGIcon size="18px" icon="DATA_GOUVERNANCE_ICON" />,
  tabs: [
    {
      id: 70,
      appId: "Admin/SuperAdmin",
      title: "User",
      icon: <SVGIcon size="16px" icon="DATA_GOUVERNANCE_ICON" />,
      description: [],
      metadata: "",
      app: () => <AdminManagementsUsers />,
    },
    {
      id: 71,
      appId: "Admin/SuperAdmin",
      title: "Permissions",
      icon: <SVGIcon size="16px" icon="DATA_GOUVERNANCE_ICON" />,
      description: [],
      app: () => <AdminManagementPermissions />,
    },
    {
      id: 72,
      appId: "Admin/SuperAdmin",
      title: "Data Analytics",
      icon: <SVGIcon size="16px" icon="DATA_GOUVERNANCE_ICON" />,
      description: [],
      app: () => <AdminManagementDataAnalytics />,
    },
    {
      id: 73,
      appId: "Admin/SuperAdmin",
      title: "Notifications",
      icon: <SVGIcon size="16px" icon="DATA_GOUVERNANCE_ICON" />,
      description: [],
      app: () => <AdminManagementNotifications />,
    },
  ],
};

export const menuData: Menu = [
  homeTab,
  securedSDH,
  dashboardsTabsHQ,
  dashboardsTabsBU,
  aiFactory,
  dataGouvernance,
  adminManagement,
  {
    id: 3,
    appId: "GenAI/DocumentTranslator",
    title: "Translator",
    icon: <SVGIcon size="18px" icon="TRANSLATOR_ICON" />,
    app: () => <DocumentTranslator />,
  },
  {
    id: 4,
    appId: "GenAI/SentimentAnalysis",
    title: "Sentiment analysis",
    icon: <SVGIcon size="18px" icon="SENTIMENT_ANALYSIS_ICON" />,
    app: () => <SentimentAnalysis />,
  },
  {
    id: 7,
    appId: "GenAI/LargeDocAssistant",
    title: "Multi Documents",
    icon: <SVGIcon size="18px" icon="CUSTOM_FINANCE_ICON" />,
    app: () => <LargeDoc />,
  },
];

export const getAppMenu = (appNames: string[] | null): Menu => {
  console.log("appNames", appNames);
  if (!appNames || !appNames.includes("Admin/Portal Staging")) {
    return [] as Menu;
  }
  if (!appNames) {
    return [] as Menu;
  }

  return menuData.reduce((filteredMenu, item) => {
    if ("appId" in item && appNames.includes(item.appId)) {
      filteredMenu.push(item);
    } else if ("tabs" in item) {
      const filteredTabs = item.tabs.filter((tab) =>
        appNames.includes(tab.appId)
      );
      if (filteredTabs.length > 0) {
        filteredMenu.push({ ...item, tabs: filteredTabs });
      }
    }
    return filteredMenu;
  }, [] as Menu);
};
