import React from "react";

interface SmallElemDotProps {
  text: string;
  color: string;
  bgColor: string;
}

export default function SmallElemDot({
  text,
  color,
  bgColor,
}: SmallElemDotProps) {
  return (
    <div
      className=" w-fit font-inter flex justify-center items-center  p-1 rounded-lg"
      style={{ backgroundColor: bgColor }}
    >
      <div
        className="w-[6px] h-[6px] rounded-full"
        style={{ backgroundColor: color }}
      ></div>
      {/* <img src={`${PATH_IMG}/dot.svg`} alt="#" /> */}
      <p className="ml-2  font-medium text-xs leading-[18px]" style={{ color }}>
        {text}
      </p>
    </div>
  );
}
