import { Dash } from "plotly.js";
import React from "react";
import {
  DashboardPermissions,
  DataGovPermissions,
  GenAiPermissions,
} from "./type/permissionsTypes";

interface PermissionElementProps {
  children: React.ReactNode;
  backgroundColor: string;
  permission: DashboardPermissions | GenAiPermissions | DataGovPermissions;
  type: "dashboard" | "genai" | "datagov";
}

export default function PermissionElement({
  children,
  backgroundColor,
  type,
  permission,
}: PermissionElementProps) {
  if (type === "dashboard") {
    return displayDashboardPermission({
      permission: permission as DashboardPermissions,
      backgroundColor,
      children,
    });
  } else if (type === "genai") {
    return displayGenAiPermission({
      permission: permission as GenAiPermissions,
      backgroundColor,
      children,
    });
  } else if (type === "datagov") {
    return displayDataGovPermission({
      permission: permission as DataGovPermissions,
      backgroundColor,
      children,
    });
  }
  return null;
}

interface displayDashboardPermissionProps {
  children: React.ReactNode;
  backgroundColor: string;
  permission: DashboardPermissions;
}

function displayDashboardPermission({
  permission,
  backgroundColor,
  children,
}: displayDashboardPermissionProps) {
  return (
    <div
      className=" w-fit h-10 font-normal text-base leading-6 flex items-center gap-1 border rounded-md p-2 "
      style={{ boxShadow: "0px 1px 2px 0px #1018280D", backgroundColor }}
    >
      <p className="flex items-center gap-2">
        <span className="text-sm leading-5 text-[#747c8a]">
          {permission.dashboard_level}
        </span>
        <span className="font-semibold text-[#344054] text-sm leading-5">
          {permission.dashboard}
        </span>
        {permission.business_unit && permission.business_unit !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>[{permission.business_unit}]</span>
          </span>
        )}
        {permission.airport && permission.airport !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>[{permission.airport}]</span>
          </span>
        )}
      </p>
      <div>
        {React.isValidElement(children)
          ? React.cloneElement(children as React.ReactElement<any>)
          : children}
      </div>
    </div>
  );
}

interface displayGenAiPermissionProps {
  children: React.ReactNode;
  backgroundColor: string;
  permission: GenAiPermissions;
}

function displayGenAiPermission({
  permission,
  backgroundColor,
  children,
}: displayGenAiPermissionProps) {
  return (
    <div
      className=" w-fit h-10 font-normal text-base leading-6 flex items-center gap-1 border rounded-md p-2 "
      style={{ boxShadow: "0px 1px 2px 0px #1018280D", backgroundColor }}
    >
      <p className="flex items-center gap-2">
        <span className="text-sm leading-5 text-[#747c8a]">
          {permission.app_name}
        </span>
        {permission.use_case && permission.use_case !== "" && (
          <span className="font-semibold text-[#344054] text-sm leading-5">
            {permission.use_case}
          </span>
        )}
        {permission.business_unit && permission.business_unit !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>{permission.business_unit}</span>
          </span>
        )}
        {permission.airport && permission.airport !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>{permission.airport}</span>
          </span>
        )}
        {permission.role && permission.role !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>{permission.role}</span>
          </span>
        )}
      </p>
      <div>
        {React.isValidElement(children)
          ? React.cloneElement(children as React.ReactElement<any>)
          : children}
      </div>
    </div>
  );
}

interface displayDataGovPermissionProps {
  children: React.ReactNode;
  backgroundColor: string;
  permission: DataGovPermissions;
}

function displayDataGovPermission({
  permission,
  backgroundColor,
  children,
}: displayDataGovPermissionProps) {
  return (
    <div
      className=" w-fit h-10 font-normal text-base leading-6 flex items-center gap-1 border rounded-md p-2 "
      style={{ boxShadow: "0px 1px 2px 0px #1018280D", backgroundColor }}
    >
      <p className="flex items-center gap-2">
        {permission.business_unit && permission.business_unit !== "" && (
          <span className="text-sm leading-5 text-[#747c8a]">
            {permission.business_unit}
          </span>
        )}
        {permission.use_case && permission.use_case !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>{permission.use_case}</span>
          </span>
        )}
        {permission.action && permission.action !== "" && (
          <span className=" text-[#747c8a] font-semibold text-sm leading-5">
            <span>{permission.action}</span>
          </span>
        )}
      </p>
      <div>
        {React.isValidElement(children)
          ? React.cloneElement(children as React.ReactElement<any>)
          : children}
      </div>
    </div>
  );
}
