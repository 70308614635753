import { useState, useEffect } from "react";
import { useAuth } from "../providers";

function useTranslationConfig({ sessionId }: { sessionId: string }) {
  const [languages, setLanguages] = useState<{ [key: string]: string }>({});
  const [docTypes, setDocTypes] = useState<string[]>([]);
  const [mapOpt, setMapOpt] = useState<{ [key: string]: string[] }>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { token } = useAuth()



  useEffect(() => {
    if (token === null || sessionId === "") return;
    fetch(`/api/translation/config/${sessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setLanguages(data.languages);
        setDocTypes(data.available_types);
        setMapOpt(data.map_options);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Erreur lors du chargement des configurations de traduction:",
          error
        );
        setError(error);
        setLoading(false);
      });
  }, [sessionId, token]);

  return { languages, docTypes, setDocTypes, mapOpt, setMapOpt, loading, error };
}

interface UseDocumentUploaderProps {
  file: File | null;
  method: string;
  sessionId: string
}

function useDocumentUploader({ file, method, sessionId }: UseDocumentUploaderProps) {
  const [usage, setUsage] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth()

  useEffect(() => {
    const postDocument = async () => {
      if (!file || !method || token === null || sessionId === "") return;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("method", method);
      formData.append("id_session", sessionId);

      try {
        const response = await fetch(
          `/api/translation/document`,
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`
            },
            body: formData,
          }
        );
        const data = await response.json();
        setUsage(data);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de l'envoi du document:", error);
        setError(
          error instanceof Error
            ? error
            : new Error("Une erreur inconnue est survenue")
        );
        setLoading(false);
      }
    };

    postDocument();
  }, [file, method, sessionId, token]);

  return { usage, error, loading };
}

interface TranslateDocumentProps {
  file: File | null;
  sessionId: string;
  selectedLanguage: string;
  method: string;
  token: string | null;
  sourceLanguage: string;
}

const translateDocument = async ({
  sessionId,
  file,
  selectedLanguage,
  method,
  token,
  sourceLanguage
}: TranslateDocumentProps) => {
  if (!file || !token || sessionId === "" || !selectedLanguage || !method) {
    console.log("Tous les champs sont requis pour la traduction.");
    return;
  }
  const formData = new FormData();
  formData.append("file", file);
  formData.append("target_language", selectedLanguage);
  formData.append("method", method);
  formData.append("id_session", sessionId);
  formData.append("source_language", sourceLanguage || "");
  
  try {
    const response = await fetch(`/api/translation`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Erreur de traduction.");
    }

    const blob = await response.blob();
    const contentType = response.headers.get('Content-Type');
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    // Si c'est un zip, on utilise le nom fourni par le serveur
    if (contentType === 'application/zip') {
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition && contentDisposition.includes('filename=')) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          link.setAttribute("download", matches[1].replace(/['"]/g, ''));
        }
      }
    } else {
      // Comportement actuel pour les autres types de fichiers
      link.setAttribute("download", `translated_${file.name}`);
    }

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    //onSuccess();
  } catch (error) {
    //onError(error);
    console.log(error);
  }
};

export { translateDocument, useDocumentUploader, useTranslationConfig }