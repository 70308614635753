import React from "react";

import Cross from "../components/elements/formSvg/Cross";
import { RolePermissions } from "./type/permissionsTypes";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import MiniLoader from "../AdminManagementsUsers/MiniLoader";

interface AddNewPermissionContainerListCopieUserProps {
  copiePermissions: RolePermissions;
  onRemove: (
    type: "dashboard" | "gen_ai" | "admin" | "data_gov",
    index: number
  ) => void;
}

export default function AddNewPermissionContainerListCopieUser({
  copiePermissions,
  onRemove,
}: AddNewPermissionContainerListCopieUserProps) {
  const { loadingSearchCopieUserPerm } = usePermissionsDataAdmin();
  return (
    <div>
      {loadingSearchCopieUserPerm ? (
        <MiniLoader size="60px" />
      ) : (
        <div className="flex mt-2 flex-wrap gap-2 w-[650px] min-h-[88px] p-4 border rounded-lg max-h-[200px] overflow-y-auto">
          {copiePermissions.dashboard.map((permission, index) => (
            <div
              key={index}
              className="bg-[#F2F8FF99] h-10 font-normal text-base leading-6 flex items-center gap-1 border border-[#F2F8FF99] rounded-md p-2 "
              style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            >
              <p className="flex items-center gap-2">
                {permission.dashboard_level !== "" && (
                  <span className="text-sm leading-5 text-[#747c8a]">
                    {permission.dashboard_level}
                  </span>
                )}
                {permission.dashboard !== "" && (
                  <span className="font-semibold text-[#344054] text-sm leading-5">
                    {permission.dashboard}
                  </span>
                )}
                {permission.business_unit !== "" && (
                  <span className=" text-[#747c8a] font-semibold text-sm leading-5">
                    [{permission.business_unit}]
                  </span>
                )}
                {permission.airport !== "" && (
                  <span className=" text-[#747c8a] font-semibold text-sm leading-5">
                    [{permission.airport}]
                  </span>
                )}
              </p>
              <div
                onClick={() => onRemove("dashboard", index)}
                className="cursor-pointer"
              >
                <Cross width="14" height="14" color={"#004289"} />
              </div>
            </div>
          ))}
          {copiePermissions.gen_ai.map((permission, index) => (
            <div
              key={index}
              className="bg-[#F2F8FF99] h-10 font-normal text-base leading-6 flex items-center gap-1 border border-[#F2F8FF99] rounded-md p-2 "
              style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            >
              <p className="flex items-center gap-2">
                <p className="font-semibold text-sm leading-5 text-[#747c8a]">
                  {permission.app_name}
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344054]">
                  {permission.use_case}
                </p>
              </p>
              <div
                onClick={() => onRemove("gen_ai", index)}
                className="cursor-pointer"
              >
                <Cross width="14" height="14" color={"#004289"} />
              </div>
            </div>
          ))}
          {copiePermissions.data_gov.map((permission, index) => (
            <div
              key={index}
              className="bg-[#F2F8FF99] h-10 font-normal text-base leading-6 flex items-center gap-1 border border-[#F2F8FF99] rounded-md p-2 "
              style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            >
              <p className="flex items-center gap-2">
                <p className="font-semibold text-sm leading-5 text-[#747c8a]">
                  {permission.business_unit}
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344054]">
                  {permission.use_case}
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344054]">
                  {permission.action}
                </p>
              </p>
              <div
                onClick={() => onRemove("data_gov", index)}
                className="cursor-pointer"
              >
                <Cross width="14" height="14" color={"#004289"} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
