import { useEffect, useRef, useState } from "react";
import { useChatGPT } from "../providers";
import { ChatModel, ConversationMessage, DataHistory, messageGPT, modelName, SessionData } from "../hooks";
import { AnswerProps, LoaderTextAnswer, TextAnswer, TextAnswerError, Ask, Info } from "./Answer";
import React from "react";
import { TypeToIcon } from "../../../components";
import { UploadFileType } from "../hooks/useMultiDocsConfig";
import { ChatGPTExplore } from "./ChatGPTExplore";
import { LanguageCode } from "../../ChatGPT/hooks";
import { ChatLogo } from "../../../assets/SVGIcon/ChatLogo";

function getIcon(type: ChatModel | "ia_multidocs" | "multimodal" | null): keyof typeof TypeToIcon {
    if (!type)
        return 'IA'
    const upperType = type.toUpperCase() as keyof typeof TypeToIcon;
    return upperType;
}

function convertConversationMessages(messages: ConversationMessage[]): messageGPT[] {
    let result: messageGPT[] = [{
        type: "Info",
        props: {
            type: "IA",
            text: "This chat is secure, all data and files remain strictly internal.",
            typed: false,
            data_type: "text_warning"
        }
    }];
    let lastInfoSwitchIndex: number | null = null;

    for (let i = 0; i < messages.length; i++) {
        const message = messages[i];
        if ('info' in message && i == 0)
            continue;
        let msg_type: string;
        let model_type: keyof typeof TypeToIcon;
        let text: string;
        let loading: boolean = false;
        const preview = message.preview;
        let upload: { filename: string; type: UploadFileType; }[] | undefined = undefined;
        let data_type: "text" | "file_ask" | "file_upload" | "text_info" | "text_switch" | "html" | "multidocs_text" = message.data_type;

        if ('info' in message && message.data_type === 'text_switch') {
            lastInfoSwitchIndex = i;
            continue;  // Skip this message for now, we'll add it later if it's the last in the sequence
        }

        if ('user' in message) {
            if (typeof message.user === 'string') {
                msg_type = "Ask";
                text = message.user;
                model_type = getIcon(message.model);
                loading = false;
                if ((message.data_type === 'file_upload' || message.data_type === 'file_ask') && message.data_name) {
                    upload = message.data_name;
                }
            } else {
                throw new Error("Invalid message: user text must be a string");
            }
        } else if ('info' in message) {
            if (typeof message.info === 'string') {
                msg_type = "Info";
                text = message.info;
                model_type = getIcon(message.model);
                loading = false;
            } else {
                throw new Error("Invalid message: info text must be a string");
            }
        } else if ('assistant' in message) {
            if (typeof message.assistant === 'string') {
                msg_type = "TextAnswer";
                text = message.assistant;
                model_type = getIcon(message.model);
                loading = message.loading == undefined ? false : message.loading;
            } else {
                throw new Error("Invalid message: assistant text must be a string");
            }
        } else if ('error' in message) {
            if (typeof message.error === 'string') {
                msg_type = "TextAnswerError";
                text = message.error;
                model_type = getIcon(message.model);
                loading = false;
            } else {
                throw new Error("Invalid message: error text must be a string");
            }
        } else {
            throw new Error("Invalid message: must contain either user, assistant, info or error text");
        }

        // If we've reached a non-info-switch message and we have a pending info-switch message,
        // add the last info-switch message before adding the current message
        if (lastInfoSwitchIndex !== null && message.data_type !== 'text_switch') {
            const lastInfoSwitch = messages[lastInfoSwitchIndex];
            result.push({
                type: "Info",
                props: {
                    type: getIcon(lastInfoSwitch.model),
                    text: 'info' in lastInfoSwitch && typeof lastInfoSwitch.info === 'string' ? lastInfoSwitch.info : "",
                    typed: false,
                    loading: false,
                    data_type: 'text_switch'
                }
            });
            lastInfoSwitchIndex = null;
        }

        result.push({
            type: msg_type,
            props: {
                type: model_type,
                text,
                typed: false,
                loading,
                upload,
                data_type,
                preview
            }
        });
    }

    // If the last message was an info-switch, add it
    if (lastInfoSwitchIndex !== null) {
        const lastInfoSwitch = messages[lastInfoSwitchIndex];
        result.push({
            type: "Info",
            props: {
                type: getIcon(lastInfoSwitch.model),
                text: 'info' in lastInfoSwitch && typeof lastInfoSwitch.info === 'string' ? lastInfoSwitch.info : "",
                typed: false,
                loading: false,
                data_type: 'text_switch'
            }
        });
    }

    return result;
}

export function getHistoryById(data: DataHistory, session_id: string): messageGPT[] {
    for (const key in data) {
        if (data[key].session_id === session_id) {
            return convertConversationMessages(data[key].conversation);
        }
    }
    return [];  // Retourne null si aucun objet avec le session_id spécifié n'est trouvé
}



export const ChatGPTTalk = ({ }: {}) => {
    const [lastTextAnswerIndex, setLastTextAnswerIndex] = useState<number | null>(null);
    const {
        gptModel,
        chatGPTAsk,
        gptConfig,
        gptGesture,
        homeStatus,
        gptInput,
        chatStatus
    } = useChatGPT()

    const h = getHistoryById(gptConfig.securedGPTHistory, gptConfig.selectedChatId)

    useEffect(() => {
        if (!homeStatus)
            gptGesture.scrollToEndOfMessages()
    }, [gptConfig.selectedChatId]);


    useEffect(() => {
        if (h.length === 0) return; // Avoid errors if the array is empty

        const lastMessageTextLength = h[h.length - 1].props.text.length;
        if (!gptGesture.isAtBottom) return;
        // Your effect logic here
        gptGesture.scrollToEndOfMessages();

        // You can perform any actions you want here

    }, [h.length, h.length > 0 ? h[h.length - 1].props.text.length : 0]);

    const descriptionStyle = "text-text-primary-light opacity-75"

    type QuestionTranslation = {
        [key in LanguageCode]: string;
    };
    const titleTranslations: QuestionTranslation = {
        "FR": "Comment puis-je vous aider aujourd'hui ?",
        "EN": "How can I help you today?",
        "PT": "Como posso ajudá-lo(a) hoje?",
        "ES": "¿Cómo puedo ayudarte hoy?",
        "JA": "今日はどのようにお手伝いしましょうか？"
    }

    const questionTranslations: QuestionTranslation[] = [
        {
            EN: "What is the AI?",
            FR: "Qu'est-ce que l'IA ?",
            PT: "O que é a IA?",
            ES: "¿Qué es la IA?",
            JA: "AIとは何ですか？"
        },
        {
            EN: "What is VINCI's core business?",
            FR: "Quel est le cœur de métier de VINCI ?",
            PT: "Qual é o negócio principal da VINCI?",
            ES: "¿Cuál es el negocio principal de VINCI?",
            JA: "VINCIの主な事業は何ですか？"
        },
        {
            EN: "Help me to compose an email to...",
            FR: "Aidez-moi à rédiger un e-mail à...",
            PT: "Ajude-me a compor um email para...",
            ES: "Ayúdame a redactar un correo electrónico a...",
            JA: "...にメールを作成するのを手伝ってください。"
        },
        {
            EN: "Help me to solve this problem: ...",
            FR: "Aidez-moi à résoudre ce problème : ...",
            PT: "Ajude-me a resolver este problema: ...",
            ES: "Ayúdame a resolver este problema: ...",
            JA: "この問題を解決するのを手伝ってください：..."
        }
    ];
    const modelIcon: any = {
        'gpt': 'GPT_CHAT_ICON_XL',
        'gpt4': 'GPT4_CHAT_ICON_XL',
        'o1': 'GPT4_CHAT_ICON_XL',
        'mistral': 'MISTRAL_CHAT_ICON_XL',
        'gemini': 'GEMINI_CHAT_ICON_XL'
    }

    return (
        <>
            {
                homeStatus ?
                    <ChatGPTExplore />
                    :
                    h && h.length > 0 ?
                        <>
                            {
                                h.map((messageObj, index) => {
                                    const isLastTextAnswer = messageObj.type === 'TextAnswer' && index === h.length - 1;
                                    //const isNewMessageAdded = messages.length > prevMessagesCountRef.current;

                                    let commonProps = {
                                        ...messageObj.props,
                                        typed: isLastTextAnswer && messageObj.props.text.length <= 200,
                                        isLastTextAnswer: isLastTextAnswer
                                    };

                                    if (messageObj.type === 'TextAnswer') {
                                        const textAnswerProps: AnswerProps = {
                                            ...commonProps,
                                            loading: messageObj.props.loading ? messageObj.props.loading : false,
                                        };
                                        return (
                                            <React.Fragment key={index}>
                                                <TextAnswer {...textAnswerProps} />
                                            </React.Fragment>
                                        );
                                    }
                                    else if (messageObj.type === 'TextAnswerError') {
                                        return (
                                            <React.Fragment key={index}>
                                                <TextAnswerError type={modelName[gptModel.selectedChatModel]} />
                                            </React.Fragment>
                                        )

                                    }
                                    else if (messageObj.type === 'Info') {

                                        return chatStatus != "ASSISTANT_OPEN" && (
                                            <React.Fragment key={index}>
                                                <Info {...commonProps} />
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment key={index}>
                                                <Ask {...commonProps} />
                                            </React.Fragment>
                                        );
                                    }
                                })
                            }
                            <div ref={gptGesture.endOfMessagesRef} />
                        </>
                        :
                        <div className="flex grow w-full text-token-text-primary" dir="auto" data-testid="conversation-turn-2" data-scroll-anchor="false">
                            <div className="text-base py-[18px] px-3 md:px-4 m-auto lg:px-1 xl:px-5">
                                <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
                                    <div className="group/conversation-turn relative flex w-full min-w-0 flex-col">
                                        <div className="flex-col gap-1 md:gap-3">
                                            <div className="flex flex-grow flex-col max-w-full">
                                                <div className="container mx-auto px-4 pb-8">
                                                    <div className="mb-4">
                                                        {
                                                            gptModel.chatModelOpt.map((e, i) => {
                                                                return (
                                                                    <span className="flex items-center justify-center">
                                                                        {
                                                                            !gptConfig.mDModeActive && gptModel.selectedChatModel == e.id &&
                                                                            <span className="flex items-center justify-center rounded-full">
                                                                                <ChatLogo icon={modelIcon[e.id]} className={`${e.id != "mistral" && "rounded-full"} mb-2`} />
                                                                            </span>
                                                                        }
                                                                    </span>
                                                                );
                                                            })
                                                        }
                                                        <div className="flex flex-col items-center my-4">
                                                            <h4 className="text-xl font-trendaSemiBold text-text-primary-light">{titleTranslations[gptConfig.languageGPT]}</h4>
                                                        </div>
                                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4">
                                                            {questionTranslations.map((question, i) => (
                                                                <button key={i} onClick={() => gptInput.setText(question[gptConfig.languageGPT])} className="border relative group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal hover:shadow-lg opacity-75 hover:opacity-100">
                                                                    <p className={`font-trendaSemiBold text-text-primary-light`}>{question[gptConfig.languageGPT]}</p>
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            }
        </>
    )
}