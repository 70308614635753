import React from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import ToggleInput from "../components/elements/inputs/ToggleInput";
import MultiOptionUsers from "../components/elements/inputs/MultiOptionUsers";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";
import OptionUserBis from "../components/elements/inputs/OptionUserBis";

interface ElemInsideModalHeaderRoleUserProps {
  handleCloseModal: () => void;
}

export default function ElemInsideModalHeaderRoleUser({
  handleCloseModal,
}: ElemInsideModalHeaderRoleUserProps) {
  const [selectedRole, setSelectedRole] = React.useState<string>("");

  const { rolesPermissions, getIdsVinciByRole } = usePermissionsDataAdmin();

  const handleSelectRole = (value: string) => {
    setSelectedRole(value);
  };

  return (
    <div className="p-4 w-full flex flex-col justify-between h-full">
      <div className="flex flex-col gap-2 w-[387px]">
        <OptionUserBis
          arrayToSelect={rolesPermissions}
          width="387"
          title="Roles"
          handleChangementExt={handleSelectRole}
          valueExt={selectedRole}
        />
        {/* <MultiOptionUsers
          arrayToSelect={rolesPermissions}
          width="387"
          title="Roles"
          handleChangementExt={handleSelectRoles}
          valueExt={selectedRoles}
        /> */}
        {/* <ToggleInput
          width="387"
          height="44"
          title="Type"
          firstTitle="Dashboard"
          secondTitle="GenAI"
          idFirst="first"
          idSecond="second"
          handleToggle={() => {}}
        />
        <OptionUser width="387" title="Role" />
        <OptionUser width="387" title="Name of app" /> */}
      </div>
      <div className="flex gap-2 justify-end w-full">
        <div onClick={() => handleCloseModal()}>
          <ButtonClassic text="Cancel" width="76" height="36" />
        </div>
        <div onClick={() => getIdsVinciByRole(selectedRole)}>
          <ButtonClassic
            text="Search"
            width="76"
            height="36"
            backgroundColor="#004289"
          />
        </div>
      </div>
    </div>
  );
}
