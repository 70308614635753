import React from "react";
import ButtonClassic from "../buttons/ButtonClassic";

interface ToggleOption {
  id: string;
  title: string;
}

interface SegmentToggleProps {
  title: string;
  width: string;
  height: string;
  options: ToggleOption[]; // Tableau d'options avec ID et titre
  handleToggle: (selectedId: string) => void;
}

export default function SegmentedToggle({
  title,
  width,
  height,
  options,
  handleToggle,
}: SegmentToggleProps) {
  const [selected, setSelected] = React.useState(options[0]?.id || "");

  const handleChange = (value: string) => {
    setSelected(value);
    handleToggle(value);
  };

  const sideWidth = parseFloat(width) / options.length;
  const sideHeight = (parseFloat(height) / 100) * 95;

  return (
    <div className="font-inter">
      <h3 className="text-[#272b32] font-medium text-sm leading-4">{title}</h3>
      <div
        className="flex justify-around mt-2 bg-[#f2f2f2] rounded-lg"
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {options.map(({ id, title }) => (
          <label
            key={id}
            htmlFor={id}
            className="relative flex justify-center items-center cursor-pointer"
            style={{ height: `${height}px`, width: `${sideWidth}px` }}
          >
            {selected === id ? (
              <div
                className="rounded-lg"
                style={{ boxShadow: "0px 1px 2px 0px #00000040" }}
              >
                <ButtonClassic
                  width={sideWidth.toString()}
                  height={sideHeight.toString()}
                  text={title}
                />
              </div>
            ) : (
              <div
                className="flex justify-center items-center text-[#344054] cursor-pointer text-sm"
                style={{ height: `${height}px` }}
              >
                <p>{title}</p>
              </div>
            )}
            <input
              id={id}
              className="opacity-0 absolute h-0 w-0"
              name="toggle"
              type="radio"
              checked={selected === id}
              onChange={() => handleChange(id)}
            />
          </label>
        ))}
      </div>
    </div>
  );
}
