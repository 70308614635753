import React from "react";
import { PATH_IMG } from "../../../../../constants/constant";

interface InformationInputProps {
  title: string;
  text: string;
  width: string;
  isIcon: boolean;
}

export default function InformationInput({
  title,
  text,
  width,
  isIcon,
}: InformationInputProps) {
  return (
    <div
      className={`w-[${width}px] h-[70px] font-inter   flex flex-col justify-between `}
    >
      <label className=" h-[26px] text-sm leading-5 font-medium block text-[#667085] mb-1">
        {title}
      </label>

      <div className="relative h-11 bg-[#f9fafb]">
        {isIcon && (
          <div className="absolute inset-y-0 right-3 pl-3 flex items-center cursor-help group">
            <img
              src={`${PATH_IMG}/helpIcon.svg`}
              alt="Help Icon"
              className="cursor-help"
            />
            {/* Tooltip */}
            <div className="absolute bottom-full right-0 mb-2 w-80 p-3 text-sm text-[#667085] bg-[#f9fafb] rounded-md shadow-lg hidden group-hover:block transition-opacity duration-200">
              Please contact the SDH administrator if you experience any issues
              with portal access, authentication, or other technical
              difficulties.
            </div>
          </div>
        )}

        <div className="flex items-center text-[#667085] w-full h-11 pl-4 pr-3 font-normal text-base leading-6 rounded-md shadow-sm border outline-none">
          {text}
        </div>
      </div>
    </div>
  );
}
