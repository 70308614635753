import React from "react";

import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import { deleteUser } from "./api/userApi";
import { PATH_IMG } from "../../../constants/constant";
import { useAuth } from "../../../providers/Auth";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";
import { usersInterface } from "../../../types/adminManagementPages";

interface ModalDeleteUserProps {
  handleModalDeleteUser: () => void;
  handleDeleteUser: (data: usersInterface | undefined) => void;
  title: string;
  isCreateUser: boolean;
  idVinci: string;
}

export default function ModalDeleteUser({
  handleDeleteUser,
  handleModalDeleteUser,
  title,
  isCreateUser,
  idVinci,
}: ModalDeleteUserProps) {
  const { token } = useAuth();
  const { setIsLoading, handleDisplayAlert, setEndLoading } =
    useUserDataAdmin();

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const data = await deleteUser(
        "/user_management/delete_user/",
        idVinci,
        token
      );
      if (data) {
        handleDeleteUser(data.deleted_user);
        handleDisplayAlert("User deleted successfully");
      }
    } catch (error) {
      if (error instanceof Error) {
      } else {
      }
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <div>
      <div className="fixed inset-0  bg-[#344054B2] backdrop-blur-[10px]  z-30 transition-all duration-300"></div>
      <div className="flex flex-col font-inter justify-between fixed w-[400px] h-[303px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gradient-to-br from-blue-50 to-white p-7 z-30 rounded-xl shadow-2xl border border-blue-100 overflow-y-auto overflow-x-hidden">
        <div>
          <img src={`${PATH_IMG}/atention.svg`} alt="#" />
        </div>
        <h3 className="font-semibold text-lg leading-7">Delete user</h3>
        <div className="flex flex-col gap-2 font-normal text-sm leading-5 text[#667085]">
          <p>Do you want to delete following user from the system?</p>
          <p>{title}</p>
        </div>
        <div className="flex justify-items-center gap-3">
          <div onClick={handleModalDeleteUser}>
            <ButtonClassic text="Cancel" width="170" height="44" />
          </div>
          <div
            onClick={() => {
              {
                !isCreateUser && handleDelete();
              }
              handleModalDeleteUser();
            }}
          >
            <ButtonClassic
              text="Delete"
              width="170"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
