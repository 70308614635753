import React from "react";

interface ArrowTurnBackwardProps {
  width: string;
  height: string;
  color?: string;
}

export default function ArrowTurnBackward({
  width,
  height,
  color,
}: ArrowTurnBackwardProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33366 4H10.3337C10.7276 4 11.1177 4.0776 11.4817 4.22836C11.8457 4.37913 12.1764 4.6001 12.455 4.87868C12.7336 5.15726 12.9545 5.48797 13.1053 5.85195C13.2561 6.21593 13.3337 6.60603 13.3337 7C13.3337 7.39397 13.2561 7.78407 13.1053 8.14805C12.9545 8.51203 12.7336 8.84274 12.455 9.12132C12.1764 9.3999 11.8457 9.62087 11.4817 9.77164C11.1177 9.9224 10.7276 10 10.3337 10H2.66699"
        stroke="#A7AAB1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 8C4.66699 8 2.66699 9.47333 2.66699 10C2.66699 10.5267 4.66699 12 4.66699 12"
        stroke="#A7AAB1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
