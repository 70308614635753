const data = [
  {
    name: "TrafficReport",
    users: "2210",
    activeUsers: "70%",
    genAICreditUse: "15421",
    avgEngagementTime: "1m 39s",
    bounceRate: "3.5%",
  },
];

export default data;
