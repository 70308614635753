import React from "react";

import Cross from "../components/elements/formSvg/Cross";
import { GenAiPermissions } from "./type/permissionsTypes";

interface AddNewPermissionContainerListGenAIProps {
  listGenAIPermissions: GenAiPermissions[];
  onRemove: (value: any) => void;
}

export default function AddNewPermissionContainerListGenAI({
  listGenAIPermissions,
  onRemove,
}: AddNewPermissionContainerListGenAIProps) {
  return (
    <div>
      <div className="flex mt-2 flex-wrap gap-2 w-[650px] min-h-[88px] p-4 border rounded-lg">
        {listGenAIPermissions.map((permission, index) => (
          <div
            key={index}
            className="bg-[#F2F8FF99] h-10 font-normal text-base leading-6 flex items-center gap-1 border border-[#F2F8FF99] rounded-md p-2 "
            style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
          >
            <p className="flex items-center gap-2">
              <p className="font-semibold text-sm leading-5 text-[#747c8a]">
                {permission.app_name}
              </p>
              <p className="font-semibold text-sm leading-5 text-[#344054]">
                {permission.use_case}
              </p>
            </p>
            <div onClick={() => onRemove(index)} className="cursor-pointer">
              <Cross width="14" height="14" color={"#004289"} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
