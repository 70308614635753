import React from "react";

import Cross from "../components/elements/formSvg/Cross";
import { DashboardPermissions } from "./type/permissionsTypes";

interface AddNewPermissionContainerListProps {
  listDashboardPermissions: DashboardPermissions[];
  onRemove: (value: any) => void;
}

export default function AddNewPermissionContainerList({
  listDashboardPermissions,
  onRemove,
}: AddNewPermissionContainerListProps) {
  return (
    <div>
      <div className="flex mt-2 flex-wrap gap-2 w-[650px] min-h-[88px] p-4 border rounded-lg">
        {listDashboardPermissions.map((permission, index) => (
          <div
            key={index}
            className="bg-[#F2F8FF99] h-10 font-normal text-base leading-6 flex items-center gap-1 border border-[#F2F8FF99] rounded-md p-2 "
            style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
          >
            <p className="flex items-center gap-2">
              {permission.dashboard_level !== "" && (
                <span className="text-sm leading-5 text-[#747c8a]">
                  {permission.dashboard_level}
                </span>
              )}
              {permission.dashboard !== "" && (
                <span className="font-semibold text-[#344054] text-sm leading-5">
                  {permission.dashboard}
                </span>
              )}
              {permission.business_unit !== "" && (
                <span className=" text-[#747c8a] font-semibold text-sm leading-5">
                  [{permission.business_unit}]
                </span>
              )}
              {permission.airport !== "" && (
                <span className=" text-[#747c8a] font-semibold text-sm leading-5">
                  [{permission.airport}]
                  {/* {permission.airport.map((elem, index) => {
                  if (permission.airport.length === 1) {
                    return <span key={index}>[{elem}]</span>;
                  }
                  if (index === permission.airport.length - 1) {
                    return <span key={index}>,{elem}]</span>;
                  } else if (index > 0) {
                    return <span key={index}>,{elem}</span>;
                  }
                  return <span key={index}>[{elem}</span>;
                })} */}
                </span>
              )}
            </p>
            <div onClick={() => onRemove(index)} className="cursor-pointer">
              <Cross width="14" height="14" color={"#004289"} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
