import { useCallback, useEffect, useRef, useState } from "react";


export interface ChatGPTGestureHook {
    endOfMessagesRef: React.RefObject<HTMLDivElement>;
    scrollToEndOfMessages: () => void;
    isAtBottom: boolean;
    isNearBottom: boolean;
    isMounted: boolean;
    setIsMounted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useChatGPTGesture = (): ChatGPTGestureHook => {

    const endOfMessagesRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [isNearBottom, setIsNearBottom] = useState(true);
    
    // Put in provider this
    const [isMounted, setIsMounted] = useState(false);


    const scrollToEndOfMessages = () => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (!isMounted) return;

        // Définissons une fonction pour trouver et configurer le conteneur de défilement
        const setupScrollListener = () => {

            if (!endOfMessagesRef.current) {
                // Si la référence n'est pas disponible, réessayer après un court délai
                setTimeout(setupScrollListener, 1000);
                return;
            }

            // Fonction pour trouver le premier parent scrollable
            const findScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
                if (!element) return null;

                // Vérifier si l'élément lui-même est scrollable
                const style = window.getComputedStyle(element);
                const isScrollable = style.overflow === 'auto' ||
                    style.overflow === 'scroll' ||
                    style.overflowY === 'auto' ||
                    style.overflowY === 'scroll';

                if (isScrollable && element.scrollHeight > element.clientHeight) {
                    return element;
                }

                // Sinon, vérifier le parent
                return findScrollableParent(element.parentElement);
            };

            // Essayer de trouver le conteneur de défilement
            let container = findScrollableParent(endOfMessagesRef.current);

            // Si aucun conteneur scrollable n'est trouvé, essayer d'utiliser le conteneur principal de l'application
            if (!container) {
                container = document.querySelector('.app-main-container') ||
                    document.querySelector('.scrollable-content') ||
                    document.body; // Fallback sur le body si rien d'autre n'est trouvé
            }


            if (!container) {
                console.warn("Impossible de trouver un conteneur de défilement");
                return;
            }

            const handleScroll = () => {
                if (container === null) return;
                const isAtBottom = container.scrollHeight - container.scrollTop - 2 <= container.clientHeight;
                const isNearBottom = container.scrollHeight - container.scrollTop - 50 <= container.clientHeight;
                setIsAtBottom(isAtBottom);
                setIsNearBottom(isNearBottom);
            };

            // Ajouter l'écouteur d'événement au conteneur
            container.addEventListener('scroll', handleScroll);

            // Stockez une référence au conteneur et à la fonction handleScroll pour le nettoyage
            return { container, handleScroll };
        };

        // Initialiser le listener
        const cleanup = setupScrollListener();

        // Fonction de nettoyage pour useEffect
        return () => {
            if (cleanup && cleanup.container) {
                cleanup.container.removeEventListener('scroll', cleanup.handleScroll);
            }
        };
    }, [isMounted]);

    return { endOfMessagesRef, scrollToEndOfMessages, isAtBottom, isMounted, setIsMounted, isNearBottom };
};

