import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDataGouv } from "../providers/DataGouvProvider";
import { SelectorFileVersion } from "./Selector";
import { ButtonIngestion, ButtonIngestionProps, CustomButton, ErrorButton } from "./ButtonIngestion";
import { FileStatus, FileStatusProps } from "./FileStatus";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { FileData } from "../hooks/useDataIngestion";
import { LinkedFileDropdown, LinkedBudgetDropdown } from "./LinkedFileDropdown";
import { useAuth } from "../../../providers";
import { SVGIcon } from "../../../assets";
import './animation.css'
import { LoaderSpinner } from "./Icons";
import { WarningIcon } from "../icons/Icons";


export const FileChecksTable = () => {
  const { buSelected, ingestionData, loaderIngestionData, useCase } = useDataGouv();
  const [openModalRowId, setOpenModalRowId] = useState<string | null>(null);

  const linkedFilesMap: { [key: string]: FileData[] } = {};
  const fileTypeGroups: { [fileType: string]: FileData[] } = {};
  const fileTypeCount: { [fileType: string]: number } = {};
  let unlinkedFiles: FileData[] = [];

  const buOptions = ingestionData && buSelected ? ingestionData[buSelected] || [] : [];

  if (buOptions.length > 0) {
    buOptions.forEach((file) => {
      if (fileTypeCount[file.file_type]) {
        fileTypeCount[file.file_type]++;
      } else {
        fileTypeCount[file.file_type] = 1;
      }
    });

    buOptions.forEach((file) => {
      if (file.linked) {
        if (!linkedFilesMap[file.linked]) {
          linkedFilesMap[file.linked] = [];
        }
        linkedFilesMap[file.linked].push(file);
      } else {
        unlinkedFiles.push(file);
      }
      if (fileTypeCount[file.file_type] > 1 || (file.file_type === 'CPXFORECAST' && buSelected !== 'ANA')) {
        if (!fileTypeGroups[file.file_type]) {
          fileTypeGroups[file.file_type] = [];
        }
        fileTypeGroups[file.file_type].push(file);
      }
    });

    unlinkedFiles = unlinkedFiles.filter(
      (file) =>
        !Object.keys(fileTypeGroups).includes(file.file_type) &&
        !Object.keys(linkedFilesMap).includes(file.file_type)
    );
  }

  unlinkedFiles = unlinkedFiles.filter(
    (file) =>
      !Object.keys(fileTypeGroups).includes(file.file_type) &&
      !Object.keys(linkedFilesMap).includes(file.file_type)
  );

  const isEmptyData =
    unlinkedFiles.length === 0 &&
    Object.keys(linkedFilesMap).length === 0 &&
    Object.keys(fileTypeGroups).length === 0;

  return (
    <div className={`w-full border border-gray-200 rounded-md`}>
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-[#ECF5FF] text-[#004289]">
            <th className="px-4 py-2 text-left text-sm font-medium">File{' '}Type</th>
            <th className="px-4 py-2 text-left text-sm font-medium">File{' '}Name</th>
            {
              useCase == "Finance" &&
              <th className="px-4 py-2 text-left text-sm font-medium">Version</th>
            }
            <th className="px-4 py-2 text-left text-sm font-medium">Date{' '}Uploaded</th>
            <th className="px-4 py-2 text-left text-sm font-medium">Uploaded{' '}By</th>
            <th className="px-4 py-2 text-left text-sm font-medium">Status</th>
            <th className="text-left text-sm font-medium"></th>
            <th className="text-left text-sm font-medium"></th>
            <th className="text-left text-sm font-medium"></th>
          </tr>
        </thead>
        <tbody>
          {loaderIngestionData ? (
            <tr>
              <td colSpan={9} className="text-center py-4">
                <LoaderSpinner />
              </td>
            </tr>
          ) : isEmptyData ? (
            <tr>
              <td colSpan={9} className="text-center py-4">
                No ingestion data available for {buSelected!} - {useCase}.
              </td>
            </tr>
          ) : (
            <>
              {unlinkedFiles.map((file, index) => (
                <FileRow
                  key={`unlinked-${index}`}
                  fileType={file.file_type}
                  version={file.version}
                  final_filename={file.final_filename}
                  rowId={`unlinked-${file.file_type}-${file.version || ''}-${index}`}
                  openModalRowId={openModalRowId}
                  setOpenModalRowId={setOpenModalRowId}
                  file_id={file.file_id}
                />
              ))}

              {Object.entries(linkedFilesMap).map(([primaryFileType, linkedFiles], index) => (
                <LinkedFileDropdown
                  key={`linked-group-${index}`}
                  linkedFiles={linkedFiles}
                  mainFileName={primaryFileType}
                  openModalRowId={openModalRowId}
                  setOpenModalRowId={setOpenModalRowId}
                  rowId={`linked-${primaryFileType}-${primaryFileType || ''}-${index}`}
                />
              ))}

              {Object.entries(fileTypeGroups).map(([primaryFileType, linkedFiles], index) => (
                <LinkedBudgetDropdown
                  key={`linked-group-${index}`}
                  linkedFiles={linkedFiles}
                  mainFileName={primaryFileType}
                  openModalRowId={openModalRowId}
                  setOpenModalRowId={setOpenModalRowId}
                  rowId={`linked-${primaryFileType}-${primaryFileType || ''}-${index}`}
                />
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

interface FileRowProps {
  fileType: string;
  version?: string;
  isDropdown?: boolean
  isMultiple?: boolean
  fileLinked?: File | null
  final_filename?: string | null
  rowId: string;
  openModalRowId: string | null;
  setOpenModalRowId: (id: string | null) => void;
  file_id?: string
}
export const FileRow: React.FC<FileRowProps> = ({ fileType, version, isDropdown, fileLinked, final_filename, rowId, openModalRowId, setOpenModalRowId, isMultiple, file_id }) => {
  const { buSelected, dateSelected, ingestionData, uploadFileFromDropzone, rejectKPIs, monthYearSelected, confirmFileAfterKpis, loadingDownloads, handleDownload, useCase } = useDataGouv();


  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [isVersioned, setIsVersioned] = useState<boolean>(false);
  const [numVersions, setNumVersions] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isExpandedError, setIsExpandedError] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null)
  const [lineHeight, setLineHeight] = useState<number>(0);
  const [lineHeightKpis, setLineHeightKpis] = useState<number>(0);
  const [loaderDownload, setLoaderDownload] = useState(false)
  const { token } = useAuth()
  // const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});

  const [isLoading, setIsLoading] = useState(false);

  const [blink, setBlink] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLTableRowElement | null>(null);
  const dropdownKpisRef = useRef<HTMLTableRowElement | null>(null);
  const showModal = rowId === openModalRowId;

  const [warning, setWarning] = useState<string[] | null>()

  const currentYear = monthYearSelected?.split('/')[1] ?? ''

  const versionsBudget = ['B0', 'R1', 'R2', 'R3']
  const versionsSCACase = ['KOS', 'PNH']
  const versionsSSACase = ['SSA', 'AMA']

  useEffect(() => {
    if (showModal && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showModal]);

  const handleToggleModal = () => {
    if (showModal) {
      setOpenModalRowId(null);
    } else {
      setOpenModalRowId(rowId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (showModal) {
          setOpenModalRowId(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  useEffect(() => {
    if (showModal && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showModal]);

  const handleReject = async () => {
    rejectKPIs(buSelected!, fileType, version!)
    setFile(null)
    setIsExpanded(false)
    setLineHeightKpis(0)
  }

  const handleDownloadTemplate = async () => {
    setLoaderDownload(true)
    try {
      //console.log("handleDownloadTemplate")
      const response = await fetch(`/api/data_gov/ingestion/template?file_asset=${buSelected}&file_type=${fileType}&uc=${useCase?.toLowerCase()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      // console.log(response)
      if (!response.ok) {
        throw new Error(`Download failed: ${response.statusText}`);
      }
      // console.log(response)
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const contentDisposition = response.headers.get('content-disposition');
      // console.log(contentDisposition)
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
      // console.log(filenameMatch)
      const filename = filenameMatch ? filenameMatch[1] : `template-${buSelected}-${fileType}.xlsx`;

      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
    catch (error) {
      console.log(error)
    }
    finally {
      setLoaderDownload(false)
    }
  };

  const toggleRow = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setWarning(currentFile.check_ref_messages)
  }, [ingestionData])

  const buData = ingestionData![buSelected!] || [];
  const currentFile = buData.find((file) => (file.file_type === fileType) && (!file.version || file.version === version)) || {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
    kpis: null,
    uploaded_by: null,
    date: null,
    check_ref_messages: [],
    tmp_bucket_url: null,
  };

  const handleConfirmKpis = async () => {
    setIsExpanded(false)
    setIsLoading(true);
    try {
      await confirmFileAfterKpis(buSelected!, fileType, version!, monthYearSelected!, currentFile.tmp_bucket_url!);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log(currentFile)
  //   console.log(version)
  // }, [currentFile])

  useEffect(() => {
    if (currentFile.status.loaded === 'running' && !file) {
      setBlink(true);
      const timer = setTimeout(() => setBlink(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [currentFile.status.loaded, file]);



  useEffect(() => {
    if (fileType === 'BUDGET' || (fileType === 'CPXFORECAST' && buSelected !== 'ANA') || fileType === 'CAPEX_FORECAST' || ((buSelected === "SCA" || buSelected === "SSA") && fileType === "RECEIVABLES")) {
      setIsVersioned(true);
      setNumVersions(version!);
    } else {
      setIsVersioned(false);
    }
  }, [fileType, currentFile]);

  useEffect(() => {
    if (file) {
      handleConfirmClick()
    }
  }, [file])

  // useEffect(()=>{
  //   if(fileLinked){
  //     if (!buSelected || !fileType) return;
  //     if (isVersioned) {
  //         uploadFileFromDropzone(buSelected, fileType, fileLinked!, version);
  //     } else {
  //         uploadFileFromDropzone(buSelected, fileType, fileLinked!);
  //     }
  //   }
  // },[fileLinked])

  useEffect(() => {
    if (dropdownRef.current && (isExpandedError)) {
      const scrollableContentHeight = dropdownRef.current.scrollHeight;
      setLineHeight(scrollableContentHeight);
    }
  }, [isExpandedError]);

  useEffect(() => {
    if (dropdownKpisRef.current && (isExpanded)) {
      const scrollableContentHeight = dropdownKpisRef.current.scrollHeight;
      setLineHeightKpis(scrollableContentHeight);
    }
  }, [isExpanded]);

  const handleConfirmClick = () => {
    if (!buSelected || !fileType) return;

    if (isVersioned) {
      uploadFileFromDropzone(buSelected, fileType, file!, version);
    } else {
      uploadFileFromDropzone(buSelected, fileType, file!);
    }
  };
  const displayKpis = currentFile.kpis || {}

  return (
    <>
      <tr className={`transition-all duration-300 overflow-x-scroll border-t ${blink ? 'blink-border' : ''}`}>
        <td className={` ${isDropdown ? 'px-8' : 'px-4'} py-1.5 text-sm text-gray-800`}>{fileType}</td>
        <td className="text-xs text-gray-800 flex flex-row items-center">
          <div className="flex items-center justify-center h-full py-2 mt-1">
            {final_filename ?
              final_filename.substring(0, 15).replaceAll(" ", "\u00A0") + "..."
              :
              file ?
                file.name.substring(0, 15).replaceAll(" ", "\u00A0") + "..."
                :
                ""
            }
          </div>
          {file && currentFile.status.loaded !== "finished" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={() => setFile(null)}
              className="cursor-pointer"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          )}
        </td>
        {
          useCase == "Finance" &&
          <td className="px-4 py-1.5 text-sm text-gray-800">
            {isVersioned && (
              <SelectorFileVersion
                buName={buSelected!}
                year={currentYear!}
                disabled={true}
                onChange={() => { }}
                version={version}
                versions={

                  buSelected! === 'SCA' && fileType === 'RECEIVABLES' ?
                    versionsSCACase :
                    (
                      buSelected! === 'SSA' && fileType === 'RECEIVABLES' ?
                        versionsSSACase
                        : versionsBudget
                    )
                }
              />
            )}
          </td>
        }

        <td className="px-4 py-1.5 text-xs text-gray-800">{currentFile.date}</td>
        <td className="px-4 py-1.5 text-xs text-gray-800">{currentFile.uploaded_by?.toLocaleUpperCase()}</td>
        <td className="px-4 py-1.5 text-sm text-gray-800">
          <FileStatus status={currentFile.status} />
        </td>

        <td className="px-4 py-1.5 text-sm text-gray-800">
          {isDropdown && !isMultiple && (currentFile.status.loaded === 'finished' && currentFile.status.checkfile === 'finished') && <ButtonIngestion onClick={() => { setIsExpanded(!isExpanded); if (!isExpanded) setLineHeightKpis(0) }} isValidatingKpis={isLoading} status={currentFile.status} setFile={setFile} />}
          {!isDropdown && !isMultiple && <ButtonIngestion onClick={() => { setIsExpanded(!isExpanded); if (!isExpanded) setLineHeightKpis(0) }} isValidatingKpis={isLoading} status={currentFile.status} setFile={setFile} />}
          {isDropdown && isMultiple && <ButtonIngestion onClick={() => { setIsExpanded(!isExpanded); if (!isExpanded) setLineHeightKpis(0) }} isValidatingKpis={isLoading} status={currentFile.status} setFile={setFile} />}
        </td>
        {(['loaded', 'checkfile', 'checkKpis', 'processed'] as Array<keyof typeof currentFile.status>).some(
          (key) => currentFile.status[key] === 'error'
        ) && (
            <td className="px-4 py-1.5 text-sm text-gray-800">
              <ErrorButton onClick={() => { setIsExpandedError(!isExpandedError); if (!isExpanded) setLineHeight(0) }} isExpanded={isExpandedError} />
            </td>
          )}
        {!(['loaded', 'checkfile', 'checkKpis', 'processed'] as Array<keyof typeof currentFile.status>).some(
          (key) => currentFile.status[key] === 'error'
        ) && <td className="px-4 py-1.5 text-sm text-gray-800"></td>}
        {!isDropdown && (useCase == "Finance" || (currentFile.url && currentFile.url?.length > 0)) &&
          <td className="relative px-4 py-1.5 text-sm text-gray-800">
            <div className="flex flex-col items-center justify-center bg-[#EEEEEE] rounded-md h-[33px] w-[33px] p-1 gap-1 cursor-pointer" onClick={handleToggleModal}>
              <div className="rounded-full w-1 h-1 bg-[#686868]" />
              <div className="rounded-full w-1 h-1 bg-[#686868]" />
              <div className="rounded-full w-1 h-1 bg-[#686868]" />
              {showModal &&
                <div style={{ zIndex: 9999 }} ref={modalRef} className="pb-2 absolute flex justify-start border-2 border-[#F6F6F6] flex-col items-start top-[40px] shadow-sm right-5 bg-white rounded-xl z-9999 w-[250px] py-3 px-2">
                  <div className="hover:bg-[#EEEEEE] flex flex-row gap-3 w-full items-center cursor-pointer rounded-xl py-2 px-3" onClick={handleDownloadTemplate}>
                    <SVGIcon icon="FOLDER_DOWNLOAD_ICON" />
                    <p className="w-full font-medium">Download Template</p>
                  </div>
                  {currentFile.url && currentFile.url?.length > 0 &&
                    <div className="hover:bg-[#EEEEEE] py-2 px-3 rounded-xl flex flex-row gap-3 w-full justify-start items-center cursor-pointer" onClick={() => handleDownload(currentFile.url!)}>
                      <SVGIcon icon="FOLDER_DOWNLOAD_ICON" />
                      <p className="w-full font-medium">Download File</p>
                      {loadingDownloads[currentFile.url] === true && <LoaderSpinner />}
                    </div>
                  }
                </div>
              }
            </div>
          </td>
        }
      </tr>
      {isExpandedError && currentFile.error &&
        <tr className="bg-gray-100" ref={dropdownRef} >
          <td colSpan={9} className="">
            <div className="flex flex-row gap-10 w-full justify-start items-start px-6 pb-2">
              <div className="relative">
                <div className="w-[2px] bg-gray-400" style={{ height: `${lineHeight}px` }}></div>
                <div className="absolute  bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full w-2 h-2"></div>
              </div>
              <div className="flex flex-col items-center justify-start no-scrollbar gap-1">
                <p className="text-red-500 text-sm w-full underline">{currentFile.error.title}</p>
                <p className="text-red-500 text-sm w-full">
                  <div
                    dangerouslySetInnerHTML={{ __html: currentFile.error.description }}
                    className="text-justify text-sm"
                  />
                </p>
              </div>
            </div>
          </td>
        </tr>
      }
      {isExpanded && currentFile.status.checkKpis === 'running' && Object.keys(displayKpis).length > 0 && (
        <tr className="bg-gray-100" ref={dropdownKpisRef}>
          <td colSpan={9} className="px-4 pb-6">
            <div className="flex flex-row gap-10 w-full overflow-scroll">
              <div className="flex items-center justify-center">
                <div className="relative ml-2">
                  <div className="w-[2px] h-[240px] bg-gray-400" style={{ height: `${lineHeightKpis}px` }}></div>
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-gray-500 rounded-full w-2 h-2"></div>
                </div>
              </div>
              <div className="flex flex-row text-sm gap-4 text-[#686868] w-full">
                <div className="flex flex-col w-full gap-1 mt-2">
                  {warning && warning.length > 0 && (
                    <div className="text-[#f78000] flex flex-col gap-1 mb-5 max-h-[200px] overflow-y-scroll overflow-hidden no-scrollbar">
                      <div className="flex items-center text-md gap-2">
                        <WarningIcon />
                        <p className="font-bold text-md underline">Important Notice :</p>
                      </div>
                      {warning?.map((message: string, index: number) => (
                        <p className="text-sm" key={index} dangerouslySetInnerHTML={{ __html: message }} />
                      ))}
                    </div>
                  )}
                  <p className="font-bold text-black">KPIs</p>
                  <div className={`flex flex-wrap w-full gap-3`}>
                    {Object.entries(displayKpis).map(([kpiKey, kpiData], index) => {
                      const data = kpiData as Array<Array<string | number>>;

                      return (
                        <div key={`${kpiKey}-${index}`} className="w-full">
                          <p className="font-bold text-black underline mb-2">{kpiKey.replace('_', ' ').toUpperCase()}</p>
                          <table className="table-auto w-full border-collapse">
                            <thead>
                              <tr>
                                {data[0].map((header, headerIndex) =>
                                  typeof header === 'string' ? (
                                    <th key={headerIndex} className="border px-4 py-2 text-left">
                                      {header}
                                    </th>
                                  ) : null
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {data.slice(1).map((row: (string | number)[], rowIndex: number) => (
                                <tr key={rowIndex}>
                                  {row.map((cell: string | number, cellIndex: number) => (
                                    <td key={cellIndex} className="border px-4 py-2">
                                      {cell}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex gap-4 flex-col w-[70%] items-center justify-center">
                <CustomButton text="Validate KPIs and process file for ingestion" onClick={handleConfirmKpis} width="w-full" />
                <CustomButton text="Reject KPIs" onClick={handleReject} width="w-full" />
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}