import { useEffect, useState } from "react";
import FileDropzone from "../../DocumentTranslator/FileDropzone";
import { SelectOptions } from "../../DocumentTranslator/SelectedOptions";
import { translateDocument, useDocumentUploader, useTranslationConfig } from "../../../hooks/documentTranslator";
import { useSessionId } from "../../../hooks";
import { useAuth, useCredit } from "../../../providers";

export const ChatGPTTranslator = ({ }) => {
    const [file, setFile] = useState<File | null>(null);
    const [method, setMethod] = useState<string>("");
    const [loaderTranslation, setLoaderTransaltion] = useState<boolean>(false)
    const sessionId = useSessionId(`/api/translation/clear_session`);
    const { token } = useAuth()

    const {
        loading: configLoading,
        languages,
        docTypes,
        mapOpt,
        setMapOpt,
        setDocTypes,
    } = useTranslationConfig({ sessionId });
    const {
        loading: documentUploaderLoading,
        usage,
        error,
    } = useDocumentUploader({ file, method, sessionId });

    const [fileName, setFileName] = useState<string>("");
    const [selectedLanguage, setSelectedLanguage] = useState<string>("");
    const [sourceLanguage, setSourceLanguage] = useState<string>("");
    const [availableOptions, setAvailableOptions] = useState<string[]>([]);
    const [price, setPrice] = useState<number | null>(null);
    const [errorTranslation, setErrorTranslation] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(0)
    const { remainingCredit, setRemainingCredit } = useCredit()

    useEffect(() => {
        if (mapOpt && Object.entries(mapOpt).length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
            const filteredMapOpt: { [key: string]: string[] } = Object.keys(mapOpt)
                .filter(key => key === 'pdf' || key === 'pptx' || key === 'docx')
                .reduce((obj, key) => {
                    obj[key] = mapOpt[key]?.filter(value => value === 'GoogleTranslate') ?? [];
                    return obj;
                }, {} as { [key: string]: string[] });

            if (JSON.stringify(filteredMapOpt) !== JSON.stringify(mapOpt)) {
                setMapOpt(filteredMapOpt);
            }
        }
    }, [mapOpt, remainingCredit]);

    useEffect(() => {
        if (docTypes && docTypes.length > 0 && typeof remainingCredit === 'number' && remainingCredit <= 0) {
            const filteredDocTypes = docTypes.filter(type => type !== 'pdf');
            if (JSON.stringify(filteredDocTypes) !== JSON.stringify(docTypes)) {
                setDocTypes(filteredDocTypes);
            }
        }
    }, [docTypes])

    useEffect(() => {
        if (usage) {
            setPrice(usage['usage']);
            if (method != "ChatGPT") {
                setPageNumber(usage['pages'])
            } else {
                setPageNumber(0)
            }
        }
    }, [usage]);

    useEffect(() => {
        setMethod("");
        setPrice(null);
        setPageNumber(0)
    }, [file]);


    const initiateTranslation = async () => {
        if (typeof remainingCredit === 'number' && remainingCredit <= 0 && method !== 'GoogleTranslate') return
        setLoaderTransaltion(true)
        try {
            await translateDocument({ file, sessionId, selectedLanguage, method, token, sourceLanguage });
            if (price && remainingCredit) {
                const newCredit = remainingCredit - price
                setRemainingCredit(newCredit)
            }
        } catch (error) {
            console.log(error)
        }
        setLoaderTransaltion(false)
    };

    return (
        <div className="py-2">
            <FileDropzone
                setFile={setFile}
                setFileName={setFileName}
                setAvailableOptions={setAvailableOptions}
                mapOpt={mapOpt}
                fileName={fileName}
                docTypes={docTypes}
            />
            {fileName != "" && availableOptions.length > 0 && (
                <SelectOptions
                    defaultValue="Select a method"
                    options={availableOptions}
                    renderOption={(method) => ({ value: method, label: method })}
                    selectedValue={method}
                    onChange={(e) => setMethod(e.target.value)}
                />

            )}
            {
                method == "GoogleTranslate" &&
                <SelectOptions
                    defaultValue="Select original language"
                    options={Object.entries(languages)}
                    renderOption={(language) => ({
                        value: language[0],
                        label: language[1],
                    })}
                    selectedValue={sourceLanguage}
                    onChange={(e) => setSourceLanguage(e.target.value)}
                />
            }
            {method != "" &&
                <SelectOptions
                    defaultValue="Select translation language"
                    options={Object.entries(languages)}
                    renderOption={(language) => ({
                        value: language[0],
                        label: language[1],
                    })}
                    selectedValue={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                />
            }
            {method != "" && selectedLanguage != "" && fileName != "" &&
                <button
                    className={`mt-4 w-full ${loaderTranslation ? 'bg-gray-300 hover:bg-gray-300 border-gray-200 text-gray-500' : 'bg-text-primary-light hover:bg-background-light border hover:text-text-primary-light hover:border-text-primary-light text-white'} py-2 px-4 rounded`}
                    onClick={initiateTranslation}
                    disabled={loaderTranslation || errorTranslation}
                >
                    {errorTranslation ? "Too much pages in file" : (loaderTranslation ? "Loading..." : `Translate ${price != null ? " | " + Math.round(price) + " credit" : ""}`)}
                </button>
            }
        </div>
    )
}