import React, { useEffect } from "react";
import { useState } from "react";

import Cross from "../formSvg/Cross";
import ButtonSvgToText from "../buttons/ButtonSvgText";
import PermissionElement from "../../../AdminManagementPermissions/PermissionElement";
import SearchInput from "../inputs/SearchInput";
import ArrowTurnBackward from "../formSvg/ArrowTurnBackward";
import { PATH_IMG } from "../../../../../constants/constant";
import { usePermissionsDataAdmin } from "../../../AdminManagementPermissions/context/PermissionsDataAdminProvider";
import { RolePermissions } from "../../../AdminManagementPermissions/type/permissionsTypes";
import {
  sortByAlphabeticalOrder,
  sortByNumericOrder,
} from "../../../../../utils/sortUtils";
import { set } from "date-fns";

type UsersDataInPermission = {
  idVINCI: string;
  upn: string;
  rolePermissionsToAdd: RolePermissions;
  rolePermissionsToDelete: RolePermissions;
  numberOfPermissionsChanged: number;
};

interface DataPermissionsRevisionsProps {
  dataPermissions: {
    idVINCI: string;
    upn: string;
    numbersOfPermissions: number;
  }[];
  currentPage: number;
}

export default function TableRevisionPermission({
  dataPermissions,
  currentPage,
}: DataPermissionsRevisionsProps) {
  const [selectedRowId, setSelectedRowId] = useState("");
  const [selectUpn, setSelectUpn] = useState("");
  const [addpermissionsSelected, setAddPermissionsSelected] =
    useState<RolePermissions>();
  const [deletePermissionsSelected, setDeletePermissionsSelected] =
    useState<RolePermissions>();

  const { permissionsToDelete, permissionsToAdd, usersInAppDirectory } =
    usePermissionsDataAdmin();

  const [sortDataPermissions, setSortDataPermissions] = useState(
    sortByAlphabeticalOrder(dataPermissions, "upn", "asc")
  );

  const [arrowDownDown, setArrowDownDown] = useState({
    usersUpdate: true,
    numbersOfPermissions: false,
  });

  const handleArrowDown = (index: number) => {
    if (index === 1) {
      console.log("je passe par ici");
      if (!arrowDownDown.usersUpdate) {
        setSortDataPermissions(
          sortByAlphabeticalOrder(dataPermissions, "upn", "asc")
        );
        setArrowDownDown({ usersUpdate: true, numbersOfPermissions: false });
      } else {
        setSortDataPermissions(
          sortByAlphabeticalOrder(dataPermissions, "upn", "desc")
        );
        setArrowDownDown({ usersUpdate: false, numbersOfPermissions: false });
      }
    }
    if (index === 2) {
      if (!arrowDownDown.numbersOfPermissions) {
        setSortDataPermissions(
          sortByNumericOrder(dataPermissions, "numbersOfPermissions", "desc")
        );
        setArrowDownDown({ usersUpdate: false, numbersOfPermissions: true });
      } else {
        setSortDataPermissions(
          sortByNumericOrder(dataPermissions, "numbersOfPermissions", "asc")
        );
        setArrowDownDown({ usersUpdate: false, numbersOfPermissions: false });
      }
    }
  };

  const handleRowClick = (id: string, upn: string) => {
    if (selectedRowId === id) {
      setSelectedRowId("");
      setSelectUpn("");
    } else {
      setSelectedRowId(id);
      setSelectUpn(upn);
      const permissionsAdd = permissionsToAdd.get(id);
      const permissionsDelete = permissionsToDelete.get(id);
      setAddPermissionsSelected(permissionsAdd);
      setDeletePermissionsSelected(permissionsDelete);
    }
  };

  const startIndex = (currentPage - 1) * 9;
  const paginatedData = sortDataPermissions.slice(startIndex, startIndex + 9);

  return (
    <div className="w-full min-h-[415px]">
      <table className="w-[100%] table-fixed font-inter">
        <thead className="sticky top-0 bg-[#f9fafb] z-10">
          <tr className="text-left text-xs leading-5 text-[#667085]">
            <th
              className="w-1/3 px-2 py-2 font-medium text-xs leading-5 cursor-pointer text-center"
              onClick={() => handleArrowDown(1)}
            >
              <span>User(s) updated</span>
              <img
                className="pl-2 inline-block"
                src={
                  arrowDownDown.usersUpdate
                    ? PATH_IMG + "/arrowDown.svg"
                    : PATH_IMG + "/arrowUp.svg"
                }
                alt="#"
              />
            </th>
            <th
              className="w-1/3 px-2 py-2 font-medium text-xs leading-5 cursor-pointer text-center"
              onClick={() => handleArrowDown(2)}
            >
              Number(s) of permissions changed
              <img
                className="inline-block"
                src={
                  arrowDownDown.numbersOfPermissions
                    ? PATH_IMG + "/arrowDown.svg"
                    : PATH_IMG + "/arrowUp.svg"
                }
                alt="#"
              />
            </th>

            <th className="w-1/3 px-2 py-2 font-medium text-xs leading-5 cursor-pointer"></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((user) => {
            if (selectedRowId === user.idVINCI || selectedRowId === "") {
              return (
                <tr
                  key={user.idVINCI}
                  className="h-[40px] text-left text-xs leading-5 border-b"
                >
                  <td className="px-2 py-1 truncate font-normal text-sm leading-5 text-[#747c8a] text-center">
                    {user.upn}
                  </td>
                  <td className="px-2 py-1 truncate font-medium text-sm leading-5 text-[#101828] text-center">
                    {user.numbersOfPermissions}
                  </td>
                  <td className="flex justify-center items-center px-2 py-[5px]">
                    <div onClick={() => handleRowClick(user.idVINCI, user.upn)}>
                      {user.idVINCI === selectedRowId ? (
                        <ButtonSvgToText
                          width="71"
                          height="28"
                          text="Hide"
                          logo={`${PATH_IMG}/chevronUp.svg`}
                          fontSize="12"
                        />
                      ) : (
                        <ButtonSvgToText
                          width="84"
                          height="30"
                          text="Details"
                          logo={`${PATH_IMG}/chevronDown.svg`}
                          fontSize="12"
                          gap="5"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            }
            return null;
          })}
          {selectedRowId && (
            <tr className="w-full font-inter ">
              <td colSpan={5} className="px-5 ">
                <div className="h-[340px] w-[100%] overflow-y-auto">
                  {/* <SearchInput
                    width="100%"
                    handleSearchQuery={(query) => console.log(query)}
                  /> */}
                  <p className="mt-4 text-[#747C8A] flex font-normal text-base leading-6 ">
                    {selectUpn}
                    <span className="font-medium text-[#2B60A3] ml-1">
                      added permissions
                    </span>
                  </p>
                  <div className="flex flex-wrap gap-2 mt-2 px-5">
                    {addpermissionsSelected && [
                      ...addpermissionsSelected.dashboard.map(
                        (permissionsToAdd) => (
                          <PermissionElement
                            key={`dashboard-${permissionsToAdd}`}
                            backgroundColor="#F2F6FB"
                            permission={permissionsToAdd}
                            type="dashboard"
                          >
                            <Cross width="14" height="14" color={"#004289"} />
                          </PermissionElement>
                        )
                      ),
                      ...addpermissionsSelected.gen_ai.map(
                        (permissionsToAdd) => (
                          <PermissionElement
                            key={`genai-${permissionsToAdd}`}
                            backgroundColor="#F2F6FB"
                            permission={permissionsToAdd}
                            type="genai"
                          >
                            <Cross width="14" height="14" color={"#004289"} />
                          </PermissionElement>
                        )
                      ),
                      ...addpermissionsSelected.data_gov.map(
                        (permissionsToAdd) => (
                          <PermissionElement
                            key={`datagov-${permissionsToAdd}`}
                            backgroundColor="#F2F6FB"
                            permission={permissionsToAdd}
                            type="datagov"
                          >
                            <Cross width="14" height="14" color={"#004289"} />
                          </PermissionElement>
                        )
                      ),
                    ]}
                    {/* <PermissionElement backgroundColor="#F2F6FB" >
                      <Cross width="14" height="14" color={"#004289"} />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#F2F6FB">
                      <Cross width="14" height="14" color={"#004289"} />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#F2F6FB">
                      <Cross width="14" height="14" color={"#004289"} />
                    </PermissionElement> */}
                  </div>
                  <p className=" mt-4 text-[#747C8A] flex font-normal text-base leading-6">
                    {selectUpn}
                    <span className="font-medium text-[#C14242] ml-1">
                      removed permissions
                    </span>
                  </p>

                  <div className=" mt-2 flex flex-wrap gap-2 px-5">
                    {deletePermissionsSelected && [
                      ...deletePermissionsSelected.dashboard.map(
                        (permissionsToDelete) => (
                          <PermissionElement
                            key={`dashboard-${permissionsToDelete}`}
                            backgroundColor="#FFF8F8"
                            permission={permissionsToDelete}
                            type="dashboard"
                          >
                            <ArrowTurnBackward height="16" width="16" />
                          </PermissionElement>
                        )
                      ),
                      ...deletePermissionsSelected.gen_ai.map(
                        (permissionsToDelete) => (
                          <PermissionElement
                            key={`genai-${permissionsToDelete}`}
                            backgroundColor="#FFF8F8"
                            permission={permissionsToDelete}
                            type="genai"
                          >
                            <ArrowTurnBackward height="16" width="16" />
                          </PermissionElement>
                        )
                      ),
                      ...deletePermissionsSelected.data_gov.map(
                        (permissionsToDelete) => (
                          <PermissionElement
                            key={`datagov-${permissionsToDelete}`}
                            backgroundColor="#FFF8F8"
                            permission={permissionsToDelete}
                            type="datagov"
                          >
                            <ArrowTurnBackward height="16" width="16" />
                          </PermissionElement>
                        )
                      ),
                    ]}
                    {/* <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement>
                    <PermissionElement backgroundColor="#FFF8F8">
                      <ArrowTurnBackward height="16" width="16" />
                    </PermissionElement> */}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
