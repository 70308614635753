import React from "react";

interface StepCardProps {
  logo: string;
  text: string;
  num: string;
}

export default function StepCard({ logo, text, num }: StepCardProps) {
  return (
    <div className="w-[243px] h-[43px] font-inter">
      <div className="flex justify-center">
        <div className=" bg-[#004289] rounded-full w-5 h-5 flex justify-center items-center">
          <p className=" text-white font-medium text-xs leading-4">{num}</p>
        </div>
        <img src={logo} alt="" />
      </div>
      <p className="font-normal text-sm leading-5 text-center text-[#344054]">
        {text}
      </p>
    </div>
  );
}
