import DOMPurify from "dompurify";
import { ChatLogo, ChatLogoType } from "../../assets/SVGIcon/ChatLogo";
import { useEffect, useState, useRef } from "react";
import { useTypingEffect } from "../../hooks/typingEffect";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { ButtonRedirectSDH } from "../ButtonRedirectSDH";
import { SVGIcon } from "../../assets";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import Plot from 'react-plotly.js';
import DataTable from 'react-data-table-component';
import { defaultStyles, FileIcon } from "react-file-icon";

interface TypeToIconProps {
    IA: ChatLogoType;
    GPT: ChatLogoType;
    GPT4: ChatLogoType;
    O1: ChatLogoType;
    MISTRAL: ChatLogoType;
    GEMINI: ChatLogoType;
    IA_HOME: ChatLogoType;
    IA_MULTIDOCS: ChatLogoType;
    IA_OAG: ChatLogoType;
    MULTIMODAL: ChatLogoType;
}

export const TypeToIcon: TypeToIconProps = {
    IA: 'IA_CHAT_ICON',
    GPT: 'GPT_CHAT_ICON',
    GPT4: 'GPT4_CHAT_ICON',
    O1: 'GPT4_CHAT_ICON',
    MISTRAL: 'MISTRAL_CHAT_ICON',
    GEMINI: 'GEMINI_CHAT_ICON',
    IA_HOME: 'IA_CHAT_HOME_ICON',
    IA_MULTIDOCS: 'IA_CHAT_MULTIDOC',
    IA_OAG: 'IA_CHAT_OAG',
    MULTIMODAL: 'IA_CHAT_MULTIDOC'
}

export interface AnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    loading?: boolean;
    graph?: string | null;
    table?: string | null;
    pluginActive?: string;
}

export interface AnswerPropsError {
    type: keyof typeof TypeToIcon
}

export interface HTMLAnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    pluginActive: string;
}

interface LoaderTextAnswerProps {
    type: keyof typeof TypeToIcon;
}

const createMarkup = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
};

type ToolIdMapping = {
    [key: string]: number[];
};

const toolIdMapping: ToolIdMapping = {
    get_traffic_report: [11, 5],
    get_finance_report: [13, 6],
    get_news: [],
    competition_analysis: [11, 5],
    predict_analyst: [11, 5],
};

const renderTable = (table: string) => {
    if (!table || table.length === 0 || table == "{}") return null;

    const tableObj = JSON.parse(table);

    // Flatten the data into an array of objects
    const flattenedData = Object.keys(tableObj).map(key => {
        const row = { category: key, ...tableObj[key] };
        return row;
    });

    // Dynamically generate columns based on the keys of the first row
    const columns = Object.keys(flattenedData[0]).map(key => {
        let obj = {
            name: key.toUpperCase(),
            selector: (row: { [x: string]: any; }) => row[key],
            sortable: true,
            grow: 0
        }
        if (key.toUpperCase() == "CATEGORY") {
            obj = {...obj, grow:100}
        }
        return obj
    });



    return (
        <div className="flex flex-col w-full justify-center items-center">
            <div className="w-11/12">
                <DataTable columns={columns} data={flattenedData} />
            </div>
            <hr className="my-4 h-2 w-11/12 " />
        </div>
    )
};


export const TextAnswer: React.FC<AnswerProps> = ({ type, text, typed, table, graph, loading, pluginActive }) => {
    const icon = TypeToIcon[type]
    const [typedText, isLoading] = useTypingEffect(text, 10)
    const ids = useRef<number[]>(toolIdMapping[pluginActive!] || []);
    const [linksLoading, setLinksLoading] = useState(true);

    // useEffect(()=>{
    //     if(pluginActive){
    //         setIds(toolIdMapping[pluginActive] || [])
    //     }
    // },[pluginActive])
    useEffect(() => {
        const timer = setTimeout(() => {
            setLinksLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [typedText, text]);
    const syntaxHighlighterRef = useRef<SyntaxHighlighter>(null);
    return (
        <div className="mb-12 flex flex-col p-4 mb-10 shadow-md rounded-xl bg-background-light">
            <div className="flex flex-row">
                <div className="flex items-start mt-2 justify-center">
                    <ChatLogo icon={icon} className={`${type != "MISTRAL" ? "rounded-full" : "mt-1"}`} />
                </div>
                <div className="markdown flex flex-col text-sm pl-3 text-[#4A4A4A]">
                    {typed ? (
                        <ReactMarkdown
                            children={typedText}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw, rehypeSanitize]}
                            components={{
                                a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: linksLoading ? 'grey' : '', pointerEvents: linksLoading ? 'none' : 'auto' }} />
                            }}
                        />
                    ) : (
                        <ReactMarkdown
                            children={text.replace(/<br>/g, '\n\n')}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw, rehypeSanitize]}
                            components={{
                                a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" style={{ color: linksLoading ? 'grey' : '', pointerEvents: linksLoading ? 'none' : 'auto' }} />,
                                code(props) {
                                    const { children, className, node, ...rest } = props
                                    const match = /language-(\w+)/.exec(className || '')
                                    return match ? (
                                        <SyntaxHighlighter
                                            {...rest}
                                            PreTag="div"
                                            children={String(children).replace(/\n$/, '')}
                                            language={match[1]}
                                            style={oneDark}
                                            ref={syntaxHighlighterRef}
                                            showLineNumbers={true}
                                            wrapLines={true}
                                        />
                                    ) : (
                                        <code {...rest} className={className}>
                                            {children}
                                        </code>
                                    )
                                }
                            }}
                        />
                    )}
                    {
                        loading &&
                        <div className="">
                            <span className="relative flex h-3 w-3">
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                            </span>
                        </div>
                    }
                    {
                        table &&
                        <div>
                            {renderTable(table)}
                        </div>
                    }
                    {
                        graph &&
                        <Plot data={JSON.parse(graph).data} layout={{ title: '' }} />
                    }
                </div>
            </div>
            {ids.current.length > 0 &&
                <div className="flex flex-row w-full jusitfy-between items-start mt-2.5 gap-4">
                    {ids.current.map((id) => (
                        <ButtonRedirectSDH id={id} />
                    ))}
                </div>
            }
        </div>

    );
};

export const TextAnswerHomeSDHPage: React.FC<AnswerProps> = ({ type, text, typed, loading }) => {
    const icon = TypeToIcon[type]
    const [typedText, isLoading] = useTypingEffect(text, 25)

    return (
        <div className="mb-12 flex p-1 mb-8 shadow-md rounded-xl bg-[#0D3D620D]">
            <div className="flex items-start mt-2 justify-center">
                <ChatLogo icon={icon} className="rounded-full" />
            </div>
            <div className="markdown flex flex-col text-sm pl-3 text-[#4A4A4A]">
                {typed ? (
                    <Markdown remarkPlugins={[remarkGfm]}>{typedText}</Markdown>
                ) : (
                    <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
                )}
            </div>
        </div>

    );
};

export const TextAnswerError: React.FC<AnswerPropsError> = ({ type }) => {
    const icon = TypeToIcon[type]

    return (
        <div className="mb-12 flex p-5 mb-8 shadow-md rounded-xl bg-background-light">
            <div className="flex items-start">
                <ChatLogo icon={icon} className="rounded-full" />
            </div>
            <p className="flex text-sm pl-3 text-red-500 mt-1">An error has occurred</p>
        </div>

    );
};


export const LoaderTextAnswer: React.FC<LoaderTextAnswerProps> = ({ type }) => {
    const icon = TypeToIcon[type];

    return (
        <div className="mb-12 flex items-center p-5 mb-8 shadow-md rounded-xl bg-background-light">
            <ChatLogo icon={icon} className={`${type != "MISTRAL" ? "rounded-full" : "mt-1"}`} />
            <div className="ml-2">
                <span className="relative flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
                </span>
            </div>
        </div>
    );
};

export const HTMLAnswer: React.FC<HTMLAnswerProps> = ({ type, text, pluginActive }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const icon = TypeToIcon[type];
    const [typedTextHTML, isLoading] = useTypingEffect('Here is the newsletter! Click on the content to enlarge', 10)
    const ids = useRef(toolIdMapping[pluginActive] || [])


    // useEffect(()=>{
    //     console.log('the text', text)
    // },[text])


    const handleDownloadPDF = () => {
        const input = document.getElementById('down');

        const customWidth = 1000;
        const customHeight = 1000;

        html2canvas(input!, { scale: 2, width: customWidth, height: customHeight }) // Use a higher scale for better resolution
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 0.8); // Adjust the quality here (0.0 - 1.0)
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'a4',
                });

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const padding = 10;

                // Calculate positioning with padding
                const x = padding;
                const y = padding;
                const contentWidth = pdfWidth - padding;
                const contentHeight = pdfHeight - padding;

                // Calculate the best fit for the image in the PDF
                const imgAspectRatio = customWidth / customHeight;
                const pdfContentAspectRatio = contentWidth / contentHeight;
                let finalImgWidth, finalImgHeight;

                if (imgAspectRatio > pdfContentAspectRatio) {
                    // Image is wider than PDF content area
                    finalImgWidth = contentWidth;
                    finalImgHeight = contentWidth / imgAspectRatio;
                } else {
                    // Image is taller than PDF content area or same aspect ratio
                    finalImgWidth = contentHeight * imgAspectRatio;
                    finalImgHeight = contentHeight;
                }

                // Adjust x and y based on the final image size to center it
                const adjustedX = x + (contentWidth - finalImgWidth) / 6;
                const adjustedY = y + (contentHeight - finalImgHeight) / 6;

                pdf.addImage(imgData, 'JPEG', adjustedX, adjustedY, finalImgWidth, finalImgHeight);

                pdf.save('newsletter.pdf');
            });
    };


    const createMarkup = () => {
        return { __html: text };
    };

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div
                className={`mb-4 flex flex-col px-6 py-4 shadow-md rounded-xl bg-[#0D3D62] bg-opacity-5`}
            >
                <div className="flex items-center justify-start">
                    <ChatLogo icon={icon} />
                    <p className="flex text-sm pl-3 text-[#4A4A4A]">{typedTextHTML}</p>
                </div>
                <div className={`relative cursor-pointer pb-14 items-center bg-white my-2 rounded-xl max-h-48 overflow-hidden ${hovered && ' shadow-2xl'}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={handleOpenModal}
                >
                    <div className="p-5" dangerouslySetInnerHTML={createMarkup()} />
                    <div className="absolute bottom-0 right-0 p-1.5">
                        <SVGIcon size="20px" icon="SIZE_SCREEN_ICON" />
                    </div>
                </div>
                {ids.current.length > 0 &&
                    <div className="flex flex-row w-full jusitfy-between items-start mt-2.5 gap-4">
                        {ids.current.map((id) => (
                            <ButtonRedirectSDH id={id} />
                        ))}
                    </div>
                }
            </div>

            {isModalOpen && (
                <div
                    className="cursor-pointer fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-300 ease-in-out pt-6 pb-6"
                    onClick={handleCloseModal}
                >
                    <div
                        className="cursor-text bg-white p-16 max-w-5xl max-h-full overflow-auto transform transition-transform duration-300 ease-in-out rounded rounded-xl"
                        style={{
                            transform: isModalOpen ? 'scale(1)' : 'scale(0.5)'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div id="down" dangerouslySetInnerHTML={createMarkup()} />
                        {/* <button className="mt-4 p-2 text-white bg-blue-500 hover:bg-blue-700 rounded" onClick={handleDownloadPDF}>
                            Download Newsletter as PDF
                        </button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export const TrafficReportViewer = ({ text, bu }: { text: string, bu: string }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hovered, setHovered] = useState(false);

    const createMarkup = () => {
        return { __html: text };
    };

    const handleMouseOver = () => {
        setHovered(true);
    };

    const handleMouseOut = () => {
        setHovered(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className={`relative cursor-pointer items-center rounded-xl overflow-hidden ${hovered && 'shadow-2xl'}`}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={handleOpenModal}
            >
                <FileIcon
                    extension={bu}
                    type="document"
                    color="#3399B1"
                    labelColor="#3399B1"
                    gradientColor="#116B81"
                    labelTextColor="white"
                    foldColor="rgba(255,255,255,0.5)"
                    glyphColor="rgba(255,255,255,0.5)"
                />
            </div>

            {isModalOpen && (
                <div
                    className="cursor-pointer fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 transition-all duration-300 ease-in-out pt-6 pb-6"
                    onClick={handleCloseModal}
                >
                    <div
                        className="cursor-text bg-white p-16 max-w-5xl max-h-full overflow-auto transform transition-transform duration-300 ease-in-out rounded"
                        style={{
                            transform: isModalOpen ? 'scale(1)' : 'scale(0.5)'
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div id="down" dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                </div>
            )}
        </div>
    );
};