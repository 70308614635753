import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

import {
  fetchUsers,
  fetchUserRevisions,
  fetchLocations,
  fetchUserProfile,
} from "../api/userApi";
import {
  usersInterface,
  usersRevisionsInterface,
  userProfileInterface,
} from "../../../../types/adminManagementPages";
import { useAuth } from "../../../../providers/Auth";

interface UserDataAdminProviderContextType {
  setNotificationUser: React.Dispatch<
    React.SetStateAction<{
      idVINCI: string;
      creditAsked: number;
      message: string;
      updateDate: Date;
    }>
  >;
  notificationUser: {
    idVINCI: string;
    creditAsked: number;
    message: string;
    updateDate: Date;
  };
  setIsRequiredValue: React.Dispatch<React.SetStateAction<boolean>>;
  isRequiredValue: boolean;
  setErrorModal: React.Dispatch<
    React.SetStateAction<{ isOpen: boolean; errorMessage: string }>
  >;
  errorModal: { isOpen: boolean; errorMessage: string };
  handleModalErrorUser: () => void;
  loadindRevisions: boolean;
  setLoadindRevisions: React.Dispatch<React.SetStateAction<boolean>>;
  displayAlert: { text: string; alert: boolean };
  handleDisplayAlert: (text: string) => void;
  setEndLoading: React.Dispatch<React.SetStateAction<boolean>>;
  endLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  refreshCallUpn: () => Promise<void>;
  profile: userProfileInterface | undefined;
  setProfile: React.Dispatch<
    React.SetStateAction<userProfileInterface | undefined>
  >;
  data: usersInterface[];
  setData: React.Dispatch<React.SetStateAction<usersInterface[]>>;
  dataRevisions: usersRevisionsInterface[];
  setDataRevisions: React.Dispatch<
    React.SetStateAction<usersRevisionsInterface[]>
  >;
  copieData: usersInterface[];
  setCopieData: React.Dispatch<React.SetStateAction<usersInterface[]>>;
  createData: usersInterface[];
  setCreateData: React.Dispatch<React.SetStateAction<usersInterface[]>>;
  upnActive: string;
  setUpnActive: React.Dispatch<React.SetStateAction<string>>;
  allLocations: Record<string, string[]>;
  setAllLocations: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >;
  allUpn: string[];
  isCreateUser: boolean;
  handleChangementCopieData: (type: string, value: string) => void;
  handleUpnSelection: (upn: string) => void;
  handleResetCopieData: () => void;
  handleCreateUser: (dataUpn: usersInterface | undefined) => void;
  handleUpdateDataUsers: (dataUpn: usersInterface | undefined) => void;
  handleDeleteUser: (dataUpn: usersInterface | undefined) => void;
  handleCreateMultipleUsers: (tabDataUpn: usersInterface[]) => void;
}

const UserDataAdminProviderContext = createContext<
  UserDataAdminProviderContextType | undefined
>(undefined);

export const UserDataAdminProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [notificationUser, setNotificationUser] = useState<{
    idVINCI: string;
    creditAsked: number;
    message: string;
    updateDate: Date;
  }>({ idVINCI: "", creditAsked: 0, message: "", updateDate: new Date() });

  const [isRequiredValue, setIsRequiredValue] = useState<boolean>(false);
  const [displayAlert, setDisplayAlert] = useState({ text: "", alert: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [endLoading, setEndLoading] = useState<boolean>(false);
  const [dataRevisions, setDataRevisions] = useState<usersRevisionsInterface[]>(
    []
  );
  const [data, setData] = useState<usersInterface[]>([]);
  const [copieData, setCopieData] = useState<usersInterface[]>([]);
  const [createData, setCreateData] = useState<usersInterface[]>([]);
  const [upnActive, setUpnActive] = useState<string>("");
  const [allLocations, setAllLocations] = useState<Record<string, string[]>>(
    {}
  );
  const [profile, setProfile] = useState<userProfileInterface>();
  const [isCreateUser, setIsCreateUser] = useState<boolean>(false);
  const [loadindRevisions, setLoadindRevisions] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<{
    isOpen: boolean;
    errorMessage: string;
  }>({ isOpen: false, errorMessage: "" });
  const existingUpnSet = new Set(data.map((user) => user.upn));

  const allUpn = [
    ...data.map((user) => user.upn),
    ...createData
      .filter((user) => !existingUpnSet.has(user.upn)) // On exclut les doublons
      .map((user) => user.upn),
  ];

  const { token } = useAuth();

  useEffect(() => {
    if (upnActive === "") {
      setIsCreateUser(false);
      return;
    }
    if (createData.find((user) => user.upn === upnActive)) {
      setIsCreateUser(true);
    } else {
      setIsCreateUser(false);
    }
  }, [upnActive, createData]);

  const handleModalErrorUser = () => {
    setErrorModal({ isOpen: false, errorMessage: "" });
  };

  const handleDisplayAlert = (text: string) => {
    setDisplayAlert({ text: text, alert: true });
    setTimeout(() => {
      setDisplayAlert({ text: "", alert: false });
    }, 5000);
  };

  const getUsers = async () => {
    try {
      const users = await fetchUsers("/user_management/get_upns", token);
      if (!Array.isArray(users.upns)) {
        throw new Error("Les données reçues ne sont pas valides.");
      }
      setData(
        users.upns?.map((user: usersInterface) => {
          const cleanedUser = { ...user };

          // On parcourt uniquement les clés de type string de l'interface
          const stringKeys: (keyof usersInterface)[] = [
            "upn",
            "id",
            "idVINCI",
            "firstName",
            "lastName",
            "email",
            "businessUnit",
            "airport",
            "type",
            "adminEmail",
          ];

          stringKeys.forEach((key) => {
            if (cleanedUser[key] == null) {
              (cleanedUser[key as keyof usersInterface] as string) = "";
            }
          });

          return cleanedUser;
        })
      );
    } catch (err) {
      if (err instanceof Error) {
      } else {
      }
    }
  };

  const handleChangementCopieData = (type: string, value: string) => {
    const updateData = (
      dataArray: usersInterface[],
      setDataArray: React.Dispatch<React.SetStateAction<usersInterface[]>>
    ) => {
      setDataArray((prevData) =>
        prevData.map(
          (user) => {
            if (user.upn === upnActive) {
              if (type === "businessUnit") {
                if (allLocations[value].includes(user.airport)) {
                  return { ...user, [type]: value, airport: user.airport };
                } else {
                  return {
                    ...user,
                    [type]: value,
                    airport: "",
                  };
                }
              }
              return { ...user, [type]: value };
            }
            return user;
          }
          // user.upn === upnActive ? {...user, [type]: value }: user
        )
      );
    };

    if (isCreateUser) {
      updateData(createData, setCreateData);
    } else {
      updateData(copieData, setCopieData);
    }
  };

  const handleResetCopieData = () => {
    if (!upnActive) {
      setCopieData([]);
      return;
    }

    console.log("je passe ici 2");

    const resetUser = {
      upn: upnActive,
      id: "",
      idVINCI: "",
      firstName: "",
      lastName: "",
      email: "",
      businessUnit: "",
      airport: "",
      type: "Standard",
      adminEmail: "",
      isInAppDirectory: false,
      isInVinciAirportsDirectory: false,
    };

    if (isCreateUser) {
      setCreateData((prev) =>
        prev.map((user) => (user.upn === upnActive ? resetUser : user))
      );
      console.log("je passe ici 3");
    } else {
      console.log("je passe ici 4");
      const originalUser = data.find((user) => user.upn === upnActive);
      if (originalUser) {
        console.log("je passe ici 5");
        console.log("originalUser", originalUser);
        setCopieData((prev) =>
          prev.map((user) =>
            user.upn === upnActive ? { ...originalUser } : user
          )
        );
      }
    }
  };

  const handleCreateMultipleUsers = (tabDataUpn: usersInterface[]) => {
    for (let i = 0; i < tabDataUpn.length; i++) {
      handleCreateUser(tabDataUpn[i]);
    }
  };

  const handleCreateUser = (dataUpn: usersInterface | undefined) => {
    if (!dataUpn || dataUpn.upn === "") {
      return;
    }

    // Nettoyer les champs string qui pourraient contenir null/undefined
    const cleanedDataUpn: usersInterface = {
      ...dataUpn,
      upn: dataUpn.upn ?? "",
      id: dataUpn.id ?? "",
      idVINCI: dataUpn.idVINCI ?? "",
      firstName: dataUpn.firstName ?? "",
      lastName: dataUpn.lastName ?? "",
      email: dataUpn.email ?? "",
      businessUnit: dataUpn.businessUnit ?? "",
      airport: dataUpn.airport ?? "",
      type: dataUpn.type ?? "",
      adminEmail: dataUpn.adminEmail ?? "",
      isInAppDirectory: dataUpn.isInAppDirectory,
      isInVinciAirportsDirectory: dataUpn.isInVinciAirportsDirectory,
    };

    // Supprime l'entrée dans `createData` si présente
    setCreateData((prevCreateData) =>
      prevCreateData.filter((user) => user.upn !== cleanedDataUpn.upn)
    );

    // Met à jour ou ajoute dans `data`
    setData((prevData) => {
      const exists = prevData.find((user) => user.upn === cleanedDataUpn.upn);
      return exists
        ? prevData.map((user) =>
            user.upn === cleanedDataUpn.upn ? cleanedDataUpn : user
          )
        : [...prevData, cleanedDataUpn];
    });

    // Met à jour ou ajoute dans `copieData`
    setCopieData((prevCopieData) => {
      const exists = prevCopieData.find(
        (user) => user.upn === cleanedDataUpn.upn
      );
      return exists
        ? prevCopieData.map((user) =>
            user.upn === cleanedDataUpn.upn ? cleanedDataUpn : user
          )
        : [...prevCopieData, cleanedDataUpn];
    });
  };

  const handleUpdateDataUsers = (dataUpn: usersInterface | undefined) => {
    if (!dataUpn || upnActive === "") {
      setCopieData([]); // On nettoie si pas de upn actif ou pas de data
      return;
    }

    // On nettoie les champs string potentiellement nuls
    const cleanedDataUpn: usersInterface = {
      ...dataUpn,
      upn: dataUpn.upn ?? "",
      id: dataUpn.id ?? "",
      idVINCI: dataUpn.idVINCI ?? "",
      firstName: dataUpn.firstName ?? "",
      lastName: dataUpn.lastName ?? "",
      email: dataUpn.email ?? "",
      businessUnit: dataUpn.businessUnit ?? "",
      airport: dataUpn.airport ?? "",
      type: dataUpn.type ?? "",
      adminEmail: dataUpn.adminEmail ?? "",
      isInAppDirectory: dataUpn.isInAppDirectory,
      isInVinciAirportsDirectory: dataUpn.isInVinciAirportsDirectory,
    };

    const updateUserArray = (array: usersInterface[]) =>
      array.map((user) => (user.upn === upnActive ? cleanedDataUpn : user));

    setData(updateUserArray);
    setCopieData(updateUserArray);
  };

  const handleUpnSelection = (upn: string) => {
    setIsRequiredValue(false);
    const existingUser =
      data.find((user) => user.upn === upn) ||
      createData.find((user) => user.upn === upn);
    if (
      !existingUser?.isInAppDirectory &&
      createData.find((user) => user.upn === upn) === undefined
    ) {
      if (existingUser) {
        setCreateData((prev) => [...prev, existingUser]);
      }
    }

    if (existingUser) {
      setUpnActive(upn);
      if (!copieData.some((user) => user.upn === upn)) {
        setCopieData((prev) => [...prev, existingUser]);
      }
    } else {
      let businessUnit = "";
      let airport = "";
      if (profile) {
        if (profile.businessUnit) {
          businessUnit = profile.businessUnit;
          airport = profile.airport;
        }
      }

      const newUser = {
        upn,
        id: "",
        idVINCI: "",
        firstName: "",
        lastName: "",
        email: "",
        businessUnit: businessUnit,
        airport: airport,
        type: "Standard",
        adminEmail: "thomas.pical-ext@vinci-airports.com",
        isInAppDirectory: false,
        isInVinciAirportsDirectory: false,
      };
      setCreateData((prev) => [...prev, newUser]);
      setUpnActive(upn);
    }
  };

  // const handleDeleteUser = (dataUpn: usersInterface | undefined) => {
  //   if (dataUpn === undefined) {
  //     return;
  //   }

  //   console.log("dataUpnDelete", dataUpn);

  //   if (!createData.find((user) => user.upn === dataUpn.upn)) {
  //     setCreateData((prev) => [...prev, dataUpn]);
  //   } else {
  //     setCreateData((prev) => {
  //       return prev.map((user) => (user.upn === dataUpn.upn ? dataUpn : user));
  //     });
  //   }

  //   setData((prev) =>
  //     prev.map((user) => (user.upn === dataUpn.upn ? dataUpn : user))
  //   );
  //   setCopieData((prev) =>
  //     prev.map((user) => (user.upn === dataUpn.upn ? dataUpn : user))
  //   );
  // };

  const handleDeleteUser = (dataUpn: usersInterface | undefined) => {
    if (!dataUpn) return;

    console.log("dataUpnDelete", dataUpn);

    // Nettoyage éventuel des champs (comme dans les autres fonctions)
    const cleanedUser: usersInterface = {
      ...dataUpn,
      upn: dataUpn.upn ?? "",
      id: dataUpn.id ?? "",
      idVINCI: dataUpn.idVINCI ?? "",
      firstName: dataUpn.firstName ?? "",
      lastName: dataUpn.lastName ?? "",
      email: dataUpn.email ?? "",
      businessUnit: dataUpn.businessUnit ?? "",
      airport: dataUpn.airport ?? "",
      type: dataUpn.type ?? "",
      adminEmail: dataUpn.adminEmail ?? "",
      isInAppDirectory: dataUpn.isInAppDirectory,
      isInVinciAirportsDirectory: dataUpn.isInVinciAirportsDirectory,
    };

    // Mise à jour de createData
    setCreateData((prev) => {
      const exists = prev.find((user) => user.upn === cleanedUser.upn);
      return exists
        ? prev.map((user) =>
            user.upn === cleanedUser.upn ? cleanedUser : user
          )
        : [...prev, cleanedUser];
    });

    // Fonction utilitaire pour update propre
    const updateUserArray = (array: usersInterface[]) =>
      array.map((user) => (user.upn === cleanedUser.upn ? cleanedUser : user));

    setData(updateUserArray);
    setCopieData(updateUserArray);
  };

  useEffect(() => {
    const getLocations = async () => {
      try {
        const data = await fetchLocations(token);
        setAllLocations(data);
      } catch (err) {}
    };

    const getProfile = async () => {
      try {
        const profile = await fetchUserProfile(
          "/user_management/get_current_user_data",
          token
        );
        setProfile(profile);
      } catch (err) {}
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([getUsers(), getLocations(), getProfile()]);
      } catch (err) {
        console.error("Erreur lors de la récupération des données", err);
      } finally {
        setEndLoading(true);
        setTimeout(() => {
          setEndLoading(false);
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchData();
  }, []);

  const wait = async (ms: number) => {
    const lala = await new Promise((resolve) => setTimeout(resolve, ms));
    return lala;
  };

  useEffect(() => {
    if (upnActive === "") {
      setDataRevisions([]);
      return;
    }
    const dataUpn =
      copieData.find((user) => user.upn === upnActive) ||
      createData.find((user) => user.upn === upnActive);
    if (!dataUpn || !dataUpn.idVINCI) {
      setDataRevisions([]);
      return;
    }
    const getUserRevisions = async () => {
      await wait(3000);
      try {
        const revisions = await fetchUserRevisions(
          "/user_management/get_revisions/",
          dataUpn.idVINCI,
          token
        );
        setDataRevisions(revisions);
      } catch (err) {
        setDataRevisions([]);
        if (err instanceof Error) {
        } else {
        }
      }
    };
    const fetchData = async () => {
      try {
        setLoadindRevisions(true);
        await getUserRevisions();
      } catch (err) {
      } finally {
        setLoadindRevisions(false);
      }
    };
    fetchData();
  }, [upnActive, data]);

  const refreshCallUpn = async () => {
    await getUsers();
  };

  useEffect(() => {
    if (upnActive === "") {
      return;
    }
    const dataUpn = copieData.find((user) => user.upn === upnActive);
    if (!dataUpn || !dataUpn.idVINCI || !dataUpn.isInAppDirectory) {
      setNotificationUser({
        idVINCI: "",
        creditAsked: 0,
        message: "",
        updateDate: new Date(),
      });
      return;
    }
    const getUserGenAiCredit = async () => {
      try {
        const response = await fetch(
          "/api/admin/credits_management/get_user_credits_requests/" +
            dataUpn.idVINCI,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("responseGetGenAI", response);
        if (!response.ok) {
          throw new Error("Erreur de récupération des crédits.");
        }
        const data = await response.json();
        setNotificationUser(data);
        // setNotificationUser({
        //   idVINCI: dataUpn.idVINCI,
        //   creditAsked: 1000,
        //   message: "je t'ai demandé 1000 crédits",
        //   updateDate: new Date(),
        // });
      } catch (error) {
        setNotificationUser({
          idVINCI: "",
          creditAsked: 0,
          message: "",
          updateDate: new Date(),
        });
      }
    };
    getUserGenAiCredit();
  }, [upnActive]);

  return (
    <UserDataAdminProviderContext.Provider
      value={{
        setNotificationUser,
        notificationUser,
        isRequiredValue,
        setIsRequiredValue,
        errorModal,
        setErrorModal,
        handleModalErrorUser,
        loadindRevisions,
        setLoadindRevisions,
        handleCreateMultipleUsers,
        setEndLoading,
        endLoading,
        handleDisplayAlert,
        displayAlert,
        setIsLoading,
        isLoading,
        refreshCallUpn,
        profile,
        setProfile,
        data,
        setData,
        dataRevisions,
        setDataRevisions,
        copieData,
        setCopieData,
        createData,
        setCreateData,
        upnActive,
        setUpnActive,
        allLocations,
        setAllLocations,
        allUpn,
        isCreateUser,
        handleChangementCopieData,
        handleUpnSelection,
        handleResetCopieData,
        handleCreateUser,
        handleUpdateDataUsers,
        handleDeleteUser,
      }}
    >
      {children}
    </UserDataAdminProviderContext.Provider>
  );
};

export const useUserDataAdmin = () => {
  const context = useContext(UserDataAdminProviderContext);
  if (context === undefined) {
    throw new Error(
      "useUserDataAdmin must be used within a UserDataAdminProvider"
    );
  }
  return context;
};
