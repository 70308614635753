import Home from "../../../../assets/SVGIcon/icons/HomeSDH1.svg";

interface ModalLoadingProps {
  endLoading: boolean;
}

export default function ModalLoading({ endLoading }: ModalLoadingProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
      <div className=" text-slate-300 p-6 rounded-lg shadow-lg text-center">
        <section className="container-fluid min-vh-100" id="landing">
          <div className="centered-content">
            <div className="logo-container">
              <div className="loading-circle"></div>
              {endLoading ? (
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                  viewBox="0 0 124 124"
                >
                  <polyline
                    className="check"
                    points="73.56 48.63 57.88 72.69 49.38 62"
                    fill="none"
                    stroke="hsl(0, 0%, 100%)"
                    strokeWidth="6px"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <img className="logo" src={Home} alt="Logo" />
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
