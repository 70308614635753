import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
  Dispatch,
} from "react";
import { useAuth } from "../Auth";
import { userProfileInterface } from "../../types/adminManagementPages";

interface InfoUserContextType {
  hasRequestedCredit: boolean;
  handleHasRequestedCredit: (value: boolean) => void;
  getHasRequestedCredit: () => void;
  infoProfile?: userProfileInterface;
  upnFromNotificationToUser: string;
  setUpnFromNotificationToUser: Dispatch<React.SetStateAction<string>>;
}

const InfoUserContext = createContext<InfoUserContextType | undefined>(
  undefined
);

export const InfoUserProvider = ({ children }: { children: ReactNode }) => {
  const BASE_URL = "/api/admin";
  const [hasRequestedCredit, setHasRequestedCredit] = useState<boolean>(false);
  const [infoProfile, setInfoProfile] = useState<userProfileInterface>();
  const [upnFromNotificationToUser, setUpnFromNotificationToUser] =
    useState<string>("");

  const { token } = useAuth();

  const fetchUserProfile = async (path: string, token: any) => {
    try {
      const response = await fetch(BASE_URL + path, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        let errorMessage = `Erreur ${response.status}`;
        try {
          const errorData = await response.json();
          if (errorData.message) errorMessage = errorData.message;
        } catch (jsonError) {
          console.warn("Réponse non JSON, erreur probable du backend.");
        }
        throw new Error(errorMessage);
      }
      const data = await response.json();
      if (!data || typeof data !== "object") {
        throw new Error("Réponse inattendue du serveur");
      }

      return data;
    } catch (err) {
      console.error("Erreur API:", err);
      throw err;
    }
  };

  const getInfoProfile = async () => {
    try {
      const profile = await fetchUserProfile(
        "/user_management/get_current_user_data",
        token
      );
      setInfoProfile(profile);
    } catch (err) {}
  };

  const getHasRequestedCredit = async () => {
    // Call API to get hasRequestedCredit
    // uncomment when back ready
    try {
      const response = await fetch(
        "/api/admin/credits_management/get_current_user_credits_requests_status",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response admin credit_management", response);
      if (!response.ok) {
        throw new Error("getHasRequestedCredit error");
      }
      const data = await response.json();
      setHasRequestedCredit(data.isPending);
    } catch (error) {
      setHasRequestedCredit(false);
    }

    // setHasRequestedCredit(false);
  };

  const handleHasRequestedCredit = (value: boolean) => {
    setHasRequestedCredit(value);
  };

  useEffect(() => {
    getInfoProfile();
  }, []);

  return (
    <InfoUserContext.Provider
      value={{
        infoProfile,
        upnFromNotificationToUser,
        setUpnFromNotificationToUser,
        hasRequestedCredit,
        handleHasRequestedCredit,
        getHasRequestedCredit,
      }}
    >
      {children}
    </InfoUserContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte facilement
export const useInfoUser = (): InfoUserContextType => {
  const context = useContext(InfoUserContext);
  if (!context) {
    throw new Error("useInfoUser must be used within an InfoUserProvider");
  }
  return context;
};
