import React from "react";

interface TitleH2Props {
  text: string;
}

export default function TitleH2({ text }: TitleH2Props) {
  return (
    <h2 className="font-inter font-medium text-[18px] leading-[20px] text-black ">
      {text}
    </h2>
  );
}
