import React from "react";
import Home from "../../../assets/SVGIcon/icons/HomeSDH1.svg";

interface MiniLoaderProps {
  size: string;
}

export default function MiniLoader({ size }: MiniLoaderProps) {
  return (
    <div className="flex justify-center items-center">
      <div className="centered-content">
        <div style={{ width: size, height: size }}>
          <div className="loading-circle border-t-[#004289]"></div>
          <div
            className="flex justify-center items-center p-2"
            style={{ width: size, height: size }}
          >
            <img className="logo" src={Home} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
