import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { messageGPT } from '../../hooks';
import { Ask } from './Ask';
import { TextAnswer } from './Answer';
import { ChatLogo, ChatLogoType } from "../../assets/SVGIcon/ChatLogo";
import { LoaderTextAnswer } from './Answer';
import { TextAnswerError } from './Answer';



interface ChatConversationGPTProps {
    messages: messageGPT[];
    loadingChat: boolean;
    errorRequestGPT: boolean;
    currentModel: string;
}

interface TypeToIconProps {
    IA: ChatLogoType;
    GPT: ChatLogoType;
    GPT4: ChatLogoType;
    O1: ChatLogoType;
    MISTRAL: ChatLogoType;
    GEMINI: ChatLogoType;
    IA_HOME: ChatLogoType;
    IA_MULTIDOCS : ChatLogoType;
    IA_OAG: ChatLogoType;
    MULTIMODAL: ChatLogoType;
}

const TypeToIcon: TypeToIconProps = {
    IA: 'IA_CHAT_ICON',
    GPT: 'GPT_CHAT_ICON',
    GPT4: 'GPT4_CHAT_ICON',
    O1: 'GPT4_CHAT_ICON',
    MISTRAL: 'MISTRAL_CHAT_ICON',
    GEMINI: 'GEMINI_CHAT_ICON',
    IA_HOME: 'IA_CHAT_HOME_ICON',
    IA_MULTIDOCS : 'IA_CHAT_MULTIDOC',
    IA_OAG: 'IA_CHAT_OAG',
    MULTIMODAL: 'IA_CHAT_MULTIDOC',
}

interface AnswerProps {
    type: keyof typeof TypeToIcon
    text: string;
    typed?: boolean
    loading: boolean;
    graph?: string | null;
    table?: string | null;
}

const modelName:any = {
    'gpt':'GPT',
    'gpt4':'GPT4',
    'mistral':'MISTRAL',
    'gemini': 'GEMINI'
  }

export const ChatConversationGPT: React.FC<ChatConversationGPTProps> = ({ messages, loadingChat, errorRequestGPT, currentModel }) => {
    const [lastTextAnswerIndex, setLastTextAnswerIndex] = useState<number | null>(null);
    const prevMessagesCountRef = useRef<number>(messages.length);
    const endOfMessagesRef = useRef<HTMLDivElement>(null); // Ref for the end of the messages
    
    useEffect(() => {
        // Find the last TextAnswer message index
        const lastTextAnswer = messages.map((msg, index) => ({ type: msg.type, index })).reverse().find(msg => msg.type === 'TextAnswer');
        if (lastTextAnswer) {
            setLastTextAnswerIndex(lastTextAnswer.index);
        }

        // Reset prevMessagesCountRef after the effect runs
        return () => {
            prevMessagesCountRef.current = messages.length;
        };
    }, [messages]);

    useEffect(() => {
        // Scroll to the last message on messages update or initial load
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [messages.length]);
  

    return (
        <div className='bg-white w-11/12 p-4 rounded-xl min-h-[300px]' style={{ height: `calc(100vh - 275px)` }}>
            <div className="overflow-auto p-1 max-h-full scrollbar-hide" >
            {messages.map((messageObj, index) => {
                const isLastTextAnswer = messageObj.type === 'TextAnswer' && index === lastTextAnswerIndex;
                const isNewMessageAdded = messages.length > prevMessagesCountRef.current;

                let commonProps = {
                    ...messageObj.props,
                    typed: isLastTextAnswer && isNewMessageAdded && messageObj.props.text.length <= 200,
                };

                if (messageObj.type === 'TextAnswer') {
                    const textAnswerProps: AnswerProps = {
                        ...commonProps,
                        loading: messageObj.props.loading ? messageObj.props.loading : false,
                    };
                    return (
                        <React.Fragment key={index}>
                            <TextAnswer {...textAnswerProps} />
                        </React.Fragment>
                    );
                }
                else if(messageObj.type === 'TextAnswerError') {
                    return(
                        <React.Fragment key={index}>
                            <TextAnswerError type={modelName[currentModel]}/>
                        </React.Fragment>
                    )

                } else {
                    return (
                        <React.Fragment key={index}>
                            <Ask {...commonProps} />
                        </React.Fragment>
                    );
                }
            })}

                {loadingChat && <LoaderTextAnswer type={modelName[currentModel]}/>}
                <div ref={endOfMessagesRef} />
            </div>
        </div>
    );
};

