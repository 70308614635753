import React from "react";
import { SVGIcon } from "../../assets/SVGIcon";
import ButtonClassic from "../../pages/AdminManagement/components/elements/buttons/ButtonClassic";
import SideBarProgressBar from "./SideBarProgressBar";
import ModalSendGenAICredit from "./ModalSendGenAICredit";
import { useInfoUser } from "../../providers/InfoUser";

interface SideBarRequestCreditProps {
  isSidebarOpen: boolean;
  remainingCredit: number;
  sideBarClass: string;
}

export default function SideBarRequestCredit({
  isSidebarOpen,
  remainingCredit,
  sideBarClass,
}: SideBarRequestCreditProps) {
  const [isModalGenAiOpen, setIsModalGenAiOpen] = React.useState(false);

  const { hasRequestedCredit, infoProfile } = useInfoUser();

  const handleCloseModalSendGenAICredit = (value: boolean) => {
    setIsModalGenAiOpen(value);
  };

  //  remainingCredit = 100; // remove this line when back ready this is a value to test the component

  const pourcentReaminingCredit = parseInt(
    ((remainingCredit / (infoProfile?.creditLimit ?? 5000)) * 100).toFixed(2)
  );

  return (
    <div>
      {isSidebarOpen ? (
        <div className="rounded-lg mt-4 w-11/12 p-5 m-auto font-medium text-sm leading-4 text-[#344054] bg-[#F1F4F675]">
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-2">
              <p>GenAI Credit</p>
              {/* <img src={`${PATH_IMG}/helpIcon.svg`} alt="" /> */}
              <SVGIcon icon="HELP_ICON" size="17px" />
            </div>

            <p
              style={
                pourcentReaminingCredit <= 20
                  ? { color: "#f04438" }
                  : { color: "#3BD19F" }
              }
            >
              {Math.round(remainingCredit)}
            </p>
          </div>
          <div className="w-full mt-4">
            {remainingCredit && (
              <SideBarProgressBar
                progress={pourcentReaminingCredit}
                barHeight={10}
                backgroundColor={
                  pourcentReaminingCredit <= 20 ? "#f04438" : "#3BD19F"
                }
              />
            )}
          </div>
          {!hasRequestedCredit ? (
            <div>
              {pourcentReaminingCredit <= 20 ? (
                <p className="mt-3 font-normal text-sm leading-5 text-[#667085]">
                  Your has used {100 - pourcentReaminingCredit}% of your
                  available credits. Need more?{" "}
                </p>
              ) : null}
              <div className="mt-3">
                <div
                  onClick={() => {
                    setIsModalGenAiOpen(true);
                  }}
                >
                  <ButtonClassic width="118" height="36" text="Send request" />
                </div>
              </div>
            </div>
          ) : hasRequestedCredit ? (
            <p className="mt-3 font-normal text-sm leading-5 text-[#667085]">
              Your request is being reviewed.
            </p>
          ) : null}
        </div>
      ) : (
        <p
          style={
            pourcentReaminingCredit <= 20
              ? { color: "#f04438" }
              : { color: "#3BD19F" }
          }
          className="text-xs text-center font-inter"
        >
          {Math.round(remainingCredit)}
        </p>
      )}
      {isModalGenAiOpen && (
        <div>
          <ModalSendGenAICredit
            handleCloseModalSendGenAICredit={handleCloseModalSendGenAICredit}
            sideBarClass={sideBarClass}
          />
        </div>
      )}
    </div>
  );
}

// background: #3BD19F;
