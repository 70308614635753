import React from "react";
import { useState } from "react";
import ButtonSvgTex from "../elements/buttons/ButtonSvgText";
import DropdownSelect from "../elements/dropdown/DropdownSelect";
import TableAssignedUserList from "../elements/tables/TableAssignedUserList";
import ButtonSvgText from "../elements/buttons/ButtonSvgText";
import ModalHeader from "./ModalHeader";
import AddUserToThisListProduct from "../../AdminManagementDataAnalytics/AddUserToThisListProduct";
import ButtonClassic from "../elements/buttons/ButtonClassic";
import { PATH_IMG } from "../../../../constants/constant";

interface ModalUsersListProps {
  handleModalUsersList: () => void;
}

export default function ModalUsersList({
  handleModalUsersList,
}: ModalUsersListProps) {
  const [isModalAddUserToListProduct, setIsModalAddUserToListProduct] =
    useState<boolean>(false);
  const [listToDelete, setListToDelete] = useState<string[]>([]);

  const handleDelete = (name: string) => {
    if (listToDelete.includes(name)) {
      setListToDelete(listToDelete.filter((item) => item !== name));
    } else {
      setListToDelete([...listToDelete, name]);
    }
  };
  const handleModalAddUserToListProduct = () => {
    setIsModalAddUserToListProduct(!isModalAddUserToListProduct);
  };

  return (
    <div className="fixed  inset-0 bg-[#344054b2] backdrop-blur-lg z-30 flex items-center justify-center">
      <div className="font-inter relative  bg-white w-[928px] h-[653px] p-2 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
        <div className="m-auto  w-[880px] pt-3 pb-3   flex justify-between align-middle items-center">
          <div className=" flex gap-2 align-middle items-center">
            <img src={`${PATH_IMG}/timeRevision.svg`} alt="#" />
            <div>
              <p className="font-semibold text-lg leading-7 text-[#101828]">
                L1 Traffic
              </p>
              <p className="font-normal text-sm leading-5">
                Product's user list
              </p>
            </div>
          </div>
          <img
            onClick={handleModalUsersList}
            className="mb-3 cursor-pointer"
            src={`${PATH_IMG}/cross.svg`}
            alt="#"
          />
        </div>
        <div className="w-[882px] h-[537px] rounded-lg border border-[#EAECFO] m-auto">
          <div className="h-14 flex justify-between align-middle items-center p-4">
            <div className="flex gap-2 items-center align-middle">
              <h2 className="font-medium text-lg leading-7">
                Assigned user list
              </h2>
              <p className=" h-6 p-1 font-medium text-xs text-[#004289] leading-4 bg-[#f5f8fa] rounded-lg">
                2210 users
              </p>
            </div>
            <div className="relative">
              {listToDelete.length > 0 ? (
                <div className="flex gap-2 items-center">
                  <ButtonClassic text="Cancel" width="76" height="36" />
                  <ButtonClassic
                    text="Remove from product"
                    width="175"
                    height="36"
                    backgroundColor="#004289"
                  />
                </div>
              ) : (
                <div onClick={handleModalAddUserToListProduct}>
                  <ButtonSvgTex
                    width="238"
                    height="36"
                    logo={`${PATH_IMG}/userAdd.svg`}
                    text="Add user(s) to this product"
                  />
                </div>
              )}

              {isModalAddUserToListProduct && (
                <ModalHeader width="500" height="342" right="0">
                  <AddUserToThisListProduct />
                </ModalHeader>
              )}
            </div>
          </div>
          <div className="w-[850px] m-auto">
            {/* <DropdownSelect
              title=""
              widthSearchBar="820px"
              logo={`${PATH_IMG}/user.svg`}
              placeholderBar="Select user(s)"
              width="850px"
            /> */}
          </div>
          <TableAssignedUserList handleDeleteList={handleDelete} />
          {/** */}

          {/* {React.isValidElement(children)
            ? React.cloneElement(children as React.ReactElement<any>)
            : children} */}

          {/* ici j'utilise absolute pour bien mettre en bas la pagination sans me soucier de combien element j'ai */}

          <div className=" border-t   absolute bottom-6 left-1/2   transform -translate-x-1/2  w-[882px] flex justify-between align-middle items-center pl-6 pr-6 h-[68px] m-auto">
            <ButtonSvgText
              logo={`${PATH_IMG}/arrowLeft.svg`}
              text="Previous"
              width="114"
              height="36"
            />
            <div className="flex gap-4">
              <button className="h-10 w-10 text-[#004289] bg-[#0042890A] rounded-lg ">
                1
              </button>
              <button className="h-10 w-10">2</button>
              <button className="h-10 w-10">3</button>
              <p>...</p>
              <button className="h-10 w-10">8</button>
              <button className="h-10 w-10">9</button>
              <button className="h-10 w-10">10</button>
            </div>

            <ButtonSvgText
              logo={`${PATH_IMG}/arrowRight.svg`}
              text="Next"
              width="88"
              height="36"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
