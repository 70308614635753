import React, { useEffect, useState } from "react";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import { PATH_IMG } from "../../../constants/constant";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";
import { useAuth } from "../../../providers";

export default function UserLimitIncreaseNotification() {
  const [credit, setCredit] = useState<number>(0);
  const { notificationUser, setEndLoading, setIsLoading, setNotificationUser } =
    useUserDataAdmin();
  const { token } = useAuth();

  useEffect(() => {
    setCredit(notificationUser.creditAsked);
  }, [notificationUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0;
    if (value > 1000000000) {
      setCredit(1000000000);
      return;
    }
    setCredit(value);
  };

  const handleCancelNotification = async () => {
    notificationUser.creditAsked = credit;
    try {
      setIsLoading(true);
      const response = await fetch(
        "/api/admin/credits_management/cancel_credits_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(notificationUser),
        }
      );
      if (!response.ok) {
        throw new Error("Error");
      } else if (response.status !== 200) {
        throw new Error("Error");
      }
      setNotificationUser({
        idVINCI: "",
        creditAsked: 0,
        message: "",
        updateDate: new Date(),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleApproveNotification = async () => {
    notificationUser.creditAsked = credit;
    try {
      const response = await fetch(
        "/api/admin/credits_management/approve_credits_request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(notificationUser),
        }
      );
      if (!response.ok) {
        throw new Error("Error");
      } else if (response.status !== 200) {
        throw new Error("Error");
      }
      setNotificationUser({
        idVINCI: "",
        creditAsked: 0,
        message: "",
        updateDate: new Date(),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <div
      className="font-inter flex justify-around items-center bg-[#FFF4D6] p-4
]"
    >
      <img src={`${PATH_IMG}/doubleChevronUp.svg`} alt="#" />
      <div className="w-[410px] font-medium text-sm leading-4">
        <h3 className="text-[#344054]">
          <input
            className="w-fit appearance-none border-none outline-none focus:ring-0 shadow-sm rounded-md px-2 py-1 transition-all duration-200 ease-in-out focus:shadow-lg focus:translate-y-[-2px] bg-transparent"
            value={credit}
            onChange={handleChange}
            size={credit.toString().length || 1}
          />{" "}
          credits
        </h3>
        <p className="text-[#747C8A] mt-2">{notificationUser.message}</p>
      </div>
      <div onClick={handleCancelNotification}>
        <button className="text-[#667085] w-12 h-5">Cancel</button>
      </div>
      <div onClick={handleApproveNotification}>
        <ButtonClassic text="Approve" width="86" height="36" />
      </div>
    </div>
  );
}
