import React, { useState, useRef } from "react";

import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import useClickOutside from "../../../hooks/useClickOutside";
import { PATH_IMG } from "../../../constants/constant";
import { useAuth } from "../../../providers/Auth";
import {
  getTemplatePermissionFile,
  postSelectMultipleUsers,
} from "./api/permissionsApi";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

interface ModalCsvPermissionsProps {
  handleShowModal: (value: boolean) => void;
}

export default function ModalCsvPermissions({
  handleShowModal,
}: ModalCsvPermissionsProps) {
  const [dragOver, setDragOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [downloadModal, setDownloadModal] = useState<{
    isActive: boolean;
    file: Blob | null;
  }>({
    isActive: false,
    file: null,
  });

  const { token } = useAuth();

  const {
    handleSelectionUsersByResponseFile,
    handleSelectionUsersByResponseFileError,
  } = usePermissionsDataAdmin();

  const downloadFile = async (file: Blob, filename: string) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const downloadTemplateCrateMultiUser = async () => {
    try {
      const response = await getTemplatePermissionFile(
        "/permissions_management/get_template_select_multiple_users_csv",
        token
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers.get("content-disposition");
      const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
      let filename = filenameMatch
        ? filenameMatch[1]
        : "template-fillMultipleUser.csv";

      link.download = decodeURIComponent(filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setDragOver(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type === "text/csv") {
      if (file.size > 10 * 1024 * 1024) {
        return;
      }
      setIsUploading(true);
      setSelectedFile(file);
    } else {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      if (file.size > 10 * 1024 * 1024) {
        return;
      }
      setIsUploading(true);
      setSelectedFile(file);
    } else {
      setIsUploading(false);
      setSelectedFile(null);
    }
  };

  const handleUploadCreateMUltipleUser = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile); // C'est le seul champ attendu

    try {
      const response: any = await postSelectMultipleUsers(
        "/permissions_management/check_select_multiple_users_csv",
        formData,
        token
      );

      if (!response.ok) {
        throw new Error(
          `Upload failed: ${response.status} - ${response.statusText}`
        );
      }

      const result = await response.json();
      handleSelectionUsersByResponseFile(result.valid_users);
      handleSelectionUsersByResponseFileError(result.validation_errors);
      handleShowModal(false);
    } catch (error) {
      console.error("Erreur pendant l'envoi :", error);
    } finally {
    }
  };

  return (
    <div
      className={`flex justify-center absolute top-14 right-0 bg-white w-[575px] h-[213px] z-30 border border-[#f2f4f7] rounded-lg ${
        dragOver ? "border-blue-500 bg-blue-50" : ""
      }`}
      style={{ boxShadow: "0px 4px 6px -2px #10182808" }}
    >
      <label
        htmlFor="fileCsv"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="cursor-pointer absolute"
      >
        <input
          id="fileCsv"
          type="file"
          accept=".csv"
          className="absolute opacity-0"
          onChange={handleFileChange}
        />
        {!isUploading ? (
          <div className="flex flex-col justify-center text-center mt-4 w-[536px] h-[126px] rounded-lg border items-center gap-3">
            <img src={`${PATH_IMG}/browseFile.svg`} alt="#" />
            <div className="font-inter text-[#667085] font-normal text-sm leading-5">
              <p>
                <span className="text-[#004289] font-semibold">
                  Click to browse files
                </span>
                <span> or drag and drop</span>
              </p>
              <p>CSV (limit 10MB per file)</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col font-inter justify-center  mt-4 w-[536px] h-[126px] rounded-lg border items-center gap-3 bg-[#0fe48b4f]">
            <div className="text-[#0a291c]">
              <p>name of file: {selectedFile?.name}</p>
              <p>
                size of file:{" "}
                {selectedFile ? (selectedFile.size / 1024).toFixed(2) : "0"} Ko
              </p>
            </div>
            <div>
              <img src={`${PATH_IMG}/checkFile.svg`} alt="" />
            </div>
          </div>
        )}
      </label>

      <div className="font-inter absolute bottom-0 flex justify-between w-[536px] items-center p-4">
        <div onClick={() => downloadTemplateCrateMultiUser()}>
          <ButtonSvgText
            width="253"
            height="36"
            logo={`${PATH_IMG}/download.svg`}
            text="Download CSV template to fill"
          />
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => handleShowModal(false)}
            className="font-semibold text-sm leading-5 text-color[#667085]"
          >
            Cancel
          </button>
          <div onClick={() => handleUploadCreateMUltipleUser()}>
            <ButtonClassic
              text="Upload"
              width="100"
              height="36"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
