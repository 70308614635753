import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { PATH_IMG } from "../../../../../constants/constant";

interface InputWithLogoProps {
  dataEmail: string;
  title: string;
  handleChangementEmail: (value: string) => void;
}

function InputWithLogo({
  dataEmail,
  title,
  handleChangementEmail,
}: InputWithLogoProps) {
  const [email, setEmail] = useState<string>(dataEmail || "");

  useEffect(() => {
    if (email !== dataEmail) setEmail(dataEmail);
  }, [dataEmail]);

  const handleChangement = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      handleChangementEmail(e.target.value);
    },
    [handleChangementEmail]
  );

  return (
    <div className="w-[654px] h-[70px] font-inter  flex flex-col justify-between">
      <label className=" h-[26px] text-sm leading-5 font-medium block text-[#344054] mb-1">
        {title}
      </label>

      <div className="relative h-11">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <img src={`${PATH_IMG}/mail.svg`} alt="Email Icon" />
        </div>

        <input
          type="email"
          placeholder="Email"
          className="block w-full h-11 pl-10 pr-3  text-[#101828] font-normal text-base leading-6 rounded-md shadow-sm border focus:outline-none  focus:border-black"
          value={email || ""}
          onChange={handleChangement}
        />
      </div>
    </div>
  );
}

export default React.memo(InputWithLogo);
