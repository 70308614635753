import { useEffect, useState } from "react";
import { CloseSidebarButton } from "../../components";
import { useActiveTabs, useAuth, useCredit } from "../../providers";
import { SideBarActiveTabs } from "./SideBarActiveTabs";
import { SideBarLogo } from "./SideBarLogo";
import { SideBarMenu } from "./SideBarMenu";
import { SVGIcon } from "../../assets/SVGIcon";
import "./SideBar.css";
import { ScreenCapture } from "../../components/ScreenCapture";
import { BugReport } from "../../assets";
import { SideBarHistoryTabs } from "./SideBarHistoryTabs";
import { NewChatBtn } from "../../pages/ChatGPT/components";
import SideBarRequestCredit from "./SideBarRequestCredit";

interface InfoModalProps {
  isVisible: boolean;
  text: string;
  isSideBarOpen: boolean;
}

const InfoModal: React.FC<InfoModalProps> = ({
  isVisible,
  text,
  isSideBarOpen,
}) => {
  if (!isVisible || !isSideBarOpen) return null;
  return (
    <div className="absolute bottom-full w-[80%] mb-1 p-4 bg-white shadow-lg rounded text-sm border border-gray-300">
      {text}
    </div>
  );
};

export const SideBar = (props: {
  isSidebarOpen: boolean;
  toggleSidebar: (newValue?: boolean | undefined) => void;
  layoutSize: { open: string; close: string };
}) => {
  const { isSidebarOpen, toggleSidebar, layoutSize } = props;
  const sidebarClass = isSidebarOpen ? layoutSize.open : layoutSize.close;
  const { logout, tokenInfo } = useAuth();
  const email = tokenInfo?.email || "";
  const { remainingCredit } = useCredit();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const text =
    "You are entitled to 5000 credits per month of use of Generative AI applications. The Dashboard navigations does not reduce credits. If you have exceeded your credits, you can request additional credits at the following address: benoit.forest@vinci-concessions.com";
  const { closeAllTabs, tabState, menu } = useActiveTabs();
  const chatAccess =
    menu &&
    menu.length > 0 &&
    menu.find((e) => e.title == "SecuredGPT" || e.title == "ChatBot_SDH") !=
      undefined;

  return (
    <aside
      className={`${sidebarClass} h-screen fixed top-0 left-0 z-50 shadow-lg bg-primary-light border-r border-[#F6F6F6] rounded-r-lg`}
    >
      <CloseSidebarButton
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={`flex flex-col h-full pt-1 justify-between no-scrollbar pb-2 ${
          isSidebarOpen && "overflow-hidden overflow-y-scroll"
        }`}
      >
        <div className="flex-shrink-0">
          {" "}
          {/* Adjust height as needed */}
          <SideBarLogo isSidebarOpen={isSidebarOpen} />
          <hr className="mx-4 h-0.5 my-6 max-w-full  bg-gray-100 border-0 rounded" />
        </div>
        <div className="flex-shrink-0">
          <SideBarMenu isSidebarOpen={isSidebarOpen} />
        </div>
        <div className="flex-grow">
          {/*<SideBarActiveTabs isSidebarOpen={isSidebarOpen} />*/}
          {chatAccess && isSidebarOpen && tabState.activeTabId === 9 && (
            <>
              <hr className="mx-4 h-0.5 my-3 max-w-full bg-gray-100 border-0 rounded" />
              <div className="flex justify-between items-center px-6">
                <h3 className="text-sm text-ellipsis overflow-hidden text-text-secondary-light">
                  Chat History
                </h3>
                <NewChatBtn />
              </div>
              <hr className="mx-4 h-0.5 mt-3 max-w-full bg-gray-100 border-0 rounded" />
              <div className="max-h-96 overflow-y-auto">
                <SideBarHistoryTabs isSidebarOpen={isSidebarOpen} />
              </div>
              <hr className="mx-4 h-0.5 mb-6 max-w-full bg-gray-100 border-0 rounded" />
            </>
          )}
        </div>
        {isSidebarOpen && <ScreenCapture />}
        {/** Ajout raphael */}
        <SideBarRequestCredit
          isSidebarOpen={isSidebarOpen}
          remainingCredit={remainingCredit || 0}
          sideBarClass={sidebarClass}
        />
        {/** Ajout raphael*/}

        {/* <div className="flex flex-row items-center justify-center gap-0.5 relative">
          <InfoModal isVisible={isModalVisible} isSideBarOpen={isSidebarOpen} text={text} />
          <h4 className={`text-center ${isSidebarOpen ? "" : "text-xs"} ${remainingCredit && remainingCredit > 0 ? "text-emerald-500" : "text-[#f27405]"}`}>{isSidebarOpen ? "Credit: " : ''}
            {typeof remainingCredit === 'number' && Math.round(remainingCredit)}</h4>
          {isSidebarOpen && <div className="flex items-start justify-start h-full cursor-pointer">
            <div className="absolute top-0"
              onMouseEnter={() => setIsModalVisible(true)}
              onMouseLeave={() => setIsModalVisible(false)}
            >
              <SVGIcon icon="HELP_ICON" size="17px" />
            </div>
          </div>
          }
        </div> */}
        <div className="flex flex-col w-full mt-1 justify-center items-center">
          {/* {isSidebarOpen ?
            <div className="flex flex-row items-center jusitfy-left gap-3 p-2 w-[80%] bg-transparent border ring-[#F6F6F6] rounded cursor-pointer">
              <SVGIcon size="38px" icon="ENGLISH_ICON" />
              <p className="text-[#0D3D62] w-full text-sm font-medium">English</p>
              <SVGIcon size="25px" icon="LANGUAGE_ARROW_ICON" />
            </div>
            :
            <SVGIcon size="38px" icon="ENGLISH_ICON" />
          } */}
          <BugReport isSidebarOpen={isSidebarOpen} />
          <div
            onClick={() => {
              closeAllTabs();
              logout();
            }}
            className={`w-[85%] flex flex-row gap-1 overflow-hidden items-center ${
              isSidebarOpen ? "justify-center" : "justify-center"
            } p-2`}
          >
            <SVGIcon size="25px" icon="LOGOUT_ICON" />
            {isSidebarOpen && (
              <button className="items-center justify-center">
                <h2 className="text-[#D55F5A] font-medium text-md">
                  Logout Account
                </h2>
              </button>
            )}
          </div>
        </div>
      </div>
    </aside>
  );
};
