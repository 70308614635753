import React, { useCallback } from "react";
import UserTypeOption from "../components/elements/inputs/UserTypeOption";
import { PATH_IMG } from "../../../constants/constant";

interface UserTypeSelectionProps {
  valueExt: string;
  handleChangementExt: (value: string) => void;
}

export default function UserTypeSelection({
  valueExt,
  handleChangementExt,
}: UserTypeSelectionProps) {
  const handleOptionChange = useCallback(
    (value: string) => handleChangementExt(value),
    [handleChangementExt]
  );

  return (
    <div className="m-auto flex flex-col justify-around align-middle h-[277px] w-[726px]">
      <p className="ml-6 mt-5 font-inter font-medium text-sm leading-5 text-[#344054]">
        User type
      </p>
      <div className="m-auto w-[679px] h-[193px] flex justify-between">
        {[
          {
            logo: PATH_IMG + "/hugeiconsHonourStar.svg",
            title: "Standard",
            text: "The user type includes a set of permissions and 5,000/month GenAI credits",
          },
          {
            logo: PATH_IMG + "/hugeiconsHonourStar2.svg",
            title: "Premium",
            text: "The user type includes a set of permissions and 10,000/month GenAI credits",
          },
          {
            logo: PATH_IMG + "/proiconsDiamond.svg",
            title: "VIP",
            text: "The user type includes a set of permissions and 15,000/month GenAI credits",
          },
        ].map(({ logo, title, text }) => (
          <UserTypeOption
            key={title}
            logo={logo}
            title={title}
            text={text}
            isChecked={valueExt === title}
            onChange={() => handleOptionChange(title)}
          />
        ))}
      </div>
    </div>
  );
}
