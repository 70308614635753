import React, { useState, useEffect } from "react";
import AdminCalendar from "./AdminCalendar";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";

interface CalendarProps {
  dataDateCalendar: Date[];
  handleExterneData: (data: Date[], type: string) => void;
}

export default function Calendar({
  dataDateCalendar,
  handleExterneData,
}: CalendarProps) {
  const [selectedRange, setSelectedRange] = useState([
    dataDateCalendar[0],
    dataDateCalendar[1],
  ]);
  const [startDate, endDate] = selectedRange;
  const [type, setType] = useState("This Month");

  useEffect(() => {
    setType(determineType(selectedRange[0], selectedRange[1]));
  }, [selectedRange]);

  const normalizeDate = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const handleStartDate = (date: Date) => {
    if (date <= selectedRange[1]) {
      const newRange: [Date, Date] = [date, selectedRange[1]];
      setSelectedRange(newRange);
      setType(determineType(newRange[0], newRange[1]));
    } else {
      setSelectedRange([date, date]);
    }
  };

  const handleEndDate = (date: Date) => {
    if (date >= selectedRange[0]) {
      const newRange: [Date, Date] = [selectedRange[0], date];
      setSelectedRange(newRange);
      setType(determineType(newRange[0], newRange[1]));
    } else {
      setSelectedRange([date, date]);
    }
  };

  const getToday = (): [Date, Date] => {
    const today = new Date();
    return [today, today];
  };

  const getYesterday = (): [Date, Date] => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return [yesterday, yesterday];
  };

  const getThisWeek = (): [Date, Date] => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(
      today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );
    return [firstDayOfWeek, today];
  };

  const getLastWeek = (): [Date, Date] => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const firstDayOfLastWeek = new Date(today);
    firstDayOfLastWeek.setDate(
      today.getDate() - (dayOfWeek === 0 ? 13 : dayOfWeek + 6)
    );
    const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
    lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6);
    return [firstDayOfLastWeek, lastDayOfLastWeek];
  };

  const getThisMonth = (): [Date, Date] => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return [firstDayOfMonth, today];
  };

  const getThisYear = (): [Date, Date] => {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    return [firstDayOfYear, today];
  };

  const getLastYear = (): [Date, Date] => {
    const today = new Date();
    const firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
    const lastDayOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
    return [firstDayOfLastYear, lastDayOfLastYear];
  };

  const handleClear = () => {
    setSelectedRange([dataDateCalendar[0], dataDateCalendar[1]]);
  };

  const handleDateSelection = (value: string) => {
    let newRange: [Date, Date];

    switch (value) {
      case "Today":
        newRange = getToday();
        break;
      case "Yesterday":
        newRange = getYesterday();
        break;
      case "This week":
        newRange = getThisWeek();
        break;
      case "Last week":
        newRange = getLastWeek();
        break;
      case "This Month":
        newRange = getThisMonth();
        break;
      case "This Year":
        newRange = getThisYear();
        break;
      case "Last Year":
        newRange = getLastYear();
        break;
      default:
        return;
    }

    setSelectedRange(newRange);
    setType(determineType(newRange[0], newRange[1]));
  };

  const determineType = (start: Date, end: Date): string => {
    const today = normalizeDate(new Date());

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(
      today.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1)
    );

    const firstDayOfLastWeek = new Date(firstDayOfWeek);
    firstDayOfLastWeek.setDate(firstDayOfWeek.getDate() - 7);
    const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
    lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6);

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);

    const firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
    const lastDayOfLastYear = new Date(today.getFullYear() - 1, 11, 31);

    start = normalizeDate(start);
    end = normalizeDate(end);

    if (
      start.getTime() === today.getTime() &&
      end.getTime() === today.getTime()
    ) {
      return "Today";
    }
    if (
      start.getTime() === yesterday.getTime() &&
      end.getTime() === yesterday.getTime()
    ) {
      return "Yesterday";
    }
    if (
      start.getTime() === firstDayOfWeek.getTime() &&
      end.getTime() === today.getTime()
    ) {
      return "This Week";
    }
    if (
      start.getTime() >= firstDayOfLastWeek.getTime() &&
      end.getTime() <= lastDayOfLastWeek.getTime()
    ) {
      return "Last Week";
    }
    if (
      start.getTime() >= firstDayOfMonth.getTime() &&
      end.getTime() <= today.getTime()
    ) {
      return "This Month";
    }
    if (
      start.getTime() >= firstDayOfYear.getTime() &&
      end.getTime() <= today.getTime()
    ) {
      return "This Year";
    }
    if (
      start.getTime() >= firstDayOfLastYear.getTime() &&
      end.getTime() <= lastDayOfLastYear.getTime()
    ) {
      return "Last Year";
    }

    return "";
  };

  return (
    <div className="w-[743px] h-[400px] p-5 flex justify-between align- shadow-lg rounded-lg bg-white">
      <div className="w-122 h-[296px] flex flex-col justify-between">
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("Today")}
        >
          Today
        </p>
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("Yesterday")}
        >
          Yesterday
        </p>
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("This week")}
        >
          This week
        </p>
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("Last week")}
        >
          Last week
        </p>
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("This Month")}
        >
          This Month
        </p>
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("This Year")}
        >
          This Year
        </p>
        <p
          className="cursor-pointer hover:text-[#004289]"
          onClick={() => handleDateSelection("Last Year")}
        >
          Last Year
        </p>
      </div>
      <div className="w-[563px] h-[336px]">
        <div className="w-[563px] h-[276px] flex justify-between gap-4">
          <AdminCalendar
            selected={startDate}
            handleSelectedDate={handleStartDate}
          />
          <AdminCalendar
            selected={endDate}
            handleSelectedDate={handleEndDate}
          />
        </div>
        <div className="w-[563px] h-[44px] flex justify-end items-center mt-10 gap-4">
          <div onClick={handleClear}>
            <ButtonClassic text="Clear" width="103" height="44" />
          </div>

          <div
            onClick={() => {
              handleExterneData([...selectedRange], type);
            }}
          >
            <ButtonClassic
              text="Confirm"
              width="103"
              height="44"
              backgroundColor="#004289"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
