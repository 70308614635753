import React from "react";
import ButtonSvgText from "../elements/buttons/ButtonSvgText";
import { usersRevisionsInterface } from "../../../../types/adminManagementPages";
import { PATH_IMG } from "../../../../constants/constant";

interface ModalRevisionUserProps {
  handleRevisionUser: () => void;
  children: React.ReactNode;
  title: string;
  currentPage: number;
  dataRevisions: usersRevisionsInterface[] | any[];
  handleCurrentPage: (page: number, nbrOfPages: number) => void;
}

export default function ModalRevision({
  title,
  handleRevisionUser,
  children,
  currentPage,
  dataRevisions,
  handleCurrentPage,
}: ModalRevisionUserProps) {
  const nbrOfPages = Math.ceil(dataRevisions.length / 9);

  const getDisplayedPages = (): (number | string)[] => {
    const pages: (number | string)[] = [];

    if (nbrOfPages <= 6) {
      for (let i = 1; i <= nbrOfPages; i++) {
        pages.push(i);
      }
      return pages;
    }

    pages.push(1); // toujours le premier

    if (currentPage > 3) {
      pages.push("..."); // si on est loin du début
    }

    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(nbrOfPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (currentPage < nbrOfPages - 2) {
      pages.push("..."); // si on est loin de la fin
    }

    pages.push(nbrOfPages); // toujours le dernier

    return pages;
  };

  return (
    <div className="fixed inset-0 bg-[#344054b2] backdrop-blur-lg z-30 flex items-center justify-center">
      <div className="font-inter bg-white w-[928px] h-[653px] p-2 rounded-xl shadow-2xl overflow-hidden  overflow-x-hidden">
        <div className="m-auto w-[880px] pt-3 pb-3   flex justify-between align-middle items-center">
          <div className=" flex gap-2 align-middle items-center">
            <img src={`${PATH_IMG}/timeRevision.svg`} alt="#" />
            <div>
              <p className="font-semibold text-lg leading-7 text-[#101828]">
                {title}
              </p>
              <p className="font-normal text-sm leading-5 text-[#667085]">
                {title === "Permission"
                  ? "Permissions of user edits"
                  : "Revisions of user edits"}
              </p>
            </div>
          </div>
          <img
            onClick={handleRevisionUser}
            className="mb-3 cursor-pointer"
            src={`${PATH_IMG}/cross.svg`}
            alt="#"
          />
        </div>
        <div className="w-[882px] h-[537px] rounded-lg border border-[#EAECFO] m-auto">
          <div className="h-14 flex gap-2 align-middle items-center p-4">
            <h2 className="font-medium text-lg leading-7">Revisions</h2>
            <p className="w-7 h-7 font-medium text-xs text-[#004289] leading-4 rounded-full  bg-[#f5f8fa] flex justify-center items-center align-middle">
              {dataRevisions.length}
            </p>
          </div>

          {React.isValidElement(children)
            ? React.cloneElement(children as React.ReactElement<any>)
            : children}

          <div className="flex justify-between align-middle items-center px-10 py-5 w-[96%] m-auto">
            <div onClick={() => handleCurrentPage(currentPage - 1, nbrOfPages)}>
              <ButtonSvgText
                logo={`${PATH_IMG}/arrowLeft.svg`}
                text="Previous"
                width="114"
                height="36"
              />
            </div>

            <div className="flex gap-4">
              {getDisplayedPages().map((page, index) => {
                if (typeof page === "string") {
                  return (
                    <span
                      key={`ellipsis-${index}`}
                      className="h-10 w-10 flex items-center justify-center"
                    >
                      ...
                    </span>
                  );
                }

                return (
                  <button
                    key={page}
                    className={`h-10 w-10 rounded-lg ${
                      currentPage === page
                        ? "text-[#004289] bg-[#0042890A]"
                        : ""
                    }`}
                    onClick={() => handleCurrentPage(page, nbrOfPages)}
                  >
                    {page}
                  </button>
                );
              })}
            </div>

            <div onClick={() => handleCurrentPage(currentPage + 1, nbrOfPages)}>
              <ButtonSvgText
                logo={`${PATH_IMG}/arrowRight.svg`}
                text="Next"
                width="88"
                height="36"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
