import { useToggle } from "@uidotdev/usehooks";
import { Main } from "../Main";
import { SideBar } from "../SideBar";
import { useActiveTabs, useAuth, useTheme } from "../../providers";
import { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { termsAndConditions } from "../../CGU/TermsAndConditions";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./layout.css";

import { useInfoUser } from "../../providers/InfoUser";

export const wSideBarSize = { open: "w-64", close: "w-20" };

export const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [acceptEnabled, setAcceptEnabled] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [markdownContent, setMarkdownContent] = useState("");
  const mlSize = { open: "ml-64", close: "ml-20" };
  const { appTheme } = useTheme();
  const { verifyTokens, token } = useAuth();
  const seconds = 30;
  const days = seconds / (24 * 60 * 60);
  const { returnFav } = useActiveTabs();
  const [lst, setLst] = useState<string[]>([]);
  const aiFactoryApp = returnFav([
    1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009,
  ]);
  const { isSidebarOpen, toggleSidebar } = useActiveTabs();
  const { getHasRequestedCredit } = useInfoUser();

  useEffect(() => {
    verifyTokens();
    const cguAccepted = Cookies.get("CGUAccepted");
    if (!cguAccepted) {
      setIsModalOpen(true);
    }
    getHasRequestedCredit();
  }, []);

  const handleScroll = (): void => {
    if (!contentRef.current || !bottomRef.current) {
      return;
    }
    const bottomPosition = bottomRef.current.getBoundingClientRect().top;
    const screenPosition = window.innerHeight;
    if (bottomPosition <= screenPosition) {
      setAcceptEnabled(true);
    } else {
      setAcceptEnabled(false);
    }
  };

  const acceptCGU = (): void => {
    Cookies.set("CGUAccepted", "true", { expires: 365 });
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (aiFactoryApp.length > 0) {
      const tmp: string[] = [];
      aiFactoryApp.forEach((e) => {
        if (e.metadata) tmp.push(e.metadata);
      });

      if (JSON.stringify(tmp) !== JSON.stringify(lst)) {
        setLst(tmp);
      }
    }
  }, [aiFactoryApp, lst]);

  useEffect(() => {
    const awakeStreamlit = async () => {
      try {
        if (lst.length > 0) {
          await fetch(`/api/streamlit/ping`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(lst),
          });
        }
      } catch (error) {
        console.error("Error in awakeStreamlit:", error);
      }
    };

    awakeStreamlit();
  }, [lst]);

  return (
    <div
      id="tooltip-root"
      className={`flex h-screen ${appTheme} bg-background-light`}
    >
      {isModalOpen && (
        <div
          className="fixed backdrop-blur-md inset-0 z-9999 flex justify-center items-center"
          style={{ zIndex: 9999 }}
        >
          <div className="absolute inset-0" style={{}} />
          <div
            className={`border-2 ${
              acceptEnabled ? "border-emerald-500" : "border-gray-300"
            }  bg-white p-5 rounded-lg w-[50%] overflow-auto z-50`}
            style={{
              zIndex: 10000,
              animation: isModalOpen
                ? "scaleIn 0.6s ease-out forwards"
                : "none",
            }}
          >
            <div
              className="border text-sm border-white h-96 overflow-auto"
              onScroll={handleScroll}
              ref={contentRef}
            >
              <h2 className="text-lg font-bold mb-4">
                Please accept the Terms and Conditions.
              </h2>
              <Markdown remarkPlugins={[remarkGfm]}>
                {termsAndConditions}
              </Markdown>
              <div ref={bottomRef}></div>
            </div>
            <button
              className={`w-full mt-4 px-4 py-2 rounded ${
                acceptEnabled ? "bg-emerald-500" : "bg-gray-500"
              } text-white font-bold `}
              disabled={!acceptEnabled}
              onClick={acceptCGU}
            >
              Accept
            </button>
          </div>
        </div>
      )}
      <SideBar
        isSidebarOpen={isSidebarOpen}
        layoutSize={wSideBarSize}
        toggleSidebar={toggleSidebar}
      />
      <Main isSidebarOpen={isSidebarOpen} layoutSize={mlSize} />
    </div>
  );
};
