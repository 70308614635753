import React from "react";
import { useState } from "react";
import { usersRevisionsInterface } from "../../../../../types/adminManagementPages";
import {
  sortByDateTime,
  sortByAlphabeticalOrder,
} from "../../../../../utils/sortUtils";
import { PATH_IMG } from "../../../../../constants/constant";

interface TableRevisionUserEditProps {
  dataRevisions: usersRevisionsInterface[];
  currentPage: number;
}

export default function TableRevisionUserEdit({
  dataRevisions,
  currentPage,
}: TableRevisionUserEditProps) {
  const [sortDataRevisions, setSortDataRevisions] = useState(
    sortByDateTime(dataRevisions, "updateDate", "updateHour", "desc")
  );
  const [arrowDownDown, setArrowDownDown] = useState({
    date: true,
    action: false,
    updatedBy: false,
  });

  const handleArrowDown = (index: number) => {
    if (index === 1) {
      if (!arrowDownDown.date) {
        setSortDataRevisions(
          sortByDateTime(sortDataRevisions, "updateDate", "updateHour", "desc")
        );
      } else {
        setSortDataRevisions(
          sortByDateTime(sortDataRevisions, "updateDate", "updateHour", "asc")
        );
      }

      setArrowDownDown({
        date: !arrowDownDown.date,
        action: false,
        updatedBy: false,
      });
    } else if (index === 2) {
      if (!arrowDownDown.action) {
        setSortDataRevisions(
          sortByAlphabeticalOrder(sortDataRevisions, "actionType", "asc")
        );
      } else {
        setSortDataRevisions(
          sortByAlphabeticalOrder(sortDataRevisions, "actionType", "desc")
        );
      }

      setArrowDownDown({
        date: false,
        action: !arrowDownDown.action,
        updatedBy: false,
      });
    } else {
      if (!arrowDownDown.updatedBy) {
        setSortDataRevisions(
          sortByAlphabeticalOrder(sortDataRevisions, "updatedBy", "asc")
        );
      } else {
        setSortDataRevisions(
          sortByAlphabeticalOrder(sortDataRevisions, "updatedBy", "desc")
        );
      }

      setArrowDownDown({
        date: false,
        action: false,
        updatedBy: !arrowDownDown.updatedBy,
      });
    }
  };

  const startIndex = (currentPage - 1) * 9;
  const paginatedData: usersRevisionsInterface[] = sortDataRevisions.slice(
    startIndex,
    startIndex + 9
  );

  return (
    <table className="font-inter font-medium  w-full min-h-[415px]">
      <thead>
        <tr className="h-11 text-left text-xs leading-5 text-[#667085] bg-[#f9fafb]">
          <th
            className="pl-2 font-medium text-xs leading-5  text-left cursor-pointer w-[214px]"
            onClick={() => handleArrowDown(1)}
          >
            Date, time
            <img
              className="inline-block"
              src={
                arrowDownDown.date
                  ? PATH_IMG + "/arrowDown.svg"
                  : PATH_IMG + "/arrowUp.svg"
              }
              alt="#"
            />
          </th>
          <th
            className="pl-2 font-medium text-xs leading-5  text-left cursor-pointer w-[329px]"
            onClick={() => handleArrowDown(2)}
          >
            Action type
            <img
              className="inline-block"
              src={
                arrowDownDown.action
                  ? PATH_IMG + "/arrowDown.svg"
                  : PATH_IMG + "/arrowUp.svg"
              }
              alt="#"
            />
          </th>
          <th
            className="pl-2 font-medium text-xs leading-5  text-left cursor-pointer w-[329px]"
            onClick={() => handleArrowDown(3)}
          >
            Updated By
            <img
              className="inline-block"
              src={
                arrowDownDown.updatedBy
                  ? PATH_IMG + "/arrowDown.svg"
                  : PATH_IMG + "/arrowUp.svg"
              }
              alt="#"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {paginatedData.map((user, index) => (
          <tr key={index} className=" text-left text-xs leading-5 border ">
            <td className=" pl-2 h-10">
              <span className="font-medium text-sm leading-5 text-[#101828]">
                {user.updateDate}{" "}
              </span>
              <span className="ml-1 font-normal text-sm leading-5 text-[#747c8a]">
                {user.updateHour}
              </span>
            </td>
            <td className="pl-2 font-medium text-sm leading-5 text-[#101828] h-10">
              {/* <div className="h-10 max-h-10 overflow-y-auto"> */}
              {user.actionType}
              {/* </div> */}
            </td>
            <td className="pl-2 font-normal text-sm leading-5 text-[#747c8a] h-10">
              {user.updatedBy}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
