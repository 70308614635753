import React from "react";

interface SideBarProgressBarProps {
  progress: number;
  barHeight?: number;
  backgroundColor?: string;
}

export default function SideBarProgressBar({
  progress,
  barHeight,
  backgroundColor,
}: SideBarProgressBarProps) {
  return (
    <div
      className="progress-bar-container "
      style={{
        width: "100%",
        backgroundColor: "#E4EAEE",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          height: `${barHeight}px`,
          backgroundColor: backgroundColor ? backgroundColor : "#004289",
        }}
      ></div>
    </div>
  );
}
