import React from "react";
import { useState } from "react";
import ButtonSvgText from "../buttons/ButtonSvgText";
import ProgressBar from "../ProgressBar";
import ModalUsersList from "../../modals/ModalUsersList";
import { PATH_IMG } from "../../../../../constants/constant";

interface TableDataAnalyticsProps {
  category: string;

  data: {
    product?: string;
    name: string;
    users: string;
    activeUsers: string;
    avgEngagementTime: string;
    bounceRate: string;
    genAICreditUse?: string;
  }[];
}

export default function TableDataAnalytics({
  data,
  category,
}: TableDataAnalyticsProps) {
  const [isModalUsersList, setModalUsersList] = useState(false);

  const handleModalUsersList = () => {
    setModalUsersList(!isModalUsersList);
  };

  return (
    <div>
      <div className="w-full py-5 font-inter m-auto rounded-lg">
        <div className="rounded-lg overflow-hidden  border border-[#EAECF0]">
          <table className="w-full table-fixed border-collapse ">
            <thead className="sticky top-0 bg-gray-100 z-10">
              <tr className="text-left text-xs leading-5 text-gray-500 h-11 w-full">
                <th className="px-2 py-2 font-medium text-xs leading-5 cursor-pointer w-[270px]">
                  Product, Name
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/arrowDown.svg`}
                    alt="#"
                  />
                </th>
                <th className="px-2 py-2 cursor-pointer w-40 font-medium text-xs leading-5">
                  Users
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/arrowUp.svg`}
                    alt="#"
                  />
                </th>
                <th className="px-2 py-2 cursor-pointer w-52 font-medium text-xs leading-5">
                  Active Users
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/helpIcon.svg`}
                    alt="#"
                  />
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/arrowUp.svg`}
                    alt="#"
                  />
                </th>
                {category === "GenAI" && (
                  <th className="px-2 py-2 cursor-pointer w-40 font-medium text-xs leading-5">
                    GenAI Credits use
                    <img
                      className="pl-2 inline-block"
                      src={`${PATH_IMG}/arrowUp.svg`}
                      alt="#"
                    />
                  </th>
                )}
                <th className="px-2 py-2 cursor-pointer w-56 font-medium text-xs leading-5">
                  Avg. Engagement Time
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/helpIcon.svg`}
                    alt="#"
                  />
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/arrowUp.svg`}
                    alt="#"
                  />
                </th>
                <th className="px-2 py-2 cursor-pointer w-40 ">
                  Bounce Rate
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/helpIcon.svg`}
                    alt="#"
                  />
                  <img
                    className="pl-2 inline-block"
                    src={`${PATH_IMG}/arrowUp.svg`}
                    alt="#"
                  />
                </th>
                <th className="w-[94px]"></th>
                <th className="w-[144px]"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr
                  key={index}
                  className="h-9 text-left text-xs leading-5 border-b"
                >
                  <td className="px-2 py-2 flex gap-3 items-center align-middle">
                    <span className="font-normal text-sm leading-5 text-[#667085]">
                      {row.product}
                    </span>{" "}
                    <span className="font-medium text-sm leading-5 text-[#101828]">
                      {row.name}
                    </span>
                  </td>
                  <td className="px-2 py-2 font-medium text-sm leading-5 text-[#101828]">
                    {row.users}{" "}
                  </td>
                  <td className="px-2 py-2 font-medium text-sm leading-5 text-[#344054] flex gap-2 items-center">
                    <div className="h-[8px] w-9/12">
                      <ProgressBar
                        progress={parseFloat(row.activeUsers)}
                        barHeight={8}
                      />
                    </div>
                    <span>{row.activeUsers}</span>
                  </td>
                  {category === "GenAI" && (
                    <td className="px-2 py-2 font-medium text-sm leading-5 text-[#101828]">
                      {row.genAICreditUse}
                    </td>
                  )}
                  <td className="px-2 py-2 font-medium text-xs leading-[18px]  ">
                    {row.avgEngagementTime.includes("m") ? (
                      <span className="bg-[#ecfdf3] p-[5px] text-[#027a48] rounded-full">
                        {row.avgEngagementTime}
                      </span>
                    ) : (
                      <span className="bg-[#f2f4f7] p-[5px] text-[#344054] rounded-full">
                        {row.avgEngagementTime}
                      </span>
                    )}
                  </td>
                  <td className="px-2 py-2 font-medium text-xs leading-[18px]  ">
                    {parseFloat(row.bounceRate) <= 20 ? (
                      <span className="bg-[#ecfdf3] text-[#027a48] p-1 rounded-full">
                        {row.bounceRate}
                      </span>
                    ) : parseFloat(row.bounceRate) >= 70 ? (
                      <span className="bg-[#fef3f2] text-[#b42318] p-1 rounded-full">
                        {row.bounceRate}
                      </span>
                    ) : (
                      <span className="bg-[#f2f4f7] p-[5px] text-[#344054] rounded-full">
                        {row.bounceRate}
                      </span>
                    )}
                  </td>
                  <td onClick={handleModalUsersList}>
                    <ButtonSvgText
                      width="88"
                      height="30"
                      logo={`${PATH_IMG}/userList.svg`}
                      text="Users"
                    />
                  </td>
                  <td>
                    <ButtonSvgText
                      width="134"
                      height="30"
                      logo={`${PATH_IMG}/fileExport.svg`}
                      text="Export in CSV"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalUsersList && (
        <ModalUsersList
          handleModalUsersList={() => {
            handleModalUsersList();
          }}
        />
      )}
    </div>
  );
}
