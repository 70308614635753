import React from "react";
import { useState, useRef, useEffect } from "react";
import Cross from "../components/elements/formSvg/Cross";
import useClickOutside from "../../../hooks/useClickOutside";
import { PATH_IMG } from "../../../constants/constant";
import { usePermissionsDataAdmin } from "./context/PermissionsDataAdminProvider";

interface DropdownPermissionProps {
  title: string;
  handleUpdatable?: (value: boolean) => void;
  list: string[];
  // handleSelection: (upn: string) => void;
}

export default function DropdownPermissionUser({
  title,
  handleUpdatable,
  // handleSelection,
  list,
}: DropdownPermissionProps) {
  const {
    handleSelectionUsers,
    usersSelected,
    handleRemovePermissionToAddAndDeleteToOneUser,
  } = usePermissionsDataAdmin();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectMultipleUser, setSelectMultipleUser] = useState<string[]>(
    usersSelected.map((user) => user.upn) || []
  );

  useEffect(() => {
    setSelectMultipleUser(usersSelected.map((user) => user.upn) || []);
  }, [usersSelected]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  const filteredUsers = list.filter((user) =>
    user.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="block min-h-[70px]" ref={dropdownRef}>
      <div className="relative h-11 w-[654px] font-inter">
        <label
          htmlFor={title}
          className=" h-[20px] text-sm leading-5 font-medium block text-[#344054] mb-1"
        >
          {title}
        </label>
        <button
          id={title}
          onClick={() => setIsOpen(!isOpen)}
          className="font-inter w-full p-2 pr-3 flex items-center justify-between bg-white border border-[#d0d5dd] rounded-lg hover:bg-gray-50 "
          style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
        >
          <div className="text-[#101828]  w-[95%] flex gap-2 items-center font-normal text-base leading-6 overflow-x-auto">
            {selectMultipleUser.length === 0 ? (
              <p className="font-normal text-base leading-6 text-[#667085] pl-[10px]">
                please select user(s)
              </p>
            ) : (
              <p className="flex items-center justify-center h-[30px] w-[30px] rounded-md text-[#004289] bg-[#f5f7f9] text-center">
                {selectMultipleUser.length}
              </p>
            )}
            <div className="flex  gap-2 flex-nowrap">
              {selectMultipleUser.map((user, index) => (
                <div
                  key={index}
                  className="font-normal text-nowrap text-base leading-6 flex items-center gap-1 border rounded-md px-2 py-1 max-h-7"
                >
                  <p>{user}</p>{" "}
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      // faire ma fonction pour enlever les droits a mettre ici
                      handleRemovePermissionToAddAndDeleteToOneUser(user);
                      //
                      const updatedUsers = selectMultipleUser.filter(
                        (u) => u !== user
                      );
                      handleSelectionUsers(updatedUsers, "");
                      setSelectMultipleUser(updatedUsers);
                      if (updatedUsers.length === 0) {
                        handleUpdatable && handleUpdatable(false);
                      }
                    }}
                  >
                    <Cross width="14" height="14" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img src={`${PATH_IMG}/chevronDown.svg`} alt="Chevron Down" />
        </button>

        {isOpen && (
          <div className="absolute w-full h-[342px] mt-1 bg-white border rounded-md z-10">
            <div className="p-2 flex justify-between items-center">
              <div className="relative">
                <img
                  className="absolute left-2 top-3"
                  src={`${PATH_IMG}/search.svg`}
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Search"
                  className=" h-11 pl-8 pr-4 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ width: "630px" }}
                />
              </div>
            </div>
            <div className="max-h-60 overflow-y-auto">
              {filteredUsers.map((user, index) => (
                <button
                  key={index}
                  className="w-full p-2 flex items-center gap-2 hover:bg-gray-50 text-left"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleSelectionUsers([...selectMultipleUser, user], user);
                    setSelectMultipleUser((old: string[]) => {
                      if (old.includes(user)) {
                        return old;
                      }
                      return [...old, user];
                    });
                    const isUserSelected = selectMultipleUser.includes(user);
                    if (!isUserSelected) {
                      handleUpdatable && handleUpdatable(true);
                    }
                  }}
                >
                  {/* <User className="h-4 w-4 text-gray-500" /> */}
                  <div
                    className="flex items-center justify-between w-full p-2"
                    style={{
                      backgroundColor: selectMultipleUser.includes(user)
                        ? "#f9fafb"
                        : "",
                    }}
                  >
                    <div className="flex items-center  h-7">
                      <img src={`${PATH_IMG}/user.svg`} alt="#" />
                      <span className="text-[#101828] font-medium text-base leading-6 pl-[10px]">
                        {user}
                      </span>
                    </div>
                    {selectMultipleUser.includes(user) ? (
                      <img src={`${PATH_IMG}/checkBlue.svg`} alt="#" />
                    ) : null}
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
