import React from "react";
import { useState } from "react";
import ButtonSvgText from "../components/elements/buttons/ButtonSvgText";
import ButtonClassic from "../components/elements/buttons/ButtonClassic";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalRevisionUser from "../components/modals/ModalRevision";
import TableRevisionUserEdit from "../components/elements/tables/TableRevisionUserEdit";
import { usersInterface } from "../../../types/adminManagementPages";
import { createUser, postTransfertUser, updateUser } from "./api/userApi";
import { PATH_IMG } from "../../../constants/constant";
import { useUserDataAdmin } from "./context/UserDataAdminProvider";
import { useAuth } from "../../../providers/Auth";
import MiniLoader from "./MiniLoader";
import ModalTransfert from "./ModalTransfert";
import ModalErrorUser from "./ModalErrorUser";

interface UserManagementFooterProps {
  copieData: usersInterface[];
}

export default function UserManagementFooter({
  copieData,
}: UserManagementFooterProps) {
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
  const [showModalRevisionUser, setShowModalRevisionUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopupTransfer, setShowPopupTransfer] = useState({
    isOpen: false,
    dataError: {
      errorMessage1: "",
      errorMessage2: "",
      permissionsToDelete: [],
    },
  });

  const {
    setIsRequiredValue,
    loadindRevisions,
    handleDisplayAlert,
    handleCreateUser,
    handleDeleteUser,
    handleResetCopieData,
    handleUpdateDataUsers,
    setIsLoading,
    setEndLoading,
    setErrorModal,
    upnActive,
    dataRevisions,
    isCreateUser,
    handleModalErrorUser,
    errorModal,
  } = useUserDataAdmin();

  const { token } = useAuth();
  const dataUpn = copieData.find((user) => user.upn === upnActive);

  const handleModalDeleteUser = () => {
    setShowModalDeleteUser(!showModalDeleteUser);
  };

  const handleRevisionUser = () => {
    setShowModalRevisionUser(!showModalRevisionUser);
    setCurrentPage(1);
  };

  const handleCurrentPage = (page: number, nbrOfPages: number) => {
    if (page >= 1 && page <= nbrOfPages) {
      setCurrentPage(page);
    }
  };

  const handleUpdateUser = async () => {
    if (upnActive === "" || !dataUpn) return;

    try {
      setIsLoading(true);
      const data = await updateUser(
        "/user_management/update_user",
        dataUpn,
        token
      );

      // Met à jour l'état parent ou effectue une action après la mise à jour
      handleUpdateDataUsers(data.updated_user);
      handleDisplayAlert("User updated successfully");
    } catch (error) {
      if (error instanceof Error) {
      } else {
      }
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleCreate = async () => {
    if (upnActive === "" || !dataUpn) return;
    console.log("dataUpn", dataUpn);
    if (
      !dataUpn.businessUnit ||
      !dataUpn.airport ||
      dataUpn.businessUnit == "" ||
      dataUpn.businessUnit == "None" ||
      dataUpn.airport == "None" ||
      dataUpn.airport == ""
    ) {
      setIsRequiredValue(true);
      return;
    }
    setIsRequiredValue(false);

    try {
      setIsLoading(true);
      dataUpn.isInAppDirectory = true;
      console.log("je passe ici");
      const data = await createUser(
        "/user_management/create_user/",
        dataUpn,
        token
      );
      // Met à jour l'état parent ou effectue une action après la création
      handleCreateUser(data.created_user);
    } catch (error: any) {
      dataUpn.isInAppDirectory = false;
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error("Erreur lors de la création de l'utilisateur :", error);
        if (error && error.status === 418) {
          const dataError = {
            errorMessage1: error.dataError.detail.error_message_1,
            errorMessage2: error.dataError.detail.error_message_2,
            permissionsToDelete: error.dataError.detail.permissions_to_delete,
          };
          console.log("error", error);
          console.log("dataError", dataError);
          setShowPopupTransfer({
            isOpen: true,
            dataError: dataError,
          });
        }
      }
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleTransfertUser = async () => {
    if (upnActive === "" || !dataUpn) return;
    try {
      setIsLoading(true);
      const data = await postTransfertUser(
        "/user_management/transfer_user",
        dataUpn,
        token
      );
      handleDeleteUser(data.deleted_user);
      handleCreateUser(data.created_user);
    } catch (error) {
      console.error("Erreur lors de la transfert de l'utilisateur :", error);
    } finally {
      setEndLoading(true);
      setTimeout(() => {
        setEndLoading(false);
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleFirstPopup = (value: boolean) => {
    setShowPopupTransfer({ ...showPopupTransfer, isOpen: value });
  };

  const handleValidationPopup = () => {
    handleFirstPopup(false);
    handleTransfertUser();
    //   handleSecondPopup(true);
    // } else if (popup === 2) {
    //   handleSecondPopup(false);
  };

  return (
    <div className="flex justify-between w-[1464px] h-11 items-center">
      {loadindRevisions ? (
        <div>
          <MiniLoader size="40px" />
        </div>
      ) : dataRevisions.length > 0 ? (
        <div onClick={handleRevisionUser}>
          <ButtonSvgText
            text={`Revisions: ${dataRevisions.length}`}
            logo={`${PATH_IMG}/time.svg`}
            width="148"
            height="40"
          />
        </div>
      ) : (
        <div></div>
      )}

      <div className="flex gap-4">
        <div onClick={handleResetCopieData}>
          <ButtonClassic text="Reset" width="104" height="40" />
        </div>
        {isCreateUser ? (
          <div></div>
        ) : (
          <div
            onClick={() => {
              if (upnActive != "") handleModalDeleteUser();
            }}
          >
            <ButtonSvgText
              text="Delete"
              width="104"
              height="40"
              logo={`${PATH_IMG}/delete.svg`}
            />
          </div>
        )}
        {isCreateUser ? (
          <div onClick={handleCreate}>
            <ButtonClassic
              text="Add New User"
              width="124"
              height="40"
              backgroundColor="#004289"
            />
          </div>
        ) : (
          <div onClick={handleUpdateUser}>
            <ButtonSvgText
              text="Update"
              width="104"
              height="40"
              logo={`${PATH_IMG}/reload.svg`}
              backgroundColor="#004289"
            />
          </div>
        )}
      </div>
      {showModalDeleteUser && (
        <ModalDeleteUser
          handleModalDeleteUser={handleModalDeleteUser}
          handleDeleteUser={handleDeleteUser}
          isCreateUser={isCreateUser}
          title={upnActive}
          idVinci={dataUpn?.idVINCI || ""}
        />
      )}

      {showModalRevisionUser && (
        <ModalRevisionUser
          handleRevisionUser={handleRevisionUser}
          handleCurrentPage={handleCurrentPage}
          title={dataUpn?.firstName || " "}
          currentPage={currentPage}
          dataRevisions={dataRevisions}
        >
          <TableRevisionUserEdit
            dataRevisions={dataRevisions}
            currentPage={currentPage}
          />
        </ModalRevisionUser>
      )}

      {showPopupTransfer.isOpen ? (
        <ModalTransfert
          handleCloseModal={handleFirstPopup}
          handleValidationPopup={handleValidationPopup}
          dataError={showPopupTransfer.dataError}
        />
      ) : null}
      {errorModal.isOpen && (
        <ModalErrorUser
          handleModalErrorUser={handleModalErrorUser}
          errorMessage={errorModal.errorMessage}
        />
      )}
    </div>
  );
}
