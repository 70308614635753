import { usersInterface } from "../../../../types/adminManagementPages";

// const BASE_URL = "http://localhost:8000/api/admin";
const BASE_URL = "/api/admin";

export const fetchGenAiExpenses = async (
  path: string,
  upn: string,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path + upn, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 500) {
      return { amount: 0 };
    }
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    const result = await response.json();
    return result;
  } catch (err) {
    console.error("Erreur API fetchGenAiExpenses:", err);
    throw err;
  }
};

export const fetchUserProfile = async (path: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    const data = await response.json();
    if (!data || typeof data !== "object") {
      throw new Error("Réponse inattendue du serveur");
    }

    return data;
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const fetchUsers = async (path: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch (jsonError) {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    const data = await response.json();
    if (!data || typeof data !== "object" || !data.upns) {
      throw new Error("Réponse inattendue du serveur");
    }
    return data;
  } catch (err) {
    console.error("Erreur API:", err);
    throw err;
  }
};

export const fetchUserRevisions = async (
  path: string,
  idVINCI: string,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path + idVINCI, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    const result = await response.json();
    if (!result || typeof result !== "object" || !result.actions) {
      throw new Error("Réponse inattendue du serveur");
    }
    if (!Array.isArray(result.actions)) {
      throw new Error("Les données reçues ne sont pas valides.");
    }
    return result.actions;
  } catch (err) {
    console.error("Erreur API fetchUserRevisions:", err);
    throw err;
  }
};

export const deleteUser = async (path: string, idVinci: string, token: any) => {
  try {
    const response = await fetch(BASE_URL + path + idVinci, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (error) {
    console.error("Erreur API deleteUser:", error);
    throw error;
  }
};

export const getTemplateToCreateMultipleUsers = async (
  path: string,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/csv",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Erreur ${response.status}`);
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const postTransfertUser = async (
  path: string,
  dataUpn: usersInterface,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataUpn),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (
  path: string,
  dataUpn: usersInterface,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataUpn),
    });

    if (response.status === 418) {
      const dataError = await response.json();
      throw {
        status: 418,
        dataError: dataError,
      };
    }

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error("Erreur API createUser:", error);
    throw error;
  }
};

export const updateUser = async (
  path: string,
  dataUpn: usersInterface,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataUpn),
    });

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error("Erreur API updateUser:", error);
    throw error;
  }
};

export const postCreateMultipleUsers = async (
  path: string,
  formData: FormData,
  token: any
) => {
  try {
    const response = await fetch(BASE_URL + path, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchDataCharts = async (
  path: string,
  upn: string,
  dataDateCalendar: Date[],
  signal: AbortSignal,
  token: any
) => {
  try {
    if (dataDateCalendar.length < 2) {
      throw new Error("dataDateCalendar doit contenir deux dates.");
    }

    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    const url = `${BASE_URL}${path}${upn}/${formatDate(
      dataDateCalendar[0]
    )}/${formatDate(dataDateCalendar[1])}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
    });

    if (response.status === 500) {
      return { appNames: [], consumptionValues: [] };
    }

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (err) {
    if ((err as Error).name !== "AbortError") {
      console.error("Erreur API fetchDataCharts:", err);
    }
    throw err;
  }
};

export const fetchLocations = async (
  token: any
): Promise<Record<string, string[]>> => {
  try {
    const response = await fetch(`${BASE_URL}/user_management/get_locations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      let errorMessage = `Erreur ${response.status}`;
      try {
        const errorData = await response.json();
        if (errorData.message) errorMessage = errorData.message;
      } catch {
        console.warn("Réponse non JSON, erreur probable du backend.");
      }
      throw new Error(errorMessage);
    }

    const data = await response.json();

    if (!data.locations || typeof data.locations !== "object") {
      throw new Error("Données invalides reçues du serveur");
    }

    return data.locations;
  } catch (err) {
    console.error("Erreur API fetchLocations:", err);
    throw err;
  }
};
