import React from "react";

interface MiniTogleProps {
  isOn: boolean;
  handleToggle: () => void;
}

export default function MiniTogle({ isOn, handleToggle }: MiniTogleProps) {
  return (
    <div
      onClick={handleToggle}
      className={`w-9 h-5 bg-[#f2f4f7] rounded-full p-[1px] flex items-center cursor-pointer  ${
        isOn ? "justify-end" : "justify-start"
      }`}
    >
      <div
        className={`w-4 h-4  rounded-full shadow-md transform translate-x-0  ${
          isOn ? "bg-[#1ce09f]" : "bg-white"
        }`}
        style={{
          boxShadow: "0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A",
        }}
      ></div>
    </div>
  );
}
