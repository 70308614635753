import React from "react";

import ProgressSteps from "./ProgressSteps";

export default function ProgressContainer() {
  return (
    <div className="w-[1530px] h-[596px] bg-white flex justify-center items-center">
      <div className="w-[1461px] h-[522px] bg-[#F9FAFB] flex justify-center items-center">
        <ProgressSteps />
      </div>
    </div>
  );
}
