const data = [
  {
    product: "L1",
    name: "Traffic",
    users: "2210",
    activeUsers: "70%",
    avgEngagementTime: "1m 39s",
    bounceRate: "3.5%",
  },
  {
    product: "L2",
    name: "Traffic",
    users: "1944",
    activeUsers: "30%",
    avgEngagementTime: "34s",
    bounceRate: "65.3%",
  },
  {
    product: "L1",
    name: "OPS",
    users: "2301",
    activeUsers: "80%",
    avgEngagementTime: "1m 56s",
    bounceRate: "5.67%",
  },
  {
    product: "L2",
    name: "OPS",
    users: "1750",
    activeUsers: "40%",
    avgEngagementTime: "14s",
    bounceRate: "78.3%",
  },
  {
    product: "L1",
    name: "Retail",
    users: "1932",
    activeUsers: "70%",
    avgEngagementTime: "54s",
    bounceRate: "81.5%",
  },
  {
    product: "L2",
    name: "Retail",
    users: "3421",
    activeUsers: "60%",
    avgEngagementTime: "14s",
    bounceRate: "80.5%",
  },
];

export default data;
