const data = [
  {
    upn: "raphifou.lala@iniata.com",
    fullName: "Laura Bernt",
    userType: "Premium",
    businessUnit: "HQ",
    airport: "HQ",
  },
  {
    upn: "test2.lala@iniata.com",
    fullName: "test 2",
    userType: "Standard",
    businessUnit: "HQ",
    airport: "HQ",
  },
  {
    upn: "test3.lala@iniata.com",
    fullName: "test 3",
    userType: "Premium",
    businessUnit: "HQ",
    airport: "HQ",
  },
  {
    upn: "test4.lala@iniata.com",
    fullName: "test 4",
    userType: "Standard",
    businessUnit: "HQ",
    airport: "HQ",
  },
  {
    upn: "test5.lala@iniata.com",
    fullName: "tedt 5",
    userType: "VIP",
    businessUnit: "HQ",
    airport: "HQ",
  },
];

export default data;
