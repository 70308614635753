import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface AdminCalendarProps {
  selected: Date;
  handleSelectedDate: (date: Date) => void;
}

const AdminCalendar = ({
  selected,
  handleSelectedDate,
}: AdminCalendarProps) => {
  const today = new Date();

  const [currentDate, setCurrentDate] = useState(new Date(selected));
  const [selectedDate, setSelectedDate] = useState<Date>(selected);

  useEffect(() => {
    setCurrentDate(new Date(selected));
    setSelectedDate(selected);
  }, [selected]);

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const firstDayIndex = (firstDayOfMonth.getDay() + 6) % 7;
  const daysInMonth = lastDayOfMonth.getDate();

  const changeMonth = (offset: number) => {
    const newDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + offset,
      1
    );
    if (
      newDate.getFullYear() > today.getFullYear() ||
      (newDate.getFullYear() === today.getFullYear() &&
        newDate.getMonth() > today.getMonth())
    ) {
      return;
    }
    setCurrentDate(newDate);
  };

  const days = [];

  for (let i = 0; i < firstDayIndex; i++) {
    days.push(<div key={`empty-${i}`} className="p-2" />);
  }

  for (let day = 1; day <= daysInMonth; day++) {
    const dayDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    const isFuture = dayDate > today;
    const isSelected =
      selectedDate && dayDate.toDateString() === selectedDate.toDateString();

    days.push(
      <div
        key={day}
        className={`flex items-center justify-center p-2 border rounded-lg cursor-pointer transition
          ${isSelected ? "bg-[#004289] text-white" : "hover:bg-gray-100"}
          ${isFuture ? "opacity-50 cursor-not-allowed" : ""}
        `}
        onClick={() => {
          if (!isFuture) {
            setSelectedDate(dayDate);
            handleSelectedDate(dayDate);
          }
        }}
      >
        {day}
      </div>
    );
  }

  const disableNext =
    currentDate.getFullYear() === today.getFullYear() &&
    currentDate.getMonth() === today.getMonth();

  return (
    <div className="bg-white h-[276px] w-80">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => changeMonth(-1)}
          className="p-2 rounded-full hover:bg-gray-200 transition"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        <h2 className="text-lg font-semibold">
          {currentDate.toLocaleString("en-US", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button
          onClick={() => changeMonth(1)}
          className={`p-2 rounded-full transition ${
            disableNext ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-200"
          }`}
          disabled={disableNext}
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      <div className="grid grid-cols-7 text-gray-500 font-medium mb-2">
        {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day, index) => (
          <div
            key={index}
            className="flex items-center justify-center p-2 text-sm"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">{days}</div>
    </div>
  );
};

export default AdminCalendar;
