import React from 'react';
import { useState, useEffect, ReactElement } from 'react';
import { templatePrompts } from '../../../utils/sdhDataTemplate';
import { useAuth } from '../../../providers';
import { TypeToIcon } from '../components/Answer';
import { v4 as uuidv4 } from 'uuid';
import { ChatModel } from './chatGPTModel';
import { useSessionId } from '../../../hooks';
import { UploadFileType } from './useMultiDocsConfig';

interface PromptDefinition {
    definition: string;
    prompt_template: string;
}

export interface ThemePrompts {
    [theme: string]: {
        [key: string]: PromptDefinition;
    };
}

export type LanguageCode = 'FR' | 'EN' | 'PT' | 'ES' | 'JA';
export type ModelGPT = 'gpt' | 'gpt4' | 'mistral' | 'gemini' | "o1";

const modelName: any = {
    'gpt': 'GPT',
    'gpt4': 'GPT4',
    'mistral': 'MISTRAL',
    'gemini': 'GEMINI'
}

export type ConversationMessage = {
    data_name: { filename: string; type: UploadFileType; }[] | null;
    data_type: "text" | "file_ask" | "file_upload" | "text_info" | "text_switch" | "html" | "multidocs_text";
    role: "user" | "assistant" | "error" | "info";
    content: string;
    model: ChatModel | "ia_multidocs" | null;
    user?: string;
    assistant?: string;
    info?: string;
    error?: string;
    loading?: boolean;
    preview?: string;
};

export type SessionData = {
    session_id: string;
    title: string;
    data_name: string | null;
    data_type: string;
    conversation: ConversationMessage[];
    timestamp: string;
    total_pages: number;
    additional_time: number;
};

export type DataHistory = {
    [key: string]: SessionData;
};

export interface SecuredGPTConfig {
    errorGPT: Error | null;
    suggestedPrompts: ThemePrompts;
    contextGPT: string;
    error: Error | null;
    languageGPT: LanguageCode;
    fetchConfig: (url: string) => Promise<void>;
    securedGPTHistory: DataHistory;
    selectedChatId: string;
    setSelectedChatId: (chatId: string) => void;
    setSecuredGPTHistory: React.Dispatch<React.SetStateAction<DataHistory>>;
    setContext: (context: string) => Promise<null | undefined>;
    mDModeActive: boolean;
    setMDModeActive: React.Dispatch<React.SetStateAction<boolean>>;
    contextGPTLoader: boolean;
}

export const useChatGPTConfig = (): SecuredGPTConfig => {
    const { token, logout } = useAuth()
    const [errorGPT, setErrorGPT] = useState<Error | null>(null);

    const [contextGPT, setContextGPT] = useState<string>('')
    const [error, setError] = useState<Error | null>(null);
    const [suggestedPrompts, setSuggestedPrompts] = useState<ThemePrompts>(templatePrompts);
    const [securedGPTHistory, setSecuredGPTHistory] = useState<DataHistory>({});
    const [newChatId, setNewChatId] = useState<string>('');
    const [selectedChatId, setSelectedChatId] = useState<string>('');

    const [languageGPT, setLanguageGPT] = useState<LanguageCode>('EN')

    const multiDocsSessionId = useSessionId(`/api/multi_doc/clear_session`);
    const [mDModeActive, setMDModeActive] = useState<boolean>(false);

    const [contextGPTLoader, setContextGPTLoader] = useState<boolean>(false)

    const fetchConfig = async (url: string) => {
        try {
            if (token === null) {
                setErrorGPT(null);
                return;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            setContextGPT(data.context)
            setSuggestedPrompts(data.suggested_prompts)
            setLanguageGPT(data.language)
            if (data.conv)
                setSecuredGPTHistory(prevHistory => ({ ...prevHistory, ...data.conv }));
        } catch (error) {
            console.error('Erreur lors de la récupération de la configuration', error);
            setError(error instanceof Error ? error : new Error('Erreur inconnue'));
        }
    };

    const setContext = async (context: string) => {
        setContextGPTLoader(true)
        try {
            const response = await fetch('/api/secured_gpt/context', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: "include",
                body: JSON.stringify({
                    context: context,
                }),
            })

            const data = await response.json()
            setContextGPT(data.context)
            setTimeout(function () {
                setContextGPTLoader(false)
            }, 1000);
        }
        catch (error) {
            console.log(error)
            setContextGPTLoader(false)
            return null
        }
    }

    useEffect(() => {
        const newSessionId = uuidv4();
        setNewChatId(newSessionId);
        const date = new Date();
        const isoString = date.toISOString();
        const formattedDate = isoString.replace(/\.\d{3}Z$/, '+00:00');
        setSecuredGPTHistory(prevHistory => ({
            ...prevHistory,
            [newSessionId]: {
                session_id: newSessionId,
                title: "New chat...",
                data_name: null,
                data_type: 'text',
                conversation: [],
                timestamp: formattedDate,
                total_pages: 0,
                additional_time: 0
            }
        }));
        setSelectedChatId(newSessionId)
        try {
            fetchConfig(`/api/secured_gpt/config`);
        } catch (error) {
            logout();
        }
    }, [])

    return {
        errorGPT,
        suggestedPrompts,
        contextGPT,
        error,
        languageGPT,
        fetchConfig,
        securedGPTHistory,
        selectedChatId,
        setSelectedChatId,
        setSecuredGPTHistory,
        setContext,
        mDModeActive,
        setMDModeActive,
        contextGPTLoader
    };
};

