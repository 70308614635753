import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import SearchInput from "../inputs/SearchInput";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { PATH_IMG } from "../../../../../constants/constant";
import useClickOutsideMultiple from "../../../../../hooks/useClickOutsideMultiple";

interface DropdownSelectProps {
  title: string;
  widthSearchBar: string;
  logo?: string;
  placeholderBar?: string;
  width: string;
  handleSelectOneValue?: (value: string) => void;
  selectedValue: string;
  data: string[];
  withSearchBar?: boolean;
}

export default function DropdownSelect({
  placeholderBar,
  title,
  widthSearchBar,
  logo,
  width,
  handleSelectOneValue,
  selectedValue,
  data,
  withSearchBar,
}: DropdownSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ left: 0, top: 0, width: 0 });

  useClickOutsideMultiple([dropdownRef, buttonRef], () => setIsOpen(false));

  // Fonction pour recalculer la position du dropdown
  const updatePosition = useCallback(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX,
        top: rect.bottom + window.scrollY,
        width: rect.width,
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      updatePosition();
      window.addEventListener("resize", updatePosition);
      window.addEventListener("scroll", updatePosition);
    }

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
    };
  }, [isOpen, updatePosition]);

  const handleSearchQuery = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const filteredUsers = data.filter((user) =>
    user.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log("filteredUsers", filteredUsers);

  const dropdownContent = (
    <div
      ref={dropdownRef}
      className="absolute mt-1 bg-white rounded-md z-20"
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: position.width,
      }}
    >
      {withSearchBar == undefined && (
        <div className="p-1 flex justify-center items-center">
          <SearchInput
            width={widthSearchBar}
            handleSearchQuery={handleSearchQuery}
          />
        </div>
      )}
      <div className="max-h-60 overflow-y-auto">
        {filteredUsers.map((elem, index) => (
          <button
            key={index}
            className="w-full h-11 pl-6 p-2 flex items-center gap-2 hover:bg-gray-50 text-left"
            style={{
              backgroundColor: selectedValue === elem ? " #F9FAFB" : "",
            }}
            onClick={() => {
              handleSelectOneValue && handleSelectOneValue(elem as string);
              setIsOpen(false);
            }}
          >
            {logo ? (
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2">
                  <img className="h-5" src={logo} alt="Logo" />
                  <span>{elem}</span>
                </div>
                {selectedValue === elem && (
                  <img
                    className="h-5"
                    src={`${PATH_IMG}/checkBlue.svg`}
                    alt="Selected"
                  />
                )}
              </div>
            ) : (
              <div className="flex items-center justify-between w-full">
                <span>{elem}</span>
                {selectedValue === elem && (
                  <img
                    className="h-5"
                    src={`${PATH_IMG}/checkBlue.svg`}
                    alt="Selected"
                  />
                )}
              </div>
            )}
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <div className="relative font-inter" style={{ width }}>
      <label className="text-sm font-medium block text-[#344054] mb-1">
        {title}
      </label>
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-2 pr-3 flex items-center justify-between bg-white border rounded-md hover:bg-gray-50"
      >
        <span className="text-[#101828] font-normal text-base">
          {selectedValue || placeholderBar || "Select"}
        </span>
        <img src={`${PATH_IMG}/chevronDown.svg`} alt="Expand" />
      </button>

      {isOpen &&
        ReactDOM.createPortal(
          <div
            className="absolute"
            style={{
              left: position.left,
              top: position.top,
              width: position.width,
            }}
          >
            {dropdownContent}
          </div>,
          document.body
        )}
    </div>
  );
}

// import React from "react";
// import { useState } from "react";
// import SearchInput from "../inputs/SearchInput.tsx";

// interface DropdownSelectProps {
//   title: string;
//   widthSearchBar: string;
//   logo?: string;
//   placeholderBar?: string;
// }

// export default function DropdownSelect({
//   placeholderBar,
//   title,
//   widthSearchBar,
//   logo,
// }: DropdownSelectProps) {
//   const [isOpen, setIsOpen] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedData, setSelectedData] = useState("");

//   const handleSearchQuery = (searchQuery: string) => {
//     setSearchQuery(searchQuery);
//   };

//   const data = [
//     { dashboard: "trafic" },
//     { dashboard: "retail" },
//     { dashboard: "OPS" },
//   ];

//   const filteredUsers = data.filter((user) =>
//     user.dashboard.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <div className=" relative font-inter" style={{ width: widthSearchBar }}>
//       <label
//         htmlFor="lala"
//         className=" text-sm leading-5 font-medium block text-[#344054] mb-1"
//       >
//         {title}
//       </label>
//       <button
//         id="lala"
//         onClick={() => setIsOpen(!isOpen)}
//         className="w-full p-2 pr-3 flex items-center justify-between bg-white border rounded-md hover:bg-gray-50"
//       >
//         <span className="text-[#101828] font-normal text-base leading-6">
//           {selectedData || placeholderBar || "Select"}
//         </span>
//         <img src="images/svg/chevronDown.svg" alt="#" />
//       </button>

//       {/*  */}
//       {isOpen && (
//         <div
//           className="absolute w-full  mt-1 bg-white  rounded-md z-10"
//           style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
//         >
//           <div className="p-1 flex justify-center items-center">
//             <SearchInput
//               width={widthSearchBar}
//               handleSearchQuery={handleSearchQuery}
//             />
//           </div>
//           <div className="max-h-60 overflow-y-auto">
//             {filteredUsers.map((elem, index) => (
//               <button
//                 key={index}
//                 className="w-full pl-6 p-2 flex items-center gap-2 hover:bg-gray-50 text-left"
//                 onClick={(e) => {
//                   setSelectedData(Object.values(data[index])[0]);
//                 }}
//               >
//                 {logo ? (
//                   <div className="flex justify-between items-center  w-full">
//                     <div className="flex items-center gap-2">
//                       <div className="h-11">
//                         <img src={logo} alt="#" />
//                       </div>

//                       <div className="h-11">
//                         {Object.values(data[index])[0]}
//                       </div>
//                     </div>
//                     <div className="h-11">
//                       {selectedData === Object.values(data[index])[0] ? (
//                         <img src="images/svg/checkBlue.svg" alt="#" />
//                       ) : null}
//                     </div>
//                   </div>
//                 ) : (
//                   <div className="flex items-center justify-between w-full">
//                     <div className="h-11">{Object.values(data[index])[0]}</div>
//                     <div className="h-11">
//                       {selectedData === Object.values(data[index])[0] ? (
//                         <img src="images/svg/checkBlue.svg" alt="#" />
//                       ) : null}
//                     </div>
//                   </div>
//                 )}
//               </button>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// {
//   /* {logo ? <img src={logo} alt="" /> : null} */
// }
