import React, { useState, useCallback } from "react";
import { PATH_IMG } from "../../../../../constants/constant";

interface UserTypeOptionProps {
  logo: string;
  title: string;
  text: string;
  isChecked: boolean;
  onChange: (value: string) => void;
}

function UserTypeOption({
  logo,
  title,
  text,
  isChecked,
  onChange,
}: UserTypeOptionProps) {
  const handleChange = useCallback(() => onChange(title), [onChange, title]);
  return (
    <div className="font-inter">
      <label
        htmlFor={title}
        className={`relative flex cursor-pointer flex-col rounded-lg border 
                 p-4 hover:bg-accent w-[215px] h-[193px] bg-white ${
                   isChecked ? " border-[#004289]" : ""
                 }`}
      >
        <input
          type="radio"
          id={title}
          name="userType"
          value={title}
          className="opacity-0 absolute h-0 w-0"
          onChange={handleChange}
          checked={isChecked}
        />
        <div className="absolute top-3 right-3">
          <img
            src={
              isChecked ? PATH_IMG + "/check.svg" : PATH_IMG + "/notCheck.svg"
            }
            alt={isChecked ? "Checked" : "Unchecked"}
          />
        </div>
        <div className="bg-blue-100 rounded-full p-2 w-10">
          <img src={logo} alt="#" />
        </div>
        <p className="mt-4 font-medium text-sm leading-5 text-[#101828]">
          {title}
        </p>
        <p className="mt-1 text-sm leading-5 font-normal text-[#667085]">
          {text}
        </p>
      </label>
    </div>
  );
}

export default React.memo(UserTypeOption);
